// Autogenerated
import { AppTranslateFuncType } from "./TranslationHelper";
import { IRequestProxy } from "./RequestHelper";
import { AuthenticationResponseViewModel } from "../models/viewModels/AuthenticationResponseViewModel";
import { GenelDurumViewModel } from "../models/viewModels/GenelDurumViewModel";
import { GeriIzlemeViewModel } from "../models/viewModels/GeriIzlemeViewModel";
import { IcerikListViewModel } from "../models/viewModels/IcerikListViewModel";
import { IcerikMetaViewModel } from "../models/viewModels/IcerikMetaViewModel";
import { OneriSarkiViewModel } from "../models/viewModels/OneriSarkiViewModel";
import { OtpViewModel } from "../models/viewModels/OtpViewModel";
import { AnketModel } from "../models/businessModels/AnketModel";
import { CalismaModel } from "../models/businessModels/CalismaModel";
import { DuyuruModel } from "../models/businessModels/DuyuruModel";
import { HataModel } from "../models/businessModels/HataModel";
import { IcerikModel } from "../models/businessModels/IcerikModel";
import { KoroAyarlariModel } from "../models/businessModels/KoroAyarlariModel";
import { KullaniciModel } from "../models/businessModels/KullaniciModel";
import { RolModel } from "../models/businessModels/RolModel";
import { SarkiModel } from "../models/businessModels/SarkiModel";
import { SesGrupModel } from "../models/businessModels/SesGrupModel";
import { SezonModel } from "../models/businessModels/SezonModel";
import { TarzModel } from "../models/businessModels/TarzModel";
import { TatilModel } from "../models/businessModels/TatilModel";
import { AnketAcKapatRequestModel } from "../models/requestModels/AnketAcKapatRequestModel";
import { AnketSonucGonderRequestModel } from "../models/requestModels/AnketSonucGonderRequestModel";
import { GeriIzlemeAraRequestModel } from "../models/requestModels/GeriIzlemeAraRequestModel";
import { GuidRequestModel } from "../models/requestModels/GuidRequestModel";
import { HataAraRequestModel } from "../models/requestModels/HataAraRequestModel";
import { HesapBilgiVeKullaniciWithContentRequestModel } from "../models/requestModels/HesapBilgiVeKullaniciWithContentRequestModel";
import { HesapCalismaKatilimDurumGuncelleRequestModel } from "../models/requestModels/HesapCalismaKatilimDurumGuncelleRequestModel";
import { HesapCalismaYoklamaBildirRequestModel } from "../models/requestModels/HesapCalismaYoklamaBildirRequestModel";
import { HesapCalismaYoklamaListBildirRequestModel } from "../models/requestModels/HesapCalismaYoklamaListBildirRequestModel";
import { HesapEgitmenYorumEkleGuncelleRequestModel } from "../models/requestModels/HesapEgitmenYorumEkleGuncelleRequestModel";
import { HesapKayitSesGrupGuncelleRequestModel } from "../models/requestModels/HesapKayitSesGrupGuncelleRequestModel";
import { HesapKayitYorumlaRequestModel } from "../models/requestModels/HesapKayitYorumlaRequestModel";
import { HesapKuartetDurumGuncelleRequestModel } from "../models/requestModels/HesapKuartetDurumGuncelleRequestModel";
import { HesapKuartetListDurumGuncelleRequestModel } from "../models/requestModels/HesapKuartetListDurumGuncelleRequestModel";
import { HesapSarkiGonderRequestModel } from "../models/requestModels/HesapSarkiGonderRequestModel";
import { HesapYorumEkleGuncelleRequestModel } from "../models/requestModels/HesapYorumEkleGuncelleRequestModel";
import { KadroEkleGuncelleRequestModel } from "../models/requestModels/KadroEkleGuncelleRequestModel";
import { KonserModelWithContentRequestModel } from "../models/requestModels/KonserModelWithContentRequestModel";
import { KoroVeHesapWithContentRequestModel } from "../models/requestModels/KoroVeHesapWithContentRequestModel";
import { KullaniciProfilSifreGuncelleRequestModel } from "../models/requestModels/KullaniciProfilSifreGuncelleRequestModel";
import { KullaniciProfilSifreSilRequestModel } from "../models/requestModels/KullaniciProfilSifreSilRequestModel";
import { LoginRequestModel } from "../models/requestModels/LoginRequestModel";
import { OneriSarkiEkleRequestModel } from "../models/requestModels/OneriSarkiEkleRequestModel";
import { OneriSarkiGuncelleRequestModel } from "../models/requestModels/OneriSarkiGuncelleRequestModel";
import { OneriSarkiReddetYorumlaRequestModel } from "../models/requestModels/OneriSarkiReddetYorumlaRequestModel";
import { OtpAraRequestModel } from "../models/requestModels/OtpAraRequestModel";
import { OtpSifreGuncellemeRequestModel } from "../models/requestModels/OtpSifreGuncellemeRequestModel";
import { OzgemicGuncelleRequestModel } from "../models/requestModels/OzgemicGuncelleRequestModel";
import { SarkiLinkEkleGuncelleRequestModel } from "../models/requestModels/SarkiLinkEkleGuncelleRequestModel";
import { SarkiLinkListEkleGuncelleRequestModel } from "../models/requestModels/SarkiLinkListEkleGuncelleRequestModel";
import { SarkiYorumEkleGuncelleRequestModel } from "../models/requestModels/SarkiYorumEkleGuncelleRequestModel";
import { SarkiZorlukEkleGuncelleRequestModel } from "../models/requestModels/SarkiZorlukEkleGuncelleRequestModel";
import { SecmeBasvuruProfilGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruProfilGuncelleRequestModel";
import { SecmeBasvuruSecmeNotuGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruSecmeNotuGuncelleRequestModel";
import { SecmeBasvuruSonucRequestModel } from "../models/requestModels/SecmeBasvuruSonucRequestModel";
import { SecmeBasvuruTarihGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruTarihGuncelleRequestModel";
import { SecmeBasvuruWithContentRequestModel } from "../models/requestModels/SecmeBasvuruWithContentRequestModel";
import { SecmeKatilimBildirmeDurumGuncelleRequestModel } from "../models/requestModels/SecmeKatilimBildirmeDurumGuncelleRequestModel";
import { SesGrupTopluSiraNoGuncelleRequestModel } from "../models/requestModels/SesGrupTopluSiraNoGuncelleRequestModel";
import { SezonAdaySarkiGuncelleRequestModel } from "../models/requestModels/SezonAdaySarkiGuncelleRequestModel";
import { SifreGuncellemeRequestModel } from "../models/requestModels/SifreGuncellemeRequestModel";
import { TaslakKayitiOnaylaRequestModel } from "../models/requestModels/TaslakKayitiOnaylaRequestModel";
import { TaslakKayitSilRequestModel } from "../models/requestModels/TaslakKayitSilRequestModel";

export function RequestEndpoints(requestProxy: IRequestProxy, translate: AppTranslateFuncType) {
    return {
        Genel: {
            "IcerikListGetir": (model: string[]) => requestProxy.PerformPostAction<IcerikListViewModel>(`api/genel/icerikListGetir`, model, translate(
                /* PRESERVE BEGIN: Genel-IcerikListGetir */
                "İçerikler getiriliyor"
                /* PRESERVE END: Genel-IcerikListGetir */
            )),
            "HC": () => requestProxy.PerformGetAction(`api/genel/hC`, undefined, translate(
                /* PRESERVE BEGIN: Genel-HC */
                "Sağlık Durumu Getiriliyor"
                /* PRESERVE END: Genel-HC */
            )),
            "IcerikGetir": (model: string) => requestProxy.PerformGetAction<IcerikModel>(`api/genel/icerikGetir?icerikId=${model}`, undefined, translate(
                /* PRESERVE BEGIN: Genel-IcerikGetir */
                "İçerikler getiriliyor"
                /* PRESERVE END: Genel-IcerikGetir */
            )),
            "IcerikGetirPDF": (model: string) => requestProxy.PerformGetAction<IcerikModel[]>(`api/genel/icerikGetirPDF?icerikId=${model}`, undefined, translate(
                /* PRESERVE BEGIN: Genel-IcerikGetirPDF */
                "PDF indiriliyor"
                /* PRESERVE END: Genel-IcerikGetirPDF */
            )),
            "IcerikMetaListGetir": (model: string[]) => requestProxy.PerformPostAction<IcerikMetaViewModel[]>(`api/genel/icerikMetaListGetir`, model, translate(
                /* PRESERVE BEGIN: Genel-IcerikMetaListGetir */
                "İçerik metaları getiriliyor"
                /* PRESERVE END: Genel-IcerikMetaListGetir */
            )),
            "GenelDurumGetir": () => requestProxy.PerformPostAction<GenelDurumViewModel>(`api/genel/genelDurumGetir`, null, translate(
                /* PRESERVE BEGIN: Genel-GenelDurumGetir */
                "Genel durum sorgulanıyor"
                /* PRESERVE END: Genel-GenelDurumGetir */
            )),
            "HataGetir": (model: HataAraRequestModel) => requestProxy.PerformPostAction<HataModel[]>(`api/genel/hataGetir`, model, translate(
                /* PRESERVE BEGIN: Genel-HataGetir */
                "Hata aranıyor"
                /* PRESERVE END: Genel-HataGetir */
            )),
            "GeriIzlemeGetir": (model: GeriIzlemeAraRequestModel) => requestProxy.PerformPostAction<GeriIzlemeViewModel[]>(`api/genel/geriIzlemeGetir`, model, translate(
                /* PRESERVE BEGIN: Genel-GeriIzlemeGetir */
                "Geri izlemeler aranıyor"
                /* PRESERVE END: Genel-GeriIzlemeGetir */
            )),
            "OtpGetir": (model: OtpAraRequestModel) => requestProxy.PerformPostAction<OtpViewModel[]>(`api/genel/otpGetir`, model, translate(
                /* PRESERVE BEGIN: Genel-OtpGetir */
                "OTP aranıyor"
                /* PRESERVE END: Genel-OtpGetir */
            )),
            "OtpTemizle": (model: number) => requestProxy.PerformPostAction(`api/genel/otpTemizle`, model, translate(
                /* PRESERVE BEGIN: Genel-OtpTemizle */
                "OTPler temizleniyor"
                /* PRESERVE END: Genel-OtpTemizle */
            )),
            /* PRESERVE BEGIN: Additional-Genel-Calls */
            "IcerikGetirDirect": (id: string, extension: string, title: string) => requestProxy.PerformGetFileAction(`Icerik/file-${id}${extension}`, undefined, translate(
                "DosyaIsim Yükleniyor", { dosyaIsim: title }
            )),
            "IcerikGetirNoMessage": (id: string) => requestProxy.PerformGetAction<IcerikModel>(`api/genel/icerikGetir?icerikId=${id}`, undefined, ""),
            "IcerikGetirCustomMessage": (model: string, dosyaIsim: string) => requestProxy.PerformGetAction<IcerikModel>(`api/genel/icerikGetir?icerikId=${model}`, undefined, translate(
                "DosyaIsim Yükleniyor", { dosyaIsim: dosyaIsim }
            )),
            /* PRESERVE END: Additional-Genel-Calls */
        },
        Korist: {
            "HesapCalismaKatilimDurumGuncelle": (model: HesapCalismaKatilimDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/korist/hesapCalismaKatilimDurumGuncelle`, model, translate(
                /* PRESERVE BEGIN: Korist-HesapCalismaKatilimDurumGuncelle */
                "Çalışmaya katılım durumu bildiriliyor"
                /* PRESERVE END: Korist-HesapCalismaKatilimDurumGuncelle */
            )),
            "TaslakKayitSil": (model: TaslakKayitSilRequestModel) => requestProxy.PerformPostAction(`api/korist/taslakKayitSil`, model, translate(
                /* PRESERVE BEGIN: Korist-TaslakKayitSil */
                "Taslak kayıt siliniyor"
                /* PRESERVE END: Korist-TaslakKayitSil */
            )),
            "HesapSarkiGonder": (model: HesapSarkiGonderRequestModel) => requestProxy.PerformPostAction(`api/korist/hesapSarkiGonder`, model, translate(
                /* PRESERVE BEGIN: Korist-HesapSarkiGonder */
                "Kayıt gönderiliyor"
                /* PRESERVE END: Korist-HesapSarkiGonder */
            )),
            "OzgecmisGuncelle": (model: OzgemicGuncelleRequestModel) => requestProxy.PerformPostAction(`api/korist/ozgecmisGuncelle`, model, translate(
                /* PRESERVE BEGIN: Korist-OzgecmisGuncelle */
                "Özgeçmiş güncelleniyor"
                /* PRESERVE END: Korist-OzgecmisGuncelle */
            )),
            "TaslakKayitiOnayla": (model: TaslakKayitiOnaylaRequestModel) => requestProxy.PerformPostAction(`api/korist/taslakKayitiOnayla`, model, translate(
                /* PRESERVE BEGIN: Korist-TaslakKayitiOnayla */
                "Taslak kayıt onaylanıyor"
                /* PRESERVE END: Korist-TaslakKayitiOnayla */
            )),
            "AnketSonucGonder": (model: AnketSonucGonderRequestModel) => requestProxy.PerformPostAction(`api/korist/anketSonucGonder`, model, translate(
                /* PRESERVE BEGIN: Korist-AnketSonucGonder */
                "Anket sonucu gönderiliyor"
                /* PRESERVE END: Korist-AnketSonucGonder */
            )),
            "DuyuruOkunduIsaretle": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/korist/duyuruOkunduIsaretle`, model, translate(
                /* PRESERVE BEGIN: Korist-DuyuruOkunduIsaretle */
                "Duyuru okuma durumu güncelleniyor"
                /* PRESERVE END: Korist-DuyuruOkunduIsaretle */
            )),
            /* PRESERVE BEGIN: Additional-Korist-Calls */
            /* PRESERVE END: Additional-Korist-Calls */
        },
        Kullanici: {
            "Signin": (model: LoginRequestModel) => requestProxy.PerformPostAction<AuthenticationResponseViewModel>(`api/kullanici/signin`, model, translate(
                /* PRESERVE BEGIN: Kullanici-Signin */
                "Giriş yapılıyor"
                /* PRESERVE END: Kullanici-Signin */
            )),
            "SifreGuncelle": (model: SifreGuncellemeRequestModel) => requestProxy.PerformPostAction(`api/kullanici/sifreGuncelle`, model, translate(
                /* PRESERVE BEGIN: Kullanici-SifreGuncelle */
                "Şifre güncelleniyor"
                /* PRESERVE END: Kullanici-SifreGuncelle */
            )),
            "SifreHatirlat": (model: string) => requestProxy.PerformPostAction<string>(`api/kullanici/sifreHatirlat`, model, translate(
                /* PRESERVE BEGIN: Kullanici-SifreHatirlat */
                "Şifre hatırlatma işlemi yapılıyor"
                /* PRESERVE END: Kullanici-SifreHatirlat */
            )),
            "OtpSifreGuncelle": (model: OtpSifreGuncellemeRequestModel) => requestProxy.PerformPostAction(`api/kullanici/otpSifreGuncelle`, model, translate(
                /* PRESERVE BEGIN: Kullanici-OtpSifreGuncelle */
                "OTP ile şifre güncelleniyor"
                /* PRESERVE END: Kullanici-OtpSifreGuncelle */
            )),
            "ProfilGuncelle": (model: KullaniciModel) => requestProxy.PerformPostAction(`api/kullanici/profilGuncelle`, model, translate(
                /* PRESERVE BEGIN: Kullanici-ProfilGuncelle */
                "Profil güncelleniyor"
                /* PRESERVE END: Kullanici-ProfilGuncelle */
            )),
            /* PRESERVE BEGIN: Additional-Kullanici-Calls */
            /* PRESERVE END: Additional-Kullanici-Calls */
        },
        OneriSarki: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/onerisarki/sil`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Sil */
                "Şarkı Önerisi siliniyor"
                /* PRESERVE END: OneriSarki-Sil */
            )),
            "Guncelle": (model: OneriSarkiGuncelleRequestModel) => requestProxy.PerformPostAction(`api/onerisarki/guncelle`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Guncelle */
                "Şarkı Önerisi güncelleniyor"
                /* PRESERVE END: OneriSarki-Guncelle */
            )),
            "Ekle": (model: OneriSarkiEkleRequestModel) => requestProxy.PerformPostAction(`api/onerisarki/ekle`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Ekle */
                "Şarkı Önerisi ekleniyor"
                /* PRESERVE END: OneriSarki-Ekle */
            )),
            "Yorumla": (model: OneriSarkiReddetYorumlaRequestModel) => requestProxy.PerformPostAction(`api/onerisarki/yorumla`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Yorumla */
                "Şarkı Önerisi yorumlanıyor"
                /* PRESERVE END: OneriSarki-Yorumla */
            )),
            "Reddet": (model: OneriSarkiReddetYorumlaRequestModel) => requestProxy.PerformPostAction(`api/onerisarki/reddet`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Reddet */
                "Şarkı Önerisi güncelleniyor"
                /* PRESERVE END: OneriSarki-Reddet */
            )),
            "Onayla": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/onerisarki/onayla`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Onayla */
                "Şarkı Önerisi güncelleniyor"
                /* PRESERVE END: OneriSarki-Onayla */
            )),
            "KullanicininOnerileriniGetir": (model: GuidRequestModel) => requestProxy.PerformPostAction<OneriSarkiViewModel[]>(`api/onerisarki/kullanicininOnerileriniGetir`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-KullanicininOnerileriniGetir */
                "Kullanıcının Şarkı Önerileri getiriliyor"
                /* PRESERVE END: OneriSarki-KullanicininOnerileriniGetir */
            )),
            "Getir": (model: GuidRequestModel) => requestProxy.PerformPostAction<OneriSarkiViewModel[]>(`api/onerisarki/getir`, model, translate(
                /* PRESERVE BEGIN: OneriSarki-Getir */
                "Şarkı Önerileri getiriliyor"
                /* PRESERVE END: OneriSarki-Getir */
            )),
            /* PRESERVE BEGIN: Additional-OneriSarki-Calls */
            /* PRESERVE END: Additional-OneriSarki-Calls */
        },
        YonetimAnket: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/anket/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimAnket-Sil */
                "Anket siliniyor"
                /* PRESERVE END: YonetimAnket-Sil */
            )),
            "Guncelle": (model: AnketModel) => requestProxy.PerformPostAction(`api/yonetim/anket/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimAnket-Guncelle */
                "Anket güncelleniyor"
                /* PRESERVE END: YonetimAnket-Guncelle */
            )),
            "Ekle": (model: AnketModel) => requestProxy.PerformPostAction(`api/yonetim/anket/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimAnket-Ekle */
                "Anket ekleniyor"
                /* PRESERVE END: YonetimAnket-Ekle */
            )),
            "AcKapat": (model: AnketAcKapatRequestModel) => requestProxy.PerformPostAction(`api/yonetim/anket/acKapat`, model, translate(
                /* PRESERVE BEGIN: YonetimAnket-AcKapat */
                model.hedefDurum === true ? "Anket Açılıyor" : "Anket Kapanıyor"
                /* PRESERVE END: YonetimAnket-AcKapat */
            )),
            /* PRESERVE BEGIN: Additional-YonetimAnket-Calls */
            /* PRESERVE END: Additional-YonetimAnket-Calls */
        },
        YonetimCalisma: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimCalisma-Sil */
                "Çalışma siliniyor"
                /* PRESERVE END: YonetimCalisma-Sil */
            )),
            "Guncelle": (model: CalismaModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimCalisma-Guncelle */
                "Çalışma güncelleniyor"
                /* PRESERVE END: YonetimCalisma-Guncelle */
            )),
            "Ekle": (model: CalismaModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimCalisma-Ekle */
                "Çalışma siliniyor"
                /* PRESERVE END: YonetimCalisma-Ekle */
            )),
            "HesapCalismaYoklamaBildir": (model: HesapCalismaYoklamaBildirRequestModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/hesapCalismaYoklamaBildir`, model, translate(
                /* PRESERVE BEGIN: YonetimCalisma-HesapCalismaYoklamaBildir */
                "Yoklamalar bildiriliyor"
                /* PRESERVE END: YonetimCalisma-HesapCalismaYoklamaBildir */
            )),
            "HesapCalismaYoklamaListBildir": (model: HesapCalismaYoklamaListBildirRequestModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/hesapCalismaYoklamaListBildir`, model, translate(
                /* PRESERVE BEGIN: YonetimCalisma-HesapCalismaYoklamaListBildir */
                "Yoklamalar bildiriliyor"
                /* PRESERVE END: YonetimCalisma-HesapCalismaYoklamaListBildir */
            )),
            /* PRESERVE BEGIN: Additional-YonetimCalisma-Calls */
            /* PRESERVE END: Additional-YonetimCalisma-Calls */
        },
        YonetimDuyuru: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/duyuru/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimDuyuru-Sil */
                "Duyuru siliniyor"
                /* PRESERVE END: YonetimDuyuru-Sil */
            )),
            "Guncelle": (model: DuyuruModel) => requestProxy.PerformPostAction(`api/yonetim/duyuru/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimDuyuru-Guncelle */
                "Duyuru güncelleniyor"
                /* PRESERVE END: YonetimDuyuru-Guncelle */
            )),
            "Ekle": (model: DuyuruModel) => requestProxy.PerformPostAction(`api/yonetim/duyuru/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimDuyuru-Ekle */
                "Duyuru ekleniyor"
                /* PRESERVE END: YonetimDuyuru-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimDuyuru-Calls */
            /* PRESERVE END: Additional-YonetimDuyuru-Calls */
        },
        YonetimHesap: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimHesap-Sil */
                "Hesap siliniyor"
                /* PRESERVE END: YonetimHesap-Sil */
            )),
            "Guncelle": (model: HesapBilgiVeKullaniciWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesap-Guncelle */
                "Hesap güncelleniyor"
                /* PRESERVE END: YonetimHesap-Guncelle */
            )),
            "Ekle": (model: HesapBilgiVeKullaniciWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesap-Ekle */
                "Hesap ekleniyor"
                /* PRESERVE END: YonetimHesap-Ekle */
            )),
            "PasifYap": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/pasifYap`, model, translate(
                /* PRESERVE BEGIN: YonetimHesap-PasifYap */
                "Hesap pasife alınıyor"
                /* PRESERVE END: YonetimHesap-PasifYap */
            )),
            "AktifYap": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/aktifYap`, model, translate(
                /* PRESERVE BEGIN: YonetimHesap-AktifYap */
                "Hesap aktife alınıyor"
                /* PRESERVE END: YonetimHesap-AktifYap */
            )),
            "KullaniciAra": (model: string) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/hesap/kullaniciAra`, model, translate(
                /* PRESERVE BEGIN: YonetimHesap-KullaniciAra */
                "Kullanıcı aranıyor"
                /* PRESERVE END: YonetimHesap-KullaniciAra */
            )),
            /* PRESERVE BEGIN: Additional-YonetimHesap-Calls */
            /* PRESERVE END: Additional-YonetimHesap-Calls */
        },
        YonetimHesapMuzikal: {
            "HesapKayitSil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapKayitSil`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapKayitSil */
                "Kayıt siliniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapKayitSil */
            )),
            "HesapKayitSesGrupGuncelle": (model: HesapKayitSesGrupGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapKayitSesGrupGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapKayitSesGrupGuncelle */
                "Kayıt ses grubu güncelleniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapKayitSesGrupGuncelle */
            )),
            "HesapKayitYorumla": (model: HesapKayitYorumlaRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapKayitYorumla`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapKayitYorumla */
                "Kayıt yorumu güncelleniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapKayitYorumla */
            )),
            "HesapKuartetDurumGuncelle": (model: HesapKuartetDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapKuartetDurumGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapKuartetDurumGuncelle */
                "Kuartet durumu güncelleniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapKuartetDurumGuncelle */
            )),
            "HesapKuartetListDurumGuncelle": (model: HesapKuartetListDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapKuartetListDurumGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapKuartetListDurumGuncelle */
                "Kuartet durumları güncelleniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapKuartetListDurumGuncelle */
            )),
            "HesapYorumEkleGuncelle": (model: HesapYorumEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapYorumEkleGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapYorumEkleGuncelle */
                "Korist yorumu güncelleniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapYorumEkleGuncelle */
            )),
            "HesapEgitmenYorumEkleGuncelle": (model: HesapEgitmenYorumEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/hesapEgitmenYorumEkleGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimHesapMuzikal-HesapEgitmenYorumEkleGuncelle */
                "Eğitmen yorumu güncelleniyor"
                /* PRESERVE END: YonetimHesapMuzikal-HesapEgitmenYorumEkleGuncelle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimHesapMuzikal-Calls */
            /* PRESERVE END: Additional-YonetimHesapMuzikal-Calls */
        },
        YonetimKadro: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/kadro/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimKadro-Sil */
                "Kadro siliniyor"
                /* PRESERVE END: YonetimKadro-Sil */
            )),
            "Guncelle": (model: KadroEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/kadro/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimKadro-Guncelle */
                "Kadro güncelleniyor"
                /* PRESERVE END: YonetimKadro-Guncelle */
            )),
            "Ekle": (model: KadroEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/kadro/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimKadro-Ekle */
                "Kadro ekleniyor"
                /* PRESERVE END: YonetimKadro-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimKadro-Calls */
            /* PRESERVE END: Additional-YonetimKadro-Calls */
        },
        YonetimKonser: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/konser/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimKonser-Sil */
                "Konser siliniyor"
                /* PRESERVE END: YonetimKonser-Sil */
            )),
            "Guncelle": (model: KonserModelWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/konser/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimKonser-Guncelle */
                "Konser güncelleniyor"
                /* PRESERVE END: YonetimKonser-Guncelle */
            )),
            "Ekle": (model: KonserModelWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/konser/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimKonser-Ekle */
                "Konser ekleniyor"
                /* PRESERVE END: YonetimKonser-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimKonser-Calls */
            /* PRESERVE END: Additional-YonetimKonser-Calls */
        },
        YonetimKoro: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-Sil */
                "Koro siliniyor"
                /* PRESERVE END: YonetimKoro-Sil */
            )),
            "Guncelle": (model: KoroVeHesapWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-Guncelle */
                "Koro güncelleniyor"
                /* PRESERVE END: YonetimKoro-Guncelle */
            )),
            "Ekle": (model: KoroVeHesapWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-Ekle */
                "Koro ekleniyor"
                /* PRESERVE END: YonetimKoro-Ekle */
            )),
            "KullaniciAra": (model: string) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/koro/kullaniciAra`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KullaniciAra */
                "Kullanıcı aranıyor"
                /* PRESERVE END: YonetimKoro-KullaniciAra */
            )),
            "KullaniciProfilSifreGuncelle": (model: KullaniciProfilSifreGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/kullaniciProfilSifreGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KullaniciProfilSifreGuncelle */
                "Kullanıcı profil şifresi güncelleniyor"
                /* PRESERVE END: YonetimKoro-KullaniciProfilSifreGuncelle */
            )),
            "KullaniciProfilSifreSil": (model: KullaniciProfilSifreSilRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/kullaniciProfilSifreSil`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KullaniciProfilSifreSil */
                "Hesap siliniyor"
                /* PRESERVE END: YonetimKoro-KullaniciProfilSifreSil */
            )),
            "KoroAyarlariniGuncele": (model: KoroAyarlariModel) => requestProxy.PerformPostAction(`api/yonetim/koro/koroAyarlariniGuncele`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KoroAyarlariniGuncele */
                "Koro ayarları güncelleniyor"
                /* PRESERVE END: YonetimKoro-KoroAyarlariniGuncele */
            )),
            "KoroDosyaBoyutGetir": (model: GuidRequestModel) => requestProxy.PerformPostAction<number>(`api/yonetim/koro/koroDosyaBoyutGetir`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KoroDosyaBoyutGetir */
                "Koro dosya boyutu getiriliyor"
                /* PRESERVE END: YonetimKoro-KoroDosyaBoyutGetir */
            )),
            "KullanilmayanIcerikSil": (model: string[]) => requestProxy.PerformPostAction(`api/yonetim/koro/kullanilmayanIcerikSil`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KullanilmayanIcerikSil */
                "Kullanılmayan içerikler siliniyor"
                /* PRESERVE END: YonetimKoro-KullanilmayanIcerikSil */
            )),
            "KullanilmayanIcerikGetir": (model: GuidRequestModel) => requestProxy.PerformPostAction<IcerikMetaViewModel[]>(`api/yonetim/koro/kullanilmayanIcerikGetir`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-KullanilmayanIcerikGetir */
                "Kullanılmaya içerikleri getiriliyor"
                /* PRESERVE END: YonetimKoro-KullanilmayanIcerikGetir */
            )),
            "TopluIslem": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/topluIslem`, model, translate(
                /* PRESERVE BEGIN: YonetimKoro-TopluIslem */
                "Toplu İşlem"
                /* PRESERVE END: YonetimKoro-TopluIslem */
            )),
            /* PRESERVE BEGIN: Additional-YonetimKoro-Calls */
            /* PRESERVE END: Additional-YonetimKoro-Calls */
        },
        YonetimRol: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/rol/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimRol-Sil */
                "Rol siliniyor"
                /* PRESERVE END: YonetimRol-Sil */
            )),
            "Guncelle": (model: RolModel) => requestProxy.PerformPostAction(`api/yonetim/rol/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimRol-Guncelle */
                "Rol güncelleniyor"
                /* PRESERVE END: YonetimRol-Guncelle */
            )),
            "Ekle": (model: RolModel) => requestProxy.PerformPostAction(`api/yonetim/rol/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimRol-Ekle */
                "Rol ekleniyor"
                /* PRESERVE END: YonetimRol-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimRol-Calls */
            /* PRESERVE END: Additional-YonetimRol-Calls */
        },
        YonetimSarki: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-Sil */
                "Şarkı siliniyor"
                /* PRESERVE END: YonetimSarki-Sil */
            )),
            "Guncelle": (model: SarkiModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-Guncelle */
                "Şarkı güncelleniyor"
                /* PRESERVE END: YonetimSarki-Guncelle */
            )),
            "Ekle": (model: SarkiModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-Ekle */
                "Şarkı ekleniyor"
                /* PRESERVE END: YonetimSarki-Ekle */
            )),
            "KayitSureciAc": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/kayitSureciAc`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-KayitSureciAc */
                "Şarkı kayıt süreci açılıyor"
                /* PRESERVE END: YonetimSarki-KayitSureciAc */
            )),
            "KayitSureciKapat": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/kayitSureciKapat`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-KayitSureciKapat */
                "Şarkı kayıt süreci kapatılıyor"
                /* PRESERVE END: YonetimSarki-KayitSureciKapat */
            )),
            "LinkSil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/linkSil`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-LinkSil */
                "Şarkı bağlantısı siliniyor"
                /* PRESERVE END: YonetimSarki-LinkSil */
            )),
            "LinkGuncelle": (model: SarkiLinkEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/linkGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-LinkGuncelle */
                "Şarkı bağlantısı güncelleniyor"
                /* PRESERVE END: YonetimSarki-LinkGuncelle */
            )),
            "LinkEkle": (model: SarkiLinkEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/linkEkle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-LinkEkle */
                "Şarkı bağlantısı ekleniyor"
                /* PRESERVE END: YonetimSarki-LinkEkle */
            )),
            "LinkListGuncelle": (model: SarkiLinkListEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/linkListGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-LinkListGuncelle */
                "Şarkı bağlantıları güncelleniyor"
                /* PRESERVE END: YonetimSarki-LinkListGuncelle */
            )),
            "AdaySarkiListGuncelle": (model: SezonAdaySarkiGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/adaySarkiListGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarki-AdaySarkiListGuncelle */
                "Aday Şarkı Listesi güncelleniyor"
                /* PRESERVE END: YonetimSarki-AdaySarkiListGuncelle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimSarki-Calls */
            /* PRESERVE END: Additional-YonetimSarki-Calls */
        },
        YonetimSarkiYorum: {
            "YorumSil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/yorumSil`, model, translate(
                /* PRESERVE BEGIN: YonetimSarkiYorum-YorumSil */
                "Şarkı yorumu siliniyor"
                /* PRESERVE END: YonetimSarkiYorum-YorumSil */
            )),
            "YorumGuncelle": (model: SarkiYorumEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/yorumGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarkiYorum-YorumGuncelle */
                "Şarkı yorumu güncelleniyor"
                /* PRESERVE END: YonetimSarkiYorum-YorumGuncelle */
            )),
            "YorumEkle": (model: SarkiYorumEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/yorumEkle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarkiYorum-YorumEkle */
                "Şarkı yorumu ekleniyor"
                /* PRESERVE END: YonetimSarkiYorum-YorumEkle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimSarkiYorum-Calls */
            /* PRESERVE END: Additional-YonetimSarkiYorum-Calls */
        },
        YonetimSarkiZorluk: {
            "ZorlukSil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/zorlukSil`, model, translate(
                /* PRESERVE BEGIN: YonetimSarkiZorluk-ZorlukSil */
                "Şarkı zorluk siliniyor"
                /* PRESERVE END: YonetimSarkiZorluk-ZorlukSil */
            )),
            "ZorlukGuncelle": (model: SarkiZorlukEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/zorlukGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarkiZorluk-ZorlukGuncelle */
                "Şarkı zorluk güncelleniyor"
                /* PRESERVE END: YonetimSarkiZorluk-ZorlukGuncelle */
            )),
            "ZorlukEkle": (model: SarkiZorlukEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/zorlukEkle`, model, translate(
                /* PRESERVE BEGIN: YonetimSarkiZorluk-ZorlukEkle */
                "Şarkı zorluk ekleniyor"
                /* PRESERVE END: YonetimSarkiZorluk-ZorlukEkle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimSarkiZorluk-Calls */
            /* PRESERVE END: Additional-YonetimSarkiZorluk-Calls */
        },
        YonetimSecmeBasvuru: {
            "BasvuruSil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruSil`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-BasvuruSil */
                "Başvuru siliniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-BasvuruSil */
            )),
            "BasvuruGuncelle": (model: SecmeBasvuruWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-BasvuruGuncelle */
                "Başvuru güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-BasvuruGuncelle */
            )),
            "BasvuruEkle": (model: SecmeBasvuruWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruEkle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-BasvuruEkle */
                "Başvuru ekleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-BasvuruEkle */
            )),
            "SecmeKatilimBildirmeDurumGuncelle": (model: SecmeKatilimBildirmeDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/secmeKatilimBildirmeDurumGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-SecmeKatilimBildirmeDurumGuncelle */
                "Başvuru güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-SecmeKatilimBildirmeDurumGuncelle */
            )),
            "BasvuruTarihGuncelle": (model: SecmeBasvuruTarihGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruTarihGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-BasvuruTarihGuncelle */
                "Başvuru güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-BasvuruTarihGuncelle */
            )),
            "SecmeBasvuruSecmeNotuGuncelle": (model: SecmeBasvuruSecmeNotuGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/secmeBasvuruSecmeNotuGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-SecmeBasvuruSecmeNotuGuncelle */
                "Başvuru Notu güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-SecmeBasvuruSecmeNotuGuncelle */
            )),
            "BasvuruProfilGuncelle": (model: SecmeBasvuruProfilGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruProfilGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-BasvuruProfilGuncelle */
                "Başvuru güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-BasvuruProfilGuncelle */
            )),
            "SonucGuncelle": (model: SecmeBasvuruSonucRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/sonucGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-SonucGuncelle */
                "Seçme Sonucu güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-SonucGuncelle */
            )),
            "SonucEkle": (model: SecmeBasvuruSonucRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/sonucEkle`, model, translate(
                /* PRESERVE BEGIN: YonetimSecmeBasvuru-SonucEkle */
                "Seçme Sonucu güncelleniyor"
                /* PRESERVE END: YonetimSecmeBasvuru-SonucEkle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimSecmeBasvuru-Calls */
            /* PRESERVE END: Additional-YonetimSecmeBasvuru-Calls */
        },
        YonetimSesGrup: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimSesGrup-Sil */
                "Ses grubu siliniyor"
                /* PRESERVE END: YonetimSesGrup-Sil */
            )),
            "Guncelle": (model: SesGrupModel) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSesGrup-Guncelle */
                "Ses grubu güncelleniyor"
                /* PRESERVE END: YonetimSesGrup-Guncelle */
            )),
            "Ekle": (model: SesGrupModel) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimSesGrup-Ekle */
                "Ses grubu ekleniyor"
                /* PRESERVE END: YonetimSesGrup-Ekle */
            )),
            "TopluSiraNoGuncelle": (model: SesGrupTopluSiraNoGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/topluSiraNoGuncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSesGrup-TopluSiraNoGuncelle */
                "Ses grubu sıra numaraları güncelleniyor"
                /* PRESERVE END: YonetimSesGrup-TopluSiraNoGuncelle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimSesGrup-Calls */
            /* PRESERVE END: Additional-YonetimSesGrup-Calls */
        },
        YonetimSezon: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sezon/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimSezon-Sil */
                "Sezon siliniyor"
                /* PRESERVE END: YonetimSezon-Sil */
            )),
            "Guncelle": (model: SezonModel) => requestProxy.PerformPostAction(`api/yonetim/sezon/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimSezon-Guncelle */
                "Sezon güncelleniyor"
                /* PRESERVE END: YonetimSezon-Guncelle */
            )),
            "Ekle": (model: SezonModel) => requestProxy.PerformPostAction(`api/yonetim/sezon/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimSezon-Ekle */
                "Sezon ekleniyor"
                /* PRESERVE END: YonetimSezon-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimSezon-Calls */
            /* PRESERVE END: Additional-YonetimSezon-Calls */
        },
        YonetimTarz: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/tarz/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimTarz-Sil */
                "Tarz siliniyor"
                /* PRESERVE END: YonetimTarz-Sil */
            )),
            "Guncelle": (model: TarzModel) => requestProxy.PerformPostAction(`api/yonetim/tarz/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimTarz-Guncelle */
                "Tarz güncelleniyor"
                /* PRESERVE END: YonetimTarz-Guncelle */
            )),
            "Ekle": (model: TarzModel) => requestProxy.PerformPostAction(`api/yonetim/tarz/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimTarz-Ekle */
                "Tarz ekleniyor"
                /* PRESERVE END: YonetimTarz-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimTarz-Calls */
            /* PRESERVE END: Additional-YonetimTarz-Calls */
        },
        YonetimTatil: {
            "Sil": (model: GuidRequestModel) => requestProxy.PerformPostAction(`api/yonetim/tatil/sil`, model, translate(
                /* PRESERVE BEGIN: YonetimTatil-Sil */
                "Tatil siliniyor"
                /* PRESERVE END: YonetimTatil-Sil */
            )),
            "Guncelle": (model: TatilModel) => requestProxy.PerformPostAction(`api/yonetim/tatil/guncelle`, model, translate(
                /* PRESERVE BEGIN: YonetimTatil-Guncelle */
                "Tatil güncelleniyor"
                /* PRESERVE END: YonetimTatil-Guncelle */
            )),
            "Ekle": (model: TatilModel) => requestProxy.PerformPostAction(`api/yonetim/tatil/ekle`, model, translate(
                /* PRESERVE BEGIN: YonetimTatil-Ekle */
                "Tatil ekleniyor"
                /* PRESERVE END: YonetimTatil-Ekle */
            )),
            /* PRESERVE BEGIN: Additional-YonetimTatil-Calls */
            /* PRESERVE END: Additional-YonetimTatil-Calls */
        },
    }
}