/* eslint-disable @typescript-eslint/no-unused-vars */
import { CSSProperties } from "react";
import { alpha, useTheme } from "@mui/material";

export interface IExtraStyleTextProps {
    bold?: boolean,
    italic?: boolean,
}

export interface IExtraStyleBorderProps {
    hasLightFullBorder?: boolean,
    hasVeryLightFullBorder?: boolean,
    hasBlack23FullBorder?: boolean,
    hasGrayFullBorder?: boolean,

    hasLightBottomBorder?: boolean,
    hasVeryLightBottomBorder?: boolean,
    hasBlack23BottomBorder?: boolean,
    hasGrayBottomBorder?: boolean,

    hasLightTopBorder?: boolean,
    hasVeryLightTopBorder?: boolean,
    hasBlack23TopBorder?: boolean,
    hasGrayTopBorder?: boolean,

    hasLightRightBorder?: boolean,
    hasVeryLightRightBorder?: boolean,
    hasBlack23RightBorder?: boolean,
    hasGrayRightBorder?: boolean,

    hasLightLeftBorder?: boolean,
    hasVeryLightLeftBorder?: boolean,
    hasBlack23LeftBorder?: boolean,
    hasGrayLeftBorder?: boolean,
}

export interface IExtraStyleSpacingProps {
    m?: number;
    ml?: number;
    mt?: number;
    mr?: number;
    mb?: number;
    p?: number;
    pl?: number;
    pt?: number;
    pr?: number;
    pb?: number;
    gap?: number;
}

const StyleHelper = {
    CreateStyle: (props: IExtraStyleTextProps | IExtraStyleBorderProps | IExtraStyleSpacingProps) => {
        const result: CSSProperties = {};
        return StyleHelper.UpdateStyle(props, result);
    },
    UpdateStyle: (props: IExtraStyleTextProps | IExtraStyleBorderProps | IExtraStyleSpacingProps, style: CSSProperties) => {
        const cp = props as IExtraStyleTextProps & IExtraStyleBorderProps & IExtraStyleSpacingProps;

        const theme = useTheme();
        const color_Black023 = alpha(theme.palette.common.black, 0.23)
        const color_gray = theme.palette.grey.A700;
        const color_GrayLight2 = theme.palette.grey.A400;
        const color_GrayLight3 = theme.palette.grey[300];

        // text
        if (cp.bold)
            style.fontWeight = "bold";

        if (cp.italic)
            style.fontStyle = "italic";

        // broder
        if (cp.hasLightBottomBorder || cp.hasLightFullBorder)
            style.borderBottom = ("1px solid " + color_GrayLight2);
        else if (cp.hasVeryLightBottomBorder || cp.hasVeryLightFullBorder)
            style.borderBottom = ("1px solid " + color_GrayLight3);
        else if (cp.hasBlack23BottomBorder || cp.hasBlack23FullBorder)
            style.borderBottom = ("1px solid " + color_Black023);
        else if (cp.hasGrayBottomBorder || cp.hasGrayFullBorder)
            style.borderBottom = ("1px solid " + color_gray);

        if (cp.hasLightTopBorder || cp.hasLightFullBorder)
            style.borderTop = ("1px solid " + color_GrayLight2);
        else if (cp.hasVeryLightTopBorder || cp.hasVeryLightFullBorder)
            style.borderTop = ("1px solid " + color_GrayLight3);
        else if (cp.hasBlack23TopBorder || cp.hasBlack23FullBorder)
            style.borderTop = ("1px solid " + color_Black023);
        else if (cp.hasGrayTopBorder || cp.hasGrayFullBorder)
            style.borderTop = ("1px solid " + color_gray);

        if (cp.hasLightLeftBorder || cp.hasLightFullBorder)
            style.borderLeft = ("1px solid " + color_GrayLight2);
        else if (cp.hasVeryLightLeftBorder || cp.hasVeryLightFullBorder)
            style.borderLeft = ("1px solid " + color_GrayLight3);
        else if (cp.hasBlack23LeftBorder || cp.hasBlack23FullBorder)
            style.borderLeft = ("1px solid " + color_Black023);
        else if (cp.hasGrayLeftBorder || cp.hasGrayFullBorder)
            style.borderLeft = ("1px solid " + color_gray);

        if (cp.hasLightRightBorder || cp.hasLightFullBorder)
            style.borderRight = ("1px solid " + color_GrayLight2);
        else if (cp.hasVeryLightRightBorder || cp.hasVeryLightFullBorder)
            style.borderRight = ("1px solid " + color_GrayLight3);
        else if (cp.hasBlack23RightBorder || cp.hasBlack23FullBorder)
            style.borderRight = ("1px solid " + color_Black023);
        else if (cp.hasGrayRightBorder || cp.hasGrayFullBorder)
            style.borderRight = ("1px solid " + color_gray);

        // spacing
        if (cp.m !== undefined) {
            style.marginLeft = `${cp.m.toFixed()}px`;
            style.marginTop = `${cp.m.toFixed()}px`;
            style.marginRight = `${cp.m.toFixed()}px`;
            style.marginBottom = `${cp.m.toFixed()}px`;
        }
        if (cp.ml !== undefined)
            style.marginLeft = `${cp.ml.toFixed()}px`;
        if (cp.mt !== undefined)
            style.marginTop = `${cp.mt.toFixed()}px`;
        if (cp.mr !== undefined)
            style.marginRight = `${cp.mr.toFixed()}px`;
        if (cp.mb !== undefined)
            style.marginBottom = `${cp.mb.toFixed()}px`;

        if (cp.p !== undefined) {
            style.paddingLeft = `${cp.p.toFixed()}px`;
            style.paddingTop = `${cp.p.toFixed()}px`;
            style.paddingRight = `${cp.p.toFixed()}px`;
            style.paddingBottom = `${cp.p.toFixed()}px`;
        }
        if (cp.pl !== undefined)
            style.paddingLeft = `${cp.pl.toFixed()}px`;
        if (cp.pt !== undefined)
            style.paddingTop = `${cp.pt.toFixed()}px`;
        if (cp.pr !== undefined)
            style.paddingRight = `${cp.pr.toFixed()}px`;
        if (cp.pb !== undefined)
            style.paddingBottom = `${cp.pb.toFixed()}px`;

        if (cp.gap !== undefined)
            style.gap = `${cp.gap.toFixed()}px`;

        return style;
    },
    OmitProps: <T extends IExtraStyleTextProps & IExtraStyleBorderProps & IExtraStyleSpacingProps>(props: T) => {
        const {
            bold,
            italic,
            hasLightBottomBorder,
            hasVeryLightBottomBorder,
            hasBlack23BottomBorder,
            hasGrayBottomBorder,
            hasLightTopBorder,
            hasVeryLightTopBorder,
            hasBlack23TopBorder,
            hasGrayTopBorder,
            hasLightRightBorder,
            hasVeryLightRightBorder,
            hasBlack23RightBorder,
            hasGrayRightBorder,
            hasLightLeftBorder,
            hasVeryLightLeftBorder,
            hasBlack23LeftBorder,
            hasGrayLeftBorder,
            m,
            ml,
            mt,
            mr,
            mb,
            p,
            pl,
            pt,
            pr,
            pb,
            gap,
            ...result } = props;

        return result;
    },
}

export default StyleHelper;