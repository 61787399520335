// AutoGenerated by CSSeeder - mergedTranslations.tmp.ts
/* eslint-disable @typescript-eslint/no-explicit-any */
import { _commonNonBusiness, _diger, _enumlar, _requestFiiler, _tarihsel } from "./korocanTranslations";
import { oneriSarkiTranslations } from "../pages/yonetim/onerisarki/oneriSarkiTranslations";

// her eklenilen translation sınıfı, buraya eklenmeli
const checkItems = {
    requestFiiler: _requestFiiler,
    commonNonBusiness: _commonNonBusiness,
    tarihsel: _tarihsel,
    enumlar: _enumlar,
    diger: _diger,
    oneriSarki: oneriSarkiTranslations
}

// her eklenilen translation sınıfı, buraya eklenmeli
export type mergedTranslationKeyType =
    keyof typeof checkItems.requestFiiler |
    keyof typeof checkItems.commonNonBusiness
    | keyof typeof checkItems.tarihsel
    | keyof typeof checkItems.enumlar
    | keyof typeof checkItems.diger
    | keyof typeof checkItems.oneriSarki
;

// her eklenilen translation sınıfı, buraya eklenmeli
export const mergedTranslations = {
    ..._requestFiiler,
    ..._commonNonBusiness,
    ..._tarihsel,
    ..._enumlar,
    ..._diger,
    ...oneriSarkiTranslations,
};

// bu noktadan sonrasına dokunmayın

const duplicates: {kelime: string, dosya: string}[] = [];
for (const key1 in checkItems)
    for (const key2 in checkItems) {
        if (key1 !== key2) {
            const items1 = Object.keys((checkItems as any)[key1]);
            const items2 = Object.keys((checkItems as any)[key2]);
            const ortaklar = items1.filter(e => items2.indexOf(e) > -1);
            duplicates.push(...ortaklar.map(e => ({ kelime: e, dosya: `${key1} ve ${key2}`})));
        }
    }

if (duplicates.length > 0)
    alert(`${duplicates.length.toFixed()} anahtarlar, birden fazla translation dosyasında geçmektedir: ` + duplicates.map(e => `"${e.kelime}"`).join(", "));