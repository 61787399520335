// AutoGenerated by CSSeeder - CsmSampleItemContainers.tsx
import { GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import { CsmAccordion } from "../../library/components/mui/containers/CsmAccordion";
import { CsmText } from "../../library/components/mui/CsmText";
import CsmAccordionSimple from "../../library/components/mui/containers/CsmAccordionSimple";
import CsmLightCollapsableContainer from "../../library/components/mui/containers/CsmLightCollapsableContainer";

export default function CsmSampleItemContainers() {
    const sample: ISampleGroup = {
        groupName: "Containers",
        componenets: [
            {
                componentName: "CsmAccordion",
                sampleUsages: [
                    {
                        item: <CsmAccordion title="Regular Accordion">
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordion>,
                        lines: [
                            `<CsmAccordion title="Regular Accordion">`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordion>`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmAccordionSimple",
                sampleUsages: [
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion">
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordionSimple>,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion">`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordionSimple>`,
                        ]
                    },
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion Always Open"
                            alwaysOpen>
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordionSimple>,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion Always Open"`,
                            `alwaysOpen>`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordionSimple>`,
                        ]
                    },
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion With subtitle"
                            subtitle="My Subtitle">
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordionSimple>,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion With subtitle"`,
                            `subtitle="My Subtitle">`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordionSimple>`,
                        ]
                    },
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion With big centered Title"
                            bigCenteredTitle>
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordionSimple>,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion With big centered Title"`,
                            `bigCenteredTitle>`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordionSimple>`,
                        ]
                    },
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion With initial opened"
                            initialOpen>
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordionSimple>,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion With initial opened"`,
                            `initialOpen>`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordionSimple>`,
                        ]
                    },
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion With Empty Note"
                            emptyNote="No Contents Here..."
                            // eslint-disable-next-line react/no-children-prop
                            children={null} />,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion With Empty Note"`,
                            `emptyNote="No Contents Here..."`,
                            `children={null} />`,
                        ]
                    },
                    {
                        itemWidth: 4,
                        linesWidth: 4,
                        item: <CsmAccordionSimple
                            title="Simple Accordion With buttons"
                            buttons={[{ text: "Action 1", action: () => alert("Actions 1") }, { text: "Action 2", action: () => alert("Actions 2") }]}>
                            <CsmText>Some Content...</CsmText>
                        </CsmAccordionSimple>,
                        lines: [
                            `<CsmAccordionSimple`,
                            `title="Simple Accordion With buttons"`,
                            `buttons={[{ text: "Action 1", action: () => alert("Actions 1") }, { text: "Action 2", action: () => alert("Actions 2") }]}>`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmAccordionSimple>`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmLightCollapsableContainer",
                sampleUsages: [
                    {
                        item: <CsmLightCollapsableContainer
                            expandedTitle="Title While Expanded"
                            collapsedTitle="Title While Collapsed">
                            <CsmText>Some Content...</CsmText>
                        </CsmLightCollapsableContainer>,
                        lines: [
                            `<CsmLightCollapsableContainer`,
                            `expandedTitle="Title While Expanded"`,
                            `collapsedTitle="Title While Collapsed">`,
                            `<CsmText>Some Content...</CsmText>`,
                            `</CsmLightCollapsableContainer>`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(sample);
}