/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { TextField, TextFieldProps, ThemeProvider } from "@mui/material";
import { KeyboardEvent } from "react"
import { FocusEvent, useEffect, useState } from "react";
import { narrowTheme } from "../helpers/CsmNarrowTheme";

export type ICsmNumericFieldProps = Omit<TextFieldProps, "onChange" | "type" | "inputMode" | "value"> & {
    narrow?: boolean,
    onChange: (e: number | null) => void,
    value: number | null,
    maxLength?: number,
    disallowDecimals?: boolean,
    errorText?: string | false | undefined,
};

function numberToTextboxString(givenValue: number | null) { return (givenValue === 0 ? "0" : givenValue ? String(givenValue) : '') }


function CsmNumericField(props: ICsmNumericFieldProps & { eventOnKeyPress?: boolean }) {

    const { eventOnKeyPress, ...rest } = props;

    if (eventOnKeyPress)
        return <CsmNumericFieldSlow {...rest} />
    else
        return <CsmNumericFieldFast {...rest} />
}

function CsmNumericFieldFast(props: ICsmNumericFieldProps) {
    const { errorText, error, helperText, maxLength, disallowDecimals, narrow, sx, fullWidth, onChange, value, ...rest } = props;
    const [stateValue, setStateValue] = useState<string>(numberToTextboxString(value));

    const helperTextToUse = (typeof errorText === "string") ? errorText : helperText;
    const errorToUse = (typeof errorText === "string") ? (errorText ?? "").length > 0 : error;

    useEffect(() => {
        setStateValue(() => { return numberToTextboxString(value) })
    }, [value]);

    const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key !== "Enter")
            return;

        e.preventDefault();
        const stringValue = (e.target as any).value;
        const finalValue = (stringValue && Number.isNaN(stringValue) === false) ? Number(stringValue) : null;
        //e.target.value = String(finalValue);
        setStateValue(() => { return numberToTextboxString(finalValue) })
        onChange(finalValue)
    }

    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        const finalValue = (stringValue && Number.isNaN(stringValue) === false) ? Number(stringValue) : null;
        //e.target.value = String(finalValue);
        setStateValue(() => { return numberToTextboxString(finalValue) })
        onChange(finalValue);
    };

    function handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
        if (disallowDecimals)
            if (e.key === ',' || e.key === '.' || e.key === '-' || e.key === 'e' || e.key === '+') {
                e.preventDefault();
                return;
            }

        if (e.key >= '0' && e.key <= '9') {
            if (((e.target as any).value ?? "").length >= (maxLength ?? 15)) {
                e.preventDefault();
                return;
            }
        }
    }

    const result = (
        <TextField
            value={stateValue ?? ''}
            sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                },
                "& input[type=number]": {
                    MozAppearance: "textfield",
                },
                ...sx,
            }}
            inputMode="numeric"
            type="number"
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onKeyDown={e => handleKeyDown(e)}
            onChange={(e) => setStateValue(() => { return e.target.value })}
            error={errorToUse}
            helperText={helperTextToUse}
            {...rest}
        />
    );

    if (narrow)
        return <ThemeProvider theme={narrowTheme}>{result}</ThemeProvider>
    else
        return result;
}

function CsmNumericFieldSlow(props: ICsmNumericFieldProps) {
    const { errorText, error, helperText, maxLength, disallowDecimals, sx, fullWidth, onChange, value, ...rest } = props;

    const helperTextToUse = (typeof errorText === "string") ? errorText : helperText;
    const errorToUse = (typeof errorText === "string") ? (errorText ?? "").length > 0 : error;

    function handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
        if (disallowDecimals)
            if (e.key === ',' || e.key === '.' || e.key === '-' || e.key === 'e' || e.key === '+') {
                e.preventDefault();
                return;
            }

        if (e.key >= '0' && e.key <= '9') {
            if (((e.target as any).value ?? "").length >= (maxLength ?? 15)) {
                e.preventDefault();
                return;
            }
        }
    }

    const onChangeInternal = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        const finalValue = (stringValue && Number.isNaN(stringValue) === false) ? Number(stringValue) : null;
        //e.target.value = String(finalValue);
        onChange(finalValue);
    };

    return (
        <TextField
            value={value ?? ''}
            sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                },
                "& input[type=number]": {
                    MozAppearance: "textfield",
                },
                ...sx,
            }}
            inputMode="numeric"
            type="number"
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            onKeyDown={e => handleKeyDown(e)}
            onChange={onChangeInternal}
            error={errorToUse}
            helperText={helperTextToUse}
            {...rest}
        />
    )
}

export default CsmNumericField;