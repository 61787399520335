// AutoGenerated
export class OzgemicGuncelleRequestModel {
    constructor(
        hesapId: string,
        ozgecmis: string | null,
    ) {
        this.hesapId = hesapId;
        this.ozgecmis = ozgecmis;
    }

    hesapId: string;
    ozgecmis: string | null;
}