// AutoGenerated by CSSeeder - CsmSampleItemDateTime.tsx
import { useState } from "react";
import JsonHelper from "../../library/helpers/JsonHelper";
import { GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmDatePicker from "../../library/components/mui/datetime/CsmDatePicker";
import CsmTimePicker from "../../library/components/mui/datetime/CsmTimePicker";
import CsmDateWithTimePicker from "../../library/components/mui/datetime/CsmDateWithTimePicker";

interface ISampleScreenState {
    dateValue1: Date | null,
    dateValue2: Date | null,
    dateValue3: Date | null,
}

export default function CsmSampleItemDateTime() {
    const [model, setModel] = useState<ISampleScreenState>({
        dateValue1: null,
        dateValue2: null,
        dateValue3: null,
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const sample: ISampleGroup = {
        groupName: "Date Time Inputs",
        componenets: [
            {
                componentName: "CsmDatePicker",
                sampleUsages: [
                    {
                        item: <CsmDatePicker
                            label="Date Picker"
                            onChange={e => modelChanged(t => t.dateValue1 = e)}
                            value={model.dateValue1} />,
                        lines: [
                            `<CsmDatePicker`,
                            `label="Date Picker"`,
                            `onChange={e => modelChanged(t => t.dateValue1 = e)}`,
                            `value={model.dateValue1} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmTimePicker",
                sampleUsages: [
                    {
                        item: <CsmTimePicker
                            label="Time Picker"
                            onChange={e => modelChanged(t => t.dateValue2 = e)}
                            value={model.dateValue2} />,
                        lines: [
                            `<CsmTimePicker`,
                            `label="Time Picker"`,
                            `onChange={e => modelChanged(t => t.dateValue2 = e)}`,
                            `value={model.dateValue2} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmDateWithTimePicker",
                sampleUsages: [
                    {
                        item: <CsmDateWithTimePicker
                            dateLabel="Date With Time Picker"
                            timeLabel="time part"
                            onChange={e => modelChanged(t => t.dateValue3 = e)}
                            value={model.dateValue3} />,
                        lines: [
                            `<CsmDateWithTimePicker`,
                            `dateLabel="Date With Time Picker"`,
                            `timeLabel="time part"`,
                            `onChange={e => modelChanged(t => t.dateValue3 = e)}`,
                            `value={model.dateValue3} />`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(sample);
}