// AutoGenerated
export class WithParentIdRequestModel<T> {
    constructor(
        model: T,
        parentId: string,
    ) {
        this.model = model;
        this.parentId = parentId;
    }

    model: T;
    parentId: string;
}