/* eslint-disable @typescript-eslint/no-explicit-any */
import EnumHelper from "../../../helpers/EnumHelper";
import CsmGenericRadioButtonGroup, { ICsmGenericRadioFieldProps } from "./CsmGenericRadioButtonGroup";

type innerListType = { value: number, presentation: string };
export type ICsmCstFieldProps = Omit<ICsmGenericRadioFieldProps<innerListType, number>, "list" | "labelGetter" | "valueGetter"> & { cstType: Record<number, string>, omitValue?: (e: any) => boolean };

function CsmCstRadioButtonGroup(props: ICsmCstFieldProps) {
    const { cstType, omitValue, onChange, ...rest } = props;
    return (
        <CsmGenericRadioButtonGroup
            {...rest}
            onChange={e => e ? onChange(parseInt(e as any)) : onChange(e)}
            list={EnumHelper.getSelectListAsArray<number, number>(cstType, e => cstType[e]).where(e => omitValue ? !omitValue(e.value) : true)}
            labelGetter={(e) => e.presentation}
            valueGetter={(e) => e.value}
        />
    )
}

export default CsmCstRadioButtonGroup;