// AutoGenerated
export class SifreGuncellemeRequestModel {
    constructor(
        eskiSifre: string,
        yeniSifre: string,
    ) {
        this.eskiSifre = eskiSifre;
        this.yeniSifre = yeniSifre;
    }

    eskiSifre: string;
    yeniSifre: string;
}