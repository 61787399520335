// AutoGenerated
import { HesapVeSesGrubuBilgiModel } from "../businessModels/HesapVeSesGrubuBilgiModel"
import { KadroModel } from "../businessModels/KadroModel"

export class KadroEkleGuncelleRequestModel {
    constructor(
        model: KadroModel,
        hesapVeSesGrubuBilgiModelList: HesapVeSesGrubuBilgiModel[],
        calismaIdList: string[],
    ) {
        this.model = model;
        this.hesapVeSesGrubuBilgiModelList = hesapVeSesGrubuBilgiModelList;
        this.calismaIdList = calismaIdList;
    }

    model: KadroModel;
    hesapVeSesGrubuBilgiModelList: HesapVeSesGrubuBilgiModel[];
    calismaIdList: string[];
}