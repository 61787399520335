/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import { Button } from "@mui/material";
import IcerikHelper from "../../../helpers/IcerikHelper";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import VatanSesGrupSelectField from "../../../components/vatan/VatanSesGrupSelectField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { HesapBilgiModel } from "../../../models/businessModels/HesapBilgiModel";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";

interface IKoristSarkiYuklemePopupEditorProps {
    sarkiModel: SarkiModel,
    basariliKayitSesGruplar: SesGrupModel[],
    hesapBilgiModel: HesapBilgiModel,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: WithContentRequestModel<HesapSarkiSesKayitModel> | null) => void,
}

function KoristSarkiYuklemePopupEditor(props: IKoristSarkiYuklemePopupEditorProps) {
    const { closer, basariliKayitSesGruplar, sarkiModel, hesapBilgiModel, genelDurumHelper } = props;

    const [yeniIcerik, setYeniIcerik] = useState<WithContentRequestModel<HesapSarkiSesKayitModel> | null>(null);
    const [sesGrupId, setSesGrupId] = useState<string | null>(ilkSesGrubuHesapla(sarkiModel, hesapBilgiModel, genelDurumHelper, basariliKayitSesGruplar));

    const kaydet = async () => {

        const webResponse = await genelDurumHelper.requestHelper.Korist.HesapSarkiGonder(yeniIcerik!);
        if (!webResponse.successfull)
            return;

        await IcerikHelper.DownloadIcerikMetaAsync(yeniIcerik!.icerikList.select(e => e.id), genelDurumHelper.requestHelper);

        closer(yeniIcerik);
    }

    function yeniIcerikAyarla(e: IcerikEklemeRequestModel | null): void {
        if (e == null) {
            setYeniIcerik(null);
            return;
        }

        const sarkiKayitModel = new HesapSarkiSesKayitModel();
        sarkiKayitModel.id = GuidHelper.NewGuid();

        sarkiKayitModel.hesapModelId = hesapBilgiModel.id;
        sarkiKayitModel.sarkiModelId = sarkiModel.id;
        sarkiKayitModel.sesGrupId = sesGrupId!;
        sarkiKayitModel.midiEslikVarMi = false;
        sarkiKayitModel.midiEslikHizi = null;
        sarkiKayitModel.kayitDurum = EnmKayitDurum["Yorum Bekleniyor"];
        sarkiKayitModel.yorumlar = "";
        sarkiKayitModel.resimIcerikId = null;
        sarkiKayitModel.sesIcerikId = e.id
        sarkiKayitModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        sarkiKayitModel.olusturmaTarih = DateTimeHelper.now();
        /*model.guncelleyen!: string;
        model.guncellemeTarih!: Date | null;*/

        const model = new WithContentRequestModel<HesapSarkiSesKayitModel>(sarkiKayitModel, [e]);
        setYeniIcerik(model);
    }

    function sesGrubuAyarla(e: any): void {
        if (yeniIcerik == null) {
            setSesGrupId(e);
            return;
        }

        const sarkiKayitModel = JsonHelper.deepCopy(yeniIcerik.model);
        sarkiKayitModel.sesGrupId = e;

        const model = new WithContentRequestModel<HesapSarkiSesKayitModel>(sarkiKayitModel, yeniIcerik.icerikList);
        setYeniIcerik(model);
        setSesGrupId(e);
    }

    const secilebilirSesGruplariniGetir = (sarkiSesGrupIdList: string[], tumSesGruplari: SesGrupModel[]): SesGrupModel[] => {
        if (sarkiSesGrupIdList.length === 0)
            return tumSesGruplari
        else
            return tumSesGruplari.where(e => sarkiSesGrupIdList.contains(e.id));
    }

    const screenWidth = useScreenWidth();

    return (
        <Rows width={screenWidth === "Max600" ? 250 : 400} pt={20}>
            <div style={{ margin: 10 }}>
                <VatanSesGrupSelectField
                    label="Ses Grubu"
                    error={sesGrupId === ""}
                    tumSesGruplari={secilebilirSesGruplariniGetir(sarkiModel.sesGrupIdList, props.genelDurumHelper.SeciliKoroModel.tumSesGruplari)}
                    value={sesGrupId}
                    isSesGrupDisabled={e => basariliKayitSesGruplar.any(t => t.id === e.id)}
                    onChange={(e) => sesGrubuAyarla(e)} />
            </div>
            <div style={{ margin: 10 }}>
                <VatanIcerikUploadField
                    label="Kayıt Dosyası"
                    showClearButton
                    genelDurumHelper={genelDurumHelper}
                    onChange={yeniIcerikAyarla}
                    icerikId={yeniIcerik?.icerikList.firstOrDefault()?.id ?? null}
                    icerikModel={yeniIcerik?.icerikList.firstOrDefault()}
                    icerikTip={EnmIcerikTip.Ses}
                />
            </div>
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={yeniIcerik == null || sesGrupId === ""} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );
}

function ilkSesGrubuHesapla(sarkiModel: SarkiModel, hesapBilgiModel: HesapBilgiModel, genelDurumHelper: GenelDurumHelper, basariliSesGrupModlList: SesGrupModel[]): string {
    const kullanilacakSesGruplari = sarkiModel.sesGrupIdList.where(e => basariliSesGrupModlList.all(t => t.id !== e));
    const ortak = kullanilacakSesGruplari.intersection(hesapBilgiModel.sesGrupIdList);

    if (ortak.length === 0)
        return "";
    else
        return genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => ortak.contains(e.id))
            .orderBy(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"] ? 0 : e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"] ? 1 : 2).first().id;
}

export default KoristSarkiYuklemePopupEditor;