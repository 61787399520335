// AutoGenerated
import { HesapBilgiVeKullaniciModel } from "../businessModels/HesapBilgiVeKullaniciModel"
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class HesapBilgiVeKullaniciWithContentRequestModel {
    constructor(
        model: HesapBilgiVeKullaniciModel,
        icerikList: IcerikEklemeRequestModel[],
    ) {
        this.model = model;
        this.icerikList = icerikList;
    }

    model: HesapBilgiVeKullaniciModel;
    icerikList: IcerikEklemeRequestModel[];
}