// AutoGenerated
import { EnmKatilimDurum } from "../enums/EnmKatilimDurum"

export class HesapCalismaYoklamaBildirRequestModel {
    constructor(
        calismaId: string,
        hesapId: string,
        gercekKatilimDurum: EnmKatilimDurum | null,
    ) {
        this.calismaId = calismaId;
        this.hesapId = hesapId;
        this.gercekKatilimDurum = gercekKatilimDurum;
    }

    calismaId: string;
    hesapId: string;
    gercekKatilimDurum: EnmKatilimDurum | null;
}