import CsmGenericMultiSelectChipField, { ICsmGenericMultiSelectChipFieldProps } from "../../library/components/mui/select/CsmGenericMultiSelectChipField";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";

export type IVatanEkHesapMultiSelectFieldProps = Omit<ICsmGenericMultiSelectChipFieldProps<HesapBilgiVeKullaniciModel, string>, "list" | "labelGetter" | "valueGetter"> & { genelDurumHelper: GenelDurumHelper };

function VatanEkHesapMultiSelectField(props: IVatanEkHesapMultiSelectFieldProps) {
    const { genelDurumHelper, ...rest } = props;

    const ekHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => 
        (e.sesGrupIdList.length === 0 && e.aktif) || (props.value ?? []).contains(e.id)    
    ).orderByTurkish(e => `${e.kullaniciModel.ad} ${e.kullaniciModel.soyad}`);

    return (
        <CsmGenericMultiSelectChipField
            {...rest}
            list={ekHesaplar}
            labelGetter={e => `${e.kullaniciModel.ad} ${e.kullaniciModel.soyad}`}
            valueGetter={e => e.id}
            isItemInactive={e => !e.aktif}
        />
    )
}

export default VatanEkHesapMultiSelectField;