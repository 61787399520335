// AutoGenerated
import { HesapKuartetDurumGuncelleRequestModel } from "../requestModels/HesapKuartetDurumGuncelleRequestModel"

export class HesapKuartetListDurumGuncelleRequestModel {
    constructor(
        kuartetler: HesapKuartetDurumGuncelleRequestModel[],
    ) {
        this.kuartetler = kuartetler;
    }

    kuartetler: HesapKuartetDurumGuncelleRequestModel[];
}