import 'dayjs/locale/tr';
import { CSSProperties } from "react";
import CsmDatePicker from "./CsmDatePicker";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import Cell from "../../layout/Cell";
import CsmTimePicker from "./CsmTimePicker";
import Columns from "../../layout/Columns";

export interface ICsmDateTimePickerProps {
    dateLabel: string,
    timeLabel: string,
    value: Date | null,
    onChange: (value: Date | null) => void,
    error?: boolean,
    helperText?: string,
    style?: CSSProperties,
    disabled?: boolean,
    fullWidth?: boolean,
    orientation?: "Vertical" | "Horizontal",
    errorText?: string | false | undefined,
    canClean?: boolean,
}

function CsmDateWithTimePicker(props: ICsmDateTimePickerProps) {

    const { value, canClean, fullWidth, onChange, timeLabel, dateLabel, orientation, ...rest } = props;
    const orientationToUse = orientation ?? "Horizontal";

    const updateTarih = (newDate: Date | null): void => {
        if (newDate === null)
            onChange(null);
        else {
            const totalMinutes = value ? DateTimeHelper.getTotalMinutesOfTime(value) : 60 * 15;
            const rawDate = DateTimeHelper.getDateWithoutTime(newDate);
            onChange(DateTimeHelper.addMinutes(rawDate, totalMinutes));
        }
    };

    const updateSaat = (newTime: Date | null): void => {
        if (newTime === null)
            onChange(null);
        else {
            const rawDate = DateTimeHelper.getDateWithoutTime(value!);
            onChange(DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(newTime)));
        }
    };

    return <Columns dontGrowForChild behaveAsRows={orientationToUse === "Vertical"} gap={5} >
        <Cell width={380}>
            <CsmDatePicker
                canClean={canClean}
                fullWidth={fullWidth}
                label={dateLabel}
                value={value}
                onChange={value => updateTarih(value)}
                {...rest} />
        </Cell>
        <Cell>
            <CsmTimePicker
                label={timeLabel}
                value={value}
                onChange={value => updateSaat(value)}
                {...rest} />
        </Cell>
    </Columns>
}

export default CsmDateWithTimePicker;