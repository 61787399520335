/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import React from "react";
import axios from "axios";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import EnvironmentHelper from "../../helpers/EnvironmentHelper";

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = new Date(value); // parseISO(value);        
        else if (typeof value === "object") handleDates(value);
    }
}

export function AxiosProvider(props: any) {
    const bearerTokenKey = "Bearer";

    axios.defaults.baseURL = EnvironmentHelper.GlobalMainEndpoint();

    axios.interceptors.response.use(originalResponse => {
        handleDates(originalResponse.data);
        return originalResponse;
    });

    // Add a request interceptor
    axios.interceptors.request.use(
        (config) => {
            const token = LocalStorageHelper.getToken();
            const seciliKoroId = LocalStorageHelper.getSeciliKoroId();

            if (token) {
                config.headers!["Authorization"] = `${bearerTokenKey} ${token}`;
                config.headers!["KoroId"] = `${seciliKoroId}`;
            }

            config.headers!["Access-Control-Allow-Origin"] = "*";
            config!.headers!["Content-Type"] = "application/json";
            config!.headers!["Accept"] = "application/json";

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // const MAX_REQUESTS_COUNT = 1000;
    // const INTERVAL_MS = 10;
    let PENDING_REQUESTS = 0;

    /**
     * Axios Request Interceptor
     */
    // axios.interceptors.request.use(function (config) {
    //     return new Promise((resolve, reject) => {
    //         let interval = setInterval(() => {
    //             if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
    //                 PENDING_REQUESTS++
    //                 clearInterval(interval)
    //                 resolve(config)
    //             }
    //         }, INTERVAL_MS)
    //     })
    // });

    /**
     * Axios Response Interceptor
     */
    axios.interceptors.response.use(function (response) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
        return Promise.resolve(response)
    }, function (error) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
        return Promise.reject(error)
    });

    /* RETRY ZIMBIRTISI */

    // // axios.interceptors.response.use(undefined, (err) => {
    // //     const { config, message } = err;
    // //     if (!config || !config.retry) {
    // //       return Promise.reject(err);
    // //     }
    // //     // retry while Network timeout or Network Error
    // //     if (!(message.includes("timeout") || message.includes("Network Error"))) {
    // //       return Promise.reject(err);
    // //     }
    // //     config.retry -= 1;
    // //     const delayRetryRequest = new Promise((resolve) => {
    // //       setTimeout(() => {
    // //         console.log("retry the request", config.url);
    // //         resolve(config);
    // //       }, config.retryDelay || 1000);
    // //     });
    // //     return delayRetryRequest.then(() => axios(config));
    // //   });
      
      // when request, can set retry times and retry delay time
      
    
    // Add a response interceptor
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return (
        <React.Fragment>
            {React.Children.only(props.children)}
        </React.Fragment>
    );
}