import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import Rows from "../../../library/components/layout/Rows";
import { useEffect } from "react";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import Cell from "../../../library/components/layout/Cell";
import CsmLineChart, { ICsmLineSeriesData } from "../../../library/components/charts/CsmLineChart";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import StringHelper from "../../../library/helpers/StringHelper";

function SezonStatScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <Inner genelDurumHelper={genelDurumHelper} />
}

function Inner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    // serilerim tarzlar, anahatlarım sezon

    const spreadEserTarzData = genelDurumHelper.SeciliKoroModel.tumSezonlar.selectMany(sezon => {
        const sezondakiSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id));
        const sezondakiTarzIdler = sezondakiSarkilar.selectMany(e => e.tarzModelIdList).distinct();
        const sezondakiTarzModeller = genelDurumHelper.SeciliKoroModel.tumTarzlar.where(e => sezondakiTarzIdler.contains(e.id));

        const result = sezondakiTarzModeller.select(tarzModel => ({
            tarz: tarzModel.isim,
            eserSayisi: sezondakiSarkilar.where(e => e.tarzModelIdList.contains(tarzModel.id)).length,
            sezonIsim: sezon.isim
        }));

        return result;
    });

    const eserTarzData: ICsmLineSeriesData<string>[] = spreadEserTarzData.groupBy(e => e.tarz).select(group => {
        return {
            seriesLabel: group.key,
            data: group.list.select(e => { return { key: e.sezonIsim, value: e.eserSayisi } }),
        };
    }).orderByDesc(e => e.data.sum(t => t.value));

    // serilerim ses grupları, anahtarlarım sezon

    const spreadKoristSesGrupSezonData = genelDurumHelper.SeciliKoroModel.tumSezonlar.selectMany(sezon => {
        const sezondakiKoristler = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => koristSezondaVarMi(e, sezon, genelDurumHelper));
        const result = sezondakiKoristler.select(e => ({ sesgrup: StringHelper.nullToEmpty(koristinSesGrupIsminiGetirBySezon(e, sezon, genelDurumHelper)) })).groupBy(e => e.sesgrup).select(group => {
            return {
                sesgrup: StringHelper.emptyToValue(group.key, "Bilinmiyor"),
                koristSayisi: group.list.length,
                sezonIsim: sezon.isim,
            }
        });
        return result;
    });

    const koristSesGrupSezonData: ICsmLineSeriesData<string>[] = spreadKoristSesGrupSezonData.groupBy(e => e.sesgrup).select(group => {
        return {
            seriesLabel: group.key,
            data: group.list.select(e => { return { key: e.sezonIsim, value: e.koristSayisi } }),
        };
    }).orderByDesc(e => e.data.sum(t => t.value));

    // serilerim zorluklar, anahtarlarım sezon

    const zorluklar = genelDurumHelper.SeciliKoroModel.sarkiZorlukModelList;
    const spreadZorlukSezonData = genelDurumHelper.SeciliKoroModel.tumSezonlar.selectMany(sezon => {
        const sezondakiSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id) && zorluklar.any(t => t.sarkiModelId === e.id));
        const result = sezondakiSarkilar.selectMany(e => {
            const zorluk = zorluklar.single(t => t.sarkiModelId === e.id);
            const stat = BusinessHelper.SarkiZorluk.StatOlustur(e, zorluk);
            return [
                {
                    tip: "Öğrenme",
                    zorluk: stat.ortalamaOgrenme,
                    sezonIsim: sezon.isim,
                }, {
                    tip: "Söyleme",
                    zorluk: stat.ortalamaSoyleme,
                    sezonIsim: sezon.isim,
                },
            ]
        });
        return result;
    });

    const zorlukSezonDataToplam: ICsmLineSeriesData<string>[] = spreadZorlukSezonData.groupBy(e => e.tip).select(zorlukTipGroup => {
        return {
            seriesLabel: zorlukTipGroup.key,
            data: zorlukTipGroup.list.groupBy(e => e.sezonIsim).select(e => { return { key: e.key, value: parseFloat(e.list.sum(t => t.zorluk).toFixed(1)) } }),
        };
    }).union([{
        seriesLabel: "Eksik Zorluk",
        data: genelDurumHelper.SeciliKoroModel.tumSezonlar.select(sezon => {
            const sezondakiSarkiIdler = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id)).select(e => e.id).distinct();

            return {
                key: sezon.isim,
                value: sezondakiSarkiIdler.where(e => zorluklar.all(t => t.sarkiModelId !== e)).length,
            };
        })
    }]).union([{
        seriesLabel: "Eser Sayısı",
        data: genelDurumHelper.SeciliKoroModel.tumSezonlar.select(sezon => {
            const sezondakiSarkiIdler = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id)).select(e => e.id).distinct();

            return {
                key: sezon.isim,
                value: sezondakiSarkiIdler.where(e => zorluklar.any(t => t.sarkiModelId === e)).length,
            };
        })
    }]).orderByDesc(e => e.data.sum(t => t.value));

    const zorlukSezonDataOrtalama: ICsmLineSeriesData<string>[] = spreadZorlukSezonData.groupBy(e => e.tip).select(zorlukTipGroup => {
        return {
            seriesLabel: zorlukTipGroup.key,
            data: zorlukTipGroup.list.groupBy(e => e.sezonIsim).select(e => { return { key: e.key, value: parseFloat(e.list.ave(t => t.zorluk).toFixed(1)) } })
        };
    }).union([{
        seriesLabel: "Eksik Zorluk",
        data: genelDurumHelper.SeciliKoroModel.tumSezonlar.select(sezon => {
            const sezondakiSarkiIdler = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id)).select(e => e.id).distinct();

            return {
                key: sezon.isim,
                value: sezondakiSarkiIdler.where(e => zorluklar.all(t => t.sarkiModelId !== e)).length,
            };
        })
    }]).union([{
        seriesLabel: "Eser Sayısı",
        data: genelDurumHelper.SeciliKoroModel.tumSezonlar.select(sezon => {
            const sezondakiSarkiIdler = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === sezon.id)).select(e => e.id).distinct();

            return {
                key: sezon.isim,
                value: sezondakiSarkiIdler.where(e => zorluklar.any(t => t.sarkiModelId === e)).length,
            };
        })
    }]).orderByDesc(e => e.data.sum(t => t.value));

    return (
        <MainLayout
            location={mainScreenLocations.sezonStat}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <Rows flex>
                <CsmAccordionSimple bigCenteredTitle
                    style={{ marginTop: "15px" }}
                    title="Sezonlarda Eser Sayılarının ve Tarzların Analizi"
                    alwaysOpen>
                    <Rows flex>
                        <Cell style={{ minHeight: "400px" }}>
                            <CsmLineChart chartLabel="Sezonlara Göre Eser Sayıları ve Tarzları" isStack data={eserTarzData} xAxisValueFormatter={e => e} marginForLegend={350} />
                        </Cell>
                        <Cell style={{ minHeight: "400px" }}>
                            <CsmLineChart chartLabel="Sezonlara Göre Tarzlar (Sezon içi Dağılım)" isStack data={eserTarzData} xAxisValueFormatter={e => e} marginForLegend={350} useSeriesAsPercent />
                        </Cell>
                    </Rows>
                </CsmAccordionSimple>
                <CsmAccordionSimple bigCenteredTitle
                    style={{ marginTop: "15px" }}
                    title="Sezonlarda Korist Sayılarının ve Ses Gruplarının Analizi"
                    alwaysOpen>
                    <Rows flex>
                        <Cell style={{ minHeight: "400px" }}>
                            <CsmLineChart chartLabel="Sezonlara Göre Korist Sayıları ve Ses Grupları" isStack data={koristSesGrupSezonData} xAxisValueFormatter={e => e} marginForLegend={350} />
                        </Cell>
                        <Cell style={{ minHeight: "400px" }}>
                            <CsmLineChart chartLabel="Sezonlara Göre Ses Grupları (Sezon içi Dağılım)" isStack data={koristSesGrupSezonData} xAxisValueFormatter={e => e} marginForLegend={350} useSeriesAsPercent />
                        </Cell>
                    </Rows>
                </CsmAccordionSimple>
                {!genelDurumHelper.SeciliKoroModel.koroAyarlari.sarkiZorlukArabirimiKullanilsin ? null :
                    <CsmAccordionSimple bigCenteredTitle
                        style={{ marginTop: "15px" }}
                        title="Sezonlarda Ortalama ve Toplam Zorluk Analizi"
                        alwaysOpen>
                        <Rows flex>
                            <Cell style={{ minHeight: "400px" }}>
                                <CsmLineChart chartLabel="Sezonlara Göre Toplam Eser Zorlukları" isStack={e => e === "Öğrenme" || e === "Söyleme"} data={zorlukSezonDataToplam} xAxisValueFormatter={e => e} marginForLegend={350} />
                            </Cell>
                            <Cell style={{ minHeight: "400px" }}>
                                <CsmLineChart chartLabel="Sezonlara Göre Ortalama Eser Zorlukları" isStack={e => e === "Öğrenme" || e === "Söyleme"} data={zorlukSezonDataOrtalama} xAxisValueFormatter={e => e} marginForLegend={350} />
                            </Cell>
                        </Rows>
                    </CsmAccordionSimple>}

            </Rows>
        </MainLayout>
    );
}

function koristSezondaVarMi(hesap: HesapBilgiVeKullaniciModel, sezon: SezonModel, genelDurumHelper: GenelDurumHelper): boolean {
    const kadrolari = genelDurumHelper.KadrolariniGetir(hesap.kadroVeSesGrubuBilgiModelList);
    return kadrolari.any(e => e.sezonModelId === sezon.id);
}

function koristinSesGrupIsminiGetirBySezon(hesap: HesapBilgiVeKullaniciModel, sezon: SezonModel, genelDurumHelper: GenelDurumHelper): string {
    const kadrolari = genelDurumHelper.KadrolariniGetir(hesap.kadroVeSesGrubuBilgiModelList);
    const ilkKadro = kadrolari.firstOrDefault(e => e.sezonModelId === sezon.id);

    if (!ilkKadro) return "";

    const altSesGrupId = hesap.kadroVeSesGrubuBilgiModelList.single(e => e.kadroModelId === ilkKadro.id).altSesGrupModelId;
    const anaSesGrupId = BusinessHelper.SesGrubu.AltSesGrubununAnaSesGrupIdGetir(altSesGrupId, genelDurumHelper.SeciliKoroModel.tumSesGruplari);
    return genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === anaSesGrupId).isim;
}

export default SezonStatScreen;

