// AutoGenerated
export class SezonAdaySarkiGuncelleRequestModel {
    constructor(
        sezonId: string,
        sarkiIdList: string[],
    ) {
        this.sezonId = sezonId;
        this.sarkiIdList = sarkiIdList;
    }

    sezonId: string;
    sarkiIdList: string[];
}