import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button, FormControl } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import { SecmeBasvuruSecmeNotuGuncelleRequestModel } from "../../../models/requestModels/SecmeBasvuruSecmeNotuGuncelleRequestModel";
import StringHelper from "../../../library/helpers/StringHelper";

interface ISecmeBasvuruSecmeNotuPopupEditorProps {
    basvuru: SecmeBasvuruModel
    genelDurumHelper: GenelDurumHelper,
    closer: (model: { secmeNotu: string } | null) => void,
}

function SecmeBasvuruSecmeNotuPopupEditor(props: ISecmeBasvuruSecmeNotuPopupEditorProps) {
    const [secmeNotu, setSecmeNotu] = useState<string>(StringHelper.nullToEmpty(props.basvuru.secmeNotu));

    const yoklamaKatilimDurumBildir = async () => {
        const reqModel = new SecmeBasvuruSecmeNotuGuncelleRequestModel(props.basvuru.id, secmeNotu);
        const webResponse = await props.genelDurumHelper.requestHelper.YonetimSecmeBasvuru.SecmeBasvuruSecmeNotuGuncelle(reqModel);

        if (!webResponse.successfull)
            return;

        props.closer({ secmeNotu: secmeNotu });
    }

    const secmeNotuMaxlenHataVarMi = (!!secmeNotu && secmeNotu.length > 4000);
    const secmeNotuHata = secmeNotuMaxlenHataVarMi;
    const isSmallScreen = useScreenWidth() === "Max600";

    return <Rows style={{ minWidth: isSmallScreen ? 250 : 450 }}>
        <FormControl style={{ maxWidth: 600, marginRight: 5, marginTop: 10, marginBottom: 10 }}>
            <CsmTextField
                fullWidth
                multiline
                showStats
                eventOnKeyPress
                rows={4}
                appendMaxlenOnLabel={4000}
                error={secmeNotuHata}
                label="Seçme Notu"
                value={secmeNotu}
                onChange={(e) => setSecmeNotu(e)} />
        </FormControl>

        <Columns mt={10}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" disabled={secmeNotuHata} onClick={() => yoklamaKatilimDurumBildir()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
        ;

}

export default SecmeBasvuruSecmeNotuPopupEditor;