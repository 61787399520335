import { BusinessHelper } from "../../helpers/BusinessHelper";
import { KadroModel } from "../../models/businessModels/KadroModel";
import CsmGenericMultiSelectChipField, { ICsmGenericMultiSelectChipFieldProps } from "../../library/components/mui/select/CsmGenericMultiSelectChipField";

export type IVatanKadroMultiSelectFieldProps = Omit<ICsmGenericMultiSelectChipFieldProps<KadroModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumKadroList: KadroModel[] };

function VatanKadroMultiSelectField(props: IVatanKadroMultiSelectFieldProps) {
    const { tumKadroList, ...rest } = props;
    const orderedKadroList = tumKadroList.orderBy(BusinessHelper.Kadro.SortFunc);

    return (
        <CsmGenericMultiSelectChipField
            {...rest}
            list={orderedKadroList}
            labelGetter={e => e.isim}
            valueGetter={e => e.id}
            isItemInactive={e => !e.aktif}
        />
    )
}

export default VatanKadroMultiSelectField;