// AutoGenerated
import { SecmeBasvuruModel } from "../businessModels/SecmeBasvuruModel"
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class SecmeBasvuruWithContentRequestModel {
    constructor(
        model: SecmeBasvuruModel,
        icerikList: IcerikEklemeRequestModel[],
    ) {
        this.model = model;
        this.icerikList = icerikList;
    }

    model: SecmeBasvuruModel;
    icerikList: IcerikEklemeRequestModel[];
}