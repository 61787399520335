import { SezonModel } from "../../models/businessModels/SezonModel";
import CsmGenericMultiSelectChipField, { ICsmGenericMultiSelectChipFieldProps } from "../../library/components/mui/select/CsmGenericMultiSelectChipField";

export type IVatanSezonMultiSelectFieldProps = Omit<ICsmGenericMultiSelectChipFieldProps<SezonModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumSezonlar: SezonModel[] };

function VatanSezonMultiSelectField(props: IVatanSezonMultiSelectFieldProps) {
    const { tumSezonlar, ...rest } = props;
    const orderedSezonList = tumSezonlar.orderByTurkish(e => e.isim);

    return (
        <CsmGenericMultiSelectChipField
            {...rest}
            list={orderedSezonList}
            labelGetter={(e) => e.isim}
            valueGetter={(e) => e.id}
        />
    )
}

export default VatanSezonMultiSelectField;