// AutoGenerated
export class SecmeBasvuruSecmeNotuGuncelleRequestModel {
    constructor(
        secmeBasvuruModelId: string,
        secmeNotu: string | null,
    ) {
        this.secmeBasvuruModelId = secmeBasvuruModelId;
        this.secmeNotu = secmeNotu;
    }

    secmeBasvuruModelId: string;
    secmeNotu: string | null;
}