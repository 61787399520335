// AutoGenerated
export class IcerikEklemeRequestModel {
    constructor(
        id: string,
        uzanti: string,
        base64Icerik: string,
    ) {
        this.id = id;
        this.uzanti = uzanti;
        this.base64Icerik = base64Icerik;
    }

    id: string;
    uzanti: string;
    base64Icerik: string;
}