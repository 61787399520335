import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { CsmText } from "../../../library/components/mui/CsmText";
import Columns from "../../../library/components/layout/Columns";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import Cell from "../../../library/components/layout/Cell";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import { CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { IconButton, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import KoristYorumKoristPopupEditor from "./KoristYorumKoristPopupEditor";
import { HesapYorumModel } from "../../../models/businessModels/HesapYorumModel";
import VatanKadroSegmentedMultiButtonList from "../../../components/vatan/VatanKadroSegmentedMultiButtonList";
import KorocanThumbnailForHesap from "../../../components/business/KorocanThumbnailForHesap";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import StringHelper from "../../../library/helpers/StringHelper";

function KoristYorumScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KoristYorumScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoristYorumScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const tumYorumlariGoster = props.genelDurumHelper.HasYetkiAll([EnmYetki["Kayıt Onaylama"]]);

  const [searchFilter, setSearchFilter] = useState<string>("");

  const tumKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.orderBy(BusinessHelper.Kadro.SortFunc);
  const [kadroIdList, setKadroIdList] = useState<string[]>(tumKadrolar.where(e => e.aktif).select(e => e.id))
  const [detayGoster, setDetayGoster] = useState<boolean>(false);

  /* -- END OF STANDARD -- */

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  const yorumGuncelle = async (hesap: HesapBilgiVeKullaniciModel, hesapYorum: HesapYorumModel | null) => {
    const adSoyad = `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad}`;

    const response = await AlertHelper.ShowClosableContent<HesapYorumModel>(`${adSoyad} kişisinin yorumlarını güncelle`, (closer) => (
      <KoristYorumKoristPopupEditor
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        hesapBilgi={hesap}
        hesapYorum={hesapYorum} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const newHesapYorumList = seciliKoro.hesapYorumModelList.slice();
        const oldHesapYorum = newHesapYorumList.singleOrDefault(e => e.hesapModelId === hesap.id);
        if (oldHesapYorum != null)
          newHesapYorumList.remove(oldHesapYorum);

        newHesapYorumList.push(response!);

        seciliKoro.hesapYorumModelList = newHesapYorumList;
      });
  }

  function koristYorumNodeGetir(title: string, yorum: string | null | undefined): ReactNode {
    return <Columns>
      <CsmText style={{ minWidth: "125px", width: "125px" }} smallNote={!detayGoster} note={detayGoster}>{title}:</CsmText>
      {detayGoster ?
        <CsmText smallNote={!detayGoster} note={detayGoster}>{StringHelper.emptyToValue(yorum, "-")}</CsmText>
        :
        StringHelper.isNotEmpty(yorum) ? appIcons.Zorluk.uygun_mini : <CsmText smallNote={!detayGoster} note={detayGoster}>-</CsmText>
      }
    </Columns>
  }

  const searchRolIdList = genelDurumHelper.SeciliKoroModel.tumRoller.where(e => includesSearchText(e.isim)).select(e => e.id);
  const searchKAdroIdList = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => includesSearchText(e.isim)).select(e => e.id);
  const searchSesGrupIdList = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => includesSearchText(e.isim)).select(e => e.id);

  let tumKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList;

  if (searchFilter.length > 2)
    tumKullanicilar = tumKullanicilar
      .where(e => includesSearchText(e.kullaniciModel.ad) ||
        includesSearchText(e.kullaniciModel.soyad) ||
        includesSearchText(e.kullaniciModel.soyad) ||
        searchRolIdList.intersection(e.rolIdList).length > 0 ||
        searchKAdroIdList.any(t => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, t)) ||
        searchSesGrupIdList.intersection(e.sesGrupIdList).length > 0);

  tumKullanicilar = tumKullanicilar.where(e => BusinessHelper.Genel.KoristMi(e) && kadroIdList.any(t => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, t)));
  const pasifKullanicilar = tumKullanicilar.where(e => e.aktif === false);
  const aktifKullanicilar = tumKullanicilar.where(e => e.aktif);

  const aktifAnaSesGruplarIdler = aktifKullanicilar.selectMany(e => e.sesGrupIdList).distinct();
  const pasifAnaSesGruplarIdler = pasifKullanicilar.selectMany(e => e.sesGrupIdList).distinct();
  const aktifAnaSesGruplar = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"] && aktifAnaSesGruplarIdler.contains(e.id)).orderBy(e => e.sira);
  const pasifAnaSesGruplar = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"] && pasifAnaSesGruplarIdler.contains(e.id)).orderBy(e => e.sira);

  const sesGrubununHesaplariniGetir = (hesaplar: HesapBilgiVeKullaniciModel[], sesGrup: SesGrupModel) => {
    return <Columns key={sesGrup.id} wrap={!detayGoster} behaveAsRows={detayGoster} >
      <CsmSwipableListItem itemDividerText={sesGrup.isim} />
      {hesaplar.where(e => e.sesGrupIdList.contains(sesGrup.id)).map(hesap => {
        const hesapYorum = genelDurumHelper.SeciliKoroModel.hesapYorumModelList.singleOrDefault(e => e.hesapModelId === hesap.id);
        const adSoyad = `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad}`;

        return <Columns key={hesap.id} hasLightBottomBorder mr={30} style={{ minWidth: detayGoster ? undefined : "310px" }}>
          <Rows>
            <Cell ml={5} mt={5} style={{ alignSelf: "center" }}>
              <KorocanThumbnailForHesap hesap={hesap} genelDurumHelper={genelDurumHelper} size="normal" />
            </Cell>
            <IconButton color="primary" onClick={() => yorumGuncelle(hesap, hesapYorum)}>
              {appIcons.CrudIcons.guncelle}
            </IconButton>
          </Rows>
          <Rows ml={5}>
            <CsmText style={{ width: detayGoster ? undefined : "150px", minWidth: "150px" }}>{adSoyad}</CsmText>
            {tumYorumlariGoster && koristYorumNodeGetir("Seçme Yorumları", hesapYorum?.secmeYorumlar)}
            {tumYorumlariGoster && koristYorumNodeGetir("Sosyal Yorumlar", hesapYorum?.arkadasYorumlar)}
            {koristYorumNodeGetir("Şef Yorumları", hesapYorum?.sefYorumlar)}
            {koristYorumNodeGetir("Eğitmen Yorumları", hesapYorum?.egitmenYorumlar)}
          </Rows>
        </Columns>
      })}
    </Columns>
  }

  return (
    <MainLayout
      location={mainScreenLocations.koristYorumYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows mt={10}>
        <Columns>
          <CsmText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Filtreleme")}: </CsmText>
          <Cell>
            <VatanKadroSegmentedMultiButtonList
              tumKadrolar={tumKadrolar}
              value={kadroIdList}
              onChanged={e => setKadroIdList(e)}
              inactiveKadroMode="Group" />
          </Cell>
          <Cell ml={10} mt={5}>
            <CsmTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell ml={10} mt={5}>
            <Columns style={{ alignItems: "center" }}>
              <Switch checked={detayGoster} onChange={(e) => setDetayGoster(e.target.checked)} />
              <Typography sx={{ fontSize: 12, width: "100px" }}>{detayGoster ? "Detay Göster" : "Detay Gösterme"}</Typography>
            </Columns>
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <CsmAccordionSimple
          title="Koristler"
          bigCenteredTitle
          alwaysOpen>
          <Rows>
            {aktifAnaSesGruplar.map(sesGrup => sesGrubununHesaplariniGetir(aktifKullanicilar, sesGrup))}
          </Rows>
        </CsmAccordionSimple>
        <CsmAccordionSimple
          title="Eski Koristler"
          bigCenteredTitle>
          <Rows>
            {pasifAnaSesGruplar.map(sesGrup => sesGrubununHesaplariniGetir(pasifKullanicilar, sesGrup))}
          </Rows>
        </CsmAccordionSimple>
      </Rows>
    </MainLayout>
  );
}

export default KoristYorumScreen;