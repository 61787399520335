/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { BusinessHelper } from "../helpers/BusinessHelper";
import DateTimeHelper from "../library/helpers/DateTimeHelper";
import GenelDurumHelper from "../helpers/GenelDurumHelper";
import { AppTranslateFuncType } from "../helpers/TranslationHelper";
import { CalismaModel } from "../models/businessModels/CalismaModel";
import { HesapCalismaModel } from "../models/businessModels/HesapCalismaModel";
import { HesapDuyuruModel } from "../models/businessModels/HesapDuyuruModel";
import { EnmYetki } from "../models/enums/EnmYetki";
import { appIcons } from "./appIcons";

export const mainScreenLocations = {
    yoklamaGiris: '/yonetim/yoklama',
    kayitSarkiYorum: '/yonetim/kayitsarkiyorum',
    kayitSarkiYorumSarki: '/yonetim/sarkiyorum/:sarkiId',
    kayitSarkiYorumSarki_func: (sarkiId: string) => `/yonetim/sarkiyorum/${sarkiId}`,
    koristYorumYonetim: '/yonetim/koristyorum',
    koristSarkiYorumYonetim: '/yonetim/koristsarkiyorum',
    koristSarkiYorumKoristScreen: '/yonetim/koristsarkiyorum/:hesapid',
    koristSarkiYorumKoristScreen_func: (hesapid: string) => `/yonetim/koristsarkiyorum/${hesapid}`,
    kuartetGiris: '/yonetim/kuartet',

    yoklamaCizelge: '/raporlar/yoklamacizelge',
    sarkiKayitCizelge: '/raporlar/sarkikayitcizelge',
    kuartetCizelge: '/raporlar/kuartetcizelge',
    konserRapor: '/raporlar/konserrapor',

    konserStat: '/raporlar/konserstat',
    sezonStat: '/raporlar/sezonstat',
    kayitStat: '/raporlar/kayitstat',

    repertuarRapor: '/raporlar/repertuar',
    kadroRapor: '/raporlar/kadro',
    anketRapor: '/raporlar/anket',
    anketRaporDetay: '/raporlar/anket/:anketId',
    anketRaporDetay_func: (anketId: string) => `/raporlar/anket/${anketId}`,

    sarkiMetaRapor: '/raporlar/sarkimetarapor',
    sarkiMetaAnaliz: '/raporlar/sarkimetaanaliz',
    sarkiZorlukRaporu: '/raporlar/sarkizorlukraporu',

    duyuruYonetim: '/yonetim/duyuru',
    anketYonetim: '/yonetim/anket',
    sarkiYonetim: '/yonetim/sarki',
    adaySarkiYonetim: '/yonetim/adaysarki',
    calismaYonetim: '/yonetim/calisma',
    hesapYonetim: '/yonetim/hesap',
    kadroYonetim: '/yonetim/kadro',
    sesgrupYonetim: '/yonetim/sesgrup',
    koroayarlari: '/yonetim/koroayarlari',
    rolYonetim: '/yonetim/rol',
    koroYonetim: '/admin/koro',
    genelKullaniciYonetim: '/admin/genelkullanici',
    hataYonetim: '/admin/hata',
    otpYonetim: '/admin/otp',
    geriIzlemeYonetim: '/admin/geriizleme',
    genelKoristRapor: '/yonetim/koristrapor',
    tatilYonetim: '/yonetim/tatil',
    sarkiYorumYonetim: '/yonetim/sarkiyorum',
    sarkiZorlukYonetim: '/yonetim/sarkizorluk',
    oneriSarkiYonetim: '/yonetim/onerisarki',
    konserYonetim: '/yonetim/konser',
    ozgecmisYonetim: '/yonetim/ozgecmis',
    tarzYonetim: '/yonetim/tarz',
    secmeBasvuruYonetim: '/yonetim/secmeBasvuru',
    secmeYonetim: '/yonetim/secme',
    secmeSonuc: '/yonetim/secmeSonuc',

    guncel: '/',
    sarkilar: '/sarkilar',
    kayitlarim: '/kayitlarim',
    sarkilarDetay: '/sarkilar/detay/:sarkiId',
    sarkilarDetay_func: (sarkiId: string) => `/sarkilar/detay/${sarkiId}`,
    midiDinle: '/sarkilar/:sarkiId',
    midiDinle_func: (sarkiId: string) => `/sarkilar/${sarkiId}`,
    kayitDinle: '/sarkilar/dinle/:sarkiLinkId',
    kayitDinle_func: (sarkiLinkId: string) => `/sarkilar/dinle/${sarkiLinkId}`,
    anketYanitla: '/anket/:anketId',
    anketYanitla_func: (anketId: string) => `/anket/${anketId}`,
    takvim: '/takvim',
    profil: '/profil',
    raporlar: '/raporlar',
    login: '/login',
    logout: '/logout',
    yonetim: '/yonetim',
}

const guncelScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Anasayfa", screenTitle: "Anasayfa", icon: appIcons.ScreenIcons.anasayfa, location: mainScreenLocations.guncel, getNotificationCount: getGuncelNotificationCount, getPageNotificationTooltip: getGuncelNotificationTooltip };
const sarkilarScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Şarkılar", screenTitle: "Şarkılar", icon: appIcons.ScreenIcons.sarkilar, location: mainScreenLocations.sarkilar };
const kayitlarimScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Kayıtlarım", screenTitle: "Kayıtlarım", icon: appIcons.ScreenIcons.kullaniciKayit, location: mainScreenLocations.kayitlarim, getNotificationCount: getSarkilarNotificationCount, getPageNotificationTooltip: getSarkilarNotificationTooltip, sadeceKorist: true, inUse: e => e.SeciliKoroModel.koroAyarlari.kayitArabirimiKullanilsin };
const takvimScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Takvim", screenTitle: "Takvim", icon: appIcons.ScreenIcons.takvim, location: mainScreenLocations.takvim, getNotificationCount: getTakvimNotificationCount, getPageNotificationTooltip: getTakvimNotificationTooltip };
const profilScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Profil", screenTitle: "Profil", icon: appIcons.ScreenIcons.profil, location: mainScreenLocations.profil };
const cikisScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Çıkış", screenTitle: "", icon: appIcons.ScreenIcons.logout, location: mainScreenLocations.logout };
const raporlarScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Raporlar", screenTitle: "Raporlar", icon: appIcons.ScreenIcons.kayitCizelge, location: mainScreenLocations.raporlar };
const yonetimScreenItem: MainScreenInfo = { kategori: "", yetkiler: [], menuText: "Yönetim", screenTitle: "Yönetim", icon: appIcons.ScreenIcons.koroAyarlari, location: mainScreenLocations.yonetim };

const _raporlarScreens: MainScreenInfo[] = [
    { kategori: "Çizelgeler", yetkiler: [], menuText: "Yoklama Çizelgesi", screenTitle: "Yoklama Çizelgesi", icon: appIcons.ScreenIcons.yoklamaCizelge, location: mainScreenLocations.yoklamaCizelge },
    { kategori: "Çizelgeler", yetkiler: [], menuText: "Kayıt Çizelgesi", screenTitle: "Kayıt Çizelgesi", icon: appIcons.ScreenIcons.kayitCizelge, location: mainScreenLocations.sarkiKayitCizelge, inUse: e => e.SeciliKoroModel.koroAyarlari.kayitArabirimiKullanilsin },
    { kategori: "Çizelgeler", yetkiler: [], menuText: "Kuartet Çizelgesi", screenTitle: "Kuartet Çizelgesi", icon: appIcons.ScreenIcons.kuartetCizelge, location: mainScreenLocations.kuartetCizelge, inUse: e => e.SeciliKoroModel.koroAyarlari.kuartetArabirimiKullanilsin },
    { kategori: "Çizelgeler", yetkiler: [], menuText: "Konser Çizelgesi", screenTitle: "Konser Çizelgesi", icon: appIcons.ScreenIcons.konserCizelge, location: mainScreenLocations.konserRapor, inUse: e => e.SeciliKoroModel.koroAyarlari.konserlerKullanilsin },

    { kategori: "İstatistikler", yetkiler: [], menuText: "Konser İstatistikleri", screenTitle: "Konser İstatistikleri", icon: appIcons.ScreenIcons.konserStat, location: mainScreenLocations.konserStat, inUse: e => e.SeciliKoroModel.koroAyarlari.konserlerKullanilsin },
    { kategori: "İstatistikler", yetkiler: [], menuText: "Sezon İstatistikleri", screenTitle: "Sezon İstatistikleri", icon: appIcons.ScreenIcons.sezonStat, location: mainScreenLocations.sezonStat },
    { kategori: "İstatistikler", yetkiler: [EnmYetki["Kayıt Onaylama"], EnmYetki["Kayıt Dinleme"], EnmYetki["Kayıt Kuartet İzleme"]], menuText: "Kayıt İstatistikleri", screenTitle: "Kayıt İstatistikleri", icon: appIcons.ScreenIcons.kayitStat, location: mainScreenLocations.kayitStat },

    { kategori: "Raporlar", yetkiler: [], menuText: "Repertuar Raporu", screenTitle: "Repertuar Raporu", icon: appIcons.ScreenIcons.rapor, location: mainScreenLocations.repertuarRapor },
    { kategori: "Raporlar", yetkiler: [], menuText: "Kadro Raporu", screenTitle: "Kadro Raporu", icon: appIcons.ScreenIcons.rapor, location: mainScreenLocations.kadroRapor },
    { kategori: "Raporlar", yetkiler: [EnmYetki["Anket Yönetim"], EnmYetki["Anket İzleme"]], menuText: "Anket Raporu", screenTitle: "Anket Raporu", icon: appIcons.ScreenIcons.anketYonetim, location: mainScreenLocations.anketRapor, inUse: e => e.SeciliKoroModel.koroAyarlari.anketArabirimiKullanilsin },

    { kategori: "Şarkı", yetkiler: [EnmYetki["Şarkı İzleme"]], menuText: "Şarkı Meta Raporu", screenTitle: "Şarkı Meta Raporu", icon: appIcons.ScreenIcons.meta, location: mainScreenLocations.sarkiMetaRapor },
    { kategori: "Şarkı", yetkiler: [EnmYetki["Şarkı İzleme"]], menuText: "Şarkı Meta Analiz", screenTitle: "Şarkı Meta Analiz", icon: appIcons.ScreenIcons.meta, location: mainScreenLocations.sarkiMetaAnaliz },
    { kategori: "Şarkı", yetkiler: [], menuText: "Şarkı Zorluk Raporu", screenTitle: "Şarkı Zorluk Raporu", icon: appIcons.ScreenIcons.meta, location: mainScreenLocations.sarkiZorlukRaporu, inUse: e => e.SeciliKoroModel.koroAyarlari.sarkiZorlukArabirimiKullanilsin },
];

const _yonetimScreens: MainScreenInfo[] = [
    { kategori: "Yoklama & Çalışmalar", yetkiler: [EnmYetki["Yoklama Yönetim"]], menuText: "Yoklama Girişi", screenTitle: "Yoklama Girişi", icon: appIcons.ScreenIcons.yoklamaGirisi, location: mainScreenLocations.yoklamaGiris },
    { kategori: "Yoklama & Çalışmalar", yetkiler: [EnmYetki["Calışma Yönetim"], EnmYetki["Calışma İzleme"]], menuText: "Çalışma Yönetimi", screenTitle: "Çalışma Yönetimi", icon: appIcons.ScreenIcons.calismaYonetim, location: mainScreenLocations.calismaYonetim },

    { kategori: "Kayıt Değerlendirme", yetkiler: [EnmYetki["Kayıt Onaylama"], EnmYetki["Kayıt Dinleme"]], menuText: "Şarkıların Kayıtları", screenTitle: "Şarkıların Kayıtları", icon: appIcons.ScreenIcons.kayitSarkiYorum, location: mainScreenLocations.kayitSarkiYorum, inUse: e => e.SeciliKoroModel.koroAyarlari.kayitArabirimiKullanilsin },
    { kategori: "Kayıt Değerlendirme", yetkiler: [EnmYetki["Kayıt Onaylama"], EnmYetki["Kayıt Dinleme"]], menuText: "Koristlerin Kayıtları", screenTitle: "Koristlerin Kayıtları", icon: appIcons.ScreenIcons.kullaniciKayit, location: mainScreenLocations.koristSarkiYorumYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.kayitArabirimiKullanilsin },

    { kategori: "Kuartet & Yorumlar", yetkiler: [EnmYetki["Kayıt Onaylama"]], menuText: "Kuartet Girişi", screenTitle: "Kuartet Girişi", icon: appIcons.ScreenIcons.kuartet, location: mainScreenLocations.kuartetGiris, inUse: e => e.SeciliKoroModel.koroAyarlari.kuartetArabirimiKullanilsin },
    { kategori: "Kuartet & Yorumlar", yetkiler: [EnmYetki["Kayıt Onaylama"], EnmYetki["Eğitmen Yorum Ekleme"]], menuText: "Korist Yorumları", screenTitle: "Korist Yorumları", icon: appIcons.ScreenIcons.kullaniciYonetim, location: mainScreenLocations.koristYorumYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.koristYorumArabirimiKullanilsin },
    { kategori: "Kuartet & Yorumlar", yetkiler: [EnmYetki["Şarkı Yorum Ekleme"], EnmYetki["Şarkı Yorum İzleme"]], menuText: "Şarkı Yorumları", screenTitle: "Şarkı Yorumları", icon: appIcons.ScreenIcons.sarkiYonetim, location: mainScreenLocations.sarkiYorumYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.sarkiYorumArabirimiKullanilsin },

    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Şarkı İzleme"]], menuText: "Şarkı Yönetim", screenTitle: "Şarkı Yönetim", icon: appIcons.ScreenIcons.sarkiYonetim, location: mainScreenLocations.sarkiYonetim },
    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Şarkı Yönetim"]], menuText: "Aday Şarkı Yönetim", screenTitle: "Aday Şarkı Yönetim", icon: appIcons.ScreenIcons.sarkiYonetim, location: mainScreenLocations.adaySarkiYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.adaySarkiKullanilsin },
    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Şarkı Yönetim"], EnmYetki["Kayıt Onaylama"]], menuText: "Şarkı Zorluk Yönetim", screenTitle: "Şarkı Zorluk Yönetim", icon: appIcons.ScreenIcons.sarkiYonetim, location: mainScreenLocations.sarkiZorlukYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.sarkiZorlukArabirimiKullanilsin },
    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Öneri Şarkı Yönetim"], EnmYetki["Öneri Şarkı İzleme"]], menuText: "Öneri Şarkı Yönetim", screenTitle: "Öneri Şarkı Yönetim", icon: appIcons.ScreenIcons.sarkiYonetim, location: mainScreenLocations.oneriSarkiYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.oneriSarkiKullanilsin },

    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Ses Grup Yönetim"]], menuText: "Ses Grup Yönetim", screenTitle: "Ses Grup Yönetim", icon: appIcons.ScreenIcons.sesGrupYonetim, location: mainScreenLocations.sesgrupYonetim },
    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Konser Yönetim"]], menuText: "Konser Yönetim", screenTitle: "Konser Yönetim", icon: appIcons.ScreenIcons.konserYonetim, location: mainScreenLocations.konserYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.konserlerKullanilsin },
    { kategori: "Repertuar & Ses Grupları", yetkiler: [EnmYetki["Şarkı Yönetim"]], menuText: "Tarz Yönetim", screenTitle: "Tarz Yönetim", icon: appIcons.ScreenIcons.tarzYonetim, location: mainScreenLocations.tarzYonetim },

    { kategori: "Kullanıcılar & Kadrolar", yetkiler: [EnmYetki["Korist Yönetim"]], menuText: "Kullanıcı", screenTitle: "Kullanıcı Yönetim", icon: appIcons.ScreenIcons.kullaniciYonetim, location: mainScreenLocations.hesapYonetim },
    { kategori: "Kullanıcılar & Kadrolar", yetkiler: [EnmYetki["Kadro Yönetim"]], menuText: "Kadro", screenTitle: "Kadro Yönetim", icon: appIcons.ScreenIcons.kadroYonetim, location: mainScreenLocations.kadroYonetim },
    { kategori: "Kullanıcılar & Kadrolar", yetkiler: [EnmYetki["Rol Yönetim"]], menuText: "Rol", screenTitle: "Rol Yönetim", icon: appIcons.ScreenIcons.rolYonetim, location: mainScreenLocations.rolYonetim },
    { kategori: "Kullanıcılar & Kadrolar", yetkiler: [EnmYetki["Korist Yönetim"]], menuText: "OTP Yönetim", screenTitle: "OTP Yönetim", icon: appIcons.ScreenIcons.koroYonetim, location: mainScreenLocations.otpYonetim },
    { kategori: "Kullanıcılar & Kadrolar", yetkiler: [EnmYetki["Korist Yönetim"]], menuText: "Özgeçmiş Yönetim", screenTitle: "Özgeçmiş Yönetim", icon: appIcons.ScreenIcons.koroYonetim, location: mainScreenLocations.ozgecmisYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.ozgecmisKullanilsin },

    { kategori: "Seçmeler", yetkiler: [EnmYetki["Seçme Başvuru Yönetim"]], menuText: "Seçme Başvuruları", screenTitle: "Seçme Başvuruları", icon: appIcons.ScreenIcons.secmeBasvuru, location: mainScreenLocations.secmeBasvuruYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.secmeBasvuruKullanilsin },
    { kategori: "Seçmeler", yetkiler: [EnmYetki["Kayıt Onaylama"]], menuText: "Seçmeler", screenTitle: "Seçmeler", icon: appIcons.ScreenIcons.secme, location: mainScreenLocations.secmeYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.secmeBasvuruKullanilsin },
    { kategori: "Seçmeler", yetkiler: [EnmYetki["Seçme Başvuru Yönetim"]], menuText: "Seçme Sonuçları", screenTitle: "Seçme Sonuçları", icon: appIcons.ScreenIcons.secmeSonuc, location: mainScreenLocations.secmeSonuc, inUse: e => e.SeciliKoroModel.koroAyarlari.secmeBasvuruKullanilsin },

    { kategori: "Yönetimsel", yetkiler: [EnmYetki["Koro Ayarları Yönetim"]], menuText: "Koro Ayarları", screenTitle: "Koro Ayarları", icon: appIcons.ScreenIcons.koroAyarlari, location: mainScreenLocations.koroayarlari },
    { kategori: "Yönetimsel", yetkiler: [EnmYetki["Yoklama Yönetim"], EnmYetki["Kayıt Kuartet İzleme"]], menuText: "Genel Korist Raporu", screenTitle: "Genel Korist Raporu", icon: appIcons.ScreenIcons.rolYonetim, location: mainScreenLocations.genelKoristRapor },
    { kategori: "Yönetimsel", yetkiler: [EnmYetki["Duyuru İzleme"]], menuText: "Duyuru", screenTitle: "Duyuru Yönetim", icon: appIcons.ScreenIcons.duyuruYonetim, location: mainScreenLocations.duyuruYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.duyuruArabirimiKullanilsin },
    { kategori: "Yönetimsel", yetkiler: [EnmYetki["Anket İzleme"], EnmYetki["Anket Yönetim"]], menuText: "Anket", screenTitle: "Anket Yönetim", icon: appIcons.ScreenIcons.anketYonetim, location: mainScreenLocations.anketYonetim, inUse: e => e.SeciliKoroModel.koroAyarlari.anketArabirimiKullanilsin },

    { kategori: "Admin", yetkiler: null, menuText: "Koro Yönetim", screenTitle: "Koro Yönetim", icon: appIcons.ScreenIcons.koroYonetim, location: mainScreenLocations.koroYonetim },
    { kategori: "Admin", yetkiler: null, menuText: "Genel Kullanıcı Yönetim", screenTitle: "Genel Kullanıcı Yönetim", icon: appIcons.ScreenIcons.koroYonetim, location: mainScreenLocations.genelKullaniciYonetim },
    { kategori: "Admin", yetkiler: null, menuText: "Hata Yönetim", screenTitle: "Hata Yönetim", icon: appIcons.ScreenIcons.koroYonetim, location: mainScreenLocations.hataYonetim },
    { kategori: "Admin", yetkiler: null, menuText: "Geri İzleme Yönetim", screenTitle: "Geri İzleme Yönetim", icon: appIcons.ScreenIcons.koroYonetim, location: mainScreenLocations.geriIzlemeYonetim },
    { kategori: "Admin", yetkiler: null, menuText: "Tatil Yönetim", screenTitle: "Tatil Yönetim", icon: appIcons.ScreenIcons.calismaYonetim, location: mainScreenLocations.tatilYonetim },
];

export type MainScreenInfo = {
    kategori: string,
    yetkiler: EnmYetki[] | null,
    menuText: string,
    screenTitle: string,
    icon: any, location: string,
    getNotificationCount?: (e: GenelDurumHelper) => number,
    getPageNotificationTooltip?: (e: GenelDurumHelper) => string | undefined,
    sadeceKorist?: boolean,
    inUse?: (genelDurumHelper: GenelDurumHelper) => boolean
};

export const ScreenDefContainer = (translate: AppTranslateFuncType) => {
    const mainScreensIlk5 = getMainScreenInfoListWithTranslation([
        guncelScreenItem,
        sarkilarScreenItem,
        kayitlarimScreenItem,
        takvimScreenItem,
        raporlarScreenItem,
    ], translate);

    const mainScreensIlk5WithYonetim = [
        ...mainScreensIlk5,
        ...getMainScreenInfoListWithTranslation([
            yonetimScreenItem
        ], translate)
    ];

    const mainScreens = [...getMainScreenInfoListWithTranslation([
        profilScreenItem, cikisScreenItem
    ], translate), ...mainScreensIlk5WithYonetim];

    const raporlarScreens = getMainScreenInfoListWithTranslation(_raporlarScreens, translate);
    const yonetimScreens = getMainScreenInfoListWithTranslation(_yonetimScreens, translate);

    return {
        yonetimScreenItem: mainScreens.single(e => e.location === mainScreenLocations.yonetim),
        raporlarScreenItem: mainScreens.single(e => e.location === mainScreenLocations.raporlar),
        raporlarScreens: raporlarScreens,
        yonetimScreens: yonetimScreens,
        mainScreens: mainScreens,
        allScreens: [...mainScreens, ...raporlarScreens, ...yonetimScreens,],
        mainScreensIlk5: mainScreensIlk5,
        mainScreensIlk5WithYonetim: mainScreensIlk5WithYonetim,
    }
}

// Notification methods

function getGuncelNotificationCount(e: GenelDurumHelper) {
    const aktifKoro = e.SeciliKoroModel;

    let sum = 0;

    if (aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin) {
        const okunacakDuyurular = BusinessHelper.Duyuru.KisiyeUygunDuyurulariGetir(aktifKoro.tumDuyurular, aktifKoro.hesapBilgiModel, false);
        const hesabinHesapDuyurulari = aktifKoro.hesapDuyuruModelList.where(e => e.hesapModelId === aktifKoro.hesapBilgiModel.id);
        sum += okunacakDuyurular.count(e => duyuruOkunmusMu(hesabinHesapDuyurulari.where(t => t.duyuruModelId === e.id).singleOrDefault()) === false)
    }

    if (aktifKoro.koroAyarlari.duyuruArabirimiKullanilsin) {
        const yapilacakAnketler = BusinessHelper.Anket.KisiyeUygunAnketleriGetir(aktifKoro.tumAnketler, aktifKoro.hesapBilgiModel, false);
        const hesabinHesapAnketCevaplarileri = aktifKoro.hesapAnketCevapModelList.where(e => e.hesapModelId === aktifKoro.hesapBilgiModel.id);
        sum += yapilacakAnketler.count(e => hesabinHesapAnketCevaplarileri.all(t => t.anketModelId !== e.id));
    }

    return sum;
}

function getSarkilarNotificationCount(e: GenelDurumHelper) {
    const aktifKoro = e.SeciliKoroModel;

    if (e.SeciliHesapBilgiModel.sesGrupIdList.length === 0)
        return 0;

    const kayitIstenenSarkilar = BusinessHelper.SesKayit.KayitBeklenenSarkilariGetir(aktifKoro.tumSarkilar, aktifKoro.sezonModelId);
    const hesabinSarkiKayitlari = aktifKoro.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === aktifKoro.hesapBilgiModel.id);
    const bekleyenKayitSayisi = kayitIstenenSarkilar.count(e => BusinessHelper.SesKayit.HesabinSarkiIcinYapmasiGerekenKayitVarmi(hesabinSarkiKayitlari.where(t => t.sarkiModelId === e.id)));

    return bekleyenKayitSayisi;
}

function getTakvimNotificationCount(e: GenelDurumHelper) {
    const aktifKoro = e.SeciliKoroModel;

    if (!e.SeciliKoroModel.koroAyarlari.yoklamaArabirimiKullanilsin)
        return 0;

    if (e.SeciliHesapBilgiModel.sesGrupIdList.length === 0)
        return 0;

    const gunCount = e.SeciliKoroModel.koroAyarlari.calismaYoklamaBildirimOnSuresi
    const yapilacakCalismalar = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(aktifKoro.tumCalismalar, aktifKoro.hesapBilgiModel, aktifKoro.hesapCalismaModelList, false);
    const hesabinHesapCalismalari = aktifKoro.hesapCalismaModelList.where(e => e.hesapModelId === aktifKoro.hesapBilgiModel.id);

    const gunCheck = (calismaTarih: Date) => (gunCount === 0) || DateTimeHelper.isDateInBetween(calismaTarih, DateTimeHelper.today(), DateTimeHelper.addDays(DateTimeHelper.today(), gunCount));
    const bekleyenYoklamaSayisi = yapilacakCalismalar.count(e => gunCheck(e.tarihBaslangic) && calismaIsaretlenmeliMi(hesabinHesapCalismalari.singleOrDefault(t => t.calismaModelId === e.id), e) === true);

    return bekleyenYoklamaSayisi;
}

function getGuncelNotificationTooltip(e: GenelDurumHelper) {
    const deger = getGuncelNotificationCount(e);

    if (deger === 0) return undefined;
    return `Okunmamış ${deger} duyurunuz bulunmaktadır.`
}


function getSarkilarNotificationTooltip(e: GenelDurumHelper) {
    const deger = getSarkilarNotificationCount(e);

    if (deger === 0) return undefined;
    return `Kaydını yapmadığınız ${deger} şarkı bulunmaktadır.`
}


function getTakvimNotificationTooltip(e: GenelDurumHelper) {
    const deger = getTakvimNotificationCount(e);

    if (deger === 0) return undefined;

    const gunCount = e.SeciliKoroModel.koroAyarlari.calismaYoklamaBildirimOnSuresi;

    if (gunCount === 0)
        return `Devamızlık durumunu belirtmediğiniz ${deger} çalışma bulunmaktadır.`

    let adet = gunCount.toFixed();
    let birim = "gün"

    if (gunCount % 30 === 0) {
        adet = (gunCount / 30).toFixed();
        birim = "ay";
    }
    else if (gunCount % 7 === 0) {
        adet = (gunCount / 7).toFixed();
        birim = "hafta";
    }

    return `Önümüzdeki ${adet} ${birim} için devamızlık durumunu belirtmediğiniz ${deger} çalışma bulunmaktadır.`
}

function duyuruOkunmusMu(hesapduyuru: HesapDuyuruModel | null) {
    if (hesapduyuru == null)
        return false;
    else
        return hesapduyuru.okundu === true;
}

function calismaIsaretlenmeliMi(hesapCalisma: HesapCalismaModel | null, calisma: CalismaModel) {
    if (DateTimeHelper.getDateWithoutTime(calisma.tarihBaslangic) < DateTimeHelper.today())
        return false;
    else if (hesapCalisma == null)
        return true;
    else
        return hesapCalisma.derseGercekKatilimDurum == null && hesapCalisma.katilimDurum == null;
}

export function getMainScreenInfoListWithTranslation(list: MainScreenInfo[], translate: AppTranslateFuncType): MainScreenInfo[] {
    return list.map(e => {
        return {
            kategori: translate(e.kategori as any),
            yetkiler: e.yetkiler,
            menuText: translate(e.menuText as any),
            screenTitle: translate(e.screenTitle as any),
            icon: e.icon,
            location: e.location,
            getNotificationCount: e.getNotificationCount,
            getPageNotificationTooltip: e.getPageNotificationTooltip,
            sadeceKorist: e.sadeceKorist,
            inUse: e.inUse,
        }
    });
}