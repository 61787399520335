import { ChangeEvent } from "react";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { appIcons } from "../../app/appIcons";
import IcerikHelper from "../../helpers/IcerikHelper";
import { IcerikEklemeRequestModel } from "../../models/requestModels/IcerikEklemeRequestModel";
import { GuidHelper } from "../../library/helpers/GuidHelper";
import appColors from "../../app/appColors";
import AlertHelper from "../../library/helpers/AlertHelper";
import { EnmIcerikTip } from "../../models/enums/EnmIcerikTip";
import KorocanThumbnail from "../business/KorocanThumbnail";
import KorocanThumbnailIcerikId from "../business/KorocanThumbnailIcerikId";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import ImageCropPopupEditor from "./ImageCropPopupEditor";

type selfProps = {
    onChange: (e: IcerikEklemeRequestModel | null) => void,
    icerikId: string | null,
    icerikModel?: IcerikEklemeRequestModel | null,
    icerikTip: EnmIcerikTip,
    useAvatarCrop?: boolean,
    showClearButton?: boolean,
    genelDurumHelper: GenelDurumHelper,
};

export type IVatanTextFieldProps = Omit<TextFieldProps, "onChange" | "type" | "value"> & selfProps;

function VatanIcerikUploadField(props: IVatanTextFieldProps) {

    const { useAvatarCrop, genelDurumHelper, icerikTip, onChange, icerikId, icerikModel, ...innerRest } = props;
    const { error, fullWidth, showClearButton, ...rest } = innerRest;

    const acceptType =
        (icerikTip === EnmIcerikTip.KoroLogo || icerikTip === EnmIcerikTip.Avatar || icerikTip === EnmIcerikTip["Konser Afiş"]) ? "image/*" :
            icerikTip === EnmIcerikTip.PDF ? "application/pdf" :
                (icerikTip === EnmIcerikTip.Ses || icerikTip === EnmIcerikTip.Midi) ? "audio/mp3, audio/opus, flac, audio/webm, audio/weba, audio/wav, audio/ogg, audio/m4a, audio/x-m4a, audio/oga, audio/mid, audio/aiff, audio/wma, audio/au, audio/mp4, audio/x-mp4" : undefined;

    const maxsize =
        icerikTip === EnmIcerikTip.PDF ? 20 * 1024 * 1024 :
            (icerikTip === EnmIcerikTip.Ses || icerikTip === EnmIcerikTip.Midi) ? 10 * 1024 * 1024 : undefined;


    const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
        // eslint-disable-next-line no-debugger
        debugger;
        const targetFile = event.target!.files![0];
        if (maxsize && targetFile.size > maxsize!) {
            AlertHelper.ShowMessage("Dikkat", `${IcerikHelper.ConvertNumberToSizeString(maxsize)}'tan büyük ${icerikTip === EnmIcerikTip.PDF ? "PDF" : "Ses"} dosyası yüklenemez`);
            event.target.value = event.target.defaultValue;
            return;
        }

        // eslint-disable-next-line no-debugger
        debugger;
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            let base64Content = (e.target!.result) as string;

            if (useAvatarCrop) {
                const subResult = await AlertHelper.ShowClosableContent2FullHeight<string>("Resim Boyutu Ayarla", closer =>
                    <ImageCropPopupEditor
                        base64ImageSource={base64Content}
                        genelDurumHelper={genelDurumHelper}
                        closer={closer} />
                )

                if (subResult === null)
                    return;

                base64Content = subResult;
            }

            if (icerikTip === EnmIcerikTip.Avatar)
                base64Content = await IcerikHelper.ResizeDataURL(base64Content, 150);

            base64Content = base64Content.substring(base64Content.indexOf(",") + 1);
            const lastDotIndex = targetFile.name.lastIndexOf(".");
            const uzanti = (lastDotIndex === -1 || lastDotIndex === targetFile.name.length) ? "." : targetFile.name.substring(lastDotIndex);
            const model = new IcerikEklemeRequestModel(GuidHelper.NewGuid(), uzanti, base64Content);
            onChange(model);
        };
        reader.readAsDataURL(targetFile);
    }

    const handleClear = () => {
        onChange(null);
    }

    let size = 0;
    let uzanti = "";
    let infotext = "";

    if (icerikModel) {
        size = icerikModel!.base64Icerik.length;
        uzanti = icerikModel!.uzanti;
        infotext = `${uzanti}, ${IcerikHelper.ConvertNumberToSizeString(size)}`;
    }
    else if (icerikId) {
        const metaInfo = IcerikHelper.IcerikDurumGetir(icerikId!);
        size = metaInfo.boyut;
        uzanti = metaInfo.uzanti;
        infotext = `${uzanti}, ${IcerikHelper.ConvertNumberToSizeString(size)}`;
    }

    // içerik bulunmuyorsa, infotext boş olacaktır
    let startIcon: JSX.Element | null = null;
    if (infotext) {
        if (icerikTip === EnmIcerikTip.Avatar || icerikTip === EnmIcerikTip.KoroLogo || icerikTip === EnmIcerikTip["Konser Afiş"]) {
            startIcon = (icerikModel?.base64Icerik) ?
                <KorocanThumbnail key={"empty"} icerikType="Icerik64" icerik={icerikModel?.base64Icerik} title={genelDurumHelper.translate("İçerik")} size="small" style={{ marginRight: "10px" }} />
                :
                <KorocanThumbnailIcerikId key={icerikId!} icerikId={icerikId!} title={genelDurumHelper.translate("İçerik")} genelDurumHelper={genelDurumHelper} size="small" style={{ marginRight: "10px" }} saveToStorage="None" />
                ;
        }
    }

    return (
        <TextField
            error={error}
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            value={infotext}
            contentEditable={false}
            type="text"
            InputProps={{
                style: { color: appColors.TEXT_SECONDARY },
                readOnly: true,
                startAdornment: startIcon,
                endAdornment: (
                    <>
                        <IconButton component="label" disabled={props.disabled}>
                            {appIcons.Genel.fileUpload}
                            <input
                                style={{ display: "none" }}
                                type="file"
                                hidden
                                onChange={handleUpload}
                                name="[licenseFile]"
                                accept={acceptType}
                            />
                        </IconButton>
                        {showClearButton && infotext &&
                            <IconButton onClick={() => handleClear()} disabled={props.disabled}>
                                {appIcons.Genel.backspace}
                            </IconButton>
                        }
                    </>
                ),
            }}
            {...rest}
        />

        // <TextField
        //     value={value}
        //     type="file"
        //     fullWidth={fullWidth !== undefined ? fullWidth : true}
        //     onChange={onChangeInternal}
        //     {...rest}
        // />
    )
}


export default VatanIcerikUploadField;