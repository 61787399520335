/* eslint-disable react/no-children-prop */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import OneriSarkiEklePopupEditor from "./OneriSarkiEklePopupEditor";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import Cell from "../../../library/components/layout/Cell";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import { CsmText } from "../../../library/components/mui/CsmText";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import { GuidRequestModel } from "../../../models/requestModels/GuidRequestModel";
import { OneriSarkiViewModel } from "../../../models/viewModels/OneriSarkiViewModel";
import { EnmOneriSarkiDurum } from "../../../models/enums/EnmOneriSarkiDurum";
import { OneriSarkiEkleRequestModel } from "../../../models/requestModels/OneriSarkiEkleRequestModel";
import appColors from "../../../app/appColors";
import StringHelper from "../../../library/helpers/StringHelper";

function OneriSarkiYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);

  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  const [data, setData] = useState<OneriSarkiViewModel[] | null>(null);

  async function veriGetir() {
    const response = await requestHelper.OneriSarki.Getir(new GuidRequestModel(user.seciliKoroId));

    if (response?.successfull !== true)
      return;

    setData(response.data);
  }

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    } else if (data === null) {
      veriGetir();
    }
  });

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <OneriSarkiYonetimScreenInner genelDurumHelper={genelDurumHelper} data={data ?? []} veriDegisti={veriGetir} />
}

function OneriSarkiYonetimScreenInner(props: IMainScreenProps & { data: OneriSarkiViewModel[], veriDegisti: () => void }) {
  const { data, veriDegisti, genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const yonetimYetkisi = genelDurumHelper.HasYetkiAll([EnmYetki["Öneri Şarkı Yönetim"]]);

  const oneriSarkiRowGetir = (sarkiList: OneriSarkiViewModel[]): JSX.Element[] | null => {
    if (sarkiList.length === 0)
      return null;

    return sarkiList.map((item) => {
      const olusturanKullanici = genelDurumHelper.SeciliKoroModel.hesapBilgiList.single(e => e.kullaniciModel.kullaniciAdi == item.olusturan).kullaniciModel
      const olusturanText = `${olusturanKullanici.ad} ${olusturanKullanici.soyad}`
      // const durumStyle = item.kayitGondermeSurecinde ? { color: appColors.RED } : { color: appColors.RED_DARK };

      const rightButtons: ICustomSwipeButton[] = [];
      // rightButtons.push({ icon: appIcons.CrudIcons.guncelle, text: "Güncelle", onClick: () => sarkiBilgiGuncelle(item, yonetimYetkisi, yonetimYetkisi), color: "success" });

      if (yonetimYetkisi) {
        rightButtons.push({ icon: appIcons.CrudIcons.sil, text: "Sil", onClick: () => sarkiSil(item.id), color: "error" });
        if (item.durum === EnmOneriSarkiDurum["Yorum Bekleniyor"])
          rightButtons.push({ icon: appIcons.Sarki.kayitAc, text: "Kayıt Aç", onClick: () => sarkiYorumla(item.id), color: "warning" });
      }

      return (
        <CsmSwipableListItem key={item.id} width={550}>
          <div style={{ height: 40, width: "500px" }}>
            <Columns hasLightBottomBorder>
              {rightButtons.map((item) =>
                <Button key={item.text} variant="text" title={item.text} color={item.color} style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => item.onClick!()}>
                  {item.icon}
                </Button>
              )}
              <Rows width={300}>
                <Typography noWrap style={{ overflow: "hidden" }}>{item.isim} - {StringHelper.emptyToValue(item.besteci, "(Besteci girilmemiş)")}</Typography>
                <Typography style={{ fontSize: "12px", fontStyle: "italic" }}>{olusturanText}</Typography>
              </Rows>
            </Columns>
          </div>
        </CsmSwipableListItem>
      );
    });
  }

  const sarkiBilgiGuncelle = async (sarkiModel: SarkiModel, guncellenebilir: boolean, silinebilir: boolean) => {
    // const response = await AlertHelper.ShowClosableContent<OneriSarkiEkleRequestModel>(genelDurumHelper.translate("Şarkısı Güncelle", { sarkiIsim: sarkiModel.isim }), (closer) =>
    //   <OneriSarkiEklePopupEditor
    //     guncellenebilir={guncellenebilir}
    //     silinebilir={silinebilir}
    //     guncellemeMi={true}
    //     oneriSarki={sarkiModel}
    //     tumSarkilar={genelDurumHelper.SeciliKoroModel.tumSarkilar}
    //     closer={closer}
    //     genelDurumHelper={genelDurumHelper}
    //   />
    // );

    // if (response == null)
    //   return;

    // veriDegisti();
  };

  const sarkiOneriEkle = async () => {
    const response = await AlertHelper.ShowClosableContent<OneriSarkiEkleRequestModel>(`Yeni Şarkı Önerisi Ekle`, (closer) =>
      <OneriSarkiEklePopupEditor
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    veriDegisti();
  }

  const sarkiYorumla = async (id: string) => {
    if (await AlertHelper.Confirm("Dikkat", "Şarkıyı silmek istediğinizden emin misiniz?") === false)
      return;

    const response = await genelDurumHelper.requestHelper.YonetimSarki.Sil(new GuidRequestModel(id));
    if (!response.successfull)
      return;

    veriDegisti();
  }

  const sarkiSil = async (id: string) => {
    if (await AlertHelper.Confirm("Dikkat", "Şarkıyı silmek istediğinizden emin misiniz?") === false)
      return;

    const response = await genelDurumHelper.requestHelper.YonetimSarki.Sil(new GuidRequestModel(id));
    if (!response.successfull)
      return;

    veriDegisti();
  }

  let oneriSarkilar = data;
  if (searchFilter !== "") {
    const searchFilterLower = searchFilter.toLocaleLowerCase();
    oneriSarkilar = oneriSarkilar.where(e =>
      e.isim.toLocaleLowerCase().indexOf(searchFilterLower) > -1 ||
      (e.besteci !== null && e.besteci.toLocaleLowerCase().indexOf(searchFilterLower) > -1));
  }

  const tumOneriSarkilar = oneriSarkilar.orderByTurkish(e => e.isim);
  const yorumBekleyenOneriSarkilar = tumOneriSarkilar.where(e => e.durum === EnmOneriSarkiDurum["Yorum Bekleniyor"]);
  const guncellemeBekleyenOneriSarkilar = tumOneriSarkilar.where(e => e.durum === EnmOneriSarkiDurum["Güncelleme Bekliyor"]);
  const onaylananOneriSarkilar = tumOneriSarkilar.where(e => e.durum === EnmOneriSarkiDurum["Onaylandı"]);
  const reddedilenOneriSarkilar = tumOneriSarkilar.where(e => e.durum === EnmOneriSarkiDurum["Reddedildi"]);

  return (
    <MainLayout
      location={mainScreenLocations.oneriSarkiYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns style={{ margin: "10px" }}>
          <Cell width={300}>
            <CsmTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell flex>
          </Cell>
          <Cell>
            <Button disabled={!yonetimYetkisi} onClick={() => sarkiOneriEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography>Yeni Şarkı Ekle</Typography>
            </Button>
          </Cell>
        </Columns>
        <CsmAccordionSimple
          title="Yorum Bekleyen Öneri Şarkılar"
          children={oneriSarkiRowGetir(yorumBekleyenOneriSarkilar) ?? <CsmText note>Yorum Bekleyen Öneri Şarkı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Yorum Bekleyen Öneri Şarkılar"
          children={oneriSarkiRowGetir(guncellemeBekleyenOneriSarkilar) ?? <CsmText note>Güncelleme Bekleyen Öneri Şarkı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Reddedilen Öneri Şarkılar"
          children={oneriSarkiRowGetir(reddedilenOneriSarkilar) ?? <CsmText note>Reddedilen Öneri Şarkı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Onaylanan Öneri Şarkılar"
          children={oneriSarkiRowGetir(onaylananOneriSarkilar) ?? <CsmText note>Onaylanan Öneri Şarkı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
      </Rows>
    </MainLayout>
  );
}

export default OneriSarkiYonetimScreen;