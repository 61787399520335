import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import { AnketSoruModel } from "../../../models/businessModels/AnketSoruModel";
import { HesapAnketCevapModel } from "../../../models/businessModels/HesapAnketCevapModel";
import { EnmSoruTipi } from "../../../models/enums/EnmSoruTipi";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import StringHelper from "../../../library/helpers/StringHelper";
import CsmGenericRadioButtonGroup from "../../../library/components/mui/radio/CsmGenericRadioButtonGroup";
import CsmGenericCheckboxListField from "../../../library/components/mui/common/CsmGenericCheckboxListField";
import CsmGenericOrderableList from "../../../library/components/mui/orderable/CsmGenericOrderableList";
import Cell from "../../../library/components/layout/Cell";
import Columns from "../../../library/components/layout/Columns";
import { CsmText } from "../../../library/components/mui/CsmText";


interface IAnketSoruDisplayerProps {
  anketModel: AnketModel,
  soruModel: AnketSoruModel,
  soruSiraNo: number,
  tumSorularinCevaplari: HesapAnketCevapModel[],
  genelDurumHelper: GenelDurumHelper,
  onTumSorularinCevaplariChanged: (model: HesapAnketCevapModel[]) => void,
  submittedOnce: boolean,
}

function AnketSoruDisplayer(props: IAnketSoruDisplayerProps) {
  const { anketModel, soruModel, soruSiraNo, genelDurumHelper, onTumSorularinCevaplariChanged, tumSorularinCevaplari, submittedOnce } = props;

  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  const soruyaVerilenCevaplar = tumSorularinCevaplari.where(e => e.anketSoruModelId === soruModel.id);

  const soruEkransalZorunluMu = submittedOnce && soruModel.zorunlu;

  // tek ve çok seçenek için
  const siraliSecenekler = soruModel.secenekModelList.orderBy(e => e.sira);

  // siralama için
  const siralamaCevap = soruModel.soruTipi === EnmSoruTipi.Sıralama ? soruyaVerilenCevaplar.orderBy(e => parseInt(e.secim!)) : [];

  // metodlar

  function cevapModelOlustur() {
    const result = new HesapAnketCevapModel();
    result.anketModelId = anketModel.id;
    result.anketSoruModelId = soruModel.id;
    result.hesapModelId = aktifKoro.hesapBilgiModel.id;
    return result;
  }

  function yaziCevapVerildi(yeniYaziIcerik: string): void {
    const newList = JsonHelper.deepCopy(tumSorularinCevaplari).where(e => e.anketSoruModelId !== soruModel.id);

    const yeniCevap = cevapModelOlustur();
    yeniCevap.secim = yeniYaziIcerik;
    newList.push(yeniCevap);

    onTumSorularinCevaplariChanged(newList);
  }

  function tekSecenekCevapVerildi(secenekId: string): void {
    const newList = JsonHelper.deepCopy(tumSorularinCevaplari).where(e => e.anketSoruModelId !== soruModel.id);

    const yeniCevap = cevapModelOlustur();
    yeniCevap.anketSoruSecenekId = secenekId;
    newList.push(yeniCevap);

    onTumSorularinCevaplariChanged(newList);
  }

  function cokSecenekCevapVerildi(secenekIdList: string[]): void {
    const newList = JsonHelper.deepCopy(tumSorularinCevaplari).where(e => e.anketSoruModelId !== soruModel.id);

    for (const secenekId of secenekIdList) {
      const yeniCevap = cevapModelOlustur();
      yeniCevap.anketSoruSecenekId = secenekId;
      newList.push(yeniCevap);
    }

    onTumSorularinCevaplariChanged(newList);
  }

  function siralamaCevapVerildi(orderedCevapList: HesapAnketCevapModel[]): void {
    const newList = JsonHelper.deepCopy(tumSorularinCevaplari).where(e => e.anketSoruModelId !== soruModel.id);

    for (let i = 0; i < orderedCevapList.length; i++) {
      const yeniCevap = cevapModelOlustur();
      yeniCevap.anketSoruSecenekId = orderedCevapList[i].anketSoruSecenekId;
      yeniCevap.secim = (i + 1).toFixed();
      newList.push(yeniCevap);
    }

    onTumSorularinCevaplariChanged(newList);
  }

  return <Rows pt={20} pb={20} pl={20} pr={20} hasLightBottomBorder >
    <CsmHeader pl={15} hasVeryLightBottomBorder>{`${soruSiraNo}. ${genelDurumHelper.translate("Soru")}:`} {soruModel.soruIcerik} {soruModel.zorunlu ? "(*Zorunlu)" : ""}</CsmHeader>
    {soruModel.soruTipi !== EnmSoruTipi.Metin ? null :
      <CsmTextField
        label=""
        appendMaxlenOnLabel={100}
        autoShowErrorOnEmpty={soruEkransalZorunluMu}
        error={(soruEkransalZorunluMu && StringHelper.isEmpty(soruyaVerilenCevaplar.singleOrDefault()?.secim ?? "")) || (soruyaVerilenCevaplar.singleOrDefault()?.secim ?? "").length > 1000}
        value={soruyaVerilenCevaplar.singleOrDefault()?.secim ?? ""}
        onChange={e => yaziCevapVerildi(e)}
      />
    }
    {soruModel.soruTipi !== EnmSoruTipi["Uzun Metin"] ? null :
      <CsmTextField
        label=""
        multiline
        rows={5}
        appendMaxlenOnLabel={1000}
        autoShowErrorOnEmpty={soruEkransalZorunluMu}
        showStats
        error={(soruEkransalZorunluMu && StringHelper.isEmpty(soruyaVerilenCevaplar.singleOrDefault()?.secim ?? "")) || (soruyaVerilenCevaplar.singleOrDefault()?.secim ?? "").length > 1000}
        value={soruyaVerilenCevaplar.singleOrDefault()?.secim ?? ""}
        onChange={e => yaziCevapVerildi(e)}
      />
    }
    {soruModel.soruTipi !== EnmSoruTipi["Tek Seçmeli"] ? null :
      <CsmGenericRadioButtonGroup
        hasLightItemSeperator
        error={soruEkransalZorunluMu && soruyaVerilenCevaplar.length === 0}
        value={soruyaVerilenCevaplar.firstOrDefault()?.anketSoruSecenekId ?? null}
        label={""}
        list={siraliSecenekler}
        labelGetter={e => e.secenekIcerik}
        valueGetter={e => e.id}
        onChange={e => tekSecenekCevapVerildi(e)}
        ml={10}
        hideGenericGroupBox
      />
    }
    {soruModel.soruTipi !== EnmSoruTipi["Çok Seçmeli"] ? null :
      <CsmGenericCheckboxListField
        error={soruEkransalZorunluMu && soruyaVerilenCevaplar.length === 0}
        selectedValues={soruyaVerilenCevaplar.select(e => e.anketSoruSecenekId!)}
        label={""}
        list={siraliSecenekler}
        labelGetter={e => e.secenekIcerik}
        valueGetter={e => e.id}
        onChange={e => cokSecenekCevapVerildi(e)}
      />
    }
    {soruModel.soruTipi !== EnmSoruTipi["Sıralama"] ? null :
      <Rows>
        <Columns ml={10}>
          <Rows>{Array.from(Array(soruModel.secenekModelList.length).keys()).map((item =>
            <Cell height={25} p={5} key={item}>
              <CsmText>{(item + 1).toFixed()}.</CsmText>
            </Cell>))}
          </Rows>
          <CsmGenericOrderableList
            list={siralamaCevap}
            renderItem={e => <Cell height={25} m={5} style={{ placeContent: "center" }}>{`${soruModel.secenekModelList.single(t => t.id === e.anketSoruSecenekId).secenekIcerik}`}</Cell>}
            idGetter={e => e.anketSoruSecenekId!}
            onOrderChanged={e => siralamaCevapVerildi(e)}
          />
        </Columns>
        <Columns gap={5}>
          <CsmText note italic>{genelDurumHelper.translate("Not:Seçenekleri XX ikonundan tutarak sıralayabilirsiniz-kısım1")} </CsmText>
          <DragHandleIcon style={{ alignSelf: "center" }} fontSize="small" />
          <CsmText note italic>{genelDurumHelper.translate("Not:Seçenekleri XX ikonundan tutarak sıralayabilirsiniz-kısım2")}</CsmText>
        </Columns>
      </Rows>
    }
  </Rows>
}

export default AnketSoruDisplayer;