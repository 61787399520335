import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { Button, IconButton } from "@mui/material";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import { CsmText } from "../../../library/components/mui/CsmText";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import KayitWithPdfPopupEditor from "../../yonetim/kayityorum/KayitWithPdfPopupEditor";
import AlertHelper from "../../../library/helpers/AlertHelper";
import KoristSarkiYuklemePopupEditor from "./KoristSarkiYuklemePopupEditor";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import VatanKayitRowListItem, { VatanKayitRowInnerPropsHazirla } from "../../../components/vatan/VatanKayitRowListItem";
import Columns from "../../../library/components/layout/Columns";
import Cell from "../../../library/components/layout/Cell";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import { appIcons } from "../../../app/appIcons";
import AudioMixer from "../../../components/media/AudioMixer";
import RegularAudioPlayer from "../../../components/media/RegularAudioPlayer";
import appColors from "../../../app/appColors";
import IcerikHelper from "../../../helpers/IcerikHelper";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import SarkiZorlukInfoBox from "./SarkiZorlukInfoBox";
import CsmGenericGroupBox from "../../../library/components/mui/CsmGenericGroupBox";
import { SarkiYorumModel } from "../../../models/businessModels/SarkiYorumModel";
import SarkiYorumSarkiPopupEditor from "../../yonetim/sarkiyorum/SarkiYorumSarkiPopupEditor";
import SarkiZorlukSarkiPopupEditor from "../../yonetim/sarkiZorluk/SarkiZorlukSarkiPopupEditor";
import { SarkiZorlukModel } from "../../../models/businessModels/SarkiZorlukModel";
import CsmIcerikHelper from "../../../library/helpers/CsmIcerikHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import PdfDisplayer from "../../../components/media/PdfDisplayer";

function SarkilarDetaylariScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SarkilarDetaylariScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkilarDetaylariScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;
  const { sarkiId } = useParams();

  // screen size
  const screenWidth = useScreenWidth();
  const isMediumScreen = screenWidth === "Max900" || screenWidth === "Max600" || screenWidth === "Max300";

  const sarki = genelDurumHelper.SeciliKoroModel.tumSarkilar.single(e => e.id === sarkiId);
  const koristMi = genelDurumHelper.SeciliHesapBilgiModel.sesGrupIdList.length > 0;

  const seciliHesabinKayitlari = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.sarkiModelId === sarkiId).orderBy(e => e.olusturmaTarih);
  const sonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(seciliHesabinKayitlari);
  const kayitYuklenebilir = koristMi;
  const eksikHataliKayitVarMi = koristMi && (sonDurum === null || sonDurum === EnmKayitDurum.Hatalı)

  const basariliKayitSesGruplar = BusinessHelper.SesKayit.KisininKayitYuklenemezSesGruplariniGetir(genelDurumHelper, seciliHesabinKayitlari);

  // eser yorum & Zorluk
  const sarkiYorumGuncellemeYetki = genelDurumHelper.SeciliKoroModel.koroAyarlari.sarkiYorumArabirimiKullanilsin && genelDurumHelper.HasYetkiAny([EnmYetki["Şarkı Yorum Ekleme"]]);
  const sarkiYorumGormeYetki = genelDurumHelper.SeciliKoroModel.koroAyarlari.sarkiYorumArabirimiKullanilsin && genelDurumHelper.HasYetkiAny([EnmYetki["Şarkı Yorum İzleme"], EnmYetki["Şarkı Yorum Ekleme"]]);
  const sarkiZorlukKullanim = genelDurumHelper.SeciliKoroModel.koroAyarlari.sarkiZorlukArabirimiKullanilsin;
  const sarkiZorlukGuncellemeYetki = sarkiZorlukKullanim && genelDurumHelper.HasYetkiAny([EnmYetki["Kayıt Onaylama"]]);

  const sarkiYorumModelList = !sarkiYorumGormeYetki ? [] : genelDurumHelper.SeciliKoroModel.sarkiYorumModelList.where(e => e.sarkiModelId === sarkiId).orderBy(e => e.baslik);
  const sarkiZorlukModel = !sarkiZorlukKullanim ? null : genelDurumHelper.SeciliKoroModel.sarkiZorlukModelList.singleOrDefault(e => e.sarkiModelId === sarkiId);

  // linkler
  const sesGrupMidiKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Midi Kaydı"]).orderBy(e => sesGrupGetir(e)?.sira);
  const pdfLinki = sarki.sarkiLinkModelList.firstOrDefault(e => e.linkTip === EnmSarkiLinkTip.PDF && (StringHelper.nullToEmpty(e.aciklama)).isNullOrEmpty() && !!e.icerikId);
  const eskiPdfLinkleri = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Eski PDF"] && (StringHelper.nullToEmpty(e.aciklama)).isNullOrEmpty() && !!e.icerikId);
  const fullMidiKaydi = sarki.sarkiLinkModelList.firstOrDefault(e => e.linkTip === EnmSarkiLinkTip["Full Midi Kaydı"]);
  const sesKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Ses Kaydı"]).orderBy(e => sesGrupGetir(e)?.sira);
  const koroKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Konser Videosu"]);
  const baskaKoroKayitlari = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip["Başka Koro Videosu"]);
  const kayitPropList = VatanKayitRowInnerPropsHazirla(seciliHesabinKayitlari, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

  // konserler
  const allKonserSarki = genelDurumHelper.SeciliKoroModel.tumKonserler.selectMany(e => e.konserSarkiModelList.where(t => t.sarkiModelId === sarkiId && StringHelper.isNotEmpty(t.videoUrl)));

  const alakaliKonserler = allKonserSarki.select(e => ({
    konserSarki: e,
    konser: genelDurumHelper.SeciliKoroModel.tumKonserler.single(t => t.id === e.konserModelId),
  }));

  // var/yok calculations
  const sesGrupMidiVar = sesGrupMidiKayitlari.length > 0;
  const midiKayitVar = fullMidiKaydi || sesGrupMidiVar;
  const ornekSesKayitVar = sesKayitlari.length > 0;
  const alakaliKonserVar = alakaliKonserler.length > 0;
  const koroKayitlariVar = koroKayitlari.length > 0;
  const baskaKoroKayitlariVar = baskaKoroKayitlari.length > 0;

  const kayitYukle = async () => {
    if (eksikHataliKayitVarMi === false) {
      const sesgrupIsimler = basariliKayitSesGruplar.select(e => e.isim).join(', ');
      const message = `Daha önce gönderilen ${sesgrupIsimler} kayıtlarınız onaylı/beklemede olduğu için, yeni kaydınızı ancak başka ses grupları için gönderebilirsiniz. Kayıt yükleme işlemine devam etmek istiyor musunuz?`;

      if (await AlertHelper.Confirm("Dikkat", message) === false)
        return;
    }

    const response = await AlertHelper.ShowClosableContent<WithContentRequestModel<HesapSarkiSesKayitModel>>(sarki.isim + " Kayıt Yükle", (closer) => (
      <KoristSarkiYuklemePopupEditor
        sarkiModel={sarki}
        basariliKayitSesGruplar={basariliKayitSesGruplar}
        hesapBilgiModel={genelDurumHelper.SeciliHesapBilgiModel}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiSesKayitModelList = seciliKoro.hesapSarkiSesKayitModelList;
        hesapSarkiSesKayitModelList.push(response!.model);
      });
  }

  const kayitDinle = async (kayitModel: HesapSarkiSesKayitModel) => {
    AlertHelper.ShowClosableContentFullHeight<HesapSarkiSesKayitModel>("Kayıt Dinle", (closer) => (
      <KayitWithPdfPopupEditor
        genelDurumHelper={genelDurumHelper}
        sarki={sarki}
        yorumKaydedebilir={false}
        closer={closer}
        hesapBilgi={{ ...genelDurumHelper.SeciliHesapBilgiModel, kullaniciModel: genelDurumHelper.genelDurum.kullanici }}
        hesapSarkiSesKayit={kayitModel} />
    ));
  }

  function sesGrupIsimGetir(model: SarkiLinkModel) {
    return StringHelper.nullToEmpty(sesGrupGetir(model)?.isim);
  }

  function sesGrupGetir(model: SarkiLinkModel) {
    return genelDurumHelper.SeciliKoroModel.tumSesGruplari.singleOrDefault(e => e.id === model.sesGrupId);
  }

  function pdfGoster() {
    AlertHelper.ShowContent(sarki.isim, <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={pdfLinki!.icerikId!} />)
  }

  function eskiPdfGoster(pdfIcerikId: string) {
    AlertHelper.ShowContent(sarki.isim, <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={pdfIcerikId} />)
  }

  const pdfIndir = async () => {
    const base64Content = await IcerikHelper.IcerikGetirDirekt(pdfLinki!.icerikId!, genelDurumHelper);

    CsmIcerikHelper.DownloadToClient(sarki.isim + ".pdf", CsmIcerikHelper.GetPdfSourceFromBase64Content(base64Content));
  }

  const eskiPdfIndir = async (link: SarkiLinkModel) => {
    const base64Content = await IcerikHelper.IcerikGetirDirekt(link.icerikId!, genelDurumHelper);

    CsmIcerikHelper.DownloadToClient(sarki.isim + " - " + StringHelper.emptyToValue(link.aciklama, "eski") + ".pdf", CsmIcerikHelper.GetPdfSourceFromBase64Content(base64Content));
  }

  const handleZorlukGuncelleClick = async () => {
    const response = await AlertHelper.ShowClosableContent<SarkiZorlukModel>(`${sarki.isim} şarkısının zorluklarını güncelle`, (closer) => (
      <SarkiZorlukSarkiPopupEditor
        guncellenebilir
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        sarkiModel={sarki}
        sarkiZorlukModel={sarkiZorlukModel}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const newSarkiZorlukList = seciliKoro.sarkiZorlukModelList.slice();
        const oldHesapZorluk = newSarkiZorlukList.singleOrDefault(e => e.id === response?.id);
        if (oldHesapZorluk != null)
          newSarkiZorlukList.remove(oldHesapZorluk);

        newSarkiZorlukList.push(response!);

        seciliKoro.sarkiZorlukModelList = newSarkiZorlukList;
      });
  }
  const handleYorumGuncelleClick = async (sarkiYorum: SarkiYorumModel | null) => {
    const response = await AlertHelper.ShowClosableContent<SarkiYorumModel>(`${sarki.isim} şarkısının yorumlarını güncelle`, (closer) => (
      <SarkiYorumSarkiPopupEditor
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        sarkiModel={sarki}
        sarkiYorumModel={sarkiYorum}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const newSarkiYorumList = seciliKoro.sarkiYorumModelList.slice();
        const oldHesapYorum = newSarkiYorumList.singleOrDefault(e => e.id === response?.id);
        if (oldHesapYorum != null)
          newSarkiYorumList.remove(oldHesapYorum);

        newSarkiYorumList.push(response!);

        seciliKoro.sarkiYorumModelList = newSarkiYorumList;
      });
  }

  const seciliHesabinKayitlariIcerik = seciliHesabinKayitlari.length === 0 ?
    <CsmText note>Yüklenen kayıt bulunmamaktadır.</CsmText>
    :
    kayitPropList.map(item => (
      <VatanKayitRowListItem
        {...item}
        key={item.kayit.id}
        style={{ background: BusinessHelper.SesKayit.KayitDurumRenkGetir(item.kayit.kayitDurum, item.sesGrubununSonKaydiMi ? 0.4 : 0.1) }}
        kayitSec={kayitDinle}
        genelDurumHelper={genelDurumHelper} />
    ));

  const pdfRows = <CsmGenericGroupBox style={{ marginLeft: "10px", marginTop: "5px" }} label="PDF'ler">
    <Columns style={{ gap: "10px" }}>
      <Columns style={{ placeItems: "center" }} hasLightRightBorder={eskiPdfLinkleri.length > 0}>
        <Cell><CsmText note>Güncel PDF</CsmText></Cell>
        <IconButton disabled={!pdfLinki || !pdfLinki.icerikId} onClick={() => pdfIndir()}>{appIcons.Genel.indir}</IconButton>
        <IconButton disabled={!pdfLinki || !pdfLinki.icerikId} onClick={() => pdfGoster()}>{appIcons.Genel.zoomIn}</IconButton>
      </Columns>
      {eskiPdfLinkleri.map((pdfLink, i) =>
        <Columns key={pdfLink.id} style={{ placeItems: "center" }} hasLightRightBorder={i < eskiPdfLinkleri.length - 1}>
          <Cell><CsmText note>{StringHelper.emptyToValue(pdfLink.aciklama, "Eski")} PDF</CsmText></Cell>
          <IconButton disabled={!pdfLinki || !pdfLinki.icerikId} onClick={() => eskiPdfIndir(pdfLink)}>{appIcons.Genel.indir}</IconButton>
          <IconButton disabled={!pdfLinki || !pdfLinki.icerikId} onClick={() => eskiPdfGoster(pdfLink.icerikId!)}>{appIcons.Genel.zoomIn}</IconButton>
        </Columns>
      )}
    </Columns>
  </CsmGenericGroupBox>
  // const pdfIcon = isMediumScreen ?
  //   <Button disabled={!pdfLinki || !pdfLinki.icerikId} style={{ marginBottom: "5px" }} variant="contained" onClick={() => pdfIndir()}>
  //     {appIcons.Sarki.pdf} PDF İndir
  //   </Button>
  //   :
  //   <IconButton disabled={!pdfLinki || !pdfLinki.icerikId} onClick={() => pdfIndir()}>
  //     {appIcons.Sarki.pdfUltraLarge}
  //   </IconButton>;

  return (
    <SubPageLayout
      title={sarki.isim}
      previousLocations={[{ location: mainScreenLocations.sarkilar }]}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>

        <Rows>
          <Columns dontGrowForChild mb={10}>
            <Rows mt={10}>
              <div style={{ marginLeft: "10px", flexGrow: 1 }}>{!sesGrupMidiVar ? <></> : <AudioMixer genelDurumHelper={genelDurumHelper} sarkiId={sarkiId!} autoloadAudio={false} />}</div>
              {pdfRows}
            </Rows>
          </Columns>
          <Rows>
            {midiKayitVar && <CsmAccordionSimple title={genelDurumHelper.translate("Midi Kayıtları")} innerContainerStyle={{ padding: "15px" }}>
              {!fullMidiKaydi ? null :
                <LinkContent title="Full Midi" isSmallScreen={isMediumScreen} aciklama={fullMidiKaydi.aciklama}>
                  <RegularAudioPlayer genelDurumHelper={genelDurumHelper} icerikId={fullMidiKaydi.icerikId!} title={sarki.isim + " - " + genelDurumHelper.translate("Full Midi Kaydı")} autoloadAudio={false} />
                </LinkContent>}
              {sesGrupMidiKayitlari.map(midiKayit => <LinkContent key={midiKayit.id} title={sesGrupIsimGetir(midiKayit) + " " + genelDurumHelper.translate("Midi Kaydı")} isSmallScreen={isMediumScreen} aciklama={midiKayit.aciklama}>
                <RegularAudioPlayer genelDurumHelper={genelDurumHelper} icerikId={midiKayit.icerikId!} title={sarki.isim + " - " + sesGrupIsimGetir(midiKayit) + " " + genelDurumHelper.translate("Midi Kaydı")} autoloadAudio={false} />
              </LinkContent>)}
            </CsmAccordionSimple>}

            {ornekSesKayitVar && <CsmAccordionSimple title={genelDurumHelper.translate("Örnek Ses Kayıtları")} innerContainerStyle={{ padding: "15px" }}>
              {sesKayitlari.map(sesKayit => <LinkContent key={sesKayit.id} title={sesGrupIsimGetir(sesKayit) + " " + genelDurumHelper.translate("Ses Kaydı")} isSmallScreen={isMediumScreen} aciklama={sesKayit.aciklama}>
                <RegularAudioPlayer genelDurumHelper={genelDurumHelper} icerikId={sesKayit.icerikId!} title={sarki.isim + " - " + sesGrupIsimGetir(sesKayit) + " " + genelDurumHelper.translate("Ses Kaydı")} autoloadAudio={false} />
              </LinkContent>)}
            </CsmAccordionSimple>}

            {sarkiZorlukKullanim && <CsmAccordionSimple title={genelDurumHelper.translate("İstatistikler")} innerContainerStyle={{ padding: "15px" }}>
              <Columns width={600} mr={10} mb={10}>
                <Rows>
                  {sarkiZorlukGuncellemeYetki &&
                    <Columns mb={-40} style={{ zIndex: 100, justifyContent: "flex-end" }}>
                      <IconButton onClick={() => handleZorlukGuncelleClick()} color="secondary">{appIcons.CrudIcons.guncelle}</IconButton>
                    </Columns>
                  }
                  <SarkiZorlukInfoBox sarkiModel={sarki} sarkiZorlukModel={sarkiZorlukModel} genelDurumHelper={genelDurumHelper} />
                </Rows>
              </Columns>
            </CsmAccordionSimple>}

            {sarkiYorumGormeYetki && <CsmAccordionSimple title={genelDurumHelper.translate("Şarkı Yorumları")} innerContainerStyle={{ padding: "15px" }}>

              <Rows style={{ minHeight: "150px" }}>
                {sarkiYorumModelList.length === 0 ?
                  <Columns>
                    <CsmText note italic>Eser Yorumu Bulunmamaktadır</CsmText>
                    <Cell flex></Cell>
                    {sarkiYorumGuncellemeYetki && <Columns style={{ justifyContent: "flex-end" }}><IconButton onClick={() => handleYorumGuncelleClick(null)} color="secondary">{appIcons.CrudIcons.ekle}</IconButton></Columns>}
                  </Columns>
                  :
                  sarkiYorumModelList.map((sarkiYorumModel, i) =>
                    <CsmGenericGroupBox key={sarkiYorumModel.id} style={{ minWidth: "350px", marginRight: "10px", marginBottom: "10px" }} label={sarkiYorumModel.baslik}>
                      <Rows key={sarkiYorumModel.id}>
                        {sarkiYorumGuncellemeYetki && <Columns mb={-40} style={{ justifyContent: "flex-end" }}><IconButton onClick={() => handleYorumGuncelleClick(sarkiYorumModel)} color="secondary">{appIcons.CrudIcons.guncelle}</IconButton></Columns>}
                        <CsmText style={{ minWidth: "0px", whiteSpace: "pre-line" }} small>{sarkiYorumModel.icerik}</CsmText>
                      </Rows>
                    </CsmGenericGroupBox>
                  )
                }
              </Rows>
            </CsmAccordionSimple>}

            {alakaliKonserVar && <CsmAccordionSimple title={genelDurumHelper.translate("Konser Videoları")} innerContainerStyle={{ padding: "15px" }}>
              <Columns wrap>
                {alakaliKonserler.map(konserVeSarki =>
                  <Rows key={konserVeSarki.konserSarki.id} width={224} ml={10} mr={10} mb={30}>
                    <div className="video-responsive">
                      <iframe
                        width="224"
                        height="168"
                        src={CsmIcerikHelper.findEmbedLink(konserVeSarki.konserSarki.videoUrl!)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                    <CsmText note>{konserVeSarki.konser.isim}</CsmText>
                    <CsmText note>{konserVeSarki.konser.yer} / {konserVeSarki.konser.sehirUlke}</CsmText>
                    <CsmText note italic>{DateTimeToStringHelper.toMonthDayAndDayOfWeekString(konserVeSarki.konser.tarih, genelDurumHelper.translate)}</CsmText>
                    <a target="_blank" rel="noreferrer" color={appColors.PRIMARY} style={{ display: "flex", fontSize: 14 }} href={konserVeSarki.konserSarki.videoUrl!}>{"Youtube'da aç"}</a>
                  </Rows>
                )}
              </Columns>
            </CsmAccordionSimple>}

            {koroKayitlariVar && <CsmAccordionSimple title={genelDurumHelper.translate("Konser Videoları (Diğer)")} innerContainerStyle={{ padding: "15px" }}>
              {koroKayitlari.map(link =>
                <Columns key={link.id}>
                  <Rows width={224} height={224} ml={10} mr={10} mb={30}>
                    <div className="video-responsive">
                      <iframe
                        width="224"
                        height="168"
                        src={CsmIcerikHelper.findEmbedLink(link.linkUrl!)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                    <a target="_blank" rel="noreferrer" color={appColors.PRIMARY} style={{ display: "flex", fontSize: 14 }} href={link.linkUrl!}>{link.aciklama}</a>
                  </Rows>
                </Columns>)}
            </CsmAccordionSimple>}

            {baskaKoroKayitlariVar && <CsmAccordionSimple title={genelDurumHelper.translate("Diğer Koro Videoları")} innerContainerStyle={{ padding: "15px" }}>
              {baskaKoroKayitlari.map(link =>
                <Columns key={link.id}>
                  <Rows width={224} height={224} ml={10} mr={10} mb={30}>
                    <div className="video-responsive">
                      <iframe
                        width="224"
                        height="168"
                        src={CsmIcerikHelper.findEmbedLink(link.linkUrl!)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                    <a target="_blank" rel="noreferrer" color={appColors.PRIMARY} style={{ display: "flex", fontSize: 14 }} href={link.linkUrl!}>{link.aciklama}</a>
                  </Rows>
                </Columns>
              )}
            </CsmAccordionSimple>}

            {!(koristMi && genelDurumHelper.SeciliKoroModel.koroAyarlari.kayitArabirimiKullanilsin) ? null :
              <CsmAccordionSimple title={genelDurumHelper.translate("Kayıtlarım")} innerContainerStyle={{ padding: "15px" }}>
                <Rows ml={10}>
                  {seciliHesabinKayitlariIcerik}
                  {!kayitYuklenebilir ? <></> :
                    <Button style={{ marginTop: "10px", width: 250 }} variant="contained" onClick={() => kayitYukle()}>Kayıt Yükle</Button>
                  }
                </Rows>
              </CsmAccordionSimple>
            }
          </Rows>
        </Rows>

      </Rows>
    </SubPageLayout>
  );
}

function LinkContent(props: { isSmallScreen: boolean, title: string, children: JSX.Element, aciklama?: string | null }) {
  return <Rows width={400} mr={10} mb={10}>
    <CsmText style={{ textAlign: "left" }} bold>{props.title}</CsmText>
    {!props.aciklama ? null : <CsmText style={{ marginLeft: "10px", textAlign: "left" }} small>Ek açıklamalar: {props.aciklama!}</CsmText>}
    <div style={{ marginLeft: "0px", flexGrow: 1 }}>{props.children}</div>
  </Rows>
  // return <div style={{ display: "flex", marginTop: "10px", flexDirection: props.isSmallScreen ? "column" : "row" }}>
  //   <Cell width={props.isSmallScreen ? undefined : 185} style={{ alignSelf: "flex-start" }}>
  //     {typeof props.title !== "string" ? props.title : <MyText style={{ textAlign: "left" }} bold>{props.title}</MyText>}
  //     <Cell style={{ display: props.isSmallScreen ? "none" : undefined }}>{!props.aciklama ? null : <MyText style={{ textAlign: "left" }} small>Ek açıklamalar: {props.aciklama!}</MyText>}</Cell>
  //   </Cell>
  //   <div style={{ marginLeft: "0px", flexGrow: 1 }}>{props.children}</div>
  //   <Cell style={{ display: props.isSmallScreen ? undefined : "none" }}>{!props.aciklama ? null : <MyText style={{ marginLeft: "10px", textAlign: "left" }} small>Ek açıklamalar: {props.aciklama!}</MyText>}</Cell>
  // </div>
}

export default SarkilarDetaylariScreen;