// AutoGenerated by CSSeeder - CsmSampleScreen.tsx
import Columns from "../../library/components/layout/Columns";
import Rows from "../../library/components/layout/Rows";
import { CsmText } from "../../library/components/mui/CsmText";
import Cell from "../../library/components/layout/Cell";
import { CsmHeader } from "../../library/components/mui/CsmHeader";
import appColors from "../../app/appColors";
import CsmAccordionSimple from "../../library/components/mui/containers/CsmAccordionSimple";
import CsmSampleItemCommon from "./CsmSampleItemCommon";
import CsmSampleItemContainers from "./CsmSampleItemContainers";
import CsmSampleItemDateTime from "./CsmSampleItemDateTime";
import CsmSampleItemOrderable from "./CsmSampleItemOrderable";
import CsmSampleItemPrmCst from "./CsmSampleItemPrmCst";
import CsmSampleItemRadio from "./CsmSampleItemRadio";
import CsmSampleItemSelect from "./CsmSampleItemSelect";
import CsmSampleItemTwoBox from "./CsmSampleItemTwoBox";

export enum CstSpeed {
    Slow = 1,
    Normal = 2,
    Fast = 3,
}

export interface ISampleCompontents {
    componentName: string,
    sampleUsages: {
        item: JSX.Element,
        itemWidth?: number,
        lines: string[]
        linesWidth?: number,
    }[]
}

export interface ISampleGroup {
    groupName: string,
    componenets: ISampleCompontents[]
}

function findadditionalSpaces(value: string) {
    return value.search(/\S/);
}

const generateCode = (...lines: string[]) => {

    return <Rows ml={20} p={10} style={{ background: appColors.BLACK, color: appColors.WHITE }}>
        {lines.map((e, i) => <CsmText key={i} small style={{ paddingLeft: i === 0 ? undefined : `${(findadditionalSpaces(e) + 2) * 10}px`, fontFamily: "monospace !important" }}>{e}</CsmText>)}
    </Rows>
}

export function GenerateSampleGroup(group: ISampleGroup) {
    return <CsmAccordionSimple title={group.groupName}>
        <Columns gap={30} p={10} wrap>
            {group.componenets.map((sampleComponent,i ) => (
                <Cell key={i}>
                    <Rows hasLightFullBorder style={{}}>
                        <Columns style={{ background: appColors.GRAY_LIGHT3 }} p={10}>
                            <CsmHeader>{sampleComponent.componentName} sample usages</CsmHeader></Columns>
                        <Columns gap={25} p={10} wrap>
                            {sampleComponent.sampleUsages.map((sampleUsage, i) => (
                                <Columns key={i} pr={25} hasGrayRightBorder={i !== sampleComponent.sampleUsages.length - 1}>
                                    <Cell width={100 * (sampleUsage.itemWidth ?? 3)}>
                                        {sampleUsage.item}
                                    </Cell>
                                    <Cell width={100 * (sampleUsage.linesWidth ?? 5)} >
                                        {generateCode(...sampleUsage.lines)}
                                    </Cell>
                                </Columns>
                            ))}
                        </Columns>
                    </Rows>
                </Cell>
            ))}
        </Columns>
    </CsmAccordionSimple>
}

function CsmSampleScreen() {

    return (
        <Rows flex>
            <CsmSampleItemCommon />
            <CsmSampleItemDateTime />
            <CsmSampleItemSelect />
            <CsmSampleItemPrmCst />
            <CsmSampleItemRadio />
            <CsmSampleItemOrderable />
            <CsmSampleItemTwoBox />
            <CsmSampleItemContainers />
        </Rows >
    );
}

export default CsmSampleScreen;