/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, Switch } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmDatePicker from "../../../library/components/mui/datetime/CsmDatePicker";
import VatanKadroMultiSelectField from "../../../components/vatan/VatanKadroMultiSelectField";
import { CsmText } from "../../../library/components/mui/CsmText";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import CsmTimePicker from "../../../library/components/mui/datetime/CsmTimePicker";
import VatanSesGrupMultiSelectField from "../../../components/vatan/VatanSesGrupMultiSelectField";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import VatanAltSezonSelectField from "../../../components/vatan/VatanAltSezonSelectField";
import StringHelper from "../../../library/helpers/StringHelper";

interface ICalismaPopupEditorProps {
    calismaModel: CalismaModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: CalismaModel | null) => void,
}

function CalismaPopupEditor(props: ICalismaPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [calismaModel, setCalismaModel] = useState<CalismaModel>(JsonHelper.deepCopy(props.calismaModel));

    const updateCalismaModel = (action: (model: CalismaModel) => void) => {
        const result = JsonHelper.deepCopy(calismaModel);
        action(result);
        setCalismaModel(result);
    }

    const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === calismaModel.sezonModelId);
    const icerikHataVar = !calismaModel.icerik;

    const baslangicTarihHata = (calismaModel.tarihBaslangic && calismaModel.tarihBitis && calismaModel.tarihBaslangic > calismaModel.tarihBitis)
        ? "Başlangıç saati, bitiş saatinden sonra olamaz" : "";

    const baslangicTarihHataVar = !!baslangicTarihHata || !calismaModel.tarihBaslangic;
    const bitisTarihHataVar = !calismaModel.tarihBitis;

    const kadroHataVar = false; //calismaModel.kadroIdList.length === 0 || secilebilirKadrolar.select(e => e.id).intersection(calismaModel.kadroIdList).length < calismaModel.kadroIdList.length;
    const sezonHataVar = StringHelper.isEmpty(calismaModel.sezonModelId);

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.YonetimCalisma.Guncelle(calismaModel);
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.YonetimCalisma.Ekle(calismaModel);
            if (!webResponse.successfull)
                return;
        }

        closer(calismaModel);
    }

    let calismaSuresiText = "";

    if (!baslangicTarihHataVar && !bitisTarihHataVar) {
        let calismaSure = DateTimeHelper.substractForMinutes(calismaModel.tarihBaslangic, calismaModel.tarihBitis);
        calismaSuresiText = `Çalışma süresi: ${Math.floor(calismaSure / 60)} saat`;
        calismaSure = calismaSure % 60;
        if (calismaSure > 0)
            calismaSuresiText = `${calismaSuresiText} ${Math.floor(calismaSure)} dakika`;
    }
    else
        calismaSuresiText = baslangicTarihHata;

    const updateCalismaTarih = (newDate: Date | null): void => {
        updateCalismaModel((e) => {
            if (newDate == null) {
                e.tarihBaslangic = (null as any);
                e.tarihBitis = (null as any);
            }
            else {
                const rawDate = DateTimeHelper.getDateWithoutTime(newDate);
                e.tarihBaslangic = DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(e.tarihBaslangic));
                e.tarihBitis = DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(e.tarihBitis));
            }
        });
    };

    const updateBaslangicSaat = (newTime: Date | null): void => {
        if (newTime === null) return;

        updateCalismaModel((e) => {
            const diffMinutes = DateTimeHelper.getTotalMinutesOfTime(e.tarihBitis) - DateTimeHelper.getTotalMinutesOfTime(e.tarihBaslangic);
            const rawDate = DateTimeHelper.getDateWithoutTime(e.tarihBaslangic);
            e.tarihBaslangic = DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(newTime));
            e.tarihBitis = DateTimeHelper.addMinutes(e.tarihBaslangic, diffMinutes);
        });
    };

    const updateBitisSaat = (newTime: Date | null): void => {
        if (newTime === null) return;

        updateCalismaModel((e) => {
            const rawDate = DateTimeHelper.getDateWithoutTime(e.tarihBitis);
            e.tarihBitis = DateTimeHelper.addMinutes(rawDate, DateTimeHelper.getTotalMinutesOfTime(newTime));
        });
    };

    return (
        <Rows width={850} height={300} pt={20}>
            <Columns>
                <CsmTextField
                    style={{ flex: 1, marginRight: "10px" }}
                    disabled={!guncellenebilir}
                    label="Çalışma İçeriği"
                    error={icerikHataVar}
                    value={calismaModel.icerik}
                    onChange={(e) => updateCalismaModel(t => t.icerik = e)} />
                <VatanSezonSelectField
                    error={sezonHataVar}
                    disabled={!guncellenebilir}
                    style={{ marginRight: "10px", width: "250px" }}
                    value={calismaModel.sezonModelId}
                    label="Aktif Sezon"
                    tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
                    onChange={value => updateCalismaModel(e => { e.sezonModelId = value; e.altSezonModelId = null; e.kadroIdList = []; })} />
                {!genelDurumHelper.SeciliKoroModel.koroAyarlari.altSezonlarKullanilsin ? null :
                    <VatanAltSezonSelectField
                        error={false}
                        disabled={!guncellenebilir}
                        style={{ marginRight: "10px", width: "250px" }}
                        value={calismaModel.altSezonModelId}
                        label="Alt Sezon"
                        tumAltSezonlar={!calismaModel.sezonModelId ? [] : genelDurumHelper.SeciliKoroModel.tumSezonlar.single(e => e.id === calismaModel.sezonModelId).altSezonlar}
                        onChange={value => updateCalismaModel(e => e.altSezonModelId = value)} />
                }
            </Columns>
            <Columns mt={10}>
                <CsmDatePicker
                    disabled={!guncellenebilir}
                    label="Çalışma Tarihi"
                    error={calismaModel.tarihBaslangic == null || calismaModel.tarihBitis == null}
                    value={calismaModel.tarihBaslangic}
                    style={{ marginRight: 10, width: "170px" }}
                    onChange={(e) => updateCalismaTarih(e ?? null as any)} />
                <CsmTimePicker
                    disabled={!guncellenebilir}
                    label="Başlama Saati"
                    error={baslangicTarihHataVar}
                    value={calismaModel.tarihBaslangic}
                    style={{ marginRight: 10, width: "125px" }}
                    onChange={(e) => updateBaslangicSaat(e)} />
                <CsmTimePicker
                    disabled={!guncellenebilir}
                    label="Bitiş Saati"
                    error={bitisTarihHataVar}
                    value={calismaModel.tarihBitis}
                    style={{ marginRight: 10, width: "125px" }}
                    onChange={(e) => updateBitisSaat(e)} />
                <Rows ml={10} mt={15}>
                    <CsmText note>{`${calismaSuresiText}`}</CsmText>
                </Rows>
                <Rows width={220} ml={10}>
                    <CsmText style={{ marginLeft: "12px" }}>Konser veya Etkinlik mi?</CsmText>
                    <Columns mt={-5}>
                        <Switch
                            checked={calismaModel.etkinlikKonserMi}
                            onChange={(value) => updateCalismaModel(t => t.etkinlikKonserMi = value.target.checked)} />
                        <CsmText note style={{ marginTop: "7px" }}>{calismaModel.etkinlikKonserMi ? "Evet, Ekinlik/Konser" : "Hayır, Çalışma"}</CsmText>
                    </Columns>
                </Rows>
            </Columns>
            <Columns>
                <VatanKadroMultiSelectField
                    sx={{ height: "90px", alignContent: "flex-start", justifyItems: "flex-start" }}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    disabled={!guncellenebilir}
                    label="Hedef Kadrolar"
                    error={kadroHataVar}
                    value={calismaModel.kadroIdList}
                    tumKadroList={secilebilirKadrolar}
                    onChange={(e) => updateCalismaModel(t => t.kadroIdList = e)}
                    id={calismaModel.id} />
                <VatanSesGrupMultiSelectField
                    sx={{ height: "90px", alignContent: "flex-start", justifyItems: "flex-start" }}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    disabled={!guncellenebilir}
                    label="Hedef Ses Grupları"
                    value={calismaModel.sesGrupIdList}
                    tumSesGruplari={genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip !== EnmSesGrupTip.Diğer)}
                    onChange={(e) => updateCalismaModel(t => t.sesGrupIdList = e)}
                    id={calismaModel.id} />
            </Columns>


            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={icerikHataVar || baslangicTarihHataVar || bitisTarihHataVar || kadroHataVar || sezonHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default CalismaPopupEditor;