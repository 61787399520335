/* eslint-disable react/no-children-prop */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { CsmText } from "../../../library/components/mui/CsmText";
import Columns from "../../../library/components/layout/Columns";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import Cell from "../../../library/components/layout/Cell";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import VatanKadroSegmentedMultiButtonList from "../../../components/vatan/VatanKadroSegmentedMultiButtonList";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";

function KoristSarkiYorumScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KoristSarkiYorumScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoristSarkiYorumScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const tumKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar;
  const [kadroIdList, setKadroIdList] = useState<string[]>(tumKadrolar.select(e => e.id))

  /* -- END OF STANDARD -- */

  const koristKayitlariniGoster = async (hesap: HesapBilgiVeKullaniciModel) => {
    genelDurumHelper.navigate(mainScreenLocations.koristSarkiYorumKoristScreen_func(hesap.id));
  }

  let tumKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e) && kadroIdList.any(t => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, t)));

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  const searchRolIdList = genelDurumHelper.SeciliKoroModel.tumRoller.where(e => includesSearchText(e.isim)).select(e => e.id);
  const searchKadroIdList = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => includesSearchText(e.isim)).select(e => e.id);
  const searchSesGrupIdList = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => includesSearchText(e.isim)).select(e => e.id);

  if (searchFilter.length > 2)
    tumKullanicilar = tumKullanicilar
      .where(e => includesSearchText(e.kullaniciModel.ad) ||
        includesSearchText(e.kullaniciModel.soyad) ||
        includesSearchText(e.kullaniciModel.soyad) ||
        searchRolIdList.intersection(e.rolIdList).length > 0 ||
        searchKadroIdList.any(t => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, t)) ||
        searchSesGrupIdList.intersection(e.sesGrupIdList).length > 0);

  const aktifKoristler = tumKullanicilar.where(e => e.aktif && !e.araVerdi);
  const araVerenKoristler = tumKullanicilar.where(e => e.aktif && e.araVerdi);
  const pasifKoristler = tumKullanicilar.where(e => e.aktif === false);

  function badgeCountGetter(hesap: HesapBilgiVeKullaniciModel): { error: number, info: number } {
    return {
      error: BusinessHelper.SesKayit.HesabinYorumBekleyenKayitSayisiniGetirForYonetim(hesap.id, genelDurumHelper),
      info: BusinessHelper.SesKayit.HesabinGonderilmisSarkiSayisiniGetirForYonetim(hesap.id, genelDurumHelper),
    }
  }

  return (
    <MainLayout
      location={mainScreenLocations.koristSarkiYorumYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows mt={10}>
        <Columns>
          <CsmText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>Filtreleme: </CsmText>
          <Cell>
            <VatanKadroSegmentedMultiButtonList
              tumKadrolar={tumKadrolar}
              value={kadroIdList}
              onChanged={e => setKadroIdList(e)}
              inactiveKadroMode="Group" />
          </Cell>
          <Cell ml={5} mt={10}>
            <CsmTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <CsmAccordionSimple
          title="Koristler"
          bigCenteredTitle
          alwaysOpen>
          <SesGrupluKisiAvatarListControl
            tumHesaplar={tumKullanicilar}
            gosterilecekHesaplar={aktifKoristler}
            onClick={(hesap) => { koristKayitlariniGoster(hesap) }}
            thumbnailSize="large"
            badgeCountGetter={badgeCountGetter}
            genelDurumHelper={genelDurumHelper} />
        </CsmAccordionSimple>
        <CsmAccordionSimple
          title="Ara Veren Koristler"
          bigCenteredTitle
          children={() => (
            <SesGrupluKisiAvatarListControl
              tumHesaplar={tumKullanicilar}
              gosterilecekHesaplar={araVerenKoristler}
              onClick={(hesap) => { koristKayitlariniGoster(hesap) }}
              thumbnailSize="normal"
              badgeCountGetter={badgeCountGetter}
              genelDurumHelper={genelDurumHelper} />)}
        />
        <CsmAccordionSimple
          title="Eski Koristler"
          bigCenteredTitle
          children={() => (
            <SesGrupluKisiAvatarListControl
              tumHesaplar={tumKullanicilar}
              gosterilecekHesaplar={pasifKoristler}
              onClick={(hesap) => { koristKayitlariniGoster(hesap) }}
              thumbnailSize="normal"
              badgeCountGetter={badgeCountGetter}
              genelDurumHelper={genelDurumHelper} />)}
        />
      </Rows>
    </MainLayout>
  );
}

export default KoristSarkiYorumScreen;