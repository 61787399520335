import CsmGenericGroupBox from "../CsmGenericGroupBox";
import { StringOrNumber } from "../../../helpers/StringOrNumber";
import CsmGenericOrderableList, { ICsmGenericOrderableListProps } from "./CsmGenericOrderableList";
import JsonHelper from "../../../helpers/JsonHelper";

export interface ICsmGenericOrderableListFieldProps<T, U extends StringOrNumber> extends ICsmGenericOrderableListProps<T, U> {
    label: string,
    error?: boolean,
    disabled?: boolean,
    orderGetter: (e: T) => number,
    orderSetter: (e: T, newOrder: number) => void,
}

export default function CsmGenericOrderableListField<T, U extends StringOrNumber>(props: ICsmGenericOrderableListFieldProps<T, U>) {
    const { label, error, disabled, style, orderGetter, orderSetter, onOrderChanged, list, ...others } = props;

    function customOnOrderChanged(newOrderedList: T[]): void {
        const result = JsonHelper.deepCopy(newOrderedList);

        for (let i = 0; i < result.length; i++)
            orderSetter(result[i], i + 1);

        onOrderChanged(result);
    }

    return <CsmGenericGroupBox disabled={disabled} label={label} error={error} style={{ width: "100%", marginTop: 0, ...style }} >
        <CsmGenericOrderableList
            list={list.orderBy(orderGetter)}
            onOrderChanged={customOnOrderChanged}
            {...others} />
    </CsmGenericGroupBox>
}