/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Rows from "../../library/components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { CsmText } from "../../library/components/mui/CsmText";
import { IMainScreenProps, SubPageLayout } from "../../components/layout/MainLayout";
import { appIcons } from "../../app/appIcons";
import { mainScreenLocations } from "../../app/mainScreens";
import Columns from "../../library/components/layout/Columns";
import React from "react";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../helpers/TranslationHelper";

function IconsYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <IconsYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function IconsYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const appIconKeys = Object.keys(appIcons);

  function RenderAsIcon(key: string, subKey: string): React.ReactNode {
    let item = ((appIcons as any)[key] as any)[subKey];

    if (React.isValidElement(item))
      return item as React.ReactNode;
    else {
      if (item instanceof Function)
        item = item(undefined);

      if (React.isValidElement(item))
        return item as React.ReactNode;
      else
        return null;
    }
  }

  return (
    <SubPageLayout
      title="Icons"
      previousLocations={[{ location: mainScreenLocations.guncel }]}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      {appIconKeys.map(key =>
        <Rows key={key}>
          <CsmText>Kadro: {key}</CsmText>
          <Columns wrap>
            {Object.keys((appIcons as any)[key]).map(subKey =>
              <Rows key={subKey} width={120} style={{ margin: 10, textAlign: "center", alignItems: "center" }}>
                {RenderAsIcon(key, subKey)}
                <CsmText>{subKey}</CsmText>
              </Rows>
            )}
          </Columns>
        </Rows>
      )}
    </SubPageLayout>
  );
}

export default IconsYonetimScreen;