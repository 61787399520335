import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";

export type ICsmCheckboxProps = Omit<CheckboxProps, "onChange"> & { label: string, onChange: (value: boolean) => void } ;

function CsmCheckbox(props: ICsmCheckboxProps) {
    const { sx, style, label, onChange,  ...rest } = props;
    
    function onChangeIntenal(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
        if (onChange)
            onChange(event.target.checked);
    }

    return <FormControlLabel sx={sx} style={style} control={<Checkbox onChange={onChangeIntenal} {...rest} />} label={label} />
}

export default CsmCheckbox;