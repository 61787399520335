// AutoGenerated
export class HesapEgitmenYorumEkleGuncelleRequestModel {
    constructor(
        hesapId: string,
        egitmenYorumlar: string | null,
    ) {
        this.hesapId = hesapId;
        this.egitmenYorumlar = egitmenYorumlar;
    }

    hesapId: string;
    egitmenYorumlar: string | null;
}