// AutoGenerated
export enum EnmYetki {
    "Duyuru Yönetim" = 1,
    "Duyuru İzleme" = 2,
    "Anket Yönetim" = 3,
    "Anket İzleme" = 4,
    "Calışma Yönetim" = 5,
    "Calışma İzleme" = 6,
    "Şarkı Yönetim" = 7,
    "Şarkı İzleme" = 8,
    "Rol Yönetim" = 9,
    "Kadro Yönetim" = 10,
    "Ses Grup Yönetim" = 11,
    "Koro Ayarları Yönetim" = 12,
    "Korist Yönetim" = 13,
    "Kayıt Onaylama" = 50,
    "Yoklama Yönetim" = 51,
    "Kayıt Kuartet İzleme" = 52,
    "Konser Yönetim" = 53,
    "Seçme Başvuru Yönetim" = 54,
    "Eğitmen Yorum Ekleme" = 55,
    "Kayıt Dinleme" = 56,
    "Şarkı Yorum Ekleme" = 57,
    "Şarkı Yorum İzleme" = 58,
    "Öneri Şarkı Ekleme" = 59,
    "Öneri Şarkı Yönetim" = 60,
    "Öneri Şarkı İzleme" = 61,
    "Kayıt Yükleme (Korist)" = 1000,
}