// AutoGenerated
export class SecmeBasvuruTarihGuncelleRequestModel {
    constructor(
        secmeBasvuruModelId: string,
        secmeTarih: Date | null,
    ) {
        this.secmeBasvuruModelId = secmeBasvuruModelId;
        this.secmeTarih = secmeTarih;
    }

    secmeBasvuruModelId: string;
    secmeTarih: Date | null;
}