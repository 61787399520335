// AutoGenerated
export class HesapYorumEkleGuncelleRequestModel {
    constructor(
        hesapId: string,
        secmeYorumlar: string | null,
        arkadasYorumlar: string | null,
        egitmenYorumlar: string | null,
        sefYorumlar: string | null,
    ) {
        this.hesapId = hesapId;
        this.secmeYorumlar = secmeYorumlar;
        this.arkadasYorumlar = arkadasYorumlar;
        this.egitmenYorumlar = egitmenYorumlar;
        this.sefYorumlar = sefYorumlar;
    }

    hesapId: string;
    secmeYorumlar: string | null;
    arkadasYorumlar: string | null;
    egitmenYorumlar: string | null;
    sefYorumlar: string | null;
}