// AutoGenerated by CSSeeder - CsmSampleItemOrderable.tsx
import { useState } from "react";
import JsonHelper from "../../library/helpers/JsonHelper";
import { GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmGenericOrderableList from "../../library/components/mui/orderable/CsmGenericOrderableList";
import { CsmText } from "../../library/components/mui/CsmText";
import CsmGenericOrderableListField from "../../library/components/mui/orderable/CsmGenericOrderableListField";
import CsmGenericOrderableListWithHandle from "../../library/components/mui/orderable/CsmGenericOrderableListWithHandle";

interface ISampleScreenState {
    orderableList1: { id: number, title: string }[],
    orderableList2: { id: number, title: string, orderNo: number }[],
    orderableList3: { id: number, title: string }[],
}

export default function CsmSampleItemOrderable() {
    const [model, setModel] = useState<ISampleScreenState>({
        orderableList1: [{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }],
        orderableList2: [{ id: 1, title: "Item 1", orderNo: 1 }, { id: 2, title: "Item 2", orderNo: 2 }, { id: 3, title: "Item 3", orderNo: 3 }],
        orderableList3: [{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }],
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const sample: ISampleGroup = {
        groupName: "Orderable List variations",
        componenets: [
            {
                componentName: "CsmGenericOrderableList",
                sampleUsages: [
                    {
                        item: <CsmGenericOrderableList
                            list={model.orderableList1}
                            renderItem={e => <CsmText>{e.title}</CsmText>}
                            idGetter={e => e.id}
                            onOrderChanged={e => modelChanged(t => t.orderableList1 = e)} />,
                        lines: [
                            `<CsmGenericOrderableList`,
                            `list={model.orderableList1}`,
                            `renderItem={e => <CsmText>{e.title}</CsmText>}`,
                            `idGetter={e => e.id}`,
                            `onOrderChanged={e => modelChanged(t => t.orderableList1 = e)} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericOrderableListField",
                sampleUsages: [
                    {
                        item: <CsmGenericOrderableListField
                            label="Generic Orderable List Field"
                            list={model.orderableList2}
                            renderItem={e => <CsmText>{e.title}</CsmText>}
                            idGetter={e => e.id}
                            onOrderChanged={e => modelChanged(t => t.orderableList2 = e)}
                            orderGetter={e => e.orderNo}
                            orderSetter={(e, no) => e.orderNo = no} />,
                        lines: [
                            `<CsmGenericOrderableListField`,
                            `label="Generic Orderable List Field"`,
                            `list={model.orderableList2}`,
                            `renderItem={e => <CsmText>{e.title}</CsmText>}`,
                            `idGetter={e => e.id}`,
                            `onOrderChanged={e => modelChanged(t => t.orderableList2 = e)}`,
                            `orderGetter={e => e.orderNo}`,
                            `orderSetter={(e, no) => e.orderNo = no} />`,
                        ]
                    },
                    {
                        item: <CsmGenericOrderableListField
                            label="Error Generic Orderable List Field"
                            list={model.orderableList2}
                            renderItem={e => <CsmText>{e.title}</CsmText>}
                            idGetter={e => e.id}
                            onOrderChanged={e => modelChanged(t => t.orderableList2 = e)}
                            orderGetter={e => e.orderNo}
                            orderSetter={(e, no) => e.orderNo = no}
                            error />,
                        lines: [
                            `<CsmGenericOrderableListField`,
                            `label="Error Generic Orderable List Field"`,
                            `list={model.orderableList2}`,
                            `renderItem={e => <CsmText>{e.title}</CsmText>}`,
                            `idGetter={e => e.id}`,
                            `onOrderChanged={e => modelChanged(t => t.orderableList2 = e)}`,
                            `orderGetter={e => e.orderNo}`,
                            `orderSetter={(e, no) => e.orderNo = no}`,
                            `error />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericOrderableListWithHandle",
                sampleUsages: [
                    {
                        item: <CsmGenericOrderableListWithHandle
                            list={model.orderableList3}
                            renderItem={e => <CsmText>{e.title}</CsmText>}
                            idGetter={e => e.id}
                            onOrderChanged={e => modelChanged(t => t.orderableList3 = e)} />,
                        lines: [
                            `<CsmGenericOrderableListWithHandle`,
                            `list={model.orderableList3}`,
                            `renderItem={e => <CsmText>{e.title}</CsmText>}`,
                            `idGetter={e => e.id}`,
                            `onOrderChanged={e => modelChanged(t => t.orderableList3 = e)} />`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(sample);
}