/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction, i18n } from "i18next";
import { mergedTranslationKeyType, mergedTranslations } from "../translations/mergedTranslations";

export type AppTranslateFuncType = (a: mergedTranslationKeyType, v?: { [key: string]: string }) => string;
export type AppUnsafeTranslateFuncType = (a: string, v?: { [key: string]: string }) => string;

const trTrans: { [key: string]: string } = {};
const enTrans: { [key: string]: string } = {};

for (const itemKey in mergedTranslations) {
    trTrans[itemKey] = (mergedTranslations[itemKey as mergedTranslationKeyType] as any).tr ?? itemKey;
    enTrans[itemKey] = (mergedTranslations[itemKey as mergedTranslationKeyType] as any).en;
}
export const allAppTranslations = {
    "tr": { translation: trTrans },
    "en": { translation: enTrans }
}

const TranslationHelper = {
    TransFunc: (t: TFunction<"translation", undefined, "translation">): AppTranslateFuncType =>
        (a: mergedTranslationKeyType, v?: { [key: string]: string }) => ((a as any) === "" ? "" : (v ? (t((a as any), v!) ?? "") : (t(a, { defaultValue: (a as any) + "-XXXXXX" }) ?? ""))),

    getCurrentLanguageItem: (i18n: i18n) => {
        const key = i18n.resolvedLanguage ?? "tr";
        return TranslationHelper.KorocanLanguages.singleOrDefault(e => e.lang === key) ?? TranslationHelper.KorocanLanguages.single(e => e.lang === "tr");
    },

    KorocanLanguages: Object.keys(allAppTranslations).filter(e => e === "en").length > 0 ? [
        { title: "Türkçe", img: "tr.png", lang: "tr" },
        { title: "English", img: "en.png", lang: "en" },
    ] : [
        { title: "Türkçe", img: "tr.png", lang: "tr" },
    ],
}

export default TranslationHelper;

// /* eslint-disable @typescript-eslint/no-explicit-any */
// import { TFunction, i18n } from "i18next";
// import allkorocanTranslations, { transMatrixKeyType } from "../translations/korocanTranslations";

// export type KorocanTranslateFuncType = (a: transMatrixKeyType, v?: { [key: string]: string }) => string;

// const TranslationHelper = {
//     TransFunc: (t: TFunction<"translation", undefined, "translation">): KorocanTranslateFuncType =>
//         (a: transMatrixKeyType, v?: { [key: string]: string }) => ((a as any) === "" ? "" : (v ? (t((a as any), v!) ?? "") : (t(a, { defaultValue: (a as any) + "-XXXXXX" }) ?? ""))),

//     getCurrentLanguageItem: (i18n: i18n) => {
//         const key = i18n.resolvedLanguage ?? "tr";
//         return TranslationHelper.KorocanLanguages.singleOrDefault(e => e.lang === key) ?? TranslationHelper.KorocanLanguages.single(e => e.lang === "tr");
//     },

//     KorocanLanguages: Object.keys(allkorocanTranslations).filter(e => e === "en").length > 0 ? [
//         { title: "Türkçe", img: "tr.png", lang: "tr" },
//         { title: "English", img: "en.png", lang: "en" },
//     ] : [
//         { title: "Türkçe", img: "tr.png", lang: "tr" },
//     ],
// }

// export default TranslationHelper;