// AutoGenerated
import { KoroVeHesapModel } from "../businessModels/KoroVeHesapModel"
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class KoroVeHesapWithContentRequestModel {
    constructor(
        model: KoroVeHesapModel,
        icerikList: IcerikEklemeRequestModel[],
    ) {
        this.model = model;
        this.icerikList = icerikList;
    }

    model: KoroVeHesapModel;
    icerikList: IcerikEklemeRequestModel[];
}