// AutoGenerated by CSSeeder - CsmSampleItemTwoBox.tsx
import { useState } from "react";
import JsonHelper from "../../library/helpers/JsonHelper";
import { GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmGenericTwoPanelListMultiSelectField from "../../library/components/mui/twobox/CsmGenericTwoPanelListMultiSelectField";
import CsmGenericTwoBoxListMultiSelectField from "../../library/components/mui/twobox/CsmGenericTwoBoxListMultiSelectField";
import CsmGenericTwoPanelOrderableListMultiSelectField from "../../library/components/mui/twobox/CsmGenericTwoPanelOrderableListMultiSelectField";

interface ISampleScreenState {
    twoBoxListIdList1: number[],
    twoBoxListIdList2: number[],
    twoBoxListIdList3: number[],
}

export default function CsmSampleItemTwoBox() {
    const [model, setModel] = useState<ISampleScreenState>({
        twoBoxListIdList1: [],
        twoBoxListIdList2: [],
        twoBoxListIdList3: [],
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const sample: ISampleGroup = {
        groupName: "Two Box variations",
        componenets: [
            {
                componentName: "CsmGenericTwoBoxListMultiSelectField",
                sampleUsages: [
                    {
                        itemWidth: 5,
                        linesWidth: 7,
                        item: <CsmGenericTwoBoxListMultiSelectField
                            value={model.twoBoxListIdList1}
                            label={"Generic Two Box List"}
                            itemLabel="Little Item(s)"
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            onChange={e => modelChanged(t => t.twoBoxListIdList1 = e)}
                            labels={{
                                secilmeyenler: "Unselecteds",
                                secilenler: "Selecteds",
                                listeyeEklemekIcinTıklayabilirsiniz: "Click to add to list",
                                siralarınıDegistirmekIcinTasiyabilirsinizListedenCikartmakIcinTiklayabilirsiniz: "click to remove"
                            }}
                        />,
                        lines: [
                            `<CsmGenericTwoBoxListMultiSelectField`,
                            `value={model.twoBoxListIdList1}`,
                            `label={"Generic Two Box List"}`,
                            `itemLabel="Little Item(s)"`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `onChange={e => modelChanged(t => t.twoBoxListIdList1 = e)}`,
                            `labels={{`,
                            `  secilmeyenler: "Unselecteds",`,
                            `  secilenler: "Selecteds",`,
                            `  listeyeEklemekIcinTıklayabilirsiniz: "Click to add to list",`,
                            `  siralarınıDegistirmek...Tiklayabilirsiniz: "click to remove"`,
                            `}}`,
                            `/>`,
                        ]
                    },
                    {
                        itemWidth: 5,
                        linesWidth: 7,
                        item: <CsmGenericTwoBoxListMultiSelectField
                            value={model.twoBoxListIdList2}
                            label={"Error Generic Two Box List"}
                            itemLabel="Little Item(s)"
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            onChange={e => modelChanged(t => t.twoBoxListIdList2 = e)}
                            labels={{
                                secilmeyenler: "Unselecteds",
                                secilenler: "Selecteds",
                                listeyeEklemekIcinTıklayabilirsiniz: "Click to add to list",
                                siralarınıDegistirmekIcinTasiyabilirsinizListedenCikartmakIcinTiklayabilirsiniz: "click to remove"
                            }}
                            error
                        />,
                        lines: [
                            `<CsmGenericTwoBoxListMultiSelectField`,
                            `value={model.twoBoxListIdList2}`,
                            `label={"Error Generic Two Box List"}`,
                            `itemLabel="Little Item(s)"`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `onChange={e => modelChanged(t => t.twoBoxListIdList2 = e)}`,
                            `labels={{`,
                            `  secilmeyenler: "Unselecteds",`,
                            `  secilenler: "Selecteds",`,
                            `  listeyeEklemekIcinTıklayabilirsiniz: "Click to add to list",`,
                            `  siralarınıDegistirmek...Tiklayabilirsiniz: "click to remove"`,
                            `}}`,
                            `error`,
                            `/>`,
                        ]
                    },
                    {
                        itemWidth: 5,
                        linesWidth: 7,
                        item: <CsmGenericTwoBoxListMultiSelectField
                            value={model.twoBoxListIdList3}
                            label={"SORTABLE Generic Two Box List"}
                            itemLabel="Little Item(s)"
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            onChange={e => modelChanged(t => t.twoBoxListIdList3 = e)}
                            labels={{
                                secilmeyenler: "Unselecteds",
                                secilenler: "Selecteds",
                                listeyeEklemekIcinTıklayabilirsiniz: "Click to add to list",
                                siralarınıDegistirmekIcinTasiyabilirsinizListedenCikartmakIcinTiklayabilirsiniz: "drag to order, click to remove"
                            }}
                            sortable
                        />,
                        lines: [
                            `<CsmGenericTwoBoxListMultiSelectField`,
                            `value={model.twoBoxListIdList3}`,
                            `label={"SORTABLE Generic Two Box List"}`,
                            `itemLabel="Little Item(s)"`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `onChange={e => modelChanged(t => t.twoBoxListIdList3 = e)}`,
                            `labels={{`,
                            `  secilmeyenler: "Unselecteds",`,
                            `  secilenler: "Selecteds",`,
                            `  listeyeEklemekIcinTıklayabilirsiniz: "Click to add to list",`,
                            `  siralarınıDegistirmek...Tiklayabilirsiniz: "drag to order, click to remove"`,
                            `}}`,
                            `sortable`,
                            `/>`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericTwoBoxListMultiSelectField",
                sampleUsages: [
                    {
                        itemWidth: 5,
                        linesWidth: 7,
                        item: <CsmGenericTwoPanelListMultiSelectField
                            value={model.twoBoxListIdList1}
                            label={"Generic Two Box List"}
                            itemLabel="Little Item"
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            onChange={e => modelChanged(t => t.twoBoxListIdList1 = e)}
                        />,
                        lines: [
                            `<CsmGenericTwoPanelListMultiSelectField`,
                            `value={model.twoBoxListIdList1}`,
                            `label={"Generic Two Box List"}`,
                            `itemLabel="Little Item"`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `onChange={e => modelChanged(t => t.twoBoxListIdList1 = e)}`,
                            `/>`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericTwoPanelOrderableListMultiSelectField",
                sampleUsages: [
                    {
                        itemWidth: 5,
                        linesWidth: 7,
                        item: <CsmGenericTwoPanelOrderableListMultiSelectField
                            value={model.twoBoxListIdList1}
                            label={"Generic Two Box List"}
                            itemLabel="Little Item"
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            onChange={e => modelChanged(t => t.twoBoxListIdList1 = e)}
                        />,
                        lines: [
                            `<CsmGenericTwoPanelOrderableListMultiSelectField`,
                            `value={model.twoBoxListIdList1}`,
                            `label={"Generic Two Box List"}`,
                            `itemLabel="Little Item"`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `onChange={e => modelChanged(t => t.twoBoxListIdList1 = e)}`,
                            `/>`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(sample);
}