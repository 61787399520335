// AutoGenerated
import { HesapCalismaYoklamaBildirRequestModel } from "../requestModels/HesapCalismaYoklamaBildirRequestModel"

export class HesapCalismaYoklamaListBildirRequestModel {
    constructor(
        yoklamalar: HesapCalismaYoklamaBildirRequestModel[],
    ) {
        this.yoklamalar = yoklamalar;
    }

    yoklamalar: HesapCalismaYoklamaBildirRequestModel[];
}