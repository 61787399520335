import { KadroModel } from "../../models/businessModels/KadroModel";
import CsmFormSegmentedMultiButtonList from "../../library/components/mui/radio/CsmFormSegmentedMultiButtonList";

export interface IVatanKadroSegmentedMultiButtonList {
    tumKadrolar: KadroModel[],
    value: string[],
    onChanged: (newlist: string[]) => void,
    inactiveKadroMode: "Hide" | "Group" | "Show",
}

export default function VatanKadroSegmentedMultiButtonList(props: IVatanKadroSegmentedMultiButtonList) {
    const { tumKadrolar, value, onChanged, inactiveKadroMode } = props;

    const aktifKadrolar = tumKadrolar.where(e => e.aktif);
    const pasifKadrolar = tumKadrolar.where(e => !e.aktif);

    let kadroListForUI: { isim: string, id: string }[];
    let valueForUI: string[] = value;

    if (inactiveKadroMode === "Hide")
        kadroListForUI = aktifKadrolar.select(e => { return { isim: e.isim, id: e.id } });
    else if (inactiveKadroMode === "Show")
        kadroListForUI = tumKadrolar.select(e => { return { isim: e.isim, id: e.id } });
    else {
        const valueContainsPasif = pasifKadrolar.any(e => value.contains(e.id));
        const pasifsizIdList = value.where(e => pasifKadrolar.all(t => t.id !== e));
        valueForUI = !valueContainsPasif ? pasifsizIdList : [...pasifsizIdList, ""];
        kadroListForUI = [...aktifKadrolar.select(e => { return { isim: e.isim, id: e.id } }), { isim: "Diğer", id: "" }];
    }

    function onChangedInner(newList: string[]): void {
        if (inactiveKadroMode === "Hide" || inactiveKadroMode === "Show")
            onChanged(newList);
        else {
            if (newList.any(e => e === ""))
                onChanged(newList.except([""]).union(pasifKadrolar.select(e => e.id)))
            else
                onChanged(newList);
        }
    }

    return <CsmFormSegmentedMultiButtonList
        value={valueForUI}
        list={kadroListForUI}
        labelGetter={e => e.isim}
        valueGetter={e => e.id}
        onChange={e => onChangedInner(e)}
    />
}