const _charMapping: Record<string, string> = {
    "Ç": "C",
    "Ğ": "G",
    "İ": "I",
    "Ö": "O",
    "Ş": "S",
    "Ü": "U",
    "ç": "c",
    "ğ": "g",
    "ı": "i",
    "ö": "o",
    "ş": "s",
    "ü": "u",
}

const TurkishStringHelper = {
    ToPascalCase: (input: string): string => {
        const splits = input.split(" ");

        if (splits.length === 1) {
            const item = splits[0];
            if (item.length < 2)
                return item;
            else
                return item[0].toLocaleUpperCase("tr-tr") + item.substring(1).toLocaleLowerCase("tr-tr");
        }

        return splits.map(item => TurkishStringHelper.ToPascalCase(item)).join(" ");
    },
    ToUppercase: (input: string | null) => {
        return (input ?? "").toLocaleUpperCase("tr-tr");
    },
    ConvertToEnglishLowerCase: (input: string) => {
        if (input == null) return "";
        const lowered = input.toLocaleLowerCase("tr-tr");
        let result = "";

        for (let i = 0; i < lowered.length; i++) {
            if (lowered[i] in _charMapping)
                result = result + _charMapping[lowered[i]];
            else
                result = result + lowered[i];
        }

        return result;
    }
}

export default TurkishStringHelper;