/* eslint-disable react/no-children-prop */
// cihan
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Rows from "../../library/components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { Button } from "@mui/material";
import { mainScreenLocations } from "../../app/mainScreens";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { EnmKayitDurum } from "../../models/enums/EnmKayitDurum";
import { CsmText } from "../../library/components/mui/CsmText";
import { HesapSarkiSesKayitModel } from "../../models/businessModels/HesapSarkiSesKayitModel";
import AlertHelper from "../../library/helpers/AlertHelper";
import KoristSarkiYuklemePopupEditor from "./sarki/KoristSarkiYuklemePopupEditor";
import { WithContentRequestModel } from "../../models/requestModels/WithContentRequestModel";
import VatanKayitRowListItem, { VatanKayitRowInnerPropsHazirla } from "../../components/vatan/VatanKayitRowListItem";
import Columns from "../../library/components/layout/Columns";
import Cell from "../../library/components/layout/Cell";
import { SarkiModel } from "../../models/businessModels/SarkiModel";
import CsmAccordionSimple from "../../library/components/mui/containers/CsmAccordionSimple";
import KayitWithPdfPopupEditor from "../yonetim/kayityorum/KayitWithPdfPopupEditor";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../helpers/TranslationHelper";
import { GuidRequestModel } from "../../models/requestModels/GuidRequestModel";

function KayitlarimScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);

  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KayitlarimScreenInner genelDurumHelper={genelDurumHelper} />
}

function KayitlarimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.orderByTurkish(e => e.isim);
  const sezonSarkilari = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id));
  const sezonDisiSarkilar = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id) === false);

  const guncelSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === true).orderBy(e => e.sira);
  const bekleyenSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === false).orderBy(e => e.sira);

  const eskiSarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length > 0).orderByTurkish(e => e.isim);
  const adaySarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length === 0).orderByTurkish(e => e.isim);

  const kayitDinle = async (sarki: SarkiModel, kayitModel: HesapSarkiSesKayitModel) => {
    AlertHelper.ShowClosableContentFullHeight<HesapSarkiSesKayitModel>(genelDurumHelper.translate("Kayıt Dinle"), (closer) => (
      <KayitWithPdfPopupEditor
        genelDurumHelper={genelDurumHelper}
        sarki={sarki}
        yorumKaydedebilir={false}
        closer={closer}
        hesapBilgi={{ ...genelDurumHelper.SeciliHesapBilgiModel, kullaniciModel: genelDurumHelper.genelDurum.kullanici }}
        hesapSarkiSesKayit={kayitModel} />
    ));
  }

  const kayitSil = async (kayitModel: HesapSarkiSesKayitModel, sarki: SarkiModel, sira: number, sesGrup: SesGrupModel) => {
    if (await AlertHelper.Confirm(genelDurumHelper.translate("Dikkat"), `${sarki.isim} eserindeki ${sira + 1}. sıradaki ${sesGrup.isim} kaydınızı silmek istediğinizden emin misiniz?`) !== true)
      return;

    const webResponse = await genelDurumHelper.requestHelper.YonetimHesapMuzikal.HesapKayitSil(new GuidRequestModel(kayitModel.id));
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiKayitlar = seciliKoro.hesapSarkiSesKayitModelList;
        const itemToRemove = hesapSarkiKayitlar.single(e => e.id === kayitModel.id);
        hesapSarkiKayitlar.remove(itemToRemove);
      });
  }

  const kayitYukle = async (sarki: SarkiModel) => {
    if (genelDurumHelper.SeciliKoroModel.koroAyarlari.sadeceKayitDonemiYukleme === true && sarki.kayitGondermeSurecinde === false) {
      AlertHelper.ShowMessage("Dikkat", genelDurumHelper.SeciliKoroModel.koroAyarlari.sadeceKayitDonemiYuklemeMesaj);
      return;
    }

    const koristMi = BusinessHelper.Genel.KoristMi(genelDurumHelper.SeciliHesapBilgiModel);
    const kisininSarkiKayitlari = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.sarkiModelId === sarki.id && e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);
    const kisiKayitSonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kisininSarkiKayitlari);
    const eksikHataliKayitVarMi = koristMi && (kisiKayitSonDurum === null || kisiKayitSonDurum === EnmKayitDurum.Hatalı)

    const basariliKayitSesGruplar = BusinessHelper.SesKayit.KisininKayitYuklenemezSesGruplariniGetir(genelDurumHelper, kisininSarkiKayitlari);

    if (eksikHataliKayitVarMi === false) {
      const sesgrupIsimler = basariliKayitSesGruplar.select(e => e.isim).join(', ');
      const message = `Daha önce gönderilen ${sesgrupIsimler} kayıtlarınız onaylı/beklemede olduğu için, yeni kaydınızı ancak başka ses grupları için gönderebilirsiniz. Kayıt yükleme işlemine devam etmek istiyor musunuz?`;

      if (await AlertHelper.Confirm("Dikkat", message) === false)
        return;
    }

    const response = await AlertHelper.ShowClosableContent<WithContentRequestModel<HesapSarkiSesKayitModel>>(sarki.isim + " Kayıt Yükle", (closer) => (
      <KoristSarkiYuklemePopupEditor
        sarkiModel={sarki}
        basariliKayitSesGruplar={basariliKayitSesGruplar}
        hesapBilgiModel={genelDurumHelper.SeciliHesapBilgiModel}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const hesapSarkiSesKayitModelList = seciliKoro.hesapSarkiSesKayitModelList;
        hesapSarkiSesKayitModelList.push(response!.model);
      });
  }

  function renderSarkiList(sarkiList: SarkiModel[], tumKayitlar: HesapSarkiSesKayitModel[], siraNoGoster: boolean, genelDurumHelper: GenelDurumHelper): React.ReactNode {
    if (sarkiList.length === 0) return null;

    const kullanicininKayitlari = tumKayitlar.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);

    return <Columns wrap>
      {sarkiList.map(sarki => {
        const kayitlar = kullanicininKayitlari.where(e => e.sarkiModelId === sarki.id);
        const kayitlarProps = VatanKayitRowInnerPropsHazirla(kayitlar, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

        const icerik = kayitlarProps.map(item => (
          <Cell width={350} key={item.kayit.id} style={{ display: "inline-flex", padding: "10px" }} >
            <VatanKayitRowListItem
              {...item}
              style={{ width: "100%", background: BusinessHelper.SesKayit.KayitDurumRenkGetir(item.kayit.kayitDurum, item.sesGrubununSonKaydiMi ? 0.4 : 0.0) }}
              kayitSec={e => kayitDinle(sarki, e)}
              kayitSil={item.kayit.kayitDurum === EnmKayitDurum["Yorum Bekleniyor"] ? (() => kayitSil(item.kayit, sarki, item.siraNo, item.sesGrup)) : undefined}
              narrow
              genelDurumHelper={genelDurumHelper} />
          </Cell>
        ));

        const yapilmasiGerekenKayitVarmi = sarki.kayitGondermeSurecinde && BusinessHelper.SesKayit.HesabinSarkiIcinYapmasiGerekenKayitVarmi(kayitlar);

        return <Rows key={sarki.id} hasLightBottomBorder hasLightRightBorder mt={10} pb={15}>
          <CsmText header style={{ marginLeft: "10px" }}>{siraNoGoster ? (sarki.sira + ". ") : ""}{sarki.isim}</CsmText>
          <Columns wrap flex>
            {kayitlar.length > 0 ? null : <CsmText note style={{ marginLeft: "10px", marginRight: 0 }} >Yüklenen kayıt bulunmamaktadır.</CsmText>}
            {icerik}
          </Columns>
          <Cell pl={10} pr={10} style={{ minWidth: "350px" }}>
            <Button fullWidth color={yapilmasiGerekenKayitVarmi ? "error" : "primary"} variant="contained" onClick={() => kayitYukle(sarki)}>{sarki.isim} Kaydı Yükle</Button>
          </Cell>
        </Rows>
      })}
    </Columns>
  }

  return (
    <MainLayout
      location={mainScreenLocations.kayitlarim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <CsmAccordionSimple
          bigCenteredTitle
          title="Güncel Şarkılar"
          emptyNote="Güncel şarkı bulunmamaktadır"
          children={renderSarkiList(guncelSarkilar, genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList, true, genelDurumHelper)}
          alwaysOpen
        />
        <CsmAccordionSimple
          bigCenteredTitle
          title="Bekleyen Şarkılar"
          emptyNote="Bekleyen şarkı bulunmamaktadır"
          children={renderSarkiList(bekleyenSarkilar, genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList, true, genelDurumHelper)}
          alwaysOpen
        />
        <CsmAccordionSimple
          bigCenteredTitle
          title="Eski Şarkılar"
          children={() => renderSarkiList(eskiSarkilar, genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList, false, genelDurumHelper)}
          visible={eskiSarkilar.length > 0}
        />
        <CsmAccordionSimple
          bigCenteredTitle
          title="Diğer Şarkılar"
          children={() => renderSarkiList(adaySarkilar, genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList, false, genelDurumHelper)}
          visible={adaySarkilar.length > 0}
        />
      </Rows>
    </MainLayout>
  );
}

export default KayitlarimScreen;