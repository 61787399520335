// AutoGenerated
export class HesapKayitSesGrupGuncelleRequestModel {
    constructor(
        hesapSarkiSesKayitId: string,
        sesGrupId: string,
    ) {
        this.hesapSarkiSesKayitId = hesapSarkiSesKayitId;
        this.sesGrupId = sesGrupId;
    }

    hesapSarkiSesKayitId: string;
    sesGrupId: string;
}