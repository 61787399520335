import { AltSezonModel } from "../../models/businessModels/AltSezonModel";
import CsmGenericMultiSelectChipField, { ICsmGenericMultiSelectChipFieldProps } from "../../library/components/mui/select/CsmGenericMultiSelectChipField";

export type IVatanAltSezonMultiSelectFieldProps = Omit<ICsmGenericMultiSelectChipFieldProps<{ isim: string, id: string }, string>, "list" | "labelGetter" | "valueGetter"> & { showDiger?: boolean, tumAltSezonlar: AltSezonModel[] };

function VatanAltSezonMultiSelectField(props: IVatanAltSezonMultiSelectFieldProps) {
    const { showDiger, tumAltSezonlar, ...rest } = props;
    const orderedAltSezonList = tumAltSezonlar.orderByTurkish(e => e.isim);
    const minifiedList = orderedAltSezonList.select(e => { return { isim: e.isim, id: e.id }; })

    return (
        <CsmGenericMultiSelectChipField
            {...rest}
            list={showDiger ? [{ isim: "Diğer", id: "-" }, ...minifiedList] : minifiedList}
            labelGetter={(e) => e.isim}
            valueGetter={(e) => e.id}
        />
    )
}

export default VatanAltSezonMultiSelectField;