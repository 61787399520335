import EnumHelper from "../../../helpers/EnumHelper";
import CsmGenericMultiSelectChipField, { ICsmGenericMultiSelectChipFieldProps } from "./CsmGenericMultiSelectChipField";

type innerListType = { value: number, presentation: string };
export type ICsmCstFieldProps = Omit<ICsmGenericMultiSelectChipFieldProps<innerListType, number>, "list" | "labelGetter" | "valueGetter"> & { cstType: Record<number, string> };

function CsmCstListField(props: ICsmCstFieldProps) {
    const { cstType: enumType, ...rest } = props;
    return (
        <CsmGenericMultiSelectChipField
            {...rest}
            list={EnumHelper.getSelectListAsArray<number, number>(enumType, e => enumType[e])}
            labelGetter={(e) => e.presentation}
            valueGetter={(e) => e.value}
        />
    )
}

export default CsmCstListField;