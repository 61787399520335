import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import Cell from "../../../library/components/layout/Cell";
import Columns from "../../../library/components/layout/Columns";
import appColors from "../../../app/appColors";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { Button, FormControl, Switch, Typography } from "@mui/material";
import CizelgeLegend from "./CizelgeLegend";
import { CizelgeCellInfo, CizelgeColumnInfo, CizelgeData, CizelgeRowInfo, CizelgeTable, GetColorForHesap, GetColorForPercent, ICizelgeStyles, TekilCizelgeTable } from "./CizelgeTable";
import Rows from "../../../library/components/layout/Rows";
import VatanKadroSelectField from "../../../components/vatan/VatanKadroSelectField";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import CsmGenericGroupBox from "../../../library/components/mui/CsmGenericGroupBox";
import StringHelper from "../../../library/helpers/StringHelper";
import CsmLightCollapsableContainer from "../../../library/components/mui/containers/CsmLightCollapsableContainer";

const mainStyles: ICizelgeStyles = {
    cellHeight: 15,
    cellWidth: 15,
    rightmostColumnWidth: 30,
    firstColumnWidth: 135,
    firstRowHeight: 100,
    leftHeaderColumnFontSize: "10px",
    topHeaderRowFontSize: "8px",
    firstRowGroupInnerHeight: 0, // ay başlıkları
    bottomSummaryRowHeight: 150,
}

type KayitCellInfo = CizelgeCellInfo<SarkiModel, HesapBilgiVeKullaniciModel, { durum: EnmKayitDurum | null, count: number }>;
type KayitData = CizelgeData<SarkiModel, HesapBilgiVeKullaniciModel, { durum: EnmKayitDurum | null, count: number }>;
type KayitColumnInfo = CizelgeColumnInfo<SarkiModel>;
type KayitRowInfo = CizelgeRowInfo<HesapBilgiVeKullaniciModel>;

function SarkiKayitCizelgeScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <SarkiKayitCizelgeScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkiKayitCizelgeScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);
    const sadeceKendisiMi = genelDurumHelper.SeciliHesapBilgiModel.kadroVeSesGrubuBilgiModelList.length > 0 && genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.all(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);

    const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === seciliSezonId).where(e => !sadeceKendisiMi || BusinessHelper.Kadro.HesapKadroyaDahilMi(genelDurumHelper.SeciliHesapBilgiModel, e.id));

    const [seciliKadroId, setSeciliKadroId] = useState<string>(StringHelper.nullToEmpty(secilebilirKadrolar.orderBy(e => e.olusturmaTarih).firstOrDefault()?.id));
    const [eskiKoristGoster, setEskiKoristGoster] = useState<boolean>(false);
    const [araVerenKoristGoster, setAraVerenKoristGoster] = useState<boolean>(false);
    const [sadeceKayitSurecindekiSarkilariGoster, setSadeceKayitSurecindekiSarkilariGoster] = useState<boolean>(false);
    const [istatistikGoster, setIstatistikGoster] = useState<boolean>(false);

    const tumSarkiKayitlar = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList;
    const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
    const koroAyarlari = genelDurumHelper.SeciliKoroModel.koroAyarlari;

    const secilebilirSezonlar = sadeceKendisiMi ? BusinessHelper.Genel.KoristinKatildigiSezonlariGetir(genelDurumHelper) : genelDurumHelper.SeciliKoroModel.tumSezonlar;

    const koristList = genelDurumHelper.SeciliKoroModel.hesapBilgiList
        .where(e => BusinessHelper.Genel.KoristMi(e) && (!sadeceKendisiMi || e.id === genelDurumHelper.SeciliHesapBilgiModel.id))
        .where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, seciliKadroId))
        .where(e => !e.araVerdi || araVerenKoristGoster)
        .where(e => e.aktif || eskiKoristGoster);

    let sarkiList: SarkiModel[] = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === seciliSezonId) && (!sadeceKayitSurecindekiSarkilariGoster || e.kayitGondermeSurecinde));

    if (seciliSezonId === genelDurumHelper.AktifSezon.id)
        sarkiList = sarkiList.orderBy(e => e!.sira);
    else
        sarkiList = sarkiList.orderBy(e => e!.isim);

    const alakaliHesapKayitlar = tumSarkiKayitlar.where(hk => sarkiList.any(e => e.id === hk.sarkiModelId) && koristList.any(e => e.id === hk.hesapModelId));

    const contentSolUst = <CizelgeLegend
        miniWidth={mainStyles.cellWidth}
        miniHeight={mainStyles.cellHeight}
        textWidth={100}
        fontSize={10}
        items={[
            { text: "Kayıt başarılı", color: appColors.VERYLIGHTGREEN },
            { text: "Kayıt gönderilmemiş", color: appColors.VERYLIGHTRED },
            { text: "Kayıt hatalı", color: appColors.RED_MAIN },
            { text: "Kayıt yorum bekliyor", color: appColors.VERYLIGHTYELLOW },
        ]}
    />

    const mySarkiInfoList: KayitColumnInfo[] = sarkiList.orderBy(e => e.sira).map((item, i) => {
        return {
            backgroundColor: undefined,
            isUnused: false,
            id: item.id,
            data: item,
            group: "",
            order: i,
        }
    });


    const hesabinSesAltGrubunuGetir = (hesap: HesapBilgiVeKullaniciModel) => {
        const bulunanGrupId = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === seciliKadroId)?.altSesGrupModelId;
        return bulunanGrupId ? genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === bulunanGrupId) : BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => hesap.sesGrupIdList.contains(e.id)))!;
    };

    const sesGruplar = koristList.select(korist => hesabinSesAltGrubunuGetir(korist)).distinct().orderBy(e => e.sira);
    const myKoristInfoList: KayitRowInfo[] = sesGruplar.flatMap(sesGrup => koristList.where(e => hesabinSesAltGrubunuGetir(e).id === sesGrup.id).map((korist, i) => {
        return {
            id: korist.id,
            data: korist,
            backColor: GetColorForHesap(korist),
            group: sesGrup!.isim,
            order: i,
        }
    }));

    const myData: KayitCellInfo[] = myKoristInfoList.flatMap(korist => mySarkiInfoList.map(sarki => {
        const kisininKayitlari = alakaliHesapKayitlar.where(e => e.sarkiModelId === sarki.data.id && e.hesapModelId === korist.data.id);
        return {
            column: sarki,
            row: korist,
            data: {
                count: kisininKayitlari.length,
                durum: BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kisininKayitlari),
            }
        }
    }));

    const mainData: KayitData = {
        cells: myData,
        columns: mySarkiInfoList,
        rows: myKoristInfoList,
        hasSummaryBottomRow: myKoristInfoList.length > 1,
        hasSummaryRightmostColumn: true,
        GetRowsFirstColumnString: (data: HesapBilgiVeKullaniciModel) => (data.kullaniciModel.ad + " " + data.kullaniciModel.soyad),
        GetColumnsFirstRowContent: (data: SarkiModel) => data.isim,
        GetColumnsFirstRowSummaryHeaderString: "Kişi Ağırlıklı Ortalaması",
        GetRowsFirstColumnSummaryHeaderString: "Şarkı Ortalaması",
        GetCellContent: GenerateKisiSarkiKutusu,
        GetRowsLastSummaryColumnCellContent: GenerateKisininOrtalamasiKutusu,
        GetColumnsLastSummaryRowCellContent: GenerateSarkiOrtalamasiKutusu,
        GetGlobalSummaryCellContent: GenerateGlobalOrtalamaKutu,
        GetLeftTopContent: contentSolUst,
    };

    function listeyiKopyala() {
        const textList = myKoristInfoList.select(hesap => {
            const isim = hesap.data.kullaniciModel.ad + " " + hesap.data.kullaniciModel.soyad;

            const sarkilar = myData.where(e => e.row.data.id === hesap.id).where(e => e.data.durum == null || e.data.durum === EnmKayitDurum.Hatalı).select(e => e.column.data.isim).join(", ");

            return { isim: isim, sarkilar: sarkilar };
        })

        navigator.clipboard.writeText(textList.select(e => e.isim + "\t" + e.sarkilar).join("\n"));

        AlertHelper.ShowMessage("Dikkat", "İçerik Kopyalandı");
    }

    function GenerateKisiSarkiKutusu(cellInfo: KayitCellInfo) {
        // const sarki = cellInfo.column.data;
        // const hesap = cellInfo.row.data;

        let backgroundColor: string | undefined = undefined;

        if (cellInfo.data.durum == null) {
            if (cellInfo.column.data.kayitGondermeSurecinde)
                backgroundColor = appColors.VERYLIGHTRED;
            else
                backgroundColor = appColors.GREYALPHA_02;
        }
        else if (cellInfo.data.durum === EnmKayitDurum.Hatalı)
            backgroundColor = appColors.RED_MAIN;
        else if (cellInfo.data.durum === EnmKayitDurum["Yorum Bekleniyor"] || cellInfo.data.durum === EnmKayitDurum["Onay Bekleniyor"])
            backgroundColor = appColors.VERYLIGHTYELLOW;
        else
            backgroundColor = appColors.VERYLIGHTGREEN;

        return { text: "", backcolor: backgroundColor }
    }

    function GenerateKisininOrtalamasiKutusu(row: HesapBilgiVeKullaniciModel) {
        const hesapKayitList = myData.where(e => e.row.data === row);
        const basariliKayit = hesapKayitList.count(e => e.data.durum === EnmKayitDurum.Onaylandı);
        const basarisizKayit = hesapKayitList.count(e => e.data.durum === EnmKayitDurum.Hatalı || (e.data.durum == null && e.column.data.kayitGondermeSurecinde));
        const toplamKayit = basariliKayit + basarisizKayit;
        const oran = (basariliKayit) / (toplamKayit === 0 ? 1 : toplamKayit);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari), extraText: "" };
    }

    function GenerateSarkiOrtalamasiKutusu(column: SarkiModel) {
        const hesapKayitList = myData.where(e => e.column.data === column);
        const basariliKayit = hesapKayitList.count(e => e.data.durum === EnmKayitDurum.Onaylandı);
        const basarisizKayit = hesapKayitList.count(e => e.data.durum === EnmKayitDurum.Hatalı || (e.data.durum == null && e.column.data.kayitGondermeSurecinde));
        const toplamKayit = basariliKayit + basarisizKayit;
        const oran = (basariliKayit) / (toplamKayit === 0 ? 1 : toplamKayit);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari) };
    }

    function GenerateGlobalOrtalamaKutu() {
        const basariliKuartet = myData.count(e => e.data.durum === EnmKayitDurum.Onaylandı);
        const basarisizKuartet = myData.count(e => e.data.durum === EnmKayitDurum.Hatalı || (e.data.durum == null && e.column.data.kayitGondermeSurecinde));
        const toplamKuartet = basariliKuartet + basarisizKuartet;
        const oran = (basariliKuartet) / (toplamKuartet === 0 ? 1 : toplamKuartet);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari) };
    }

    function sezonSecildi(value: string): void {
        setSeciliSezonId(value);
        const olasiKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === value).select(e => e.id);
        setSeciliKadroId(StringHelper.nullToEmpty(olasiKadrolar.firstOrDefault()));
    }

    return <MainLayout
        location={mainScreenLocations.sarkiKayitCizelge}
        genelDurumHelper={genelDurumHelper}
        hideHeader={false}
        hideMenu={false}
        disallowScroll={true}>
        {mainData.rows.length === 0 ? <CsmText header italic style={{ marginLeft: "10px", marginTop: "10px", minHeight: 0 }} >{genelDurumHelper.translate("Gösterilecek veri bulunmamaktadır")}</CsmText> :
            <Rows ml={10} mt={10} style={{minHeight: 0 }}>
                <Rows mb={10}>
                    <CsmLightCollapsableContainer collapsedTitle="Filtreler" initialOpen>
                        <Columns mt={10} mb={10} wrap>
                            <Cell style={{ flexGrow: 0, minWidth: "250px" }}>
                                <VatanSezonSelectField
                                    value={seciliSezonId}
                                    label="Seçili Sezon"
                                    tumSezonlar={secilebilirSezonlar}
                                    onChange={value => sezonSecildi(value)}
                                />
                            </Cell>
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ width: "250px", marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <VatanKadroSelectField
                                        value={secilebilirKadrolar.any(e => e.id === seciliKadroId) ? seciliKadroId : ""}
                                        label="Seçili Kadro"
                                        tumKadrolar={secilebilirKadrolar}
                                        onChange={value => setSeciliKadroId(value)}
                                    />
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={araVerenKoristGoster} onChange={(e) => setAraVerenKoristGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{araVerenKoristGoster ? "Ara Veren Koristleri Göster" : "Ara Veren Koristleri Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={sadeceKayitSurecindekiSarkilariGoster} onChange={(e) => setSadeceKayitSurecindekiSarkilariGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{sadeceKayitSurecindekiSarkilariGoster ? "Sadece Kayıt sürecindeki şarkıları göster" : "Tüm Şarkıları Göster"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={eskiKoristGoster} onChange={(e) => setEskiKoristGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{eskiKoristGoster ? "Eski Koristleri Göster" : "Eski Koristleri Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={istatistikGoster} onChange={(e) => setIstatistikGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{istatistikGoster ? "İstatistik göster" : "İstatistik Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <Button variant="contained" onClick={() => listeyiKopyala()}>
                                    Listeyi Yazı Olarak Kopyala
                                </Button>
                            }
                            <Cell flex></Cell>
                        </Columns>
                    </CsmLightCollapsableContainer>
                </Rows>
                {istatistikGoster &&
                    <Columns wrap mb={10}>
                        <CsmGenericGroupBox label="Genel İstatistikler">
                            <DataDisplayer title="Korist Sayısı" value={myKoristInfoList.length.toFixed()} />
                            <DataDisplayer title="Eser Sayısı" value={mySarkiInfoList.length.toFixed()} />
                            <DataDisplayer title="Yüklenen Kayıt Sayısı" value={myData.where(e => e.data.durum !== null).length.toFixed()} />
                            <DataDisplayer title="Yorumlanan Kayıt Sayısı" value={myData.where(e => e.data.durum === EnmKayitDurum.Hatalı || e.data.durum === EnmKayitDurum.Onaylandı).length.toFixed()} />
                            <DataDisplayer title="Başarılı Kayıt Sayısı" value={myData.where(e => e.data.durum === EnmKayitDurum.Onaylandı).length.toFixed()} />
                            <DataDisplayer title="Hatalı Kayıt Sayısı" value={myData.where(e => e.data.durum === EnmKayitDurum.Hatalı).length.toFixed()} />
                        </CsmGenericGroupBox>
                        {sesGruplar.map(sesGrup => {
                            const relatedData = myData.where(e => e.row.data.sesGrupIdList.contains(sesGrup.id));

                            return <CsmGenericGroupBox key={sesGrup.id} label={`${sesGrup.isim} İstatistikleri`}>
                                <DataDisplayer title="Yüklenen Kayıt Sayısı" value={relatedData.where(e => e.data.durum !== null).length.toFixed()} />
                                <DataDisplayer title="Yorumlanan Kayıt Sayısı" value={relatedData.where(e => e.data.durum === EnmKayitDurum.Hatalı || e.data.durum === EnmKayitDurum.Onaylandı).length.toFixed()} />
                                <DataDisplayer title="Başarılı Kayıt Sayısı" value={relatedData.where(e => e.data.durum === EnmKayitDurum.Onaylandı).length.toFixed()} />
                                <DataDisplayer title="Hatalı Kayıt Sayısı" value={relatedData.where(e => e.data.durum === EnmKayitDurum.Hatalı).length.toFixed()} />
                                <DataDisplayer title="Yorum Bekleyen Kayıt Sayısı" value={relatedData.where(e => e.data.durum === EnmKayitDurum["Yorum Bekleniyor"]).length.toFixed()} />
                            </CsmGenericGroupBox>
                        }
                        )}
                    </Columns>
                }

                {sadeceKendisiMi ?
                    <TekilCizelgeTable data={mainData} styles={mainStyles} />
                    :
                    <CizelgeTable data={mainData} styles={mainStyles} />
                }
            </Rows>
        }
    </MainLayout>
}

function DataDisplayer(props: { title: string, value: string }) {
    return <Columns ml={15}>
        <CsmText note>{props.title}</CsmText>
        <CsmText bold note style={{ color: appColors.PRIMARY, marginLeft: "5px" }}>{props.value}</CsmText>
    </Columns>
}

export default SarkiKayitCizelgeScreen;