// AutoGenerated by CSSeeder - CsmSampleItemPrmCst.tsx
import { useState } from "react";
import JsonHelper from "../../library/helpers/JsonHelper";
import { CstSpeed, GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmPrmField from "../../library/components/mui/select/CsmPrmField";
import CsmCstListField from "../../library/components/mui/select/CsmCstListField";
import CsmCstField from "../../library/components/mui/select/CsmCstField";

interface ISampleScreenState {
    cstSpeedValue1: CstSpeed | null;
    cstSpeedList1: CstSpeed[];
    prmValue1: number | null;
}

export default function CsmSampleItemPrmCst() {
    const [model, setModel] = useState<ISampleScreenState>({
        cstSpeedValue1: null,
        cstSpeedList1: [],
        prmValue1: null,
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const sample: ISampleGroup = {
        groupName: "Combo Box (Select) Cst-Prm Extensions",
        componenets: [
            {
                componentName: "CsmCstField",
                sampleUsages: [
                    {
                        item: <CsmCstField
                            label="Cst (Enum) Select"
                            onChange={e => modelChanged(t => t.cstSpeedValue1 = e)}
                            value={model.cstSpeedValue1}
                            cstType={CstSpeed} />,
                        lines: [
                            `<CsmCstField`,
                            `label="Cst (Enum) Select"`,
                            `onChange={e => modelChanged(t => t.cstSpeedValue1 = e)}`,
                            `value={model.cstSpeedValue1}`,
                            `cstType={CstSpeed} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmCstListField",
                sampleUsages: [
                    {
                        item: <CsmCstListField
                            label="Cst (Enum) Select Chips"
                            onChange={e => modelChanged(t => t.cstSpeedList1 = e)}
                            value={model.cstSpeedList1}
                            cstType={CstSpeed} />,
                        lines: [
                            `<CsmCstListField`,
                            `label="Cst (Enum) Select Chips"`,
                            `onChange={e => modelChanged(t => t.cstSpeedList1 = e)}`,
                            `value={model.cstSpeedList1}`,
                            `cstType={CstSpeed} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmPrmField",
                sampleUsages: [
                    {
                        item: <CsmPrmField
                            label="Prm Select"
                            onChange={e => modelChanged(t => t.prmValue1 = e)}
                            value={model.prmValue1}
                            prmType={[
                                { kod: 1, aciklama: "Item 1" },
                                { kod: 2, aciklama: "Item 2" },
                                { kod: 3, aciklama: "Item 3" },]} />,
                        lines: [
                            `<CsmPrmField`,
                            `label="Prm Select"`,
                            `onChange={e => modelChanged(t => t.prmValue1 = e)}`,
                            `value={model.prmValue1}`,
                            `prmType={[`,
                            `{ kod: 1, aciklama: "Item 1" },`,
                            `{ kod: 2, aciklama: "Item 2" },`,
                            `{ kod: 3, aciklama: "Item 3" },]} />`,
                        ]
                    },
                ]
            }
        ]
    }

    return GenerateSampleGroup(sample);
}