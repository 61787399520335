import CsmGenericSelectField, { ICsmGenericSelectFieldProps } from "./CsmGenericSelectField";

interface IPrmTypeArray { kod: number, aciklama: string }
export type ICsmPrmFieldProps = Omit<ICsmGenericSelectFieldProps<IPrmTypeArray, number>, "list" | "labelGetter" | "valueGetter"> & { prmType: IPrmTypeArray[] };

function CsmPrmField(props: ICsmPrmFieldProps) {
    const { prmType, ...rest } = props;
    return (
        <CsmGenericSelectField
            {...rest}
            list={prmType}
            labelGetter={(e) => `${e.kod} - ${e.aciklama}`}
            valueGetter={(e) => e.kod}
        />
    )
}

export default CsmPrmField;