/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps, TextField, Theme, ThemeProvider } from "@mui/material";
import { CSSProperties } from "react";
import { StringOrNumber } from "../../../helpers/StringOrNumber";
import StringHelper from '../../../helpers/StringHelper';
import { GuidHelper } from '../../../helpers/GuidHelper';
import { narrowThemeForSelect } from '../helpers/CsmNarrowTheme';

export interface ICsmGenericSelectFieldProps<T, U extends StringOrNumber> extends Omit<ICsmGenericSelectFieldBaseProps<T, U>, "list"> {
    list: T[] | (() => Promise<T[] | null>)
}

interface ICsmGenericSelectFieldBaseProps<T, U extends StringOrNumber> {
    id?: string,
    value: U | null,
    list: T[],
    label: string,
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    isItemBoldGetter?: (e: T) => boolean,
    onChange: (e: U | null) => void,
    narrow?: boolean,
    error?: boolean,
    helperText?: string,
    errorText?: string | false | undefined,
    fontSize?: number,
    isAutoComplete?: boolean,
    autoCompleteCharNumTreshold?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    showEmpty?: boolean,
}


export default function CsmGenericSelectField<T, U extends StringOrNumber>(props: ICsmGenericSelectFieldProps<T, U>) {
    const { list, ...rest } = props;

    const [listToUse, setListToUse] = useState<T[] | null>(typeof list === "function" ? null : list);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (listToUse == null && isLoading === false && typeof list === "function") veriGetir(list);
    }, [list]);

    const veriGetir = async (loader: () => Promise<T[] | null>) => {
        setIsLoading(true);
        try {
            const response = await loader();
            if (response !== null)
                setListToUse(response);

            setIsLoading(false);
        }
        catch {
            setIsLoading(false);
        }
    }

    return <CsmGenericListSelectBaseField list={listToUse ?? []} {...rest} />
}

function CsmGenericListSelectBaseField<T, U extends StringOrNumber>(props: ICsmGenericSelectFieldBaseProps<T, U>) {
    const { id, value, label, list, labelGetter, valueGetter, isItemBoldGetter, onChange, errorText, error, narrow, helperText, fontSize, isAutoComplete, autoCompleteCharNumTreshold, sx, disabled, showEmpty, style } = props;
    const fontSizeToUse = fontSize ?? 14;

    const helperTextToUse = (typeof errorText === "string") ? errorText : helperText;
    const errorToUse = (typeof errorText === "string") ? (errorText ?? "").length > 0 : error;

    const strings = (list.select(labelGetter));
    strings.push('');

    const selectedItem = list.firstOrDefault(e => valueGetter(e) === value);
    const stringOfValue = selectedItem ? labelGetter(selectedItem) : '';
    const kodOfValue = selectedItem ? valueGetter(selectedItem) : null;

    const onAutoCompleteChange = (event: SyntheticEvent<Element, Event>, value: ReactNode/*, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<ReactNode> | undefined*/): void => {
        const found = list.firstOrDefault(e => labelGetter(e) === value);
        const finalValue = found ? valueGetter(found) : null;

        onChange(finalValue);
    }

    const result = (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            {!isAutoComplete ? (
                <>
                    <InputLabel error={errorToUse} id={"label" + label}>{label}</InputLabel>
                    <Select
                        disabled={disabled}
                        sx={sx}
                        inputProps={{
                            style: {
                                background: "aqua",
                                paddingTop: "5px !important",
                                paddingBottom: "5px !important",
                                fontSize: fontSizeToUse,
                            },
                        }}
                        error={errorToUse}
                        labelId={id}
                        id={id}
                        value={kodOfValue ?? ''}
                        label={label}
                        onChange={(e) => onChange(!e.target.value ? null : e.target.value === "" ? null : (e.target.value as U))}>
                        {showEmpty && <MenuItem key={GuidHelper.Empty} value={GuidHelper.Empty} style={{ height: "36px" }}>&nbsp;</MenuItem>}
                        {list.map(e => (isItemBoldGetter && isItemBoldGetter(e)) ?
                            (
                                <MenuItem key={valueGetter(e).toString()} value={valueGetter(e)}><b>{labelGetter(e)}</b></MenuItem>
                            ) : (
                                <MenuItem key={valueGetter(e).toString()} value={valueGetter(e)}>{labelGetter(e)}</MenuItem>
                            ))}
                    </Select>
                    <FormHelperText error={errorToUse}>{helperTextToUse}</FormHelperText>
                </>
            ) : (
                <>
                    <Autocomplete
                        filterOptions={(x: any[], s: { inputValue: string | any[]; }) => { return s.inputValue.length < (autoCompleteCharNumTreshold ?? 2) ? [] : x.where(e => StringHelper.containsFilter(e!.toString(), s.inputValue as any)); }}
                        disablePortal
                        value={stringOfValue}
                        id="id"
                        options={strings}
                        fullWidth
                        renderInput={(params) => <TextField {...params} error={error} style={{ margin: "0px", paddingTop: "0px !important", paddingBottom: "0px !important", fontSize: fontSizeToUse }} label={label} />}
                        onChange={onAutoCompleteChange}
                    />
                    <FormHelperText error={errorToUse}>{helperTextToUse}</FormHelperText>
                </>
            )}

        </FormControl>
    );

    if (narrow)
        return <ThemeProvider theme={narrowThemeForSelect}>{result}</ThemeProvider>
    else
        return result;
}