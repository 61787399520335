import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import appColors from "../../../app/appColors";
import Columns from "../../../library/components/layout/Columns";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";
import { CsmText } from "../../../library/components/mui/CsmText";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import CsmPieChart from "../../../library/components/charts/CsmPieChart";
import { AnketSoruModel } from "../../../models/businessModels/AnketSoruModel";
import { EnmSoruTipi } from "../../../models/enums/EnmSoruTipi";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch } from "@mui/material";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import Cell from "../../../library/components/layout/Cell";
import { ScreenSizeNames, useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import StringHelper from "../../../library/helpers/StringHelper";

function AnketRaporDetayScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <AnketRaporDetayScreenInner genelDurumHelper={genelDurumHelper} />
}

function AnketRaporDetayScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;
  const { anketId } = useParams();

  const [pivotIsimGoster, setPivotIsimGoster] = useState<boolean>(false);

  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  const screenWidth = useScreenWidth();

  const [pieWidth, pieHeight, rowLikeDesign] = calculatePieWidthAndHeight(screenWidth);

  const anket = aktifKoro.tumAnketler.single(e => e.id === anketId);
  const anaSesGruplari = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]).orderBy(e => e.sira);

  let sesGrupIsim = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(aktifKoro.tumSesGruplari, anket.sesGrupIdList);
  const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(aktifKoro.tumKadrolar, anket.kadroIdList, "Full");

  const anketinTumCevaplari = aktifKoro.hesapAnketCevapModelList.where(e => e.anketModelId === anket.id);

  const uygunKisiIdList = BusinessHelper.Anket.AnketeUygunKisiIdleriGetir(anket, genelDurumHelper);
  const tumCevaplayanlarIdList = anketinTumCevaplari.select(e => e.hesapModelId).distinct();
  const anketeDahilOlacakHerkes = [...uygunKisiIdList, ...tumCevaplayanlarIdList].distinct();
  const cevaplamayanlarIdList = anketeDahilOlacakHerkes.except(tumCevaplayanlarIdList).distinct();

  function soruPieDataOlustur(soru: AnketSoruModel): { label: string, value: number, hesapIdList: string[] }[] {
    const cevaplar = anketinTumCevaplari.where(e => e.anketSoruModelId === soru.id);

    let cevapData: { id?: string, label: string, value: number, hesapIdList: string[] }[] = [];

    if (soru.soruTipi === EnmSoruTipi.Metin || soru.soruTipi === EnmSoruTipi["Uzun Metin"]) {
      cevapData = cevaplar
        .select(e => e.secim ?? "")
        .distinct()
        .select(secim => ({
          label: secim,
          value: cevaplar.where(e => (e.secim ?? "") === secim).length,
          hesapIdList: cevaplar.where(e => (e.secim ?? "") === secim).select(e => e.hesapModelId),
        }));
    }
    else if (soru.soruTipi === EnmSoruTipi["Tek Seçmeli"] || soru.soruTipi === EnmSoruTipi["Çok Seçmeli"]) {
      cevapData = cevaplar
        .select(e => e.anketSoruSecenekId ?? "")
        .distinct()
        .select(secenekId => ({
          label: soru.secenekModelList.single(e => e.id === secenekId).secenekIcerik,
          value: cevaplar.where(e => e.anketSoruSecenekId === secenekId).length,
          hesapIdList: cevaplar.where(e => e.anketSoruSecenekId === secenekId).select(e => e.hesapModelId),
        }));
    }
    else if (soru.soruTipi === EnmSoruTipi["Sıralama"]) {
      cevapData = cevaplar
        .select(e => e.anketSoruSecenekId ?? "")
        .distinct()
        .select(secenekId => ({
          label: soru.secenekModelList.single(e => e.id === secenekId).secenekIcerik,
          value: cevaplar.where(e => e.anketSoruSecenekId === secenekId).sum(e => 1 + soru.secenekModelList.length - parseInt(e.secim!)),
          hesapIdList: []
        }));
    }

    return cevapData;
  }

  if (sesGrupIsim)
    sesGrupIsim = ` (${sesGrupIsim})`;

  const altBilgi2 = `Hedef kitle: ${kadroIsim}${sesGrupIsim}`;
  const altBilgi3 = `Tarih: ${DateTimeToStringHelper.toMonthDayStringOptionalYear(anket.tarihBaslangic, genelDurumHelper.translate)} - ${DateTimeToStringHelper.toMonthDayStringOptionalYear(anket.tarihBitis, genelDurumHelper.translate)}`;

  const temelBilgiler = <Rows key={anket.id} style={{ width: "250px" }}>
    <CsmHeader>Başlık: {anket.baslik} </CsmHeader>
    {!anket.aktif ? null : <CsmText note>Durum: <span style={{ fontWeight: "bold", color: appColors.GREEN }}>Açık</span> ({tumCevaplayanlarIdList.length} / {anketeDahilOlacakHerkes.length})</CsmText>}
    {anket.aktif ? null : <CsmText note>Durum: Kapalı ({tumCevaplayanlarIdList.length} Kişi doldurdu)</CsmText>}
    <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi2}</CsmText>
    <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi3}</CsmText>
  </Rows>

  const katilimPieData = [{ label: "Ankete Katılan", value: tumCevaplayanlarIdList.length }, { label: "Ankete Katılmayan", value: cevaplamayanlarIdList.length }];

  const soruSonuclar = anket.soruModelList.orderBy(e => e.sira).select(soru => ({
    soru: soru,
    data: soruPieDataOlustur(soru)
  }));

  function HesapIsimGetir(hesapId: string): string {
    const hesap = aktifKoro.hesapBilgiList.single(e => e.id === hesapId);
    return `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad}`;
  }

  function isimListesiYap(soruTipi: EnmSoruTipi, isimleriGoster: boolean, cevapData: { label: string, value: number, hesapIdList: string[] }, herkes: boolean, sesGrupId: string | null) {
    if (soruTipi === EnmSoruTipi.Sıralama)
      if (herkes)
        return cevapData.value.toFixed();
      else
        return "N/A";

    const hesaplar = aktifKoro.hesapBilgiList.where(e => cevapData.hesapIdList.contains(e.id) && (herkes === true || (sesGrupId === null && e.sesGrupIdList.length === 0) || (sesGrupId !== null && e.sesGrupIdList.contains(sesGrupId))));

    if (hesaplar.length === 0)
      return "0";

    return <span>
      <span>{hesaplar.length.toFixed()}</span>
      {isimleriGoster &&
        <span style={{ fontSize: 12 }}> ({hesaplar.select(hesap => `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad}`).orderByTurkish(e => e).join(", ")}) </span>
      }
    </span>;
  }

  function SoruyaGoreCevapTextGetir(soru: AnketSoruModel, hesapId: string): string {
    const cevaplar = anketinTumCevaplari.where(e => e.anketSoruModelId === soru.id && e.hesapModelId === hesapId);

    if (soru.soruTipi === EnmSoruTipi.Metin || soru.soruTipi === EnmSoruTipi["Uzun Metin"])
      return cevaplar.firstOrDefault()?.secim ?? ""
    else if (soru.soruTipi === EnmSoruTipi["Tek Seçmeli"] || soru.soruTipi === EnmSoruTipi["Çok Seçmeli"])
      return cevaplar.select(e => soru.secenekModelList.single(t => t.id === e.anketSoruSecenekId)).orderBy(e => e.sira).select(e => e.secenekIcerik).join(", ");
    else if (soru.soruTipi === EnmSoruTipi.Sıralama)
      return cevaplar.select(e => ({ secenek: soru.secenekModelList.single(t => t.id === e.anketSoruSecenekId), sira: parseInt(e.secim!) }))
        .orderBy(e => e.sira).select(e => `${e.sira}. ${e.secenek.secenekIcerik}`).join(", ");

    throw new Error("Rapor Detay ekranında İşlenmemiş soru tipi: " + EnmSoruTipi[soru.soruTipi]);
  }

  return (
    <SubPageLayout
      title="Anket Detay Raporu"
      previousLocations={[{ location: mainScreenLocations.anketRapor }]}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <CsmAccordionSimple title={"Temel Bilgiler"} alwaysOpen>
          <Columns m={20}>
            <Rows>
              {temelBilgiler}
              <CsmPieChart width={250} hideLegend showPercent data={katilimPieData} />
            </Rows>
            <Rows flex>
              <CsmText bold>Anketi Dolduranlar:</CsmText>
              <CsmText note>{tumCevaplayanlarIdList.select(e => HesapIsimGetir(e)).orderByTurkish(e => e).join(", ")}</CsmText>
              <CsmText bold>Anketi Doldurmayanlar:</CsmText>
              <CsmText note>{cevaplamayanlarIdList.select(e => HesapIsimGetir(e)).orderByTurkish(e => e).join(", ")}</CsmText>
            </Rows>
          </Columns>
        </CsmAccordionSimple>
        <CsmAccordionSimple title={"Genel Yüzdeler"} alwaysOpen>
          <Rows>
            {soruSonuclar.map(soruSonuc =>
              <Columns key={soruSonuc.soru.id}>
                <Cell width={20} style={{ background: appColors.GRAY_LIGHT2 }}></Cell>
                <Rows flex key={soruSonuc.soru.id} style={{ placeItems: "flex-start" }} pl={10} pr={20} pb={20} pt={10} gap={10} hasLightRightBorder hasLightBottomBorder>
                  <CsmText>{soruSonuc.soru.sira.toFixed()}. Soru: {soruSonuc.soru.soruIcerik}</CsmText>
                  {soruSonuc.soru.soruTipi === EnmSoruTipi.Metin || soruSonuc.soru.soruTipi === EnmSoruTipi["Uzun Metin"]
                    ?
                    <Rows>
                      <ul style={{ marginTop: "0px", marginBottom: "0px", minWidth: "0px", paddingLeft: "20px" }}>{soruSonuc.data.where(e => StringHelper.isNotEmpty(e.label)).map(e =>
                        <li key={e.label + e.hesapIdList.join(".")}>
                          <CsmText smallNote key={e.label + e.hesapIdList.join(".")}>{e.label} ({e.hesapIdList.select(t => HesapIsimGetir(t)).join(", ")})</CsmText>
                        </li>
                      )}</ul>
                    </Rows>
                    :
                    <CsmPieChart width={pieWidth} height={pieHeight} rowLikeDesign={rowLikeDesign} showPercent data={soruSonuc.data} />
                  }
                </Rows>
              </Columns>
            )}
          </Rows>
        </CsmAccordionSimple>
        <CsmAccordionSimple title={"Pivot Tablosu"}>
          <Columns style={{ alignItems: "center" }}>
            <Switch checked={pivotIsimGoster} onChange={(e) => setPivotIsimGoster(e.target.checked)} />
            <CsmText>{pivotIsimGoster ? "İsimleri Göster" : "İsimleri Gösterme"}</CsmText>
          </Columns>
          <TableContainer>
            <Table size="small" sx={{ flex: 0, minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>İsim</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Herkes</TableCell>
                  {anaSesGruplari.map(e =>
                    <TableCell key={e.id} style={{ fontWeight: "bold" }} >{e.isim}</TableCell>
                  )}
                  <TableCell style={{ fontWeight: "bold" }}>Diğer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {anket.soruModelList.orderBy(e => e.sira).flatMap(soru => {
                  const cevap = soruSonuclar.single(e => e.soru.id === soru.id);

                  const soruIsimRow = <TableRow
                    key={soru.id}
                    style={{ backgroundColor: appColors.GRAY_LIGHT2 }}>
                    <TableCell colSpan={anaSesGruplari.length + 3} style={{ fontWeight: "bold" }} >Soru {soru.sira}: {soru.soruIcerik} ({EnmSoruTipi[soru.soruTipi]})</TableCell>
                  </TableRow>

                  const soruCevapRows = cevap.data.orderByDesc(e => e.value).map(secenekData =>
                    <TableRow
                      key={soru.id + "_" + secenekData.label}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                      }}>
                      <TableCell style={{ fontWeight: "bold" }}>{secenekData.label}</TableCell>
                      <TableCell>{isimListesiYap(soru.soruTipi, pivotIsimGoster, secenekData, true, null)}</TableCell>
                      {anaSesGruplari.map(e =>
                        <TableCell key={e.id} >{isimListesiYap(soru.soruTipi, pivotIsimGoster, secenekData, false, e.id)}</TableCell>
                      )}
                      <TableCell>{isimListesiYap(soru.soruTipi, pivotIsimGoster, secenekData, false, null)}</TableCell>
                    </TableRow>
                  );

                  return [soruIsimRow, ...soruCevapRows];
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CsmAccordionSimple>
        <CsmAccordionSimple title={"Açık Veri"}>
          <TableContainer>
            <Table size="small" sx={{ flex: 0, minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>İsim</TableCell>
                  {anket.soruModelList.orderBy(e => e.sira).select(soru =>
                    <TableCell style={{ fontWeight: "bold" }}>{soru.soruIcerik}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tumCevaplayanlarIdList.select(hesapId => (
                  <TableRow
                    key={hesapId}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                    }}
                  >
                    <TableCell component="th" scope="row">{HesapIsimGetir(hesapId)}</TableCell>
                    {anket.soruModelList.orderBy(e => e.sira).select(soru =>
                      <TableCell>{SoruyaGoreCevapTextGetir(soru, hesapId)}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CsmAccordionSimple>
      </Rows>
    </SubPageLayout>
  );
}

export default AnketRaporDetayScreen;

function calculatePieWidthAndHeight(screenWidth: ScreenSizeNames): [number, number, boolean] {
  switch (screenWidth) {
    case "MoreThan1600": return [1200, 250, false];
    case "Max1600": return [1200, 250, false];
    case "Max1200": return [900, 250, false];
    case "Max900": return [600, 250, false];
    case "Max600": return [250, 500, true];
    case "Max300": return [250, 500, true];
  }
}
