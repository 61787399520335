// AutoGenerated
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class OneriSarkiEkleRequestModel {
    constructor(
        koroModelId: string,
        id: string,
        isim: string,
        besteci: string,
        notaPdfId: string | null,
        notaUrl: string | null,
        performansUrl: string | null,
        onerenAciklama: string | null,
        icerikList: IcerikEklemeRequestModel[],
    ) {
        this.koroModelId = koroModelId;
        this.id = id;
        this.isim = isim;
        this.besteci = besteci;
        this.notaPdfId = notaPdfId;
        this.notaUrl = notaUrl;
        this.performansUrl = performansUrl;
        this.onerenAciklama = onerenAciklama;
        this.icerikList = icerikList;
    }

    koroModelId: string;
    id: string;
    isim: string;
    besteci: string;
    notaPdfId: string | null;
    notaUrl: string | null;
    performansUrl: string | null;
    onerenAciklama: string | null;
    icerikList: IcerikEklemeRequestModel[];
}