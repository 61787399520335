// AutoGenerated
export class OtpAraRequestModel {
    constructor(
        sonDakika: number | null,
        kullaniciAdiIceren: string | null,
    ) {
        this.sonDakika = sonDakika;
        this.kullaniciAdiIceren = kullaniciAdiIceren;
    }

    sonDakika: number | null;
    kullaniciAdiIceren: string | null;
}