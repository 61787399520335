import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../library/components/layout/Columns";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import { mainScreenLocations } from "../../../app/mainScreens";
import Cell from "../../../library/components/layout/Cell";
import JsonHelper from "../../../library/helpers/JsonHelper";
import CsmGenericSelectField from "../../../library/components/mui/select/CsmGenericSelectField";
import Rows from "../../../library/components/layout/Rows";
import { GeriIzlemeAraRequestModel } from "../../../models/requestModels/GeriIzlemeAraRequestModel";
import { GeriIzlemeViewModel } from "../../../models/viewModels/GeriIzlemeViewModel";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { EnmCrud } from "../../../models/enums/EnmCrud";
import appColors from "../../../app/appColors";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

const sureValues = [{ text: "", value: 0 }, { text: "5 dakika", value: 5, }, { text: "20 dakika", value: 20, }, { text: "Son 1 gün", value: 60 * 24, }, { text: "Son 1 hafta", value: 60 * 24 * 7, },];

function GeriIzlemeYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <GeriIzlemeYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function GeriIzlemeYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [model, setModel] = useState<GeriIzlemeAraRequestModel>(new GeriIzlemeAraRequestModel(null, genelDurumHelper.SeciliKoroModel.koroModel.id, "", ""));
  const [geriIzlemeler, setGeriIzlemeler] = useState<GeriIzlemeViewModel[]>([]);

  const eklist = genelDurumHelper.SeciliKoroModel.koroVeHesapModelList.select(e => { return { id: e.koroModel.id, isim: e.koroModel.isim } });
  eklist.push({ id: GuidHelper.Empty, isim: "Genel" });

  const updateModel = (action: (model: GeriIzlemeAraRequestModel) => void) => {
    const result = JsonHelper.deepCopy(model);
    action(result);
    setModel(result);
  }

  function detayGoster(geriIzleme: GeriIzlemeViewModel): void {
    const message = geriIzleme.geriIzlemeDetayModelList.select(
      detay => `${EnmCrud[detay.islem]} - ${detay.tablo} - ${detay.nesneId}`).join("\n");
    //let message = `İçerik: ${hata.hataIcerik}\n\nRequest: ${hata.request}Source: ${hata.hataSource}\n\nStack: ${hata.hataStackTrace}\n\nFull Hata: ${hata.hataFullHata}`;

    AlertHelper.ShowMessage(geriIzleme.geriIzlemeModel.id, message);
  }

  const hataTableGetir = (hataList: GeriIzlemeViewModel[]): JSX.Element => {
    return (
      <TableContainer>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Tarih</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Kullanıcı</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Koro</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Açıklama</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">Detay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hataList.map((geriIzleme) => (
              <TableRow
                key={geriIzleme.geriIzlemeModel.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                }}>
                <TableCell component="th" scope="row">{DateTimeToStringHelper.toDatetimeWithSecondsString(geriIzleme.geriIzlemeModel.olusturmaTarih)}</TableCell>
                <TableCell align="right">{geriIzleme.geriIzlemeModel.olusturan}</TableCell>
                <TableCell align="right">{genelDurumHelper.SeciliKoroModel.koroVeHesapModelList.singleOrDefault(e => e.koroModel.id === geriIzleme.geriIzlemeModel.koroId)?.koroModel.isim ?? "Silinmiş Koro"}</TableCell>
                <TableCell align="right">{geriIzleme.geriIzlemeModel.aciklama}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => detayGoster(geriIzleme)}>
                    {appIcons.Genel.search}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const search = async () => {
    const webResponse = await genelDurumHelper.requestHelper.Genel.GeriIzlemeGetir(model);
    if (!webResponse.successfull)
      return;

    setGeriIzlemeler(webResponse.data?.orderByDesc(e => e.geriIzlemeModel.olusturmaTarih) ?? []);
  }

  return (
    <MainLayout
      location={mainScreenLocations.geriIzlemeYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows>
        <CsmText header style={{ marginBottom: "10px" }}>Kriterler</CsmText>
        <Columns flex mb={10}>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmGenericSelectField
              value={model.sonDakika}
              label="Süre"
              list={sureValues}
              labelGetter={e => e.text}
              valueGetter={e => e.value}
              onChange={e => updateModel(t => t.sonDakika = e)} />
          </Cell>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmTextField value={model.kullaniciAdiIceren} onChange={(e) => updateModel(t => t.kullaniciAdiIceren = e)} label="E-Posta Adresi" />
          </Cell>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmTextField value={model.aciklamaIceren} onChange={(e) => updateModel(t => t.aciklamaIceren = e)} label="Hata Mesajı" />
          </Cell>
          <Cell width={200} style={{ margin: "5px" }}>
            <CsmGenericSelectField
              value={model.koroModelId}
              label="Koro"
              list={eklist}
              labelGetter={e => e.isim}
              valueGetter={e => e.id}
              onChange={value => updateModel(e => e.koroModelId = value)} />
          </Cell>
          <Cell ml={10} mt={10}>
            <Button variant="contained" onClick={() => search()}>
              {appIcons.Genel.search}
              <Typography>Ara</Typography>
            </Button>
          </Cell>
        </Columns>
        <CsmText style={{ marginBottom: "10px" }} header>Sonuçlar</CsmText>
        {hataTableGetir(geriIzlemeler)}
      </Rows>
    </MainLayout>
  );
}

export default GeriIzlemeYonetimScreen;

