// AutoGenerated
export class HesapKuartetDurumGuncelleRequestModel {
    constructor(
        sarkiId: string,
        hesapId: string,
        onaylandi: boolean | null,
    ) {
        this.sarkiId = sarkiId;
        this.hesapId = hesapId;
        this.onaylandi = onaylandi;
    }

    sarkiId: string;
    hesapId: string;
    onaylandi: boolean | null;
}