import { ButtonGroup, Button, Typography, SxProps, Theme, useTheme } from "@mui/material";
import CssType from "csstype";

export interface ICsmSelectableRadioButtonItem<T> {
    key?: string,
    title?: string,
    icon?: React.ReactNode,
    data: T,
}

export interface ICsmSelectableRadioButtonGroupProps<T> {
    selectedValue: T | null,
    onValueChanged: (value: T) => void,
    values: ICsmSelectableRadioButtonItem<T>[],
    selectedColor?: CssType.Property.BackgroundColor,
    unselectedColor?: CssType.Property.BackgroundColor,
    hoverColor?: CssType.Property.BackgroundColor,
    sx?: SxProps<Theme>,
}

export default function CsmSelectableRadioButtonGroup<T>(props: ICsmSelectableRadioButtonGroupProps<T>) {
    const { selectedValue, onValueChanged, values, sx } = props;

    const theme = useTheme();

    const hoverColor = props.hoverColor ?? theme.palette.secondary.main;
    const selectedColor = props.selectedColor ?? theme.palette.primary.main;
    const unselectedColor = props.unselectedColor ?? theme.palette.grey[300];

    return (
        <ButtonGroup sx={sx} >
            {values.map(item => (
                <Button key={item.title ?? item.key!} onClick={() => onValueChanged(item.data)} size={"small"} sx={{
                    backgroundColor: item.data === selectedValue ? selectedColor : unselectedColor,
                    boxShadow: item.data === selectedValue ? "inset 0 3px 5px #333" : "",
                    ":hover": { backgroundColor: hoverColor }
                }}>
                    <>
                        {item.icon ?? null}
                        {!item.title ? null : (
                            <Typography>{item.title}</Typography>
                        )}
                    </>
                </Button>
            ))}
        </ButtonGroup>
    );
}