// AutoGenerated
import { KonserModel } from "../businessModels/KonserModel"
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class KonserModelWithContentRequestModel {
    constructor(
        model: KonserModel,
        icerikList: IcerikEklemeRequestModel[],
    ) {
        this.model = model;
        this.icerikList = icerikList;
    }

    model: KonserModel;
    icerikList: IcerikEklemeRequestModel[];
}