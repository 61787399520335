import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { KoroModel } from "../../models/businessModels/KoroModel";
import KorocanImage from "../business/KorocanImage";
import CsmGenericSelectField, { ICsmGenericSelectFieldProps } from "../../library/components/mui/select/CsmGenericSelectField";
import { CsmText } from "../../library/components/mui/CsmText";
import Cell from "../../library/components/layout/Cell";
import Columns from "../../library/components/layout/Columns";

export type IVatanKoroSelectFieldProps = Omit<ICsmGenericSelectFieldProps<KoroModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumKorolar: KoroModel[], genelDurumHelper: GenelDurumHelper, };

function VatanKoroSelectField(props: IVatanKoroSelectFieldProps) {
    const { tumKorolar, genelDurumHelper, ...rest } = props;
    const orderedKoroList = tumKorolar.orderByTurkish(e => e.isim);

    return (
        <CsmGenericSelectField
            {...rest}
            list={orderedKoroList}
            labelGetter={(e) => (
                <Columns dontGrowForChild style={{ alignItems: "center" }}>
                    <Cell width={100} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <KorocanImage style={{ height: "50px", boxShadow: "0px 0px 5px 1px #fff" }} title={e.isim} icerikId={e.resimIcerikId}  genelDurumHelper={genelDurumHelper} saveToStorage="FullSize" />
                    </Cell>
                    <Cell dontGrowForChild flex>
                        <CsmText header style={{ whiteSpace: "normal", marginLeft: "20px" }}>{e.isim}</CsmText>
                    </Cell>
                </Columns>
            )
            }
            valueGetter={(e) => e.id}
        />
    )
}

export default VatanKoroSelectField;