/* eslint-disable @typescript-eslint/no-explicit-any */
import CsmGenericSelectField, { ICsmGenericSelectFieldProps } from "../select/CsmGenericSelectField";

type innerListType = { value: number, presentation: string };
export type ICsmBooleanSelectFieldPrpps = Omit<ICsmGenericSelectFieldProps<innerListType, number>, "list" | "labelGetter" | "valueGetter" | "value" | "onChange">
    & {
        value: boolean | null,
        trueText?: string,
        falseText?: string,
        onChange: (e: boolean) => void,
    };

function CsmBooleanSelectField(props: ICsmBooleanSelectFieldPrpps) {
    const { trueText, falseText, onChange, value, ...rest } = props;

    const trueTextToUse = trueText ?? "Evet";
    const falseTextToUse = falseText ?? "Hayır";

    return (
        <CsmGenericSelectField
            {...rest}
            onChange={e => onChange(e === 1 ? true : false)}
            value={value === true ? 1 : value === false ? 2 : null}
            list={[{ value: 1, presentation: trueTextToUse }, { value: 2, presentation: falseTextToUse }]}
            labelGetter={e => e.presentation}
            valueGetter={e => e.value as any}
        />
    )
}

export default CsmBooleanSelectField;