/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import JsonHelper from "../../../library/helpers/JsonHelper";
import Cell from "../../../library/components/layout/Cell";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import { AnketSoruModel } from "../../../models/businessModels/AnketSoruModel";
import { AnketSoruKosulModel } from "../../../models/businessModels/AnketSoruKosulModel";
import CsmGenericSelectField from "../../../library/components/mui/select/CsmGenericSelectField";
import CsmGenericRadioButtonGroup from "../../../library/components/mui/radio/CsmGenericRadioButtonGroup";
import EnumHelper from "../../../library/helpers/EnumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";

interface IAnketKosulPopupEditorProps {
    anket: AnketModel,
    soru: AnketSoruModel,
    orijinalKosulList: AnketSoruKosulModel[],
    guncellenebilir: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: AnketSoruKosulModel[] | null) => void,
}

enum EnmAnketKosulRadioSecim {
    "Anketi Sonlandır",
    "Seçili bölüme Git",
    "Seçilen seçeneğe göre davran"
}

function AnketKosulPopupEditor(props: IAnketKosulPopupEditorProps) {
    const { anket, soru, orijinalKosulList, guncellenebilir, genelDurumHelper, closer } = props;

    const [kosulModelList, setKosulModelList] = useState<AnketSoruKosulModel[]>(JsonHelper.deepCopy(orijinalKosulList));
    const [radioSecim, setRadioSecim] = useState<EnmAnketKosulRadioSecim | null>(ilkRadioHesapla(orijinalKosulList));

    const secilebilirBolumSayilar = anket.soruModelList.where(e => e.bolumNo > soru.bolumNo).select(e => e.bolumNo).distinct().orderBy(e => e);
    const secilebilirBolumler = secilebilirBolumSayilar.map(e => ({ value: e, name: `${e}. Bölüme Geç` }))
    const secilebilirBolumlerWithAnketiBitir = [
        ...secilebilirBolumler,
        { value: 0, name: 'Anketi bitir' }
    ];

    const seciliBolumeGitKosul = (radioSecim !== EnmAnketKosulRadioSecim["Seçili bölüme Git"]) ? null : kosulModelList[0];

    const secilebilirSecenekler = soru.secenekModelList;

    const secenekGenelHataMesaj = GenelHataMesajGetir(radioSecim, kosulModelList);
    const hataVar = GenelHataGetir(radioSecim, seciliBolumeGitKosul, kosulModelList);

    const updateKosulModel = (kosul: AnketSoruKosulModel, action: (model: AnketSoruKosulModel) => void) => {
        const result = JsonHelper.deepCopy(kosulModelList);
        const bulunanKosul = result.single(e => e.id === kosul.id);
        action(bulunanKosul);
        setKosulModelList(result);
    }

    function addKosulModel() {
        const result = JsonHelper.deepCopy(kosulModelList);
        const yeniKosul = kosulOlustur(soru, anket, genelDurumHelper);
        result.push(yeniKosul);
        setKosulModelList(result);
    }

    function kosulSil(kosul: AnketSoruKosulModel): void {
        const result = JsonHelper.deepCopy(kosulModelList).where(e => e.id !== kosul.id);
        setKosulModelList(result);
    }

    function setKosulListByRadioSecim(radioSecim: EnmAnketKosulRadioSecim): void {
        const result: AnketSoruKosulModel[] = [];

        if (radioSecim === EnmAnketKosulRadioSecim["Anketi Sonlandır"]) {
            const itemToAdd = kosulOlustur(soru, anket, genelDurumHelper);
            itemToAdd.hedefBolum = null;
            result.push(itemToAdd);
            setKosulModelList(result);
        }
        else if (radioSecim === EnmAnketKosulRadioSecim["Seçili bölüme Git"]) {
            const itemToAdd = kosulOlustur(soru, anket, genelDurumHelper);
            result.push(itemToAdd);
            setKosulModelList(result);
        }
        else
            setKosulModelList([]);

        setRadioSecim(radioSecim);
    }

    return (
        <Rows width={650} height={300} gap={10}>
            <CsmText>Bölümün devamında:</CsmText>
            <CsmGenericRadioButtonGroup
                valueIsNumber
                value={radioSecim}
                itemsDirection="Horizontal"
                label={""}
                list={EnumHelper.getNamesAndValues<EnmAnketKosulRadioSecim>(EnmAnketKosulRadioSecim)}
                labelGetter={e => e.name}
                valueGetter={e => e.value}
                onChange={e => setKosulListByRadioSecim(e)}
                disabledGetter={e => e.value === EnmAnketKosulRadioSecim["Seçilen seçeneğe göre davran"] && secilebilirSecenekler.length === 0}
                hideGenericGroupBox
            />
            {radioSecim !== EnmAnketKosulRadioSecim["Seçili bölüme Git"] ? null :
                <CsmGenericSelectField
                    narrow
                    value={seciliBolumeGitKosul!.hedefBolum ?? 0}
                    label={"Koşul Seçeneği"}
                    list={secilebilirBolumler}
                    labelGetter={e => e.name}
                    valueGetter={e => e.value}
                    onChange={e => updateKosulModel(seciliBolumeGitKosul!, t => t.hedefBolum = e === 0 ? null : e!)}
                />}
            {radioSecim !== EnmAnketKosulRadioSecim["Seçilen seçeneğe göre davran"] ? null :
                <Rows pt={10} gap={10}>
                    <Columns>
                        <Cell flex> <CsmText error note>{secenekGenelHataMesaj}</CsmText> </Cell>
                        <Cell><Button onClick={() => addKosulModel()}>Koşul Ekle</Button></Cell>
                    </Columns>
                    {kosulModelList.map(kosul =>
                        <Columns key={kosul.id} gap={10}>
                            <CsmGenericSelectField
                                narrow
                                label={"Seçenek"}
                                value={kosul.secenekModelId}
                                error={StringHelper.isEmpty(kosul.secenekModelId) || kosuldanOnceAyniSecenekVarMi(kosul, kosulModelList)}
                                list={secilebilirSecenekler}
                                labelGetter={e => `${StringHelper.maxlenToThreeDash(e.secenekIcerik, 30)}`}
                                valueGetter={e => e.id}
                                onChange={e => updateKosulModel(kosul, t => t.secenekModelId = e ?? "")}
                            />
                            <CsmGenericSelectField
                                narrow
                                label={"Hedef Bölüm"}
                                value={kosul.hedefBolum ?? 0}
                                error={kosul.hedefBolum === null}
                                list={secilebilirBolumlerWithAnketiBitir}
                                labelGetter={e => e.name}
                                valueGetter={e => e.value}
                                onChange={e => updateKosulModel(kosul, t => t.hedefBolum = e === 0 ? null : e!)}
                            />
                            <IconButton color="error" onClick={() => kosulSil(kosul)}>{appIcons.CrudIcons.sil}</IconButton>
                        </Columns>
                    )}
                </Rows>
            }

            <Cell flex></Cell>
            {/* <CsmText note><span style={{ fontWeight: "bold" }}>Koşul: </span> {KosulYaziOlustur(kosulModel, anket)}</CsmText> */}
            <Columns height={60} mt={10}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(kosulModelList)} disabled={hataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );
}

export default AnketKosulPopupEditor;

function kosulOlustur(soru: AnketSoruModel, anket: AnketModel, genelDurumHelper: GenelDurumHelper): AnketSoruKosulModel {
    const result = new AnketSoruKosulModel();

    result.id = GuidHelper.NewGuid();
    result.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
    result.olusturmaTarih = DateTimeHelper.now();

    const uygunHedefBolumler = anket.soruModelList.select(e => e.bolumNo).distinct().where(e => e > soru.bolumNo).orderBy(e => e);
    result.hedefBolum = uygunHedefBolumler.firstOrDefault() ?? null;
    result.secenekModelId = null;

    return result;
}


function ilkRadioHesapla(kosulModelList: AnketSoruKosulModel[]): EnmAnketKosulRadioSecim | null {
    if (kosulModelList.length === 0)
        return null;
    else if (kosulModelList.length === 1 && kosulModelList[0].secenekModelId === null) {
        if (kosulModelList[0].hedefBolum === null)
            return EnmAnketKosulRadioSecim["Anketi Sonlandır"];
        else
            return EnmAnketKosulRadioSecim["Seçili bölüme Git"];
    }
    else
        return EnmAnketKosulRadioSecim["Seçilen seçeneğe göre davran"];
}

function kosuldanOnceAyniSecenekVarMi(kosul: AnketSoruKosulModel, kosulModelList: AnketSoruKosulModel[]): boolean {
    const index = kosulModelList.indexOf(kosul);
    for (let i = 0; i < index; i++)
        if (kosulModelList[i].secenekModelId === kosul.secenekModelId)
            return true;
    return false;
}

function GenelHataMesajGetir(radioSecim: EnmAnketKosulRadioSecim | null, kosulModelList: AnketSoruKosulModel[]) {
    if (radioSecim !== EnmAnketKosulRadioSecim["Seçilen seçeneğe göre davran"])
        return "";

    if (kosulModelList.length === 0)
        return "En az bir koşul eklenmelidir";

    const duplikeSecenek = kosulModelList.firstOrDefault(kosul => kosuldanOnceAyniSecenekVarMi(kosul, kosulModelList));
    if (duplikeSecenek !== null)
        return `Seçenekler birden fazla koşulda kullanılmamalıdır`;

    return "";
}

function GenelHataGetir(radioSecim: EnmAnketKosulRadioSecim | null, seciliBolumeGitKosul: AnketSoruKosulModel | null, kosulModelList: AnketSoruKosulModel[]) {
    if (radioSecim === null || radioSecim === EnmAnketKosulRadioSecim["Anketi Sonlandır"])
        return false
    else if (radioSecim === EnmAnketKosulRadioSecim["Seçili bölüme Git"] && seciliBolumeGitKosul !== null)
        return seciliBolumeGitKosul.hedefBolum === null;
    else if (radioSecim === EnmAnketKosulRadioSecim["Seçilen seçeneğe göre davran"])
        if (kosulModelList.length === 0)
            return true;
        else
            return kosulModelList.any(kosul => StringHelper.isEmpty(kosul.secenekModelId) || kosuldanOnceAyniSecenekVarMi(kosul, kosulModelList) || kosul.hedefBolum === null)
    else
        return true;
}

// export function KosulYaziOlustur(kosulModel: AnketSoruKosulModel, anket: AnketModel) {
//     const kosulSoru = anket.soruModelList.singleOrDefault(e => e.id === kosulModel.kosulSoruModelId);
//     const kosulSecenek = kosulSoru?.secenekModelList?.singleOrDefault(e => e.id === kosulModel.kosulSecenekModelId);

//     const soruSira = kosulSoru?.sira ?? "??";
//     const soruIcerik = StringHelper.maxlenToThreeDash(kosulSoru?.soruIcerik ?? "", 30);
//     const secenekIcerik = StringHelper.maxlenToThreeDash(kosulSecenek?.secenekIcerik ?? "??", 30);

//     if (kosulModel.kosulTip === EnmKosulTip["Seçili cevap"])
//         return <>Bu Soru, <span style={{ fontWeight: "bold" }}>{soruSira}. {soruIcerik} </span> sorusuna <span style={{ fontWeight: "bold" }}>{secenekIcerik}</span> cevabı verildiği zaman açılacaktır</>;
//     else if (kosulModel.kosulTip === EnmKosulTip["Seçili dışında cevap"])
//         return <>Bu Soru, <span style={{ fontWeight: "bold" }}>{soruSira}. {soruIcerik} </span> sorusuna <span style={{ fontWeight: "bold" }}>{secenekIcerik}</span> dışında bir cevap verildiği zaman açılacaktır</>;
//     else if (kosulModel.kosulTip === EnmKosulTip["Sorunun Dahil olması"])
//         return <>Bu Soru, <span style={{ fontWeight: "bold" }}>{soruSira}. {soruIcerik} </span> sorusu ankete dahil olduğu zaman açılacaktır</>;
//     else if (kosulModel.kosulTip === EnmKosulTip["Sorunun hariç olması"])
//         return <>Bu Soru, <span style={{ fontWeight: "bold" }}>{soruSira}. {soruIcerik}</span> sorusu ankete dahil olmadığı zaman açılacaktır</>;

//     throw new Error("İşlenmemiş koşul tipi: " + EnmKosulTip[kosulModel.kosulTip]);
// }
