// AutoGenerated
export class AnketAcKapatRequestModel {
    constructor(
        anketId: string,
        hedefDurum: boolean,
    ) {
        this.anketId = anketId;
        this.hedefDurum = hedefDurum;
    }

    anketId: string;
    hedefDurum: boolean;
}