// AutoGenerated by CSSeeder - CsmSampleItemCommon.tsx
import { useState } from "react";
import CsmTextField from "../../library/components/mui/common/CsmTextField";
import JsonHelper from "../../library/helpers/JsonHelper";
import CsmNumericField from "../../library/components/mui/common/CsmNumericField";
import CsmPasswordField from "../../library/components/mui/common/CsmPasswordField";
import CsmCheckbox from "../../library/components/mui/common/CsmCheckbox";
import CsmBooleanSelectField from "../../library/components/mui/common/CsmBooleanSelectField";
import CsmThreeWaySelectField from "../../library/components/mui/common/CsmThreeWaySelectField";
import { GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmGenericCheckboxListField from "../../library/components/mui/common/CsmGenericCheckboxListField";

interface ISampleScreenState {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    number1: null | number;
    number2: null | number;
    number3: null | number;
    password: string;
    boolValue1: boolean;
    boolValue2: boolean | null;
    boolValue3: boolean | null;
    boolValue4: boolean | null;
    checkboxListId: number[];
}

export default function CsmSampleItemCommon() {
    const [model, setModel] = useState<ISampleScreenState>({
        text1: "",
        text2: "",
        text3: "",
        text4: "",
        text5: "",
        number1: null,
        number2: null,
        number3: null,
        password: "",
        boolValue1: false,
        boolValue2: null,
        boolValue3: null,
        boolValue4: null,
        checkboxListId: [],
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const commonlar: ISampleGroup = {
        groupName: "Common Inputs",
        componenets: [
            {
                componentName: "CsmTextField",
                sampleUsages: [
                    {
                        item: <CsmTextField
                            label="Regular Text Input"
                            onChange={e => modelChanged(t => t.text1 = e)}
                            value={model.text1} />,
                        lines: [
                            `<CsmTextField`,
                            `label="Regular Text Input"`,
                            `onChange={e => modelChanged(t => t.text1 = e)}`,
                            `value={model.text1} />`]
                    },
                    {
                        item: <CsmTextField
                            label="Error Text Input"
                            onChange={e => modelChanged(t => t.text2 = e)}
                            value={model.text2}
                            errorText="Value entered is invalid" />,
                        lines: [
                            `<CsmTextField`,
                            `label="Error Text Input"`,
                            `onChange={e => modelChanged(t => t.text2 = e)}`,
                            `value={model.text2}`,
                            `errorText="Value entered is invalid" />`]
                    },
                    {
                        item: <CsmTextField
                            label="Clearable Text Input"
                            onChange={e => modelChanged(t => t.text3 = e)}
                            value={model.text3}
                            showClear />,
                        lines: [
                            `<CsmTextField`,
                            `label="Clearable Text Input"`,
                            `onChange={e => modelChanged(t => t.text3 = e)}`,
                            `value={model.text3}`,
                            `showClear />`]
                    },
                    {
                        item: <CsmTextField
                            label="MultiLine Text Input with stats"
                            onChange={e => modelChanged(t => t.text4 = e)}
                            value={model.text4}
                            showStats
                            multiline
                            rows={5} />,
                        lines: [
                            `<CsmTextField`,
                            `label="MultiLine Text Input with stats"`,
                            `onChange={e => modelChanged(t => t.text4 = e)}`,
                            `value={model.text4}`,
                            `showStats`,
                            `multiline`,
                            `rows={5} />`,
                        ]
                    },
                    {
                        item: <CsmTextField
                            label="Narrow Text Input"
                            onChange={e => modelChanged(t => t.text5 = e)}
                            value={model.text5}
                            narrow />,
                        lines: [
                            `<CsmTextField`,
                            `label="Narrow Text Input"`,
                            `onChange={e => modelChanged(t => t.text5 = e)}`,
                            `value={model.text5}`,
                            `narrow />`,
                        ]
                    },

                ]
            },
            {
                componentName: "CsmNumericField",
                sampleUsages: [
                    {
                        item: <CsmNumericField
                            label="Regular Numeric Input"
                            onChange={e => modelChanged(t => t.number1 = e)}
                            value={model.number1} />,
                        lines: [
                            `<CsmNumericField`,
                            `label="Regular Numeric Input"`,
                            `onChange={e => modelChanged(t => t.number1 = e)}`,
                            `value={model.number1} />`,
                        ]
                    },
                    {
                        item: <CsmNumericField
                            label="Error Numeric Input"
                            onChange={e => modelChanged(t => t.number2 = e)}
                            value={model.number2}
                            errorText="Value entered is invalid" />,
                        lines: [
                            `<CsmNumericField`,
                            `label="Error Numeric Input"`,
                            `onChange={e => modelChanged(t => t.number2 = e)}`,
                            `value={model.number2}`,
                            `errorText="Value entered is invalid" />`,
                        ]
                    },
                    {
                        item: <CsmNumericField
                            label="Numeric Input, Decimals not allowed"
                            onChange={e => modelChanged(t => t.number3 = e)}
                            value={model.number3}
                            disallowDecimals />,
                        lines: [
                            `<CsmNumericField`,
                            `label="Numeric Input, Decimals not allowed"`,
                            `onChange={e => modelChanged(t => t.number3 = e)}`,
                            `value={model.number3}`,
                            `disallowDecimals />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmPasswordField",
                sampleUsages: [
                    {
                        item: <CsmPasswordField
                            label="Regular Password Input"
                            onChange={e => modelChanged(t => t.password = e)}
                            value={model.password} />,
                        lines: [
                            `<CsmPasswordField`,
                            `label="Regular Password Input"`,
                            `onChange={e => modelChanged(t => t.password = e)}`,
                            `value={model.password} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmCheckbox",
                sampleUsages: [
                    {
                        item: <CsmCheckbox
                            label="Regular Checkbox"
                            onChange={e => modelChanged(t => t.boolValue1 = e)}
                            value={model.boolValue1} />,
                        lines: [
                            `<CsmCheckbox`,
                            `label="Regular Checkbox"`,
                            `onChange={e => modelChanged(t => t.boolValue1 = e)}`,
                            `value={model.boolValue1} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericCheckboxListField",
                sampleUsages: [
                    {
                        item: <CsmGenericCheckboxListField
                            label="Generic Checkbox List"
                            onChange={e => modelChanged(t => t.checkboxListId = e)}
                            selectedValues={model.checkboxListId}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `AMAN <CsmGenericCheckboxListField`,
                            `label="Generic Checkbox List"`,
                            `onChange={e => modelChanged(t => t.checkboxListId = e)}`,
                            `selectedValues={checkboxListId}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmBooleanSelectField",
                sampleUsages: [
                    {
                        item: <CsmBooleanSelectField
                            label="Regular Boolean Select Input"
                            onChange={e => modelChanged(t => t.boolValue2 = e)}
                            value={model.boolValue2}
                            trueText="Yes Sir!"
                            falseText="No Sir!" />,
                        lines: [
                            `<CsmBooleanSelectField`,
                            `label="Regular Boolean Select Input"`,
                            `onChange={e => modelChanged(t => t.boolValue2 = e)}`,
                            `value={model.boolValue2}`,
                            `trueText="Yes Sir!"`,
                            `falseText="No Sir!" />`,
                        ]
                    },
                    {
                        item: <CsmBooleanSelectField
                            label="Initial Empty Boolean Select Input"
                            onChange={e => modelChanged(t => t.boolValue3 = e)}
                            value={model.boolValue3}
                            trueText="Yes Sir!"
                            falseText="No Sir!"
                            showEmpty />,
                        lines: [
                            `<CsmBooleanSelectField`,
                            `label="Initial Empty Boolean Select Input"`,
                            `onChange={e => modelChanged(t => t.boolValue3 = e)}`,
                            `value={model.boolValue3}`,
                            `trueText="Yes Sir!"`,
                            `falseText="No Sir!"`,
                            `showEmpty />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmThreeWaySelectField",
                sampleUsages: [
                    {
                        item: <CsmThreeWaySelectField
                            label="Regular Three Way Select Input"
                            onChange={e => modelChanged(t => t.boolValue4 = e)}
                            value={model.boolValue4}
                            trueText="Yes Sir!"
                            falseText="No Sir!"
                            emptyText="No Idea Sir!"
                        />,
                        lines: [
                            `<CsmThreeWaySelectField`,
                            `label="Regular Three Way Select Input"`,
                            `onChange={e => modelChanged(t => t.boolValue4 = e)}`,
                            `value={model.boolValue4}`,
                            `trueText="Yes Sir!"`,
                            `falseText="No Sir!"`,
                            `emptyText="No Idea Sir!"`,
                            `/>`,
                        ]
                    },
                    {
                        item: <CsmBooleanSelectField
                            label="Initial Empty Boolean Select Input"
                            onChange={e => modelChanged(t => t.boolValue3 = e)}
                            value={model.boolValue3}
                            trueText="Yes Sir!"
                            falseText="No Sir!"
                            showEmpty />,
                        lines: [
                            `<CsmBooleanSelectField`,
                            `label="Initial Empty Boolean Select Input"`,
                            `onChange={e => modelChanged(t => t.boolValue3 = e)}`,
                            `value={model.boolValue3}`,
                            `trueText="Yes Sir!"`,
                            `falseText="No Sir!"`,
                            `showEmpty />`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(commonlar);
}