import { Chip, useTheme } from "@mui/material";
import Columns from "../../layout/Columns";
import Cell from "../../layout/Cell";
import Rows from "../../layout/Rows";
import { CsmText } from "../CsmText";
import "drag-drop-touch";
import { StringOrNumber } from "../../../helpers/StringOrNumber";
import CsmGenericGroupBox from "../CsmGenericGroupBox";
import { ICsmGenericTwoPanelListMultiSelectFieldProps } from "./CsmGenericTwoPanelOrderableListMultiSelectField";

export default function CsmGenericTwoPanelListMultiSelectField<T, U extends StringOrNumber>(props: ICsmGenericTwoPanelListMultiSelectFieldProps<T, U>) {
    const { isItemDisabled, value, label, itemLabel, list, labelGetter, valueGetter, onChange, error, disabled, style } = props;

    const theme = useTheme();
    const color_GrayLight2 = theme.palette.grey.A400
    
    const selectedList = value?.slice() ?? [];

    const chipClicked = (newValue: U) => {
        const newIdList = value?.slice() ?? [];
        if (isSelected(newValue))
            newIdList.remove(newValue);
        else
            newIdList.push(newValue);

        onChange(newIdList);
    }

    const isSelected = (e: U) => (value?.contains(e) ?? false);
    const isDisabled = (e: U) => (isItemDisabled ? isItemDisabled(e) : false);

    return <CsmGenericGroupBox disabled={disabled} label={label} error={error} style={{ width: "100%", marginTop: 0, ...style }} >
        <Columns>
            <Rows flex mt={-13} mb={-11} pt={13} pb={11} hasBlack23RightBorder style={{ minWidth: "0px" }}>
                <Cell mt={-10} ml={-13} hasBlack23BottomBorder>
                    <Columns style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                        <CsmText style={{ textAlign: "center" }}>Seçilmeyenler</CsmText>
                        <CsmText smallNote style={{ marginLeft: "5px" }}>{`${list.length - selectedList.length} ${itemLabel}`}</CsmText>
                    </Columns>
                </Cell>
                <Cell style={{ padding: "10px" }}>
                    <Columns wrap>
                        {list.where(e => !isSelected(valueGetter(e))).select(e => valueGetter(e)).map(itemValue =>
                            <Chip disabled={isDisabled(itemValue)} onClick={() => chipClicked(itemValue)} key={itemValue} style={{ margin: "5px" }} label={labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim()} />
                        )}
                    </Columns>
                </Cell>
                <Cell flex></Cell>
                <Columns mb={-12} ml={-12}>
                    <CsmText smallNote italic style={{ marginLeft: "5px", color: color_GrayLight2 }}>Listeye eklemek için tıklayabilirsiniz</CsmText>
                </Columns>
            </Rows>
            <Rows flex style={{ minWidth: "0px" }}>
                <Cell mt={-10} mr={-13} hasBlack23BottomBorder>
                    <Columns style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                        <CsmText style={{ textAlign: "center" }}>Seçilenler</CsmText>
                        <CsmText smallNote style={{ marginLeft: "5px" }}>{`${selectedList.length} ${itemLabel}`}</CsmText>
                    </Columns>
                </Cell>
                <Cell style={{ padding: "10px" }}>
                    <Columns wrap>
                        {selectedList.map((itemValue, index) =>
                            <Chip id={itemValue.toString()}
                                onClick={() => chipClicked(itemValue)} key={itemValue} style={{ margin: "5px" }} label={(index + 1).toFixed(0) + ". " + labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim()} />
                        )}
                    </Columns>
                </Cell>
                <Cell flex></Cell>
                <Columns mb={-12}>
                    <CsmText smallNote italic style={{ marginLeft: "5px", color: color_GrayLight2 }}>Sıralarını değiştirmek için taşıyabilirsiniz. Listeden çıkartmak için tıklayabilirsiniz</CsmText>
                </Columns>
            </Rows>
        </Columns>
    </CsmGenericGroupBox>
}