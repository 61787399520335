// AutoGenerated
import { IcerikEklemeRequestModel } from "../requestModels/IcerikEklemeRequestModel"

export class WithParentIdAndContentRequestModel<T> {
    constructor(
        model: T,
        parentId: string,
        icerikList: IcerikEklemeRequestModel[],
    ) {
        this.model = model;
        this.parentId = parentId;
        this.icerikList = icerikList;
    }

    model: T;
    parentId: string;
    icerikList: IcerikEklemeRequestModel[];
}