/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField } from "@mui/material";
import { CSSProperties, SyntheticEvent } from "react";
import { StringOrNumber } from "../../../helpers/StringOrNumber";

export interface ICsmGenericAutoCompleteMultiSelectFieldProps<T, U extends StringOrNumber> {
    value: U[] | null,
    label: string,
    list: T[],
    labelGetter: (e: T) => string,
    valueGetter: (e: T) => U,
    onChange: (e: any) => void,
    error?: boolean,
    style?: CSSProperties,
    disabled?: boolean,
    // isItemInactive?: (e: T) => boolean,
    // isItemDisabled?: (e: U) => boolean,
}

export default function CsmGenericAutoCompleteMultiSelectField<T, U extends StringOrNumber>(props: ICsmGenericAutoCompleteMultiSelectFieldProps<T, U>) {
    const { value, label, list, labelGetter, valueGetter, onChange, error, disabled, style } = props;
    // const fontSizeToUse = fontSize ?? 14;

    function handleChange(event: SyntheticEvent<Element, Event>, value: T[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T> | undefined): void {
        onChange(value.select(e => valueGetter(e)));
    }

    return <Autocomplete
        disabled={disabled}
        multiple
        id="tags-outlined"
        options={list}
        value={list.where(e => value?.contains(valueGetter(e)) ?? false)}
        getOptionLabel={e => labelGetter(e)}
        defaultValue={[]}
        filterSelectedOptions
        onChange={handleChange}
        style={style}
        renderInput={(params) => (
            <TextField
                {...params}
                error={error}
                label={label}
                placeholder={`${label} yazınız`}
            />
        )}
    />    
}
