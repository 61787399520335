/* eslint-disable @typescript-eslint/no-explicit-any */

export const _requestFiiler = {
    "Giriş yapılıyor": { en: "Logging In" },
    "Şifre güncelleniyor": { en: "Changing Password" },
    "OTP ile şifre güncelleniyor": { en: "Changing password with OTP" },
    "Profil güncelleniyor": { en: "Updating Profile" },
    "Şifre hatırlatma işlemi yapılıyor": { en: "Performing Password Recovery" },
    "Genel durum sorgulanıyor": { en: "Querying general state" },
    "İçerikler getiriliyor": { en: "Retreiving Content" },
    "Sağlık Durumu Getiriliyor": { en: "Retreiving Health Check" },
    "PDF indiriliyor": { en: "Downloading PDF" },
    "İçerik metaları getiriliyor": { en: "Downloading content meta" },
    "Hata aranıyor": { en: "Searching Errors" },
    "Geri izlemeler aranıyor": { en: "Searching Logs" },
    "OTP aranıyor": { en: "Searching OTP" },
    "OTPler temizleniyor": { en: "Cleaning OTP" },
    "Kadro siliniyor": { en: "Deleting Cast" },
    "Kadro güncelleniyor": { en: "Updating Cast" },
    "Kadro ekleniyor": { en: "Creating Cast" },
    "Tarz siliniyor": { en: "Deleting Genre" },
    "Tarz güncelleniyor": { en: "Updating Genre" },
    "Tarz ekleniyor": { en: "Creating Genre" },
    "Ses grubu siliniyor": { en: "Deleting Voice Group" },
    "Ses grubu güncelleniyor": { en: "Updating Voice Group" },
    "Ses grubu ekleniyor": { en: "Creating Voice Group" },
    "Ses grubu sıra numaraları güncelleniyor": { en: "Updating Voice Group order" },
    "Rol siliniyor": { en: "Deleting Role" },
    "Rol güncelleniyor": { en: "Updating Role" },
    "Rol ekleniyor": { en: "Creating Role" },
    "Sezon siliniyor": { en: "Deleting Season" },
    "Sezon güncelleniyor": { en: "Updating Season" },
    "Sezon ekleniyor": { en: "Creating Season" },
    "Duyuru siliniyor": { en: "Deleting Announcement" },
    "Duyuru güncelleniyor": { en: "Updating Announcement" },
    "Duyuru ekleniyor": { en: "Creating Announcement" },
    "Anket siliniyor": { en: "Deleting Poll" },
    "Anket güncelleniyor": { en: "Updating Poll" },
    "Anket ekleniyor": { en: "Creating Poll" },
    "Anket cevaplanıyor": { en: "Submitting Poll" },
    "Çalışma siliniyor": { en: "Deleting Rehearsal" },
    "Çalışma güncelleniyor": { en: "Updating Rehearsal" },
    "Çalışma ekleniyor": { en: "Creating Rehearsal" },
    "Yoklama bildiriliyor": { en: "Submitting Attendence" },
    "Yoklamalar bildiriliyor": { en: "Submitting Attendence List" },
    "Hesap siliniyor": { en: "Deleting Account" },
    "Hesap güncelleniyor": { en: "Updating Account" },
    "Hesap ekleniyor": { en: "Creating Account" },
    "Hesap pasife alınıyor": { en: "Deactivating Account" },
    "Hesap aktife alınıyor": { en: "Activating Account" },
    "Kullanıcı aranıyor": { en: "Searching User" },
    "Kuartet durumu güncelleniyor": { en: "Updating Quartet" },
    "Kuartet durumları güncelleniyor": { en: "Updating Quartet" },
    "Eğitmen yorumu güncelleniyor": { en: "Updating Instructors User Musical Comment" },
    "Korist yorumu güncelleniyor": { en: "Updating User Musical Comment" },
    "Kayıt yorumu güncelleniyor": { en: "Updating Recording Evaluation" },
    "Kayıt ses grubu güncelleniyor": { en: "Updating Voice Group" },
    "Kayıt siliniyor": { en: "Deleting Recording" },
    "Şarkı siliniyor": { en: "Deleting Song" },
    "Şarkı güncelleniyor": { en: "Updating Song" },
    "Şarkı ekleniyor": { en: "Creating Song" },
    "Şarkı kayıt süreci açılıyor": { en: "Enabling Song record entry period" },
    "Şarkı kayıt süreci kapatılıyor": { en: "Disabling Song record entry period" },
    "Şarkı bağlantısı siliniyor": { en: "Deleting Song Link" },
    "Şarkı bağlantısı ekleniyor": { en: "Creating Song Link" },
    "Şarkı bağlantısı güncelleniyor": { en: "Updating Song Link" },
    "Şarkı bağlantıları güncelleniyor": { en: "Updating Song Links" },
    "Aday Şarkı Listesi güncelleniyor": { en: "xxxxxxxxxxxxxx" },
    "Şarkı yorumu siliniyor": { en: "Deleting Song Comment" },
    "Şarkı yorumu ekleniyor": { en: "Creating Song Comment" },
    "Şarkı yorumu güncelleniyor": { en: "Updating Song Comment" },
    "Şarkı zorluk güncelleniyor": { en: "Deleting Song Difficulty" },
    "Şarkı zorluk ekleniyor": { en: "Creating Song Difficulty" },
    "Şarkı zorluk siliniyor": { en: "Updating Song Difficulty" },
    "Çalışmaya katılım durumu bildiriliyor": { en: "Updating attendance" },
    "Taslak kayıt onaylanıyor": { en: "Approving draft recoding" },
    "Kayıt gönderiliyor": { en: "Submitting recoding" },
    "Taslak kayıt siliniyor": { en: "Deleting Draft Recroding" },
    "Anket sonucu bildiriliyor": { en: "Submitting survey " },
    "Duyuru okuma durumu güncelleniyor": { en: "Submitting Announcement state" },
    "Koro siliniyor": { en: "Deleting Choir" },
    "Koro güncelleniyor": { en: "Updating Choir" },
    "Koro ekleniyor": { en: "Creating Choir" },
    "Kullanıcı profil şifresi güncelleniyor": { en: "Updating user profile password" },
    "Hesap aranıyor": { en: "Searching Account" },
    "Koro ayarları güncelleniyor": { en: "Updating Choir Settings" },
    "Koro dosya boyutu getiriliyor": { en: "Calcualting Choir file size" },
    "Kullanılmayan içerikler siliniyor": { en: "Deleting unused material" },
    "Kullanılmaya içerikleri getiriliyor": { en: "Analysing unused material" },
    "Toplu İşlem": { en: "xxxxxxxxxxxxx" },
    "Tatil siliniyor": { en: "Deleting Holiday Entry" },
    "Tatil güncelleniyor": { en: "Updating Holiday Entry" },
    "Tatil ekleniyor": { en: "Creating Holiday Entry" },
    "Özgeçmiş güncelleniyor": { en: "Updating Biography" },
    "Başvuru siliniyor": { en: "Deleting Application" },
    "Başvuru güncelleniyor": { en: "Updating Application" },
    "Başvuru Notu güncelleniyor": { en: "Updating Application Note" },
    "Başvuru ekleniyor": { en: "Creating Application" },
    "Seçme Sonucu güncelleniyor": { en: "Updating Audition Result" },
    "Seçme Sonucu ekleniyor": { en: "Creating Audition Result" },
    "Toplu Başvuru ekleniyor": { en: "Creating Applications" },
    "Anket sonucu gönderiliyor": { en: "Submitting poll result" },
    "Aday Şarkı siliniyor": { en: "XXXXX" },
    "Aday Şarkı güncelleniyor": { en: "XXXXX" },
    "Aday Şarkı ekleniyor": { en: "XXXXX" },

    "Client Request Hatası": { en: "Client Request Error" },
    "Sunucu Hatası": { en: "Server Error" },
};

export const _commonNonBusiness = {
    // fiiller
    "Geri": { en: "Back" },
    "Tazele": { en: "Refresh" },
    "Bilgileri Tazele": { en: "Refresh" },

    // crud
    "Güncelle": { en: "Update" },
    "Sil": { en: "Delete" },
    "İptal": { en: "Cancel" },
    "Kaydet": { en: "Save" },
    "Kopyala": { en: "Copy" },
    "Dikkat": { en: "Attention" },
}

export const _tarihsel = {
    "Ocak": { en: "January" },
    "Şubat": { en: "February" },
    "Mart": { en: "March" },
    "Nisan": { en: "April" },
    "Mayıs": { en: "May" },
    "Haziran": { en: "June" },
    "Temmuz": { en: "July" },
    "Ağustos": { en: "August" },
    "Eylül": { en: "September" },
    "Ekim": { en: "October" },
    "Kasım": { en: "November" },
    "Aralık": { en: "December" },
    "Pazartesi": { en: "Monday" },
    "Salı": { en: "Tuesday" },
    "Çarşamba": { en: "Wednesday" },
    "Perşembe": { en: "Thursday" },
    "Cuma": { en: "Friday" },
    "Cumartesi": { en: "Saturday" },
    "Pazar": { en: "Sunday" },
}

export const _enumlar = {
    "Kadın": { en: "Female" },
    "Erkek": { en: "Male" },
    "PDF": { en: "PDF" },
    "Ses": { en: "Voice" },
    "Midi": { en: "Midi" },
    "Katılamıyorum": { en: "xxxxxxxxxxxxx" },
    "Belirsiz": { en: "xxxxxxxxxxxxx" },
    "Katılacağım": { en: "xxxxxxxxxxxxx" },
    "Katıldı": { en: "xxxxxxxxxxxxx" },
    "Katılmadı": { en: "xxxxxxxxxxxxx" },
    "Geç Katıldı": { en: "xxxxxxxxxxxxx" },
    "Yorum Bekleniyor": { en: "xxxxxxxxxxxxx" },
    "Hatalı": { en: "xxxxxxxxxxxxx" },
    "Onay Bekleniyor": { en: "xxxxxxxxxxxxx" },
    "Onaylandı": { en: "xxxxxxxxxxxxx" },
    "Seçili cevap": { en: "xxxxxxxxxxxxx" },
    "Seçili dışında cevap": { en: "xxxxxxxxxxxxx" },
    "Sorunun Dahil olması": { en: "xxxxxxxxxxxxx" },
    "Sorunun hariç olması": { en: "xxxxxxxxxxxxx" },
    "Ses Grubu içeren": { en: "xxxxxxxxxxxxx" },
    "Ses Grubu içermeyen": { en: "xxxxxxxxxxxxx" },
    "Kadro içeren": { en: "xxxxxxxxxxxxx" },
    "Kadro içermeyen": { en: "xxxxxxxxxxxxx" },
    "Midi Kaydı": { en: "Midi Recording" },
    "Full Midi Kaydı": { en: "Full Midi Recording" },
    "Ses Kaydı": { en: "Voice Recoding" },
    "Konser Videosu": { en: "Concert Video" },
    "Başka Koro Videosu": { en: "Other Choir Video" },
    "Ana Ses Grubu": { en: "Main Voice Group" },
    "Alt Ses Grubu": { en: "Sub Voice Group" },
    "Diğer": { en: "Other" },
    "Üst Ses Grubu": { en: "General Voice Group" },
    "Alt Divizi Ses Grubu": { en: "Sub Division Voice Group" },
    "Metin": { en: "Text" },
    "Tek Seçmeli": { en: "xxxxxxxxxxxxx" },
    "Çok Seçmeli": { en: "xxxxxxxxxxxxx" },
    "Nominal (Mavi, Yeşil)": { en: "xxxxxxxxxxxxx" },
    "Ordinal (iyi, orta, kötü)": { en: "xxxxxxxxxxxxx" },
    "Interval (Sıcaklık)": { en: "xxxxxxxxxxxxx" },
    "Ratio (Ağırlık)": { en: "xxxxxxxxxxxxx" },
    "Duyuru İzleme": { en: "xxxxxxxxxxxxx" },
    "Anket İzleme": { en: "xxxxxxxxxxxxx" },
    "Calışma Yönetim": { en: "xxxxxxxxxxxxx" },
    "Calışma İzleme": { en: "xxxxxxxxxxxxx" },
    "Aday Şarkı Yönetim": { en: "xxxxxxxxxxxxx" },
    "Şarkı Zorluk Yönetim": { en: "xxxxxxxxxxxxx" },
    "Şarkı Zorluk Raporu": { en: "xxxxxxxxxxxxx" },
    "Şarkı İzleme": { en: "xxxxxxxxxxxxx" },
    "Koro Ayarları Yönetim": { en: "xxxxxxxxxxxxx" },
    "Korist Yönetim": { en: "xxxxxxxxxxxxx" },
    "Kayıt Onaylama": { en: "xxxxxxxxxxxxx" },
    "Yoklama Yönetim": { en: "xxxxxxxxxxxxx" },
    "Kayıt Kuartet İzleme": { en: "xxxxxxxxxxxxx" },
    "Kayıt Yükleme (Korist)": { en: "xxxxxxxxxxxxx" },
}

export const _diger = {
    // ana sayfa isimleri
    "Anasayfa": { en: "Homepage" },
    "Takvim": { en: "Calendar" },
    "Raporlar": { en: "Reports" },
    "Yönetim": { en: "Management" },
    "Şarkılar": { en: "Songs" },

    "Yoklama & Çalışmalar": { en: "Attendance & Rehearsals" },
    "Kayıt Değerlendirme": { en: "Recoding Assessement" },
    "Kuartet & Yorumlar": { en: "Quartets & Comments" },
    "Repertuar & Ses Grupları": { en: "Repertoire & Voive Groups" },
    "Kullanıcılar & Kadrolar": { en: "Users & Casts" },
    "Seçmeler": { en: "Auditions" },
    "Seçme Başvuruları": { en: "Audition Applications" },
    "Seçme Sonuçları": { en: "Audition Results" },
    "Seçme Yapma": { en: "Auditions" },
    "Yönetimsel": { en: "Administrative" },
    "Admin": { en: "Admin" },
    "Kayıtlarım": { en: "My Recordings" },
    "Profil": { en: "Profile" },
    "Çıkış": { en: "Logout" },
    "Yoklama Girişi": { en: "Attendance Entry" },
    "Çalışma Yönetimi": { en: "Rehearsal Management" },
    "Şarkıların Kayıtları": { en: "Recordings by Songs" },
    "Koristlerin Kayıtları": { en: "Recordings by Choristers" },
    "Kuartet Girişi": { en: "Quartet Entry" },
    "Korist Yorumları": { en: "General Evaluations on Choristers" },
    "Şarkı Yorumları": { en: "General  on Songs" },
    "Şarkı Yönetim": { en: "Song Management" },
    "Ses Grup Yönetim": { en: "Voice Group Management" },
    "Kullanıcı": { en: "User" },
    "Kullanıcı Yönetim": { en: "User Management" },
    "Kadro Yönetim": { en: "Cast Management" },
    "Kadro": { en: "Cast" },
    "Rol": { en: "Role" },
    "Rol Yönetim": { en: "Role Management" },
    "OTP Yönetim": { en: "OTP Management" },
    "Koro Ayarları": { en: "Choir Settings" },
    "Duyuru": { en: "Announcement" },
    "Anket": { en: "Poll" },
    "Duyuru Yönetim": { en: "Announcement Management" },
    "Anket Yönetim": { en: "Poll Management" },
    "Koro Yönetim": { en: "Choir Management" },
    "Genel Kullanıcı Yönetim": { en: "General User Management" },
    "Hata Yönetim": { en: "Error Management" },
    "Geri İzleme Yönetim": { en: "Trace Management" },
    "Tatil Yönetim": { en: "Holiday Management" },
    "Konser Yönetim": { en: "Concert Management" },
    "Özgeçmiş Yönetim": { en: "Biography Management" },
    "Tarz Yönetim": { en: "Genre Management" },

    "Çizelgeler": { en: "Charts" },
    "Yoklama Çizelgesi": { en: "Attendance Chart" },
    "Kayıt Çizelgesi": { en: "Recording Chart" },
    "Kuartet Çizelgesi": { en: "Quartet Chart" },
    "Konser Çizelgesi": { en: "Concert Chart" },
    "Repertuar Raporu": { en: "Repertoire Report" },
    "Şarkı Meta Raporu": { en: "Song Meta Report" },
    "Şarkı Meta Analiz": { en: "Song Meta Analysis" },
    "Kadro Raporu": { en: "Cast Report" },
    "Genel Korist Raporu": { en: "General Chorister Report" },
    "Anket Raporu": { en: "Poll Report" },

    "İstatistikler": { en: "Statistics" },
    "Konser İstatistikleri": { en: "Concert Statistics" },
    "Sezon İstatistikleri": { en: "Season Statistics" },
    "Kayıt İstatistikleri": { en: "Recording Statistics" },

    // genel isimler"
    "Tamam": { en: "OK" },
    "Koro": { en: "Choir" },
    "Hedef Kitle": { en: "Target Audience" },
    "Konser": { en: "Concert" },
    "Konserler": { en: "Concerts" },
    "Korist": { en: "Chorister" },
    "Şarkı": { en: "Song" },
    "Ses Grubu": { en: "Voice Group" },
    "İçerik": { en: "Content" },
    "Duyurular": { en: "Announcements" },
    "Anketler": { en: "Polls" },
    "Güncel Çalışmalar": { en: "Recent Rehearsals" },
    "Sonraki Hafta Haftası": { tr: "Sonraki Hafta ({{hafta}} Haftası)", en: "Next Week (week of {{hafta}})" },
    "Diğer Haftalar Haftası ve Sonrası": { tr: "Diğer Haftalar ({{hafta}} ve sonrası)", en: "Other Weeks ({{hafta}} and beyond)" },
    "TARIH ve sonrası": { tr: "{{tarih}} ve sonrası", en: "{{tarih}} and beyond" },
    "Arama": { en: "Search" },
    "Arama Anahtarı": { en: "Keyword" },
    "Filtreleme": { en: "Filter" },
    "Filtre": { en: "Filter" },
    "Filtreler (NN Filtreleme)": { tr: "Filtreler ({{filtreCount}} filtreleme)", en: "Filters ({{filtreCount}} filters)" },
    "Yaklaş": { en: "Zoom In" },
    "Uzaklaş": { en: "Zoom Out" },
    "Sıfırla": { en: "Reset Zoom" },

    // Şarkı Meta

    "Şarkı İsmi": { en: "Name of the Song" },
    "Süresi": { en: "Duration" },
    "Divizi": { en: "Division" },
    "MIDI": { en: "MIDI" },
    "Örnek Kayıt": { en: "Sample Recording" },
    "Dönem": { en: "Era" },
    "Tarz": { en: "Genre" },
    "Ölçü Sayısı": { en: "Measure Count" },
    "Dil": { en: "Language" },
    "Eşlik": { en: "Accompany" },
    "Zorluk": { en: "Difficulty" },
    "Aktif": { en: "Is Active" },
    "Ek Yorum": { en: "Additional Comment" },
    "Kayıt Gönderme Sürecinde": { en: "In Record submission process" },
    "Kısa Açıklama": { en: "Short Description" },
    "Kısa İngilizce Açıklama": { en: "Short English Description" },
    "Uzun Açıklama": { en: "Long Description" },
    "Uzun İngilizce Açıklama": { en: "Long English Description" },
    "Partisyon Sayısı": { en: "Partition count" },
    "Tempo": { en: "Tempo" },
    "Tarih": { en: "Date" },
    "Söz Yazarı": { en: "Lyricist" },
    "Ses Grupları": { en: "Voice Groups" },
    "Ses Grupları (Kısaltma)": { en: "Voice Groups (Abbrv)" },
    "Süre": { en: "Duration" },
    "Besteci": { en: "Composer" },
    "Düzenleme": { en: "Arranger" },
    "Sıra": { en: "Order No" },
    "Midi Tam Mı": { en: "Has Midi for all partitions" },
    "Örnek Ses Kaydı Tam Mı": { en: "Has Sample recoding for all partitions" },
    "Örnek Video Kaydı": { en: "Has video recording" },
    "Zorluk Öğrenme": { en: "xxxxx" },
    "Zorluk Söyleme": { en: "xxxxxx" },

    // ------- PUBLIC -------

    // login

    "E-Posta Adresi": { en: "E-mail Address" },
    "Şifre": { en: "Password" },
    "Giriş": { en: "Login" },
    "Şifremi Unuttum": { en: "Forgot Password" },
    "XXX kullanıcısı için şifre hatırlatma işlemi yapılacaktır. Onaylıyor musunuz?": { tr: "{{username}} kullanıcısı için şifre hatırlatma işlemi yapılacaktır. Onaylıyor musunuz?", en: "A password recovery email will be sent to {{username}}. Do you confirm?" },
    "Lütfen kullanıcı adınızı ve şifrenizi giriniz": { en: "Please enter e-mail address and password" },
    "Lütfen şifrenizi giriniz": { en: "Please enter password" },
    "Lütfen kullanıcı adınızı giriniz": { en: "Please enter e-mail address" },
    "Sistemden çıkış yapılmıştır. Otomatik olarak girişi sayfasına yönlendirileceksiniz": { en: "You have been logged out of the system. You will be automatically redirected to the login page" },
    "Eğer yönlendirme yapılmazsa, aşağıdaki link üzerinden giriş sayfasına ilerleyebilirsiniz": { en: "If you are not redirected, you can proceed to the login page via the following link" },
    "Şifre Tekrar": { en: "Password (verification)" },

    // raporlar

    "Sezonlar": { en: "Seasons" },
    "Sezon": { en: "Season" },
    "NNN Sezonu": { tr: "{{sezonIsim}} sezonu", en: "{{sezonIsim}} season" },
    "Genel Repertuvar": { en: "General Repertoire" },
    "Gösterilecek Bilgiler": { en: "Columns to show" },
    "Gösterilecek Bilgiler (NN bilgi seçildi)": { tr: "Gösterilecek Bilgiler ({{bilgiCount}} bilgi seçildi)", en: "Columns to show ({{bilgiCount}} column(s) selected)" },
    "Tabloyu Kopyala": { en: "Copy Table" },
    "Eserler (NN kayıt)": { tr: "Eserler ({{sarkiSayisi}} kayıt)", en: "Songs ({{sarkiSayisi}} item(s))" },
    "AA kolondan BB adedi seçili": { tr: "{{a}} kolondan {{b}} adedi seçili", en: "{{b}} columns selected out of {{a}}" },
    "Kolonlar": { en: "Columns" },
    "Metalar": { en: "Meta Columns" },
    "Kolon Seçimini/Sırasını güncelle": { en: "Update columns selected/order" },
    "Şarkı Seçimini/Sırasını güncelle": { en: "Update song selected/order" },
    "Seçilmeyenler": { en: "Available" },
    "Seçilenler": { en: "Selected" },
    "Listeye eklemek için tıklayabilirsiniz": { en: "Client to add to selection" },
    "Sıralarını değiştirmek için taşıyabilirsiniz. Listeden çıkartmak için tıklayabilirsiniz": { en: "Drag to reorder, click to remove from the list" },

    "Konser Sayısı": { en: "Concert Count" },
    "Kadro Sayısı": { en: "Singer Count" },
    "Yükleme Sayısı": { en: "Uplod Count" },

    // Güncel

    "Yaklaşan Çalışmalar": { en: "Upcoming Rehearsals" },
    "Güncel duyuru bulunmamaktadır": { en: "No Recent Announcements" },
    "Bu Hafta": { en: "This week" },
    "Gösterilecek veri bulunmamaktadır": { en: "No Data to Show" },
    "Bir Koristimiz ile tanışalım": { en: "XXXXXXXX" },
    "Siz de burada görünmek istiyorsanız, Profilinizdeki 'Özgeçmiş Güncelle' düğmesi üzerinden özgeçmişinizi güncelleyiniz :)": { en: "XXXXXXXX" },

    // Anket
    "Anketi Doldur": { en: "Take the Survey" },
    "Not:Seçenekleri XX ikonundan tutarak sıralayabilirsiniz-kısım1": { tr: "Not: Seçenekleri", en: "Note: You can sort the options via" },
    "Not:Seçenekleri XX ikonundan tutarak sıralayabilirsiniz-kısım2": { tr: "ikonundan tutarak sıralayabilirsiniz", en: "icon handle" },
    "Soru": { en: "Question" },

    // Kayıtlarım

    "Kayıt Dinle": { en: "Listen to Recording" },

    // Şarkılar
    "Güncel Şarkılar": { en: "Current Songs" },
    "Bekleyen Şarkılar": { en: "Pending Songs" },
    "Eski Şarkılar": { en: "Old Songs" },
    "Aday Şarkılar": { en: "Candidate Songs" },
    "Diğer Şarkılar": { en: "Other Songs" },
    "Güncel şarkı bulunmamaktadır": { en: "No Current Songs" },
    "Bekleyen şarkı bulunmamaktadır": { en: "No Pending Songs" },
    "Eski şarkı bulunmamaktadır": { en: "No Old Songs" },
    "Diğer şarkı bulunmamaktadır": { en: "No Other Songs" },
    "PDF Göster": { en: "Show PDF" },
    "PDF İndir": { en: "Download PDF" },
    "Midi Kayıtları": { en: "Midi Recordings" },
    "Midi Dinle": { en: "Listen Midi Channels" },
    "Full Midi Dinle": { en: "Listen Full Midi" },
    "SESGRUP Ses Kaydı Dinle": { tr: "{{sesGrupIsim}} Ses Kaydı Dinle", en: "Listen {{sesGrupIsim}} voice recroding" },
    "Ses Kayıtları": { en: "Sample Voice Recordings" },
    "Konser Videoları": { en: "Concert Videos" },
    "Konser Videoları (Diğer)": { en: "Concert Videos (Others)" },
    "Başka Koro Kayıtları": { en: "Other Choir Videos" },
    "Kayıt Yükleme": { en: "Upload Recording" },
    "Örnek Ses Kayıtları": { en: "Sample Voice Recordings" },
    "Koro Kaydı": { en: "Choir Recordings" },
    "Başka Koro Kaydı": { en: "Other Choir recording" },
    "Diğer Koro Videoları": { en: "Other Choir recordings" },

    // Takvim:
    "Bugün": { en: "Today" },
    "NTH Hafta": { tr: "{{weekNumber}}. hafta", en: "{{weekNumber}}. week", },

    // Profil:
    "Koro Bilgileri": { en: "Choir Information" },
    "Roller": { en: "Roles" },
    "Kadrolar": { en: "Casts" },
    "Eski Kadrolar": { en: "Old Casts" },
    "Toplam gelinen çalışma sayısı": { en: "Total number of rehearsals attended" },
    "Çalışmalarda bulunma oranı": { en: "Partitipation rate in rehearsals" },
    "Kayıt yapılan şarkı sayısı": { en: "Total number of songs recorded" },
    "Kuartet yapılan şarkı sayısı": { en: "Total number of songs  recordedsss" },
    "Katılınan Konser sayısı": { en: "Number of concerts attended" },
    "Konserlerde şarkı söyleme sayısı": { en: "Number of songs sung at concerts" },
    "Konserlerde söylenen farklı şarkı sayısı": { en: "Number of distinct songs sung at concerts" },
    "Sezonda gelinen çalışma sayısı": { en: "Total number of rehearsals attended for current season" },
    "Sezonda geç gelinen çalışma sayısı": { en: "Total number of late arrivals in rehearsals for current season" },
    "Sezonda gelinmeyen çalışma sayısı": { en: "Total number of missed rehearsals for current season" },
    "Sezonda çalışmalara katılım oranı (ağırlıklı)": { en: "Partitipation rate in rehearsals for current seasons (weighted)" },
    "Yorumlanan Kayıt Sayısı": { en: "Number of recordings evaluated" },
    "Yorumlanan Şarkı Sayısı": { en: "Number of songs evaluated" },
    "Yorumlanan Korist Sayısı": { en: "Number of choristers evaluated" },
    "Dinlenilen Kuartet Sayısı": { en: "Number of quartets evaluated" },
    "Seçili Koro": { en: "Selected Choir" },
    "Genel İstatistikler": { en: "General Statistics" },
    "Aktif Sezon İstatistikleri": { en: "Active Season Statistics" },
    "Aktif Alt Sezon İstatistikleri": { en: "Active Sub-Season Statistics" },
    "Kayıt Dinleme İstatistikleri": { en: "Evaluation Statistics" },
    "Özgeçmişi": { en: "Biography" },
    "Özgeçmiş Güncelle": { en: "Update Biography" },

    "Şifre Güncelle": { en: "Change Password" },
    "Eski Şifre": { en: "Old Password" },
    "Yeni Şifre": { en: "New Password" },
    "Yeni Şifre (Tekrar)": { en: "New Password (Again)" },
    "Girilen yeni şifre değerleri birbirinden farklıdır.": { en: "The new password values are different from each other." },

    // ------- YÖNETİMOSMAN -------

    // bişi
    "Kullanıcısının Eserindeki kaydını silmek istediğinizden emin misiniz": {
        tr: "{{adSoyad}} kullanıcısının {{sarkiIsim}} eserindeki {{sira}}. {{sesGrupIsim}} kaydını silmek istediğinizden emin misiniz?",
        en: "{{adSoyad}} kullanıcısının {{sarkiIsim}} eserindeki {{sira}}. {{sesGrupIsim}} kaydını silmek istediğinizden emin misiniz?"
    },
    "AdSoyad Ekleniyor": { tr: "{{adSoyad}} Ekleniyor", en: "Createing user {{adSoyad}}" },
    "DosyaIsim Yükleniyor": { tr: "{{dosyaIsim}} Yükleniyor", en: "Loading {{dosyaIsim}}" },

    // ------- YÖNETİM -------

    // Konser Yönetim
    "Konser siliniyor": { en: "Deleting Concert" },
    "Konser güncelleniyor": { en: "Updating Concert" },
    "Konser ekleniyor": { en: "Adding Concert" },

    // Şarkı Yönetim
    "Şarkısı Güncelle": { tr: "{{sarkiIsim}} Şarkısı Güncelle", en: "Update Song {{sarkiIsim}}" },
    "Aday Şarkılarını Güncelle": { tr: "{{sezonIsim}} Aday Şarkılarını Güncelle", en: "xxxxxxx {{sezonIsim}}" },
    "Toplu Ses Dosyası Ekle": { en: "Add Multiple Soung files" },
    "Yeni Şarkı Linki Ekle": { en: "Add New Song Link" },
    "Şarkı Link Ekle": { en: "Add Song Link" },

    // Çalışma Yönetim
    "Çalışma Ekle": { en: "Add Rehearsal" },
    "Çalışmayı silmek istediğinizden emin misiniz?": { en: "Are you sure you want to delete the rehearsal?" },
    "Çalışma Güncelle": { en: "Update Rehearsal" },
    "Takvimi Göster": { en: "Show Calendar" },
    "Takvimi Gösterme": { en: "Don't Show Calendar" },
    "Alt Sezonları Göster": { en: "Show Sub seasons" },
    "Alt Sezonları Gösterme": { en: "Don't Show Sub seasons" },
    "Kadroları Göster": { en: "Show casts" },
    "Kadroları Gösterme": { en: "Don't show casts" },
    "İleri Tarihli Çalışmalar": { en: "Future Reherasals" },
    "İleri tarihli çalışma bulunmamaktadır": { en: "No future reherasals" },
    "Güncel çalışma bulunmamaktadır": { en: "No current rehearsals" },
    "Çalışma bulunmamaktadır": { en: "No rehearsals" },
    "1 Hafta İleri Taşı": { en: "Move forward one week" },
    "1 Hafta Geri Taşı": { en: "Move backward one week" },

    // anket Yönetim
    "Anket Ekle": { en: "Add Poll" },
    "Soru Ekle": { en: "Add Question" },
    "Bölüm Ekle": { en: "Add Section" },
    "Anketi silmek istediğinizden emin misiniz?": { en: "Are you sure you want to delete the poll?" },
    "Anket Güncelle": { en: "Update Poll" },
    "İleri Tarihli Anketler": { en: "Future Polls" },
    "Güncel Anketler": { en: "Current polls" },
    "Eski Anketler": { en: "Old polls" },
    "Kapalı Anketler": { en: "Closed polls" },
    "Anket bulunmamaktadır": { en: "No polls" },
    "Anket Açılıyor": { en: "Opening poll" },
    "Anket Kapanıyor": { en: "Closing poll" },
    "Seçenekler": { en: "Options" },
    "Seçenek Ekle": { en: "Add option" },
    "SeçenekAdet": { tr: "Seçenek", en: "options" },
}