import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, SubPageLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import AnketSoruDisplayer from "./AnketSoruDisplayer";
import { HesapAnketCevapModel } from "../../../models/businessModels/HesapAnketCevapModel";
import appColors from "../../../app/appColors";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { EnmSoruTipi } from "../../../models/enums/EnmSoruTipi";
import { Button } from "@mui/material";
import Columns from "../../../library/components/layout/Columns";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { CsmHeader } from "../../../library/components/mui/CsmHeader";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { AnketSonucGonderRequestModel } from "../../../models/requestModels/AnketSonucGonderRequestModel";

function AnketYanitlaScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <AnketYanitlaScreenInner genelDurumHelper={genelDurumHelper} />
}

function AnketYanitlaScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;
  const { anketId } = useParams();

  // states 
  const [cevaplar, setCevaplar] = useState<HesapAnketCevapModel[]>(HesabinIlkCevaplariniHazirla(genelDurumHelper, anketId));

  // consts
  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  const anket = aktifKoro.tumAnketler.single(e => e.id === anketId);
  const sorulacakSorular = anket.soruModelList.where(e => BusinessHelper.Anket.BolumUygunMu(e.bolumNo, cevaplar, anket.soruModelList)).orderBy(e => e.sira);
  const hataVarMi = sorulacakSorular.any(e => e.zorunlu && cevaplar.where(t => t.anketSoruModelId === e.id).length === 0);

  // Methods

  async function anketSonucGonder() {
    const sorulacakSoruIdler = sorulacakSorular.select(e => e.id);
    const gonderilecekCevaplar = cevaplar.where(e => sorulacakSoruIdler.contains(e.anketSoruModelId));

    const webResponse = await genelDurumHelper.requestHelper.Korist.AnketSonucGonder(new AnketSonucGonderRequestModel(anketId!, gonderilecekCevaplar));
    if (!webResponse.successfull)
      return;

    await AlertHelper.ShowMessage("Teşekkürşer", "Anketi doldurduğunuz için teşekkürler :)");

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        seciliKoro.hesapAnketCevapModelList =
          [...seciliKoro.hesapAnketCevapModelList.where(e => (e.anketModelId === anketId && e.hesapModelId === aktifKoro.hesapBilgiModel.id) == false),
          ...gonderilecekCevaplar];
      });

    genelDurumHelper.navigate(mainScreenLocations.guncel);
  }

  return (
    <SubPageLayout
      title="Anket Yanıtla"
      previousLocations={[{ location: mainScreenLocations.guncel }]}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex style={{ background: appColors.GRAY_LIGHT3, alignItems: "center" }}>
        <Columns width="100%" style={{ background: appColors.GRAY_LIGHT3, placeContent: "center" }}>
          <Rows flex pl={50} pr={50} pt={20} style={{ width: "100%", maxWidth: "1200px" }} >
            <Rows style={{ borderRadius: "25px", background: appColors.WHITE }}>
              <CsmHeader mt={10} style={{ width: "100%" }} bigCentered>{anket.baslik}</CsmHeader>
              {sorulacakSorular.map((soru, i) =>
                <AnketSoruDisplayer
                  key={soru.id}
                  anketModel={anket}
                  soruSiraNo={i + 1}
                  soruModel={soru}
                  tumSorularinCevaplari={cevaplar}
                  genelDurumHelper={genelDurumHelper}
                  submittedOnce={true}
                  onTumSorularinCevaplariChanged={e => setCevaplar(e)} />)}
              <Button style={{ margin: "20px" }} variant="contained" disabled={hataVarMi} onClick={() => anketSonucGonder()}>
                Gönder
              </Button>
            </Rows>
          </Rows>
        </Columns>
      </Rows>
    </SubPageLayout>
  );
}

export default AnketYanitlaScreen;

function HesabinIlkCevaplariniHazirla(genelDurumHelper: GenelDurumHelper, anketId: string | undefined): HesapAnketCevapModel[] {
  const aktifKoro = genelDurumHelper.SeciliKoroModel;
  const anket = aktifKoro.tumAnketler.single(e => e.id === anketId);
  const hesabinAnketeOrijinalCevaplari = aktifKoro.hesapAnketCevapModelList.where(e => e.anketModelId === anketId && e.hesapModelId === aktifKoro.hesapBilgiModel.id);

  let result = JsonHelper.deepCopy(hesabinAnketeOrijinalCevaplari);

  for (const soru of anket.soruModelList) {

    if (soru.soruTipi !== EnmSoruTipi.Sıralama)
      continue;

    const soruyaCevaplar = result.where(e => e.anketSoruModelId === soru.id);

    if (soruyaCevaplar.length !== soru.secenekModelList.length || soruyaCevaplar.all(e => soru.secenekModelList.any(t => t.id === e.anketSoruSecenekId) == false)) {
      result = result.where(e => e.anketSoruModelId !== soru.id);
      for (let i = 0; i < soru.secenekModelList.length; i++) {
        const itemToAdd: HesapAnketCevapModel =
        {
          anketModelId: anket.id,
          anketSoruModelId: soru.id,
          anketSoruSecenekId: soru.secenekModelList[i].id,
          hesapModelId: aktifKoro.hesapBilgiModel.id,
          secim: (i + 1).toFixed()
        };

        result.push(itemToAdd);
      }
    }
  }

  return result;
}