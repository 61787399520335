// AutoGenerated by CSSeeder - CsmSampleItemSelect.tsx
import { useState } from "react";
import JsonHelper from "../../library/helpers/JsonHelper";
import { GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmGenericSelectField from "../../library/components/mui/select/CsmGenericSelectField";
import CsmGenericMultiSelectChipField from "../../library/components/mui/select/CsmGenericMultiSelectChipField";
import { ColorCalculationHelper } from "../../library/helpers/ColorCalculationHelper";
import CsmFreeAutoCompleteField from "../../library/components/mui/select/CsmFreeAutoCompleteField";
import CsmGenericAutoCompleteMultiSelectField from "../../library/components/mui/select/CsmGenericAutoCompleteMultiSelectField";

interface ISampleScreenState {
    selectId1: number | null;
    selectId2: number | null;
    selectId3: number | null;
    selectId4: number | null;
    selectId5: number | null;
    selectId6: number | null;
    selectId7: number | null;
    selectIdList1: number[];
    selectIdList2: number[];
    selectIdList3: number[];
    selectIdList4: number[];
    selectIdList5: number[];
    selectIdList6: number[];
    selectIdList7: number[];
    freeAutoComplete: string;
    genericAutoCompleteList: number[];
}

export default function CsmSampleItemSelect() {
    const [model, setModel] = useState<ISampleScreenState>({
        selectId1: null,
        selectId2: null,
        selectId3: null,
        selectId4: null,
        selectId5: null,
        selectId6: null,
        selectId7: null,
        selectIdList1: [],
        selectIdList2: [],
        selectIdList3: [],
        selectIdList4: [],
        selectIdList5: [],
        selectIdList6: [],
        selectIdList7: [],
        freeAutoComplete: "",
        genericAutoCompleteList: [],
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const sample: ISampleGroup = {
        groupName: "Combo Box (Select) variations",
        componenets: [
            {
                componentName: "CsmGenericSelectField",
                sampleUsages: [
                    {
                        item: <CsmGenericSelectField
                            label="Regular Generic Select"
                            onChange={e => modelChanged(t => t.selectId1 = e)}
                            value={model.selectId1}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="Regular Generic Select"`,
                            `onChange={e => modelChanged(t => t.selectId1 = e)}`,
                            `value={model.selectId1}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                    {
                        item: <CsmGenericSelectField
                            label="Error Generic Select"
                            onChange={e => modelChanged(t => t.selectId2 = e)}
                            value={model.selectId2}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            errorText="Value selected is nonbeautiful" />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="Error Generic Select"`,
                            `onChange={e => modelChanged(t => t.selectId2 = e)}`,
                            `value={model.selectId2}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `errorText="Value selected is nonbeautiful" />`,
                        ]
                    },
                    {
                        item: <CsmGenericSelectField
                            label="Generic Select With Empty Item"
                            onChange={e => modelChanged(t => t.selectId3 = e)}
                            value={model.selectId3}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            showEmpty />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="Generic Select With Empty Item"`,
                            `onChange={e => modelChanged(t => t.selectId3 = e)}`,
                            `value={model.selectId3}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `showEmpty />`,
                        ]
                    },
                    {
                        item: <CsmGenericSelectField
                            label="Generic Select with some Bold Items"
                            onChange={e => modelChanged(t => t.selectId4 = e)}
                            value={model.selectId4}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            isItemBoldGetter={e => e.id % 2 === 1} />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="Generic Select with some Bold Items"`,
                            `onChange={e => modelChanged(t => t.selectId4 = e)}`,
                            `value={model.selectId4}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `isItemBoldGetter={e => e.id % 2 === 1} />`,
                        ]
                    },
                    {
                        item: <CsmGenericSelectField
                            label="Narrow Generic Select"
                            onChange={e => modelChanged(t => t.selectId5 = e)}
                            value={model.selectId5}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            narrow />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="Narrow Generic Select"`,
                            `onChange={e => modelChanged(t => t.selectId5 = e)}`,
                            `value={model.selectId5}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `narrow />`,
                        ]
                    },
                    {
                        item: <CsmGenericSelectField
                            label="ASYNC Data Generic Select"
                            onChange={e => modelChanged(t => t.selectId6 = e)}
                            value={model.selectId6}
                            list={async () => {
                                await new Promise(f => setTimeout(f, 2000));
                                return [{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]
                            }}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="ASYNC Data Generic Select"`,
                            `onChange={e => modelChanged(t => t.selectId6 = e)}`,
                            `value={model.selectId6}`,
                            `list={async () => {`,
                            `  await new Promise(f => setTimeout(f, 2000));`,
                            `  return [{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]`,
                            `}}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                    {
                        item: <CsmGenericSelectField
                            label="AUTOCOMPLETE Generic Select"
                            onChange={e => modelChanged(t => t.selectId7 = e)}
                            value={model.selectId7}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            isAutoComplete
                            autoCompleteCharNumTreshold={0} />,
                        lines: [
                            `<CsmGenericSelectField`,
                            `label="AUTOCOMPLETE Generic Select"`,
                            `onChange={e => modelChanged(t => t.selectId7 = e)}`,
                            `value={model.selectId7}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `isAutoComplete`,
                            `autoCompleteCharNumTreshold={0} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericMultiSelectChipField",
                sampleUsages: [
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Generic Multi Select Chips"
                            onChange={e => modelChanged(t => t.selectIdList1 = e)}
                            value={model.selectIdList1}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Generic Multi Select Chips"`,
                            `onChange={e => modelChanged(t => t.selectIdList1 = e)}`,
                            `value={model.selectIdList1}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Error Generic Multi Select Chips"
                            onChange={e => modelChanged(t => t.selectIdList2 = e)}
                            value={model.selectIdList2}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            errorText="Selection is inavlid" />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Error Generic Multi Select Chips"`,
                            `onChange={e => modelChanged(t => t.selectIdList2 = e)}`,
                            `value={model.selectIdList2}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `errorText="Selection is inavlid" />`,
                        ]
                    },
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Generic Multi Select Chips With Groups"
                            onChange={e => modelChanged(t => t.selectIdList3 = e)}
                            value={model.selectIdList3}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            groupGetter={e => e.id % 2 ? "Evens" : "Odds"} />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Generic Multi Select Chips With Groups"`,
                            `onChange={e => modelChanged(t => t.selectIdList3 = e)}`,
                            `value={model.selectIdList3}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `groupGetter={e => e.id % 2 ? "Evens" : "Odds"} />`,
                        ]
                    },
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Generic Multi Select Chips With Inactives"
                            onChange={e => modelChanged(t => t.selectIdList4 = e)}
                            value={model.selectIdList4}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            isItemInactive={e => e.id % 2 === 0} />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Generic Multi Select Chips With Inactives"`,
                            `onChange={e => modelChanged(t => t.selectIdList4 = e)}`,
                            `value={model.selectIdList4}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `isItemInactive={e => e.id % 2 === 0} />`,
                        ]
                    },
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Generic Multi Select Chips with Disabled"
                            onChange={e => modelChanged(t => t.selectIdList5 = e)}
                            value={model.selectIdList5}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            isItemDisabled={e => e.id === 1} />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Generic Multi Select Chips with Disabled"`,
                            `onChange={e => modelChanged(t => t.selectIdList5 = e)}`,
                            `value={model.selectIdList5}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `isItemDisabled={e => e.id === 1} />`,
                        ]
                    },
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Narrow Generic Multi Select Chips"
                            onChange={e => modelChanged(t => t.selectIdList6 = e)}
                            value={model.selectIdList6}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            small />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Narrow Generic Multi Select Chips"`,
                            `onChange={e => modelChanged(t => t.selectIdList6 = e)}`,
                            `value={model.selectIdList6}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `small />`,
                        ]
                    },
                    {
                        item: <CsmGenericMultiSelectChipField
                            label="Color Overriden Generic Multi Select Chips"
                            onChange={e => modelChanged(t => t.selectIdList7 = e)}
                            value={model.selectIdList7}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            chipColorOverride={e => ColorCalculationHelper.ChartPalettes.colors_Bright[e.id]}
                        />,
                        lines: [
                            `<CsmGenericMultiSelectChipField`,
                            `label="Color Overriden Generic Multi Select Chips"`,
                            `onChange={e => modelChanged(t => t.selectIdList7 = e)}`,
                            `value={model.selectIdList7}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `chipColorOverride={e => ColorCalculationHelper.ChartPalettes.colors_Bright[e.id]}`,
                            `/>`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmFreeAutoCompleteField",
                sampleUsages: [
                    {
                        item: <CsmFreeAutoCompleteField
                            label="Free Auto Complete"
                            onChange={e => modelChanged(t => t.freeAutoComplete = e)}
                            sourceList={["John", "Depp", "Keanu", "Reeves"]}
                            value={model.freeAutoComplete} />,
                        lines: [
                            `<CsmFreeAutoCompleteField`,
                            `label="Free Auto Complete"`,
                            `onChange={e => modelChanged(t => t.freeAutoComplete = e)}`,
                            `sourceList={["John", "Depp", "Keanu", "Reeves"]}`,
                            `value={model.freeAutoComplete} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmGenericAutoCompleteMultiSelectField",
                sampleUsages: [
                    {
                        item: <CsmGenericAutoCompleteMultiSelectField
                            label="Generic Auto Complete Select Chips"
                            onChange={e => modelChanged(t => t.genericAutoCompleteList = e)}
                            value={model.genericAutoCompleteList}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmGenericAutoCompleteMultiSelectField`,
                            `label="Generic Auto Complete Select Chips"`,
                            `onChange={e => modelChanged(t => t.genericAutoCompleteList = e)}`,
                            `value={model.genericAutoCompleteList}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(sample);
}