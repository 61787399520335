/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { AnketSoruModel } from "../../../models/businessModels/AnketSoruModel";
import { EnmSoruTipi } from "../../../models/enums/EnmSoruTipi";
import CsmGenericGroupBox from "../../../library/components/mui/CsmGenericGroupBox";
import EnumHelper from "../../../library/helpers/EnumHelper";
import { Button, IconButton, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import Cell from "../../../library/components/layout/Cell";
import AnketSecenekListPopupEditor from "./AnketSecenekListPopupEditor";
import { AnketSoruSecenekModel } from "../../../models/businessModels/AnketSoruSecenekModel";
import { CsmText } from "../../../library/components/mui/CsmText";
import { AnketSoruKosulModel } from "../../../models/businessModels/AnketSoruKosulModel";
import { AnketModel } from "../../../models/businessModels/AnketModel";
import AnketKosulPopupEditor from "./AnketKosulPopupEditor";
import StringHelper from "../../../library/helpers/StringHelper";

interface IAnketSoruEditorProps {
    anket: AnketModel,
    anketSoruModel: AnketSoruModel,
    guncellenebilir: boolean,
    genelDurumHelper: GenelDurumHelper,
    onChanged: (model: AnketSoruModel) => void,
    onDeleted: (model: AnketSoruModel) => void,
}

function AnketSoruEditor(props: IAnketSoruEditorProps) {
    const { anket, onChanged, onDeleted, guncellenebilir, genelDurumHelper, anketSoruModel } = props;

    const updateAnketSoruModel = (action: (model: AnketSoruModel) => void) => {
        const result = JsonHelper.deepCopy(anketSoruModel);
        action(result);
        onChanged(result);
    }

    const soruTipString = EnumHelper.getNamesAndValues(EnmSoruTipi).single(e => e.value === anketSoruModel.soruTipi).name;

    async function sorusil() {
        if (await AlertHelper.Confirm("Dikkat", "Soruyu silmek istediğinizden emin misiniz?") !== true)
            return;

        onDeleted(anketSoruModel);
    }

    async function kosulEkleGuncelle(guncellemeMi: boolean) {

        if (anketSoruModel.sira === anket.soruModelList.length) {
            AlertHelper.ShowMessage("Dikkat", "Anketin son sorusuna koşul eklenemez");
            return;
        }

        const response = await AlertHelper.ShowClosableContent2<AnketSoruKosulModel[]>(guncellemeMi ? "Devam Kuralı Güncelle" : "Devam Kuralı Ekle", (closer) => (
            <AnketKosulPopupEditor
                guncellenebilir={guncellenebilir}
                anket={anket}
                soru={anketSoruModel}
                orijinalKosulList={anketSoruModel.kosulModelList}
                genelDurumHelper={genelDurumHelper}
                closer={closer} />
        ));

        if (response == null)
            return;

        updateAnketSoruModel(e => e.kosulModelList = response);
    }

    async function secenekEkleGuncelle() {
        const response = await AlertHelper.ShowClosableContent2<AnketSoruSecenekModel[]>("Seçenekleri Güncelle", (closer) => (
            <AnketSecenekListPopupEditor
                guncellenebilir={guncellenebilir}
                orijinalModelList={anketSoruModel.secenekModelList}
                genelDurumHelper={genelDurumHelper}
                closer={closer} />
        ));

        if (response == null)
            return;

        const yeniSecenekIdler = response.select(e => e.id);
        const kosulIdListToRemove = anketSoruModel.kosulModelList.where(e => yeniSecenekIdler.contains(e.secenekModelId!) == false).select(e => e.id);
        updateAnketSoruModel(e => {
            e.kosulModelList = e.kosulModelList.where(t => kosulIdListToRemove.contains(t.id) === false);
            e.secenekModelList = response;
        });
    }

    const cokSecmeMi = anketSoruModel.soruTipi === EnmSoruTipi["Çok Seçmeli"];
    const tekSecmeMi = anketSoruModel.soruTipi === EnmSoruTipi["Tek Seçmeli"];
    const siralamaMi = anketSoruModel.soruTipi === EnmSoruTipi["Sıralama"];

    const cokVeyaTekSecimMi = cokSecmeMi || tekSecmeMi;
    const secenekliMi = cokSecmeMi || tekSecmeMi || siralamaMi;

    const bolumunSonSorusuMu = anket.soruModelList.where(e => e.bolumNo === anketSoruModel.bolumNo).orderBy(e => e.sira).last().id === anketSoruModel.id;
    const anketinSonSorusuMu = anket.soruModelList.orderBy(e => e.sira).last().id === anketSoruModel.id;
    const seceneksizTekKosul = anketSoruModel.kosulModelList.singleOrDefault(e => StringHelper.isEmpty(e.secenekModelId));

    function secenekIcerikGetir(secenekModelId: string) {
        const actualIcerik = anketSoruModel.secenekModelList.single(e => e.id === secenekModelId).secenekIcerik;
        return StringHelper.maxlenToThreeDash(actualIcerik, 15);
    }

    function kosulCumlesiOlstur() {
        return <CsmText note>Bölümün devamında{kosulIcCumlesiOlstur()}</CsmText>
    }

    function kosulIcCumlesiOlstur() {

        if (anketSoruModel.kosulModelList.length === 0)
            return " Bir sonraki bölüme geç";
        else if (seceneksizTekKosul !== null) {
            if (seceneksizTekKosul.hedefBolum === null)
                return " Anketi bitir";
            else
                return ` ${seceneksizTekKosul.hedefBolum}. Bölüme geç`;
        }
        else {
            return ": " + anketSoruModel.kosulModelList.map(kosul => ` "${secenekIcerikGetir(kosul.secenekModelId!)}" seçilirse`
                +
                (kosul.hedefBolum === null ? " Anketi bitir" : ` ${kosul.hedefBolum}. Bölüme geç`)
            ).join(", ");
        }

    }

    return (
        <Rows>
            <CsmGenericGroupBox label={`${anketSoruModel.sira}. Soru (${soruTipString})`}>
                <Rows style={{ gap: "20px" }}>
                    <Columns style={{ gap: "10px" }}>
                        <Rows flex mt={10}>
                            <CsmTextField
                                label="Soru Metni"
                                value={anketSoruModel.soruIcerik}
                                rows={4}
                                multiline
                                showStats
                                onChange={e => updateAnketSoruModel(t => t.soruIcerik = e)}
                            />
                        </Rows>
                        <Rows width={180} style={{ gap: "10px" }}>
                            <Columns>
                                <Columns style={{ alignItems: "center" }}>
                                    <Switch checked={anketSoruModel.zorunlu} onChange={e => updateAnketSoruModel(t => t.zorunlu = e.target.checked)} />
                                    <Typography sx={{ fontSize: 12 }}>{anketSoruModel.zorunlu ? "Zorunlu" : "Opsiyonel"}</Typography>
                                </Columns>
                                <Cell flex></Cell>
                                <IconButton color="error" onClick={() => sorusil()}>{appIcons.CrudIcons.sil}</IconButton>
                            </Columns>
                            {!secenekliMi ? null :
                                <Rows style={{ gap: "10px" }}>
                                    {!cokVeyaTekSecimMi ? null :
                                        <Columns style={{ alignItems: "center" }}>
                                            <Switch checked={anketSoruModel.soruTipi === EnmSoruTipi["Çok Seçmeli"]} onChange={e => updateAnketSoruModel(t => t.soruTipi = (tekSecmeMi ? EnmSoruTipi["Çok Seçmeli"] : EnmSoruTipi["Tek Seçmeli"]))} />
                                            <Typography sx={{ width: "110px", textAlign: "center", fontSize: 12 }}>{tekSecmeMi ? "Sadece tek seçenek seçilebilir" : "Birden fazla seçenek seçilebilir"}</Typography>
                                        </Columns>
                                    }
                                    <Button disabled={!guncellenebilir} variant="outlined" onClick={() => secenekEkleGuncelle()}>
                                        {appIcons.Genel.secenekist}
                                        <Rows ml={10}>
                                            <Typography fontSize="small">{genelDurumHelper.translate("Seçenekler")} ({anketSoruModel.secenekModelList.length})</Typography>
                                        </Rows>
                                    </Button>
                                </Rows>}
                        </Rows>
                    </Columns>
                    {(bolumunSonSorusuMu == false || anketinSonSorusuMu == true) ? null :
                        <Columns pt={10} hasLightTopBorder style={{ placeItems: "center" }}>
                            {kosulCumlesiOlstur()}
                            <Cell flex>
                            </Cell>
                            {anketSoruModel.kosulModelList.length > 0 ?
                                <Cell><Button size="small" onClick={() => kosulEkleGuncelle(true)} variant="outlined">Devam Kuralı Güncelle</Button></Cell>
                                :
                                <Cell><Button size="small" onClick={() => kosulEkleGuncelle(false)} variant="outlined">Devam Kuralı Ekle</Button></Cell>}
                        </Columns>
                    }
                </Rows>
            </CsmGenericGroupBox>
        </Rows>
    );

}

export default AnketSoruEditor;