/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Columns from "../../layout/Columns";
import Rows from "../../layout/Rows";
import "drag-drop-touch";
import { StringOrNumber } from "../../../helpers/StringOrNumber";

export interface ICsmGenericOrderableListProps<T, U extends StringOrNumber> {
    list: T[],
    renderItem: (e: T) => React.ReactNode,
    idGetter: (e: T) => U,
    onOrderChanged: (newOrderedList: T[]) => void,
    style?: CSSProperties,
    dragHandleIcon?: React.ReactNode
}

export default function CsmGenericOrderableList<T, U extends StringOrNumber>(props: ICsmGenericOrderableListProps<T, U>) {
    const { renderItem, idGetter, onOrderChanged, style } = props;

    const [movingValue, setMovingValue] = useState<T | null>(null);
    const [movingOrderedList, setMovingOrderedList] = useState<T[]>(props.list?.slice() ?? []);

    const theme = useTheme();
    const color_PrimaryLight = theme.palette.primary.light;

    const dragHandleIcon = props.dragHandleIcon ?? <DragHandleIcon />

    useEffect(() => {
        console.log("list geldi " + props.list.length.toFixed());
        setMovingOrderedList(props.list);
    }, [props.list])

    function calculateNewOrderedList(list: T[], source: T, dest: T) {
        const newList = list.slice();
        const sourceIndex = list.indexOf(source);
        const destIndex = list.indexOf(dest);

        if (newList.length === 0)
            return [];

        const d = newList[sourceIndex];
        newList.splice(sourceIndex, 1);
        newList.splice(destIndex, 0, d);
        return newList;
    }

    const onChipDrop = (value: T, event: any) => {
        // taşıma işlemi, bu kontrolün üzerinde tamamlanınca tetiklenir
        event.preventDefault();
        setMovingValue(null);
        onOrderChanged(movingOrderedList);
        //onChange(movingOrderedList);
    }

    const onDragEnter = (value: T, event: any) => {
        // taşıma işlemi, bu controlün üzerinden geçmeye başladığında tetiklenir
        if (value !== movingValue && !!movingValue) {
            setMovingOrderedList(calculateNewOrderedList(movingOrderedList, movingValue!, value));
        }
        event.preventDefault();
    }

    const onDragOver = (value: T, event: any) => {
        // taşıma işlemi, bu controlün üzerinden geçerken (pek çok kere) tetiklenir
        event.preventDefault();
    }

    const onDragStart = (value: T, event: any) => {
        // taşıma işlemi bu control üzerinden tutularak başlayınca tetiklenir
        setMovingValue(value);
    }

    const onDragEnd = (value: T, event: any) => {
        // taşıma işleminden vazgeçilince (veya tamamlanınca) tetiklenir
        setMovingValue(null);
    }

    return <Rows wrap style={{ ...style }}>
        {movingOrderedList.map((item) =>
            <Columns draggable
                id={idGetter(item).toString()}
                key={idGetter(item)}
                onDragStart={e => onDragStart(item, e)}
                onDragOver={e => onDragOver(item, e)}
                onDragEnter={e => onDragEnter(item, e)}
                onDragEnd={e => onDragEnd(item, e)}
                onDrop={e => onChipDrop(item, e)}
                style={{ placeItems: "center", backgroundColor: movingValue === item ? color_PrimaryLight : undefined }}>
                {dragHandleIcon}
                {renderItem(item)}
            </Columns>
        )}
    </Rows>
}