// AutoGenerated by CSSeeder - CsmSampleItemRadio.tsx
import { useState } from "react";
import JsonHelper from "../../library/helpers/JsonHelper";
import { CstSpeed, GenerateSampleGroup, ISampleGroup } from "./CsmSampleScreen";
import CsmGenericRadioButtonGroup from "../../library/components/mui/radio/CsmGenericRadioButtonGroup";
import CsmCstRadioButtonGroup from "../../library/components/mui/radio/CsmCstRadioButtonGroup";
import CsmFormSegmentedRadioButtonList from "../../library/components/mui/radio/CsmFormSegmentedRadioButtonList";
import { ColorCalculationHelper } from "../../library/helpers/ColorCalculationHelper";
import CsmFormSegmentedMultiButtonList from "../../library/components/mui/radio/CsmFormSegmentedMultiButtonList";
import { appIcons } from "../../app/appIcons";
import CsmSelectableRadioButtonGroup from "../../library/components/mui/CsmSelectableRadioButtonGroup";

interface ISampleScreenState {
    radioId1: number | null;
    radioId2: number | null;
    radioId3: number | null;
    radioCstSpeed: CstSpeed | null;
    radioButtonGroup: CstSpeed | null;
    formSegmentedId1: number | null;
    formSegmentedId2: number | null;
    formSegmentedMultiId1: number[];
}

export default function CsmSampleItemRadio() {
    const [model, setModel] = useState<ISampleScreenState>({
        radioId1: null,
        radioId2: null,
        radioId3: null,
        radioCstSpeed: null,
        radioButtonGroup: null,
        formSegmentedId1: null,
        formSegmentedId2: null,
        formSegmentedMultiId1: [],
    });

    function modelChanged(action: (t: ISampleScreenState) => void): void {
        const newModel = JsonHelper.deepCopy(model);
        action(newModel);
        setModel(newModel);
    }

    const sample: ISampleGroup = {
        groupName: "Radio List Variations",
        componenets: [
            {
                componentName: "CsmGenericRadioButtonGroup",
                sampleUsages: [
                    {
                        item: <CsmGenericRadioButtonGroup
                            label="Regular Generic Radio Group"
                            onChange={e => modelChanged(t => t.radioId1 = e)}
                            value={model.radioId1}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmGenericRadioButtonGroup`,
                            `label="Regular Generic Radio Group"`,
                            `onChange={e => modelChanged(t => t.radioId1 = e)}`,
                            `value={model.radioId1}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                    {
                        item: <CsmGenericRadioButtonGroup
                            label="Horizontal Generic Radio Group"
                            onChange={e => modelChanged(t => t.radioId2 = e)}
                            value={model.radioId2}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            itemsDirection="Horizontal"
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmGenericRadioButtonGroup`,
                            `label="Horizontal Generic Radio Group"`,
                            `onChange={e => modelChanged(t => t.radioId2 = e)}`,
                            `value={model.radioId2}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `itemsDirection="Horizontal"`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                    {
                        item: <CsmGenericRadioButtonGroup
                            label="Regular Generic Radio Group With Seperator"
                            onChange={e => modelChanged(t => t.radioId3 = e)}
                            value={model.radioId3}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            hasLightItemSeperator />,
                        lines: [
                            `<CsmGenericRadioButtonGroup`,
                            `label="Regular Generic Radio Group With Seperator"`,
                            `onChange={e => modelChanged(t => t.radioId3 = e)}`,
                            `value={model.radioId3}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `hasLightItemSeperator />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmCstRadioButtonGroup",
                sampleUsages: [
                    {
                        item: <CsmCstRadioButtonGroup
                            label="Cst (Enum) Radio Group"
                            onChange={e => modelChanged(t => t.radioCstSpeed = e)}
                            value={model.radioCstSpeed}
                            cstType={CstSpeed} />,
                        lines: [
                            `<CsmCstRadioButtonGroup`,
                            `label="Cst (Enum) Radio Group"`,
                            `onChange={e => modelChanged(t => t.radioCstSpeed = e)}`,
                            `value={model.radioCstSpeed}`,
                            `cstType={CstSpeed} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmFormSegmentedRadioButtonList",
                sampleUsages: [
                    {
                        item: <CsmFormSegmentedRadioButtonList
                            onChange={e => modelChanged(t => t.formSegmentedId1 = e)}
                            value={model.formSegmentedId1}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmFormSegmentedRadioButtonList`,
                            `onChange={e => modelChanged(t => t.formSegmentedId1 = e)}`,
                            `value={model.formSegmentedId1}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                    {
                        item: <CsmFormSegmentedRadioButtonList
                            onChange={e => modelChanged(t => t.formSegmentedId2 = e)}
                            value={model.formSegmentedId2}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id}
                            itemStyle={(e, s) => ({
                                background: s ? ColorCalculationHelper.ChartPalettes.colors_Bright[1] : undefined
                            })
                            } />,
                        lines: [
                            `<CsmFormSegmentedRadioButtonList`,
                            `onChange={e => modelChanged(t => t.formSegmentedId2 = e)}`,
                            `value={model.formSegmentedId2}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id}`,
                            `itemStyle={(e, s) => ({ `,
                            `  background: s ? ColorCalculationHelper.ChartPalettes.colors_Bright[1] : undefined })`,
                            `} />,`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmFormSegmentedMultiButtonList",
                sampleUsages: [
                    {
                        item: <CsmFormSegmentedMultiButtonList
                            onChange={e => modelChanged(t => t.formSegmentedMultiId1 = e)}
                            value={model.formSegmentedMultiId1}
                            list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}
                            labelGetter={e => e.title}
                            valueGetter={e => e.id} />,
                        lines: [
                            `<CsmFormSegmentedMultiButtonList`,
                            `onChange={e => modelChanged(t => t.formSegmentedMultiId1 = e)}`,
                            `value={model.formSegmentedMultiId1}`,
                            `list={[{ id: 1, title: "Item 1" }, { id: 2, title: "Item 2" }, { id: 3, title: "Item 3" }]}`,
                            `labelGetter={e => e.title}`,
                            `valueGetter={e => e.id} />`,
                        ]
                    },
                ]
            },
            {
                componentName: "CsmSelectableRadioButtonGroup",
                sampleUsages: [
                    {
                        item: <CsmSelectableRadioButtonGroup
                            onValueChanged={e => modelChanged(t => t.radioButtonGroup = e)}
                            values={[{
                                key: "1",
                                title: "Item A",
                                icon: appIcons.Genel.duyuruPrimary,
                                data: CstSpeed.Slow,
                            }, {
                                key: "2",
                                title: "Item B",
                                icon: appIcons.Genel.duyuruPrimary,
                                data: CstSpeed.Normal,
                            }, {
                                key: "1",
                                title: "Item C",
                                icon: appIcons.Genel.duyuruPrimary,
                                data: model.radioButtonGroup,
                            }]}
                            selectedValue={CstSpeed.Fast} />,
                        lines: [
                            `<CsmSelectableRadioButtonGroup`,
                            `onValueChanged={e => modelChanged(t => t.radioButtonGroup = e)}`,
                            `values={[{`,
                            `  key: "1",`,
                            `  title: "Item A",`,
                            `  icon: appIcons.Genel.duyuruPrimary,`,
                            `  data: CstSpeed.Slow,`,
                            `}, {`,
                            `  key: "2",`,
                            `  title: "Item B",`,
                            `  icon: appIcons.Genel.duyuruPrimary,`,
                            `  data: CstSpeed.Normal,`,
                            `}, {`,
                            `  key: "1",`,
                            `  title: "Item C",`,
                            `  icon: appIcons.Genel.duyuruPrimary,`,
                            `  data: CstSpeed.Fast,`,
                            `}]}`,
                            `selectedValue={model.radioButtonGroup} />`,
                        ]
                    },
                ]
            },
        ]
    }

    return GenerateSampleGroup(sample);
}