/* eslint-disable @typescript-eslint/no-explicit-any */
import { alpha, Chip, useTheme } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import Columns from "../../layout/Columns";
import Cell from "../../layout/Cell";
import Rows from "../../layout/Rows";
import { CsmText } from "../CsmText";
import "drag-drop-touch";
import { StringOrNumber } from "../../../helpers/StringOrNumber";
import CsmGenericGroupBox from "../CsmGenericGroupBox";

export interface ICsmGenericTwoPanelListMultiSelectFieldProps<T, U extends StringOrNumber> {
    value: U[] | null,
    label: string,
    itemLabel: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    onChange: (e: any) => void,
    error?: boolean,
    style?: CSSProperties,
    disabled?: boolean,
    isItemDisabled?: (e: U) => boolean,
}

export default function CsmGenericTwoPanelOrderableListMultiSelectField<T, U extends StringOrNumber>(props: ICsmGenericTwoPanelListMultiSelectFieldProps<T, U>) {
    const { isItemDisabled, value, label, itemLabel, list, labelGetter, valueGetter, onChange, error, disabled, style } = props;

    const [movingValue, setMovingValue] = useState<U | null>(null);
    const [movingOrderedList, setMovingOrderedList] = useState<U[]>(value?.slice() ?? []);

    const theme = useTheme();
    const color_Black023 = alpha(theme.palette.common.black, 0.23)
    const color_GrayLight2 = theme.palette.grey.A400
    const color_PrimaryLight = theme.palette.primary.light;

    function calculateNewOrderedList(list: U[], source: U, dest: U) {
        const newList = list.slice();
        const sourceIndex = list.indexOf(source);
        const destIndex = list.indexOf(dest);

        if (newList.length === 0)
            return [];

        const d = newList[sourceIndex];
        newList.splice(sourceIndex, 1);
        newList.splice(destIndex, 0, d);
        return newList;
    }

    const chipClicked = (newValue: U) => {
        const newIdList = value?.slice() ?? [];
        if (isSelected(newValue))
            newIdList.remove(newValue);
        else
            newIdList.push(newValue);

        onChange(newIdList);
        setMovingOrderedList(newIdList);
    }

    const onChipDrop = (value: U, event: any) => {
        // taşıma işlemi, bu kontrolün üzerinde tamamlanınca tetiklenir
        event.preventDefault();
        setMovingValue(null);
        onChange(movingOrderedList);
    }

    const onDragEnter = (value: U, event: any) => {
        // taşıma işlemi, bu controlün üzerinden geçmeye başladığında tetiklenir
        if (value !== movingValue && !!movingValue) {
            setMovingOrderedList(calculateNewOrderedList(movingOrderedList, movingValue!, value));
        }
        event.preventDefault();
    }

    const onDragOver = (value: U, event: any) => {
        // taşıma işlemi, bu controlün üzerinden geçerken (pek çok kere) tetiklenir
        event.preventDefault();
    }

    const onDragStart = (value: U, event: any) => {
        // taşıma işlemi bu control üzerinden tutularak başlayınca tetiklenir
        setMovingValue(value);
    }

    const onDragEnd = (value: U, event: any) => {
        // taşıma işleminden vazgeçilince (veya tamamlanınca) tetiklenir
        setMovingValue(null);
    }

    const isSelected = (e: U) => (value?.contains(e) ?? false);
    const isDisabled = (e: U) => (isItemDisabled ? isItemDisabled(e) : false);

    return <CsmGenericGroupBox disabled={disabled} label={label} error={error} style={{ width: "100%", marginTop: 0, ...style }} >
        <Columns>
            <Rows flex mt={-13} mb={-11} pt={13} pb={11} hasBlack23RightBorder style={{ minWidth: "0px" }}>
                <Cell mt={-10} ml={-13} hasBlack23BottomBorder>
                    <Columns style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                        <CsmText style={{ textAlign: "center" }}>Seçilmeyenler</CsmText>
                        <CsmText smallNote style={{ marginLeft: "5px" }}>{`${list.length - movingOrderedList.length} ${itemLabel}`}</CsmText>
                    </Columns>
                </Cell>
                <Cell style={{ padding: "10px" }}>
                    <Columns wrap>
                        {list.where(e => !isSelected(valueGetter(e))).select(e => valueGetter(e)).map(itemValue =>
                            <Chip disabled={isDisabled(itemValue)} onClick={() => chipClicked(itemValue)} key={itemValue} style={{ margin: "5px" }} label={labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim()} />
                        )}
                    </Columns>
                </Cell>
                <Cell flex></Cell>
                <Columns mb={-12} ml={-12}>
                    <CsmText smallNote italic style={{ marginLeft: "5px", color: color_GrayLight2 }}>Listeye eklemek için tıklayabilirsiniz</CsmText>
                </Columns>
            </Rows>
            <Rows flex style={{ minWidth: "0px" }}>
                <Cell mt={-10} mr={-13} hasBlack23BottomBorder>
                    <Columns style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                        <CsmText style={{ textAlign: "center" }}>Seçilenler</CsmText>
                        <CsmText smallNote style={{ marginLeft: "5px" }}>{`${movingOrderedList.length} ${itemLabel}`}</CsmText>
                    </Columns>
                </Cell>
                <Cell style={{ padding: "10px" }}>
                    <Columns wrap>
                        {movingOrderedList.map((itemValue, index) =>
                            <Chip draggable id={itemValue.toString()}
                                onDragStart={e => onDragStart(itemValue, e)}
                                onDragOver={e => onDragOver(itemValue, e)}
                                onDragEnter={e => onDragEnter(itemValue, e)}
                                onDragEnd={e => onDragEnd(itemValue, e)}
                                onDrop={e => onChipDrop(itemValue, e)}
                                onClick={() => chipClicked(itemValue)} key={itemValue} style={{ margin: "5px", backgroundColor: movingValue === itemValue ? color_PrimaryLight : undefined }} label={(index + 1).toFixed(0) + ". " + labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim()} />
                        )}
                    </Columns>
                </Cell>
                <Cell flex></Cell>
                <Columns mb={-12}>
                    <CsmText smallNote italic style={{ marginLeft: "5px", color: color_GrayLight2 }}>Sıralarını değiştirmek için taşıyabilirsiniz. Listeden çıkartmak için tıklayabilirsiniz</CsmText>
                </Columns>
            </Rows>
        </Columns>
    </CsmGenericGroupBox>
}