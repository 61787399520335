import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import Cell from "../../../library/components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import Columns from "../../../library/components/layout/Columns";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import AnketRaporDisplayer from "./AnketRaporDisplayer";

function AnketRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <RepertuarRaporScreenInner genelDurumHelper={genelDurumHelper} />
}

function RepertuarRaporScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const [searchText, setSearchText] = useState<string>("");

  const aktifKoro = genelDurumHelper.SeciliKoroModel;

  const tumAnketler = aktifKoro.tumAnketler;
  const filtreliAnketler = StringHelper.isEmpty(searchText) ? tumAnketler : tumAnketler.where(e => includesSearchText(e.baslik));
  const digerSezonlar: SezonModel[] = genelDurumHelper.SeciliKoroModel.tumSezonlar.where(e => e.id !== genelDurumHelper.AktifSezon.id).orderByDesc(e => e.isim);
  const sezonVeAnketler = digerSezonlar.select(e => ({ sezon: e, anketler: filtreliAnketler.where(t => t.sezonModelId === e.id) }));

  const guncelAnketler = tumAnketler.where(e => e.sezonModelId === genelDurumHelper.AktifSezon.id && e.aktif);
  const kapaliAnketler = tumAnketler.where(e => e.sezonModelId === genelDurumHelper.AktifSezon.id && !e.aktif);

  function includesSearchText(content: string) {
    return content.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
  }

  return (
    <MainLayout
      location={mainScreenLocations.anketRapor}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns>
          <CsmText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Arama")}: </CsmText>
          <Cell ml={10} mt={5}>
            <CsmTextField narrow value={searchText} onChange={(e) => setSearchText(e)} label={genelDurumHelper.translate("Arama Anahtarı")} eventOnKeyPress />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <CsmAccordionSimple
          title={genelDurumHelper.translate("Güncel Anketler")}
          visible={guncelAnketler.length > 0}
          alwaysOpen>
          <Columns>
            {guncelAnketler.map(anket =>
              <AnketRaporDisplayer key={anket.id} anket={anket} genelDurumHelper={genelDurumHelper} />
            )}
          </Columns>
        </CsmAccordionSimple>
        <CsmAccordionSimple
          title={genelDurumHelper.translate("Kapalı Anketler")}
          visible={kapaliAnketler.length > 0}
          alwaysOpen>
          <Columns>
            {kapaliAnketler.map(anket =>
              <AnketRaporDisplayer key={anket.id} anket={anket} genelDurumHelper={genelDurumHelper} />
            )}
          </Columns>
        </CsmAccordionSimple>
        {sezonVeAnketler.map(sezonAnket => (
          <CsmAccordionSimple
            key={sezonAnket.sezon.id}
            visible={sezonAnket.anketler.length > 0}
            title={sezonAnket.sezon.isim}>
            <Columns>
              {sezonAnket.anketler.map(anket =>
                <AnketRaporDisplayer key={anket.id} anket={anket} genelDurumHelper={genelDurumHelper} />
              )}
            </Columns>
          </CsmAccordionSimple>
        ))}       
      </Rows>
    </MainLayout>
  );
}

export default AnketRaporScreen;