import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetecetor from 'i18next-browser-languagedetector';
import { allAppTranslations } from '../helpers/TranslationHelper';

i18next
    .use(initReactI18next)
    .use(LanguageDetecetor)
    .init({
        debug: true,
        fallbackLng: 'tr',
        resources: allAppTranslations,
    });