import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { HesapYorumEkleGuncelleRequestModel } from "../../../models/requestModels/HesapYorumEkleGuncelleRequestModel";
import { HesapYorumModel } from "../../../models/businessModels/HesapYorumModel";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import Cell from "../../../library/components/layout/Cell";
import { useScreenWidth } from "../../../library/components/hooks/useScreenWidth";
import StringHelper from "../../../library/helpers/StringHelper";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { HesapEgitmenYorumEkleGuncelleRequestModel } from "../../../models/requestModels/HesapEgitmenYorumEkleGuncelleRequestModel";

interface IKoristYorumKoristPopupEditorProps {
    hesapBilgi: HesapBilgiVeKullaniciModel,
    hesapYorum: HesapYorumModel | null,
    closer: (model: HesapYorumModel | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function KoristYorumKoristPopupEditor(props: IKoristYorumKoristPopupEditorProps) {
    const [secmeYorumlar, setSecmeYorumlar] = useState<string>(StringHelper.nullToEmpty(props.hesapYorum?.secmeYorumlar));
    const [arkadasYorumlar, setArkadasYorumlar] = useState<string>(StringHelper.nullToEmpty(props.hesapYorum?.arkadasYorumlar));
    const [egitmenYorumlar, setEgitmenYorumlar] = useState<string>(StringHelper.nullToEmpty(props.hesapYorum?.egitmenYorumlar));
    const [sefYorumlar, setSefYorumlar] = useState<string>(StringHelper.nullToEmpty(props.hesapYorum?.sefYorumlar));

    const tumYorumlariGoster = props.genelDurumHelper.HasYetkiAll([EnmYetki["Kayıt Onaylama"]]);

    const yorumKaydet = async () => {
        if (!tumYorumlariGoster) {
            const model = new HesapEgitmenYorumEkleGuncelleRequestModel(props.hesapBilgi.id, egitmenYorumlar);

            const webResponse = await props.genelDurumHelper.requestHelper.YonetimHesapMuzikal.HesapEgitmenYorumEkleGuncelle(model);
            if (!webResponse.successfull)
                return;

            const hesapYorumModel = new HesapYorumModel();
            hesapYorumModel.id = GuidHelper.NewGuid();
            hesapYorumModel.arkadasYorumlar = "";
            hesapYorumModel.egitmenYorumlar = egitmenYorumlar;
            hesapYorumModel.sefYorumlar = "";
            hesapYorumModel.secmeYorumlar = "";
            hesapYorumModel.hesapModelId = props.hesapBilgi.id;

            props.closer(hesapYorumModel);
        }
        else {
            const model = new HesapYorumEkleGuncelleRequestModel(props.hesapBilgi.id, secmeYorumlar, arkadasYorumlar, egitmenYorumlar, sefYorumlar);

            const webResponse = await props.genelDurumHelper.requestHelper.YonetimHesapMuzikal.HesapYorumEkleGuncelle(model);
            if (!webResponse.successfull)
                return;

            const hesapYorumModel = new HesapYorumModel();
            hesapYorumModel.id = GuidHelper.NewGuid();
            hesapYorumModel.arkadasYorumlar = arkadasYorumlar;
            hesapYorumModel.egitmenYorumlar = egitmenYorumlar;
            hesapYorumModel.sefYorumlar = sefYorumlar;
            hesapYorumModel.secmeYorumlar = secmeYorumlar;
            hesapYorumModel.hesapModelId = props.hesapBilgi.id;

            props.closer(hesapYorumModel);
        }

    }

    const screenSize = useScreenWidth();
    const isSmallScreen = screenSize === "Max300";
    const isMediumScreen = screenSize === "Max600";
    const isLargeScreen = screenSize === "Max900";
    const isXLargeScreen = screenSize === "Max1200";

    const minWidth = isSmallScreen ? 250 : isMediumScreen ? 250 : isLargeScreen ? 550 : isXLargeScreen ? 850 : 1150;

    return <Rows flex style={{ minWidth: minWidth }}>
        {tumYorumlariGoster &&
            <Cell style={{ margin: "5px", flexGrow: 1 }}>
                <CsmTextField
                    multiline
                    rows={4}
                    label="Seçme Yorumları"
                    value={secmeYorumlar}
                    showStats
                    onChange={(e) => setSecmeYorumlar(() => e)} />
            </Cell>}
        <Cell style={{ margin: "5px", flexGrow: 1 }}>
            <CsmTextField
                multiline
                rows={4}
                disabled={!tumYorumlariGoster}
                label="Şef Yorumları"
                value={sefYorumlar}
                showStats
                eventOnKeyPress
                onChange={(e) => setSefYorumlar(() => e)} />
        </Cell>
        <Cell style={{ margin: "5px", flexGrow: 1 }}>
            <CsmTextField
                multiline
                rows={4}
                label="Eğitmen Yorumları"
                value={egitmenYorumlar}
                showStats
                eventOnKeyPress
                onChange={(e) => setEgitmenYorumlar(() => e)} />
        </Cell>
        {tumYorumlariGoster &&
            <Cell style={{ margin: "5px", flexGrow: 1 }}>
                <CsmTextField
                    multiline
                    rows={4}
                    label="Arkadaş Yorumları"
                    value={arkadasYorumlar}
                    showStats
                    eventOnKeyPress
                    onChange={(e) => setArkadasYorumlar(() => e)} />
            </Cell>
        }
        <Columns mt={10}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" onClick={() => yorumKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
}

export default KoristYorumKoristPopupEditor;