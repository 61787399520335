import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { Button, Typography, FormControl } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";
import { OneriSarkiEkleRequestModel } from "../../../models/requestModels/OneriSarkiEkleRequestModel";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import StringHelper from "../../../library/helpers/StringHelper";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";

interface IOneriSarkiModelPopupEditorProps {
    genelDurumHelper: GenelDurumHelper
    closer: (model: OneriSarkiEkleRequestModel | null) => void,
}

function OneriSarkiEklePopupEditor(props: IOneriSarkiModelPopupEditorProps) {
    const { genelDurumHelper, closer } = props;
    const translate = genelDurumHelper.translate;

    const [oneriSarkiModel, setOneriSarkiModel] = useState<OneriSarkiEkleRequestModel>(new OneriSarkiEkleRequestModel(genelDurumHelper.SeciliKoroModel.koroModel.id, GuidHelper.NewGuid(), "", "", "", "", "", "", []));

    const sarkiOneriBilgiChanged = (action: (model: OneriSarkiEkleRequestModel) => void): void => {
        const dummy = JsonHelper.deepCopy(oneriSarkiModel);
        action(dummy);
        setOneriSarkiModel(dummy);
    }

    let isimHata: string | undefined;
    if (oneriSarkiModel.isim === "")
        isimHata = "Bu alan zorunludur";

    const sarkiKaydet = async () => {
        const webResponse = await props.genelDurumHelper.requestHelper.OneriSarki.Ekle(oneriSarkiModel);
        if (!webResponse.successfull)
            return;

        props.closer(oneriSarkiModel)
    }

    const handlePdfIcerikUpdate = (model: IcerikEklemeRequestModel | null) => {
        // eslint-disable-next-line no-debugger
        debugger;
        sarkiOneriBilgiChanged(e => {
            // eslint-disable-next-line no-debugger
            debugger;
            // bir işlem öncesinde başka dosya upload edildiyse, onu upload listesinden kaldır
            const oldIcerikModel = e.icerikList.singleOrDefault(t => t.id === e.notaPdfId);
            if (oldIcerikModel)
                e.icerikList.remove(oldIcerikModel);

            // eğer yeni içerik dolu ise, onu upload listesine ekle
            if (model)
                e.icerikList.push(model);
            e.notaPdfId = model?.id ?? null;
        });
    }

    return <Rows gap={10} style={{ minHeight: 350, maxWidth: 650 }}>
        <Typography style={{ fontWeight: "bold", alignSelf: "center", marginLeft: 5, marginBottom: 10 }}>Temel Bilgiler</Typography>
        <Columns wrap gap={10}>
            <FormControl style={{ width: 295 }}>
                <CsmTextField
                    label="Şarkı İsmi"
                    error={isimHata !== undefined}
                    helperText={isimHata}
                    value={oneriSarkiModel.isim}
                    onChange={(e) => sarkiOneriBilgiChanged(t => t.isim = e)} />
            </FormControl>
        </Columns>
        <Columns wrap gap={10}>
            <FormControl style={{ width: 295 }}>
                <CsmTextField
                    label="Besteci"
                    value={oneriSarkiModel.besteci}
                    onChange={(e) => sarkiOneriBilgiChanged(t => t.besteci = e)} />
            </FormControl>
            <FormControl style={{ width: 295 }}>
                <VatanIcerikUploadField
                    fullWidth={false}
                    label="PDF"
                    showClearButton
                    onChange={handlePdfIcerikUpdate}
                    genelDurumHelper={genelDurumHelper}
                    icerikId={oneriSarkiModel.notaPdfId}
                    icerikModel={oneriSarkiModel.icerikList.singleOrDefault(e => e.id === oneriSarkiModel.notaPdfId)}
                    icerikTip={EnmIcerikTip.PDF}
                />
            </FormControl>

            <FormControl style={{ width: 595 }}>
                <CsmTextField
                    label="Nota Url"
                    value={oneriSarkiModel.notaUrl}
                    onChange={(e) => sarkiOneriBilgiChanged(t => t.notaUrl = e)} />
            </FormControl>
            <FormControl style={{ width: 595 }}>
                <CsmTextField
                    label="Video Kaydı URL"
                    value={oneriSarkiModel.performansUrl}
                    onChange={(e) => sarkiOneriBilgiChanged(t => t.performansUrl = e)} />
            </FormControl>
            <FormControl style={{ width: 595 }}>
                <CsmTextField
                    multiline
                    rows={5}
                    showStats
                    label="Açıklama"
                    value={oneriSarkiModel.onerenAciklama}
                    onChange={(e) => sarkiOneriBilgiChanged(t => t.onerenAciklama = e)} />
            </FormControl>
        </Columns>
        <Columns mt={10}>
            <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {translate("İptal")}</Button>
            <Button color="success" variant="contained" disabled={StringHelper.isNotEmpty(isimHata)} onClick={() => sarkiKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {translate("Kaydet")}</Button>
        </Columns>
    </Rows>
        ;

}

export default OneriSarkiEklePopupEditor;