import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, FormControl, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import { KonserModel } from "../../../models/businessModels/KonserModel";
import KonserPopupEditor from "./KonserPopupEditor";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { KonserModelWithContentRequestModel } from "../../../models/requestModels/KonserModelWithContentRequestModel";
import JsonHelper from "../../../library/helpers/JsonHelper";
import Columns from "../../../library/components/layout/Columns";
import KorocanImage from "../../../components/business/KorocanImage";
import Cell from "../../../library/components/layout/Cell";
import appColors from "../../../app/appColors";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import StringHelper from "../../../library/helpers/StringHelper";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";
import { GuidRequestModel } from "../../../models/requestModels/GuidRequestModel";

function KonserYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KonserYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

const boldSpan = (content: string) => <span style={{ fontWeight: "bold" }}>{content}</span>

function KonserYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [showKadro, setShowKadro] = useState<boolean>(false);

  const tumKoristHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e));

  /* -- END OF STANDARD -- */

  const konserRowGetir = (konserList: KonserModel[]): JSX.Element[] | null => {
    if (konserList.length === 0)
      return null;

    return konserList.map(konser => {
      const kadro = genelDurumHelper.SeciliKoroModel.tumKadrolar.single(e => e.id === konser.kadroModelId);

      const koristler = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, kadro.id));
      const koristCount = koristler.length;

      const eserSayi = konser.konserSarkiModelList.length;
      const videoSayi = konser.konserSarkiModelList.where(e => StringHelper.isNotEmpty(e.videoUrl)).length;
      const videolarTam = eserSayi === videoSayi;

      const altBilgi_Kadro = `${kadro.isim} (${koristCount} korist)`;
      const altBilgi_Yer = `${konser.yer}`;
      const altBilgi_Sehir = `${konser.sehirUlke}`;
      const altBilgi_Tarih = `${DateTimeToStringHelper.toMonthDayAndDayOfWeekString(konser.tarih, genelDurumHelper.translate)}`;
      const altBilgi_Eserler = `${eserSayi.toFixed()} eser`;
      const altBilgi_OrtakKoro = StringHelper.isNotEmpty(konser.ortakKoro) ? `Ortak Koro: ${konser.ortakKoro}` : "";

      return (
        <Columns hasLightBottomBorder width={showKadro ? "100%" : 750} key={konser.id} ml={10} mb={10}>
          <Rows width={115} pr={5} mb={5} mr={5} hasVeryLightRightBorder>
            <Cell height={100} style={{ margin: "5px", placeItems: "center" }}>
              <KorocanImage
                title={`${konser.isim} Afişi`}
                icerikId={konser.resimIcerikId}
                genelDurumHelper={genelDurumHelper}
                style={{ maxHeight: "100px", maxWidth: "100px" }} />
            </Cell>

            <Columns>
              <Button variant="text" color="error" style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => konserSil(konser)}>
                {appIcons.CrudIcons.sil}</Button>
              <Button variant="text" color="primary" style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => konserGuncelle(konser)}>
                {appIcons.CrudIcons.guncelle}</Button>
              <Button variant="text" color="primary" style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => konserKopyala(konser)}>
                {appIcons.CrudIcons.kopyala}</Button>
            </Columns>
          </Rows>
          <Rows width={380}>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }}>{konser.isim}</CsmText>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }} note>{boldSpan("Kadro: ")}{altBilgi_Kadro}</CsmText>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }} note>{boldSpan("Yer: ")}{altBilgi_Yer}</CsmText>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }} note>{boldSpan("Şehir: ")}{altBilgi_Sehir}</CsmText>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }} note>{boldSpan("Tarih: ")}{altBilgi_Tarih}</CsmText>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }} note>{boldSpan("Eserler: ")}{altBilgi_Eserler}
              <span style={{ marginLeft: "5px", padding: "3px", borderRadius: "5px", background: eserSayi === 0 ? appColors.LIGHTRED : videolarTam ? appColors.LIGHTGREEN : appColors.LIGHTYELLOW }}>
                {eserSayi === 0 ? '(eserler eksik)' : videolarTam ? '(Videolar tam)' : videoSayi === 0 ? '(Videoların hepsi eksik)' : `${eserSayi - videoSayi} video eksik`}
              </span>
            </CsmText>
            <CsmText style={{ alignSelf: "flex-start", marginBottom: "3px" }} italic note>{altBilgi_OrtakKoro}</CsmText>
          </Rows>
          {showKadro && <SesGrupluKisiAvatarListControl
            darkSeperator
            tumHesaplar={tumKoristHesaplar}
            gosterilecekHesaplar={tumKoristHesaplar.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, konser.kadroModelId))}
            genelDurumHelper={genelDurumHelper} />}
        </Columns>
      );
    });
  }

  const konserEkle = async () => {
    const konserModel = new KonserModel();
    konserModel.id = GuidHelper.NewGuid();
    konserModel.isim = "";
    konserModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    konserModel.sezonModelId = "";
    konserModel.kadroModelId = "";
    konserModel.yer = "";
    konserModel.sehirUlke = "";
    konserModel.tarih = DateTimeHelper.today();
    konserModel.resimIcerikId = null;
    konserModel.konserSarkiModelList = [];
    konserModel.ortakKoro = "";
    konserModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    konserModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<KonserModelWithContentRequestModel>("Konser Ekle", (closer) => (
      <KonserPopupEditor
        konserModel={konserModel}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const konserler = seciliKoro.tumKonserler;
        konserler.push(response!.model);
      });
  }

  const konserSil = async (konserModel: KonserModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", `${konserModel.isim} isimli konseri silmek istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.YonetimKonser.Sil(new GuidRequestModel(konserModel.id));
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const konserler = seciliKoro.tumKonserler;
        konserler.remove(konserler.single(e => e.id === konserModel.id));
      });
  }

  const konserGuncelle = async (grupModel: KonserModel): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<KonserModelWithContentRequestModel>("Konser Güncelle", (closer) => (
      <KonserPopupEditor
        konserModel={grupModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const konserler = seciliKoro.tumKonserler;
        konserler.remove(konserler.single(e => e.id === response!.model.id));
        konserler.push(response!.model);
      });
  }

  const konserKopyala = async (original: KonserModel) => {
    const konserModel = new KonserModel();

    konserModel.id = GuidHelper.NewGuid();
    konserModel.isim = original.isim + " Kopyası";
    konserModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    konserModel.sezonModelId = original.sezonModelId;
    konserModel.kadroModelId = original.kadroModelId;
    konserModel.yer = original.yer;
    konserModel.sehirUlke = original.sehirUlke;
    konserModel.tarih = original.tarih
    konserModel.resimIcerikId = null;
    konserModel.konserSarkiModelList = JsonHelper.deepCopy(original.konserSarkiModelList);
    konserModel.ortakKoro = original.ortakKoro
    konserModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    konserModel.olusturmaTarih = new Date();

    konserModel.konserSarkiModelList.forEach(item => {
      item.id = GuidHelper.NewGuid();
      item.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
      item.olusturmaTarih = new Date();
    });

    const response = await AlertHelper.ShowClosableContent<KonserModelWithContentRequestModel>("Konser Ekle", (closer) => (
      <KonserPopupEditor
        konserModel={konserModel}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const konserler = seciliKoro.tumKonserler;
        konserler.push(response!.model);
      });
  }

  const tumKonserler = genelDurumHelper.SeciliKoroModel.tumKonserler.orderByTurkish(e => e.isim);
  const konserSezonIdList = tumKonserler.select(e => e.sezonModelId);

  const sezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar.where(e => konserSezonIdList.contains(e.id)).orderByDesc(e => e.isim);

  function sezonAciklamaGetir(sezon: SezonModel): string {
    const konserler = tumKonserler.where(e => e.sezonModelId === sezon.id);

    const adet = konserler.length;
    const eserEksikAdet = konserler.where(e => e.konserSarkiModelList.length === 0).length;
    const videoEksikAdet = konserler.where(e => e.konserSarkiModelList.length > 0 && e.konserSarkiModelList.any(t => StringHelper.isEmpty(t.videoUrl))).length;

    const hataMesajlar: string[] = [];

    if (eserEksikAdet > 0)
      hataMesajlar.push(`${eserEksikAdet} konserin eser kaydı yok`);

    if (videoEksikAdet > 0)
      hataMesajlar.push(`${videoEksikAdet} konserin videosu eksik`);


    if (hataMesajlar.length > 0)
      return `${sezon.isim} Konserleri - ${adet} konser (${hataMesajlar.join(" - ")})`;
    else
      return `${sezon.isim} Konserleri - ${adet} konser`;
  }

  return (
    <MainLayout
      location={mainScreenLocations.konserYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns>
          <Cell style={{ margin: "5px" }}>
            <Button variant="outlined" onClick={() => konserEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography>Yeni Konser Ekle</Typography>
            </Button>
          </Cell>
          <Cell width={450}>
            <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "5px" }}>
              <Columns>
                <Switch checked={showKadro} onChange={(e) => setShowKadro(e.target.checked)} />
                <Typography sx={{ marginTop: "5px" }}>{showKadro ? "Kadro Üyelerini Göster" : "Kadro Üyelerini Gösterme"}</Typography>
              </Columns>
            </FormControl>
          </Cell>
        </Columns>

        {sezonlar.map(sezon =>
          <CsmAccordionSimple key={sezon.id} title={sezonAciklamaGetir(sezon)}>
            <Rows key={sezon.id}>
              <Columns wrap>
                {konserRowGetir(tumKonserler.where(e => e.sezonModelId === sezon.id).orderBy(e => e.tarih))}
              </Columns>
            </Rows>
          </CsmAccordionSimple>
        )}

      </Rows>
    </MainLayout>
  );
}

export default KonserYonetimScreen;