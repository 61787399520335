// AutoGenerated
import { KadroVeSesGrubuBilgiModel } from "../businessModels/KadroVeSesGrubuBilgiModel"
import { KullaniciModel } from "../businessModels/KullaniciModel"

export class HesapBilgiVeKullaniciModel {
    kullaniciModel!: KullaniciModel;
    kullaniciModelId!: string;
    aktif!: boolean;
    araVerdi!: boolean;
    ozgecmis!: string | null;
    resimIcerikId!: string | null;
    kadroVeSesGrubuBilgiModelList!: KadroVeSesGrubuBilgiModel[];
    sesGrupIdList!: string[];
    rolIdList!: string[];
    superAdmin!: boolean;
    koroModelId!: string;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}