import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Rows from "../../library/components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { CsmText } from "../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { List } from "@mui/material";
import Cell from "../../library/components/layout/Cell";
import { mainScreenLocations } from "../../app/mainScreens";
import CsmAccordionSimple from "../../library/components/mui/containers/CsmAccordionSimple";
import Columns from "../../library/components/layout/Columns";
import appColors from "../../app/appColors";
import CsmTextField from "../../library/components/mui/common/CsmTextField";
import { GenerateKoristRaporData, IGrupMainData, IKullaniciData, IKullaniciSezonData, ISesGrubuMainData } from "./GenelKoristRaporCalculator";
import { SezonModel } from "../../models/businessModels/SezonModel";
import { GetColorForPercent } from "./cizelge/CizelgeTable";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../helpers/TranslationHelper";

function KoristRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  const allData = GenerateKoristRaporData(genelDurumHelper);

  return <KoristRaporScreenInner allData={allData} genelDurumHelper={genelDurumHelper} />
}

function KoristRaporScreenInner(props: IMainScreenProps & { allData: IGrupMainData[] }) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const koroAyarlari = genelDurumHelper.SeciliKoroModel.koroAyarlari;
  
  const [searchText, setSearchText] = useState<string>("");

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
  }
  
  const arananKullaniciMiStyle = (searchedText: string): CSSProperties | undefined => {
    if (searchText.length < 3)
      return undefined;

    return (includesSearchText(searchedText)) ? { background: appColors.LIGHTYELLOW } : undefined;
  }

  const font11Cell: CSSProperties = { fontSize: "11px" };
  const font14Cell: CSSProperties = { fontSize: "14px" };
  const flexCenteredCell: CSSProperties = { display: "flex", alignItems: "center", placeContent: "center" };
  const boldBigCell: CSSProperties = { fontSize: "14px", textAlign: "center", fontWeight: "bold" };
  const cellWidth: CSSProperties = { width: "89px" };
  const cellWidth2: CSSProperties = { width: "179px" };
  const headerCell: CSSProperties = { fontWeight: "bold" };

  const bottomLight: CSSProperties = { borderBottomColor: appColors.LIGHTGRAY, borderBottomStyle: "solid", borderBottomWidth: "1px" };
  const rightLight: CSSProperties = { borderRightColor: appColors.LIGHTGRAY, borderRightStyle: "solid", borderRightWidth: "1px" };
  const bottomBold: CSSProperties = { borderBottomColor: appColors.TEXT_PRIMARY, borderBottomStyle: "solid", borderBottomWidth: "1px" };
  const rightBold: CSSProperties = { borderRightColor: appColors.TEXT_PRIMARY, borderRightStyle: "solid", borderRightWidth: "1px" };

  function oranBackRenkGetir(oran: number): React.CSSProperties | undefined {
    return { background: GetColorForPercent(oran, koroAyarlari) }
  }

  // Single Columns

  function renderEmptyRowSezonColumn(sezonId: string) {
    return <Columns key={sezonId}>
      <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomLight, ...rightLight }}></div>
      <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomLight, ...rightBold }}></div>
    </Columns>;
  }

  function renderKullaniciSezonColumn(amac: string, userSezonData: IKullaniciSezonData): React.ReactNode {
    const myKey = amac + ":" + userSezonData.sezonId + ":" + userSezonData.userId;

    if (!userSezonData.calismaOranNormal || userSezonData.calismaOranNormal === 0)
      return <Columns key={myKey}>
        <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomLight, ...rightLight }}></div>
        <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomLight, ...rightBold }}></div>
      </Columns>;

    // 0.77, altta sadece geç basıldı ekrana
    const calismaText = userSezonData.calismaToplam === 0 ? `%${userSezonData.calismaOranGecKalmali}` :
      userSezonData.calismaGecKaldigi > 0 ?
        `%${userSezonData.calismaOranGecKalmali} - ${userSezonData.calismaGeldigi}(+${userSezonData.calismaGecKaldigi})/${userSezonData.calismaToplam}`
        :
        `%${userSezonData.calismaOranGecKalmali} - ${userSezonData.calismaGeldigi}/${userSezonData.calismaToplam}`;

    const sarkiText = userSezonData.sarkiToplam === 0 ? `%${userSezonData.sarkiOran}` :
      `%${userSezonData.sarkiOran} (${userSezonData.sarkiBasarili}/${userSezonData.sarkiToplam})`;

    // 0.77, altta sadece geç basıldı ekrana
    return <Columns key={myKey}>
      <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomLight, ...rightLight, ...oranBackRenkGetir(userSezonData.calismaOranGecKalmali / 100) }}>{calismaText}</div>
      <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomLight, ...rightBold, ...oranBackRenkGetir(userSezonData.sarkiOran / 100) }}>{sarkiText}</div>
    </Columns>
  }

  // Single Row

  function renderSezonHeadersRow(sezonList: SezonModel[]): React.ReactNode {
    return <Columns>
      <div style={{ ...boldBigCell, ...cellWidth2, ...bottomBold, ...rightBold, ...headerCell }}></div>
      {[...sezonList, null].map(sezon => <Rows key={sezon?.id ?? "ilk"}>
        <CsmText style={{ ...boldBigCell, ...cellWidth2, ...bottomBold, ...rightBold }}>{sezon?.isim ?? "Genel"}</CsmText>
        <Columns>
          <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomBold, ...rightLight, ...headerCell }}>Çalışma</div>
          <div style={{ ...flexCenteredCell, ...font11Cell, ...cellWidth, ...bottomBold, ...rightBold, ...headerCell }}>Şarkı</div>
        </Columns>
      </Rows>)}
    </Columns>
  }

  function renderEmptyHeaderRow(sesGrupIsim: string, sezonList: SezonModel[]): React.ReactNode {
    const isSeperator = sesGrupIsim !== "";
    return <Columns height={isSeperator ? 30 : undefined} style={{ background: isSeperator ? appColors.GRAY_LIGHT4 : undefined }}>
      <CsmText style={{ ...cellWidth2, ...bottomBold, ...rightBold, ...headerCell }}>{sesGrupIsim}</CsmText>
      {sezonList.map(sezon => renderEmptyRowSezonColumn(sezon.id))}
      {renderEmptyRowSezonColumn("ilk")}
    </Columns>;
  }

  function sesGrupOrtalamaRow(sezonOrtalama: IKullaniciSezonData[], genelOrtalama: IKullaniciSezonData): React.ReactNode {
    return <Columns>
      <CsmText style={{ ...font14Cell, ...cellWidth2, ...bottomLight, ...rightBold, ...headerCell, }}>Ortalama</CsmText>
      {sezonOrtalama.map(sezon => renderKullaniciSezonColumn("sesGrupOrtalama", sezon))}
      {renderKullaniciSezonColumn("sesGrupGenelOrtalama", genelOrtalama)}
    </Columns>
  }

  function renderKullaniciRow(kullaniciData: IKullaniciData): React.ReactNode {
    return <Columns key={kullaniciData.kullanici} style={arananKullaniciMiStyle(kullaniciData.kullanici)}>
      <CsmText style={{ ...font14Cell, ...cellWidth2, ...bottomLight, ...rightBold }}>{kullaniciData.kullanici}</CsmText>
      {kullaniciData.sezonlar.map(sezon => renderKullaniciSezonColumn("kullaniciOrtalama", sezon))}
      {renderKullaniciSezonColumn("kullaniciGenelOrtalama", kullaniciData.genelOrtalama)}
    </Columns>
  }

  // Multiple Rows

  function renderSesGrubuRows(sesGrubuData: ISesGrubuMainData, sezonList: SezonModel[]): React.ReactNode {
    return <Rows key={sesGrubuData.sesGrupIsim}>
      {renderEmptyHeaderRow(sesGrubuData.sesGrupIsim, sezonList)}
      {sesGrubuData.data.map(sesGrubuList => renderKullaniciRow(sesGrubuList))}
      {sesGrupOrtalamaRow(sesGrubuData.sezonOrtalama, sesGrubuData.genelOrtalama)}
    </Rows>
  }

  // Main Result

  return (
    <MainLayout
      location={mainScreenLocations.genelKoristRapor}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <Columns>
          <CsmText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Arama")}: </CsmText>
          <Cell ml={10} mt={5}>
            <CsmTextField narrow value={searchText} onChange={(e) => setSearchText(e)} label={genelDurumHelper.translate("Arama Anahtarı")} eventOnKeyPress />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        {props.allData.map(kadroData =>
          <CsmAccordionSimple
            key={kadroData.kadroIsim}
            title={kadroData.kadroIsim}
            alwaysOpen={kadroData.kadroIsim !== "Eski Koristler"}>
            <Rows ml={10}>
              {renderSezonHeadersRow(kadroData.sezonList)}
              {kadroData.data.map(kullaniciData => renderSesGrubuRows(kullaniciData, kadroData.sezonList))}
            </Rows>
          </CsmAccordionSimple>
        )}
      </List>
    </MainLayout>
  );
}

export default KoristRaporScreen;



