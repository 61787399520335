import { useState } from "react";
import Columns from "../../../library/components/layout/Columns";
import Rows from "../../../library/components/layout/Rows";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button, Typography, FormControl, Switch, Box } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import CsmTextField from "../../../library/components/mui/common/CsmTextField";
import JsonHelper from "../../../library/helpers/JsonHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import { CsmText } from "../../../library/components/mui/CsmText";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import PdfDisplayer from "../../../components/media/PdfDisplayer";
import RegularAudioPlayer from "../../../components/media/RegularAudioPlayer";
import appColors from "../../../app/appColors";
import Cell from "../../../library/components/layout/Cell";
import { HesapKayitYorumRequestModel } from "../../../models/requestModels/HesapKayitYorumRequestModel";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import KorocanThumbnailForHesap from "../../../components/business/KorocanThumbnailForHesap";
import StringHelper from "../../../library/helpers/StringHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { EnmYetki } from "../../../models/enums/EnmYetki";

interface IKayitWithPdfPopupEditorProps {
    sarki: SarkiModel,
    yorumKaydedebilir: boolean,
    hesapBilgi: HesapBilgiVeKullaniciModel,
    hesapSarkiSesKayit: HesapSarkiSesKayitModel,
    closer: (model: HesapSarkiSesKayitModel | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function KayitWithPdfPopupEditor(props: IKayitWithPdfPopupEditorProps) {
    const [yorumlar, setYorumlar] = useState<string>(StringHelper.nullToEmpty(props.hesapSarkiSesKayit.yorumlar));
    const [kayitUygun, setKayitUygun] = useState<boolean>(props.hesapSarkiSesKayit.kayitDurum === EnmKayitDurum.Onaylandı);

    const kayitSonDurum = !props.yorumKaydedebilir ? props.hesapSarkiSesKayit.kayitDurum : (kayitUygun ? EnmKayitDurum.Onaylandı : EnmKayitDurum.Hatalı)

    const adSoyad = `${props.hesapBilgi.kullaniciModel.ad} ${props.hesapBilgi.kullaniciModel.soyad}`;

    const guncellemeYetki = props.genelDurumHelper.HasYetki(EnmYetki["Kayıt Onaylama"]);

    const { pdfVariMi, eskiPdfMi, pdfIcerikId } = BusinessHelper.Icerik.SarkiLinktenPdfLinkGetir(props.sarki?.sarkiLinkModelList);

    const yorumKaydet = async () => {
        const model = new WithContentRequestModel<HesapKayitYorumRequestModel>(
            new HesapKayitYorumRequestModel(props.hesapSarkiSesKayit.id, kayitUygun, yorumlar, null),
            []
        );

        const webResponse = await props.genelDurumHelper.requestHelper.YonetimHesapMuzikal.HesapKayitYorumla(model);
        if (!webResponse.successfull)
            return;

        const hesapSarkiSesKayit = JsonHelper.deepCopy(props.hesapSarkiSesKayit);
        hesapSarkiSesKayit.kayitDurum = kayitUygun ? EnmKayitDurum.Onaylandı : EnmKayitDurum.Hatalı;
        hesapSarkiSesKayit.yorumlar = yorumlar;
        props.closer(hesapSarkiSesKayit);
    }

    const yorumBox = props.yorumKaydedebilir ?
        <CsmTextField
            contentEditable={props.yorumKaydedebilir}
            multiline
            disabled={!guncellemeYetki}
            error={guncellemeYetki && !yorumlar}
            rows={4}
            label="Yorumlar"
            value={yorumlar}
            showStats
            eventOnKeyPress
            onChange={(e) => setYorumlar(() => e)} />
        :
        <div style={{ overflowY: "auto" }}>
            <CsmText>YORUMLAR: {yorumlar}</CsmText>
        </div>;

    const bilgBoxRenk = kayitSonDurum === EnmKayitDurum.Onaylandı ? appColors.LIGHTGREEN : kayitSonDurum === EnmKayitDurum["Yorum Bekleniyor"] ? appColors.LIGHTYELLOW : appColors.LIGHTRED;
    const textRenk = kayitSonDurum === EnmKayitDurum.Onaylandı ? appColors.GREEN_MAIN : kayitSonDurum === EnmKayitDurum["Yorum Bekleniyor"] ? appColors.YELLOW : appColors.RED_MAIN;
    const textIcerik = kayitSonDurum === EnmKayitDurum.Onaylandı ? "Kayıt Başarılı" : kayitSonDurum === EnmKayitDurum["Yorum Bekleniyor"] ? "Yorum Bekliyor" : "Kayıt Hatalı";

    const bilgiBox = <Rows width={250} mb={5} p={5} style={{ background: bilgBoxRenk, borderRadius: 15 }}>
        <Cell>
            <CsmText bold style={{ textAlign: "center" }}>{props.sarki.isim}</CsmText>
        </Cell>
        <Columns>
            <KorocanThumbnailForHesap style={{ marginLeft: "5px" }} hesap={props.hesapBilgi} size="normal" genelDurumHelper={props.genelDurumHelper} />
            <Rows>
                <CsmText note style={{ marginLeft: "5px" }}>{props.genelDurumHelper.SesGruplariniGetir([props.hesapSarkiSesKayit.sesGrupId]).singleOrDefault()?.isim ?? "Belirsiz"}</CsmText>
                <CsmText note style={{ marginLeft: "5px" }}>{adSoyad}</CsmText>
                {props.yorumKaydedebilir ?
                    <FormControl style={{ marginRight: 5, display: "flex", flexDirection: "row", placeSelf: "center" }}>
                        <Switch checked={kayitUygun} onChange={(e) => setKayitUygun(e.target.checked)} disabled={!guncellemeYetki} />
                        <Typography sx={{ marginTop: "8px", color: textRenk }}>{kayitUygun ? "Başarılı" : "Hatalı"}</Typography>
                    </FormControl>
                    :
                    <Typography sx={{ marginTop: "8px", marginLeft: "5px", placeSelf: "center" }}>Durum: {textIcerik}</Typography>
                }
            </Rows>
        </Columns>
    </Rows>;

    return <Box height="100%" sx={{ minWidth: { xs: 300, md: 850, lg: 1024, xl: 1450 } }}>
        <Rows height="100%">
            <Columns height={140}>
                {bilgiBox}
                <FormControl style={{ flex: 1, marginLeft: 5, marginTop: 5, marginBottom: 5 }}>
                    {yorumBox}
                </FormControl>
                <Box sx={{ display: { xs: 'none', md: 'none', lg: 'flex', xl: 'flex', }, flex: 1, padding: "5px" }}>
                    <RegularAudioPlayer genelDurumHelper={props.genelDurumHelper} icerikId={props.hesapSarkiSesKayit.sesIcerikId} title={`${adSoyad} - ${props.sarki.isim} Kaydı`} />
                </Box>
            </Columns>
            <Box sx={{ display: { xs: 'block', md: 'block', lg: 'none', xl: 'none', } }}>
                <Columns mb={10}>
                    <RegularAudioPlayer genelDurumHelper={props.genelDurumHelper} icerikId={props.hesapSarkiSesKayit.sesIcerikId} title={`${adSoyad} - ${props.sarki.isim} Kaydı`} />
                </Columns>
            </Box>
            <Cell flex style={{ display: "flex", justifyContent: "center", overflowY: "hidden" }}>
                {!pdfVariMi ? <CsmText>PDF Bulunmamaktadır</CsmText> : <PdfDisplayer genelDurumHelper={props.genelDurumHelper} icerikId={pdfIcerikId} />}
            </Cell>
            {props.yorumKaydedebilir ?
                <Columns mt={10}>
                    <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                        {appIcons.Popup.iptal}
                        {props.genelDurumHelper.translate("İptal")}</Button>
                    <Button color="success" variant="contained" disabled={!guncellemeYetki || !yorumlar} onClick={() => yorumKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                        {appIcons.Popup.tamam}
                        {props.genelDurumHelper.translate("Kaydet")}</Button>
                </Columns>
                :
                <Columns mt={10}>
                    <Button color="info" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                        Tamam</Button>
                </Columns>
            }
        </Rows>
    </Box>
}

export default KayitWithPdfPopupEditor;