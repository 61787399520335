/* eslint-disable react/no-children-prop */
// cihan
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { List } from "@mui/material";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import Cell from "../../../library/components/layout/Cell";
import AlertHelper from "../../../library/helpers/AlertHelper";
import KuartetSarkiPopupEditor from "./KuartetSarkiPopupEditor";
import { HesapKuartetModel } from "../../../models/businessModels/HesapKuartetModel";
import { appIcons } from "../../../app/appIcons";
import { mainScreenLocations } from "../../../app/mainScreens";
import CsmAccordionSimple from "../../../library/components/mui/containers/CsmAccordionSimple";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../helpers/TranslationHelper";

function KuartetYonetimScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KuartetYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function KuartetYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const sarkiKayitlariniGoster = async (sarki: SarkiModel) => {
    const response = await AlertHelper.ShowClosableContent<HesapKuartetModel[]>(`${sarki.isim} eseri için Kuartet Doldur`, (closer) => (
      <KuartetSarkiPopupEditor
        sarki={sarki}
        hesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList}
        kayitlar={genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList}
        kuartetler={genelDurumHelper.SeciliKoroModel.hesapKuartetModelList}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        seciliKoro.hesapKuartetModelList = response!;
      });
  }

  const sarkiRowGetir = (sarkiList: SarkiModel[], siraNoEkle: boolean): JSX.Element[] | null => {
    if (sarkiList.length === 0)
      return null;

    return sarkiList.map((sarki) => {

      const uygunKullanicilar: string[] = [];
      const yorumBekleyenKullanicilar: string[] = [];
      const hataliKullanicilar: string[] = [];
      const gondermeyenKullanicilar: string[] = [];


      const kullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif === true && BusinessHelper.Genel.KoristMi(e));

      kullanicilar.forEach((hesap) => {

        const hesapSarkiSesKayitModelList = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList
        const kayitlar = hesapSarkiSesKayitModelList.where(e => e.hesapModelId === hesap.id && e.sarkiModelId === sarki.id);

        const sonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar);

        if (sonDurum == null)
          gondermeyenKullanicilar.push(hesap.id);
        else if (sonDurum === EnmKayitDurum["Yorum Bekleniyor"])
          yorumBekleyenKullanicilar.push(hesap.id);
        else if (sonDurum === EnmKayitDurum["Onaylandı"])
          uygunKullanicilar.push(hesap.id);
        else
          hataliKullanicilar.push(hesap.id);
      });

      const alakaliKuartetler = genelDurumHelper.SeciliKoroModel.hesapKuartetModelList.where(e => e.sarkiModelId === sarki.id && kullanicilar.any(t => t.id === e.hesapModelId));
      const basariliKuartetler = alakaliKuartetler.where(e => e.kuartetBasarili === true);
      const basarisizKuartetler = alakaliKuartetler.where(e => e.kuartetBasarili === false);

      const kuartetDurum = `Başarılı: ${basariliKuartetler.length}, Başarısız: ${basarisizKuartetler.length}, Yapmayan: ${kullanicilar.length - basariliKuartetler.length - basarisizKuartetler.length}`;

      const rightButtons: ICustomSwipeButton[] = [];
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => sarkiKayitlariniGoster(sarki) });

      return (
        <CsmSwipableListItem hasLightBottomBorder width={490} leftButtons={rightButtons} key={sarki.id}>
          <Rows>
            <Cell>
              <CsmText>{siraNoEkle ? (sarki.sira + ". ") : ""}{sarki.isim}</CsmText>
            </Cell>
            <Cell>
              <CsmText note>{kuartetDurum}</CsmText>
            </Cell>
          </Rows>
        </CsmSwipableListItem>
      );
    });
  }

  const sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.orderByTurkish(e => e.isim);

  const sezonSarkilari = sarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id));
  const eskiSarkilar = sarkilar.except(sezonSarkilari).where(e => e.sezonSarkiModelList.length > 0);

  const sezondaKayitSurecindekiSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde).orderBy(e => e.sira);
  const sezondaDigerSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === false).orderBy(e => e.sira);

  const sezondaKayitSurecindekiSarkilarIcerik = sarkiRowGetir(sezondaKayitSurecindekiSarkilar, true);
  const sezondaDigerSarkilarIcerik = sarkiRowGetir(sezondaDigerSarkilar, true);
  const eskiSarkilarIcerik = sarkiRowGetir(eskiSarkilar, false);

  return (
    <MainLayout
      location={mainScreenLocations.kuartetGiris}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <CsmAccordionSimple
          title="Aktif ve Kayıt Sürecinde olan Şarkılar"
          children={sezondaKayitSurecindekiSarkilarIcerik ?? <CsmText note>Kayıt sürecide sezon şarkısı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Aktif ve Kayıt Sürecinde olmayan Şarkılar"
          children={sezondaDigerSarkilarIcerik ?? <CsmText note>Kayıt sürecide olmayan sezon şarkısı bulunmamaktadır</CsmText>}
          alwaysOpen
        />
        <CsmAccordionSimple
          title="Eski Şarkılar"
          children={() => eskiSarkilarIcerik ?? <CsmText note>Eski şarkı bulunmamaktadır</CsmText>}
          alwaysOpen={!eskiSarkilarIcerik}
        />

      </List>
    </MainLayout>
  );
}

export default KuartetYonetimScreen;